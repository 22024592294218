const coats = [
  {
    value: 'shorthair',
    enGB: 'Shorthair',
    ukUA: 'Коротка'
  },
  {
    value: 'longhair',
    enGB: 'Longhair',
    ukUA: 'Довга'
  },
  {
    value: 'semi-longhair',
    enGB: 'Semi-Longhair',
    ukUA: 'Напівдовга'
  },
  {
    value: 'hairless',
    enGB: 'Hairless',
    ukUA: 'Безшерста'
  },
  {
    value: 'rex',
    enGB: 'Rex',
    ukUA: 'Рекс'
  },
  {
    value: 'curly',
    enGB: 'Curly',
    ukUA: 'Кучерява'
  },
  {
    value: 'other',
    enGB: 'Other',
    ukUA: 'Інша'
  }
];

export default coats;
