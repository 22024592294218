import './scss/ViewEditInvoice.scss';

import { Form } from 'antd';
import ConfirmCancelModal from 'components/ConfirmCancelModal';
import ViewDetailsButtonsGroup from 'components/ViewDetailsButtonsGroup';
import VLXModal from 'components/VLXModal';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import useClinicId from 'hooks/useClinicId';
import { useUserAccess } from 'hooks/useUserAccess';
import { ILocale } from 'interfaces/locale';
import React, { Dispatch, SetStateAction, useState } from 'react';
import { useDispatch } from 'react-redux';
import { getInvoiceById, updateInvoice } from 'redux/inventory/invoice/actions';
import { IInvoiceDataItem } from 'services/interfaces/clinics/inventory/invoice/IInvoiceData';

import InvoiceForm from './components/InvoiceForm';

dayjs.extend(customParseFormat);

type Props = React.PropsWithChildren<{
  invoiceId: number;
  locale: ILocale;
  selectedInvoice: IInvoiceDataItem;
  showEditInvoiceModal: boolean;
  setShowEditInvoiceModal: Dispatch<SetStateAction<boolean>>;
}>;

const EditInvoiceModal = ({
  locale,
  invoiceId,
  selectedInvoice,
  setShowEditInvoiceModal,
  showEditInvoiceModal
}: Props): JSX.Element => {
  const clinicId = useClinicId();
  const dispatch = useDispatch();
  const userAccess = useUserAccess();
  const [form] = Form.useForm();

  const [editMode, setEditMode] = useState<boolean>(true);
  const [showCancelModal, setShowCancelModal] = useState<boolean>(false);

  const onSubmit = (payload: any): void => {
    payload.purchase_date = payload.purchase_date
      ? dayjs(payload.purchase_date).format('DD.MM.YYYY')
      : dayjs().format('DD.MM.YYYY');

    if (form.isFieldsTouched()) dispatch(updateInvoice(clinicId, selectedInvoice?.id, payload));
    setShowEditInvoiceModal(false);
  };

  const onConfirmCancelClick = (): void => {
    form.resetFields();
    dispatch(getInvoiceById(clinicId, invoiceId));
    setShowCancelModal(false);
    setShowEditInvoiceModal(false);
  };

  const onFieldsChange = (e: any): void => {
    if (e.length < 2 && !editMode) {
      setEditMode(true);
    }
  };

  const getInitialValues = () => {
    const data = { ...selectedInvoice };
    data.purchase_date = dayjs(selectedInvoice?.purchase_date);
    data.provider_id = data?.provider?.id;
    return data;
  };

  return (
    <>
      <VLXModal
        title={locale.labels.editInvoice}
        centered
        form={form}
        open={showEditInvoiceModal}
        onModalClose={() => {
          setShowEditInvoiceModal(false);
        }}
        width={800}
        hideDeleteBtn
        initialValues={getInitialValues()}
      >
        <InvoiceForm
          onFieldsChange={onFieldsChange}
          onFinish={onSubmit}
          form={form}
          initialValues={getInitialValues()}
        >
          <ViewDetailsButtonsGroup
            fixed
            hideDeleteBtn
            accessGroup={userAccess.accesses.invoices}
            editMode={editMode}
            onCancelClick={() => {
              if (form.isFieldsTouched()) {
                setShowCancelModal(true);
              } else {
                setShowEditInvoiceModal(false);
              }
            }}
            onEditClick={() => setEditMode(true)}
          />
        </InvoiceForm>
      </VLXModal>
      <ConfirmCancelModal
        open={showCancelModal}
        onOk={onConfirmCancelClick}
        onCancel={() => {
          setShowCancelModal(false);
          setEditMode(true);
        }}
      />
    </>
  );
};

export default EditInvoiceModal;
