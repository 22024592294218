type Props = {
  label: string;
  value: string;
  id: string;
};

export const BillFormPrice = ({ label, value, id }: Props): JSX.Element => (
  <>
    <div
      style={{
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center'
      }}
    >
      <div
        style={{
          textAlign: 'right',
          flex: 1,
          fontWeight: '600',
          fontSize: '16px'
        }}
      >
        {label}:
      </div>
      <span
        id={id}
        style={{
          fontWeight: 600,
          fontSize: '16px',
          color: '#000',
          textAlign: 'right',
          marginLeft: '8px',
          width: '30%'
        }}
      >
        {value}
      </span>
    </div>
  </>
);
