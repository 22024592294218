import { eventsColors } from 'constants/common';
import { IUserAccesses } from 'interfaces/user-accesses';

import { ColoredClinic } from '../types';

export const getColoredClinics = (clinics: IUserAccesses[]): ColoredClinic[] =>
  clinics.map((item, i): ColoredClinic => {
    let colorIndex;
    if (eventsColors[i]) {
      colorIndex = i;
    } else {
      colorIndex = clinics.length % eventsColors.length;
    }
    return { ...item, color: eventsColors[colorIndex] };
  });
