import { Tooltip } from 'antd';
import { IClient } from 'interfaces/client';
import React from 'react';

const DEFAULT_SIZE = 14;

export function blacklistedStatus(client: IClient, size: number = DEFAULT_SIZE): JSX.Element {
  const language = localStorage.getItem('locale');

  const localizedDictionary = (locale) => {
    switch (locale) {
      case '"enGB"':
        return 'Blacklisted';
      case '"ukUA"':
        return 'У чорному списку';
    }
  };

  return (
    client?.blacklisted?.status && (
      <Tooltip
        title={`${localizedDictionary(language)}: ${client.blacklisted.reason}`}
        trigger={'hover'}
        placement='topRight'
      >
        <span
          className={'icofont-ui-block'}
          style={{
            fontSize: `${size}px`,
            marginLeft: '5px',
            color: 'red',
            opacity: '80%'
          }}
        />
      </Tooltip>
    )
  );
}
