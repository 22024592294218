import './scss/ViewEditEquipment.scss';

import { Form, Spin } from 'antd';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useBlocker, useNavigate, useParams } from 'react-router-dom';

import ConfirmCancelModal from '../../../components/ConfirmCancelModal';
import ConfirmDeleteModal from '../../../components/ConfirmDeleteModal';
import ViewDetailsButtonsGroup from '../../../components/ViewDetailsButtonsGroup';
import { deviceStatuses } from '../../../constants/dictionary/default/inventoryOptions';
import { useLocale } from '../../../hooks/useLocale';
import useLocalizedList from '../../../hooks/useLocalizedList';
import { useUserAccess } from '../../../hooks/useUserAccess';
import { IAppState } from '../../../interfaces/app-state';
import { getEquipmentById, removeEquipment, updateEquipment } from '../../../redux/inventory/equipment/actions';
import {
  EquipmentCreateUpdateRequest,
} from '../../../services/interfaces/clinics/inventory/equipment/equipment-create-update-request';
import EquipmentForm from './components/EquipmentForm';
import { deviceStatus } from './helpers/TagSelector';

dayjs.extend(customParseFormat);

const ViewEditEquipment = () => {
  const dispatch = useDispatch();
  const { clinicId, equipmentId }: any = useParams();
  const navigate = useNavigate();
  const selectedDevice = useSelector<IAppState, any>((state) => state.equipment.selectedDevice);
  const loading = useSelector<IAppState, any>((state) => state.equipment.loading);
  const userAccess = useUserAccess();
  const [editMode, setEditMode] = useState<boolean>(false);
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
  const [showCancelModal, setShowCancelModal] = useState<boolean>(false);
  const [form] = Form.useForm();
  const locale = useLocale('private.inventory.technical-equipment');

  useEffect(() => {
    dispatch(getEquipmentById(clinicId, equipmentId));
  }, []);

  const onSubmit = (data: EquipmentCreateUpdateRequest) => {
    if (form.isFieldsTouched()) dispatch(updateEquipment(clinicId, selectedDevice?.id, data));
    setEditMode(false);
  };

  function onConfirmCancelClick() {
    form.resetFields();
    dispatch(getEquipmentById(clinicId, equipmentId));
    setEditMode(false);
    setShowCancelModal(false);
    if (blocker.state === 'blocked') {
      blocker.proceed();
    }
  }

  function onConfirmDeleteClick() {
    dispatch(removeEquipment(clinicId, equipmentId, locale));
    navigate(`/clinic/${clinicId}/inventory/equipment`);
  }

  function handleDate(date) {
    return date ? dayjs(date, 'YYYY-MM-DD') : null;
  }

  function mapValues() {
    const device = {
      ...selectedDevice,
      warranty_expiration_date: handleDate(selectedDevice.warranty_expiration_date),
      next_maintenance_date: handleDate(selectedDevice.next_maintenance_date),
      purchase_date: handleDate(selectedDevice.purchase_date)
    };
    if (!selectedDevice.warranty_expiration_date) delete device.warranty_expiration_date;
    if (!selectedDevice.next_maintenance_date) delete device.next_maintenance_date;
    if (!selectedDevice.purchase_date) delete device.purchase_date;
    delete device.user_id;
    return device;
  }

  const onFieldsChange = (e: any) => {
    if (e.length < 2 && !editMode) {
      setEditMode(true);
    }
  };

  const localizedMaintenanceStatuses = useLocalizedList(deviceStatuses);

  const getDeviceStatus = (e: any) => {
    const res = localizedMaintenanceStatuses.find((el) => el.value === e);
    const tag = deviceStatus(res);
    return res ? tag : '';
  };

  const blocker = useBlocker(({ currentLocation, nextLocation }) => {
    console.log(nextLocation);
    if (nextLocation) {
      setShowCancelModal(true);
    }
    return editMode;
  });

  return (
    <Spin spinning={loading} className='stock-details-page-spin-container'>
      {!!selectedDevice && (
        <div style={{ paddingLeft: '20px' }}>
          <div className={'name-statuses-form-header'}>
            <h4 style={{ marginRight: '8px' }} id='device-name'>
              {selectedDevice.name}
            </h4>
            {getDeviceStatus(selectedDevice?.device_status)}
          </div>
          <EquipmentForm
            onFieldsChange={onFieldsChange}
            editMode={editMode}
            onFinish={onSubmit}
            form={form}
            initialValues={mapValues()}
            clinicId={clinicId}
            initialResponsible={{
              value: selectedDevice.user_id,
              label: selectedDevice.responsible_person
            }}
            initialRoom={{
              value: selectedDevice.location.id,
              label: selectedDevice.location.details
            }}
            initialLocationType={selectedDevice.location.type}
            initialLocationDetails={selectedDevice.location.details}
          >
            <ViewDetailsButtonsGroup
              accessGroup={userAccess.accesses.equipment}
              editMode={editMode}
              onCancelClick={() => {
                if (form.isFieldsTouched()) {
                  setShowDeleteModal(false);
                  setShowCancelModal(true);
                }
                setEditMode(false);
              }}
              onEditClick={() => setEditMode(true)}
              onDeleteClick={() => {
                setShowCancelModal(false);
                setShowDeleteModal(true);
              }}
            />
          </EquipmentForm>
          <ConfirmDeleteModal
            type={'device'}
            open={showDeleteModal}
            onOk={onConfirmDeleteClick}
            onCancel={() => setShowDeleteModal(false)}
          />
          <ConfirmCancelModal
            open={showCancelModal}
            onOk={onConfirmCancelClick}
            onCancel={() => setShowCancelModal(false)}
          />
        </div>
      )}
    </Spin>
  );
};

export default ViewEditEquipment;
