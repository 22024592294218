import './PerformAuditItemForm.scss';

import { Col, Form, FormInstance, Row, Tag } from 'antd';
import VLXInputNumber from 'components/VLXInputNumber';
import { AUDIT_TYPES, SAVED_STATUS } from 'constants/enums';
import { ILocale } from 'interfaces/locale';
import { useGetResidual } from 'pages/inventory/stock/utils/useGetResidual';
import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { updateAuditItem } from 'redux/inventory/audit/actions';
import { ISelectedAuditItem } from 'services/interfaces/clinics/inventory/audit/ISelectedAuditItemsData';
import { capitalize } from 'utils/capitalize';

import { SaveAction } from '../components/SaveAction';

type Props = React.PropsWithChildren<{
  onSubmit: (data) => void;
  form: FormInstance;
  selectedStock: ISelectedAuditItem;
  locale: ILocale;
  currentPage: number;
}>;

type ReassignInputType = {
  label: string | JSX.Element;
  name: [number, string];
  required?: boolean;
  onChange?: (val) => void;
};

type PriceListType = {
  id: number;
  expectedResidual: string;
  type: AUDIT_TYPES;
};

export const PerformAuditItemForm = ({
  children,
  onSubmit,
  form,
  selectedStock,
  locale,
  currentPage
}: Props): JSX.Element => {
  const dispatch = useDispatch();
  const { clinicId, auditId } = useParams();
  const { getResidual, unitOfMeasurement, packagingFormat, noPackageFormat } =
    useGetResidual(selectedStock);

  const priceResidual = selectedStock.prices?.reduce(
    (acc, el) => acc + (el.actual_residual || 0),
    0
  );
  const locationsResidual = selectedStock.locations?.reduce(
    (acc, el) => acc + (el.actual_residual || 0),
    0
  );

  const saveChanges = (key: number, type: AUDIT_TYPES): void => {
    const data = form.getFieldsValue()[type][key];
    const payload = {
      actual_quantity: data.actual_quantity || 0,
      actual_leftover: noPackageFormat ? 0 : data.actual_leftover || 0
    };

    form.setFieldValue([type, key, 'savedStatus'], SAVED_STATUS.saved);
    dispatch(updateAuditItem(+clinicId, +auditId, data.audit_item_id, payload, currentPage));
  };

  const totalResidualTag = useCallback(() => {
    if (
      selectedStock.prices.some((record) => record.actual_residual === null) ||
      selectedStock.locations.some((record) => record.actual_residual === null)
    )
      return;

    return locationsResidual !== priceResidual ? (
      <Tag color='red' className='changes-status result'>
        {locale.labels.differentResidual}
      </Tag>
    ) : (
      <Tag color='green' className='changes-status result'>
        {locale.labels.sameResidual}
      </Tag>
    );
  }, [selectedStock, priceResidual, locationsResidual]);

  const getPriceExpectedResidual = (index: number): string => {
    const { price_per_unit, price_per_package } = selectedStock.prices[index];
    const unitPrice = `${capitalize(unitOfMeasurement)}: ${price_per_unit} ${locale.labels.uah}`;
    const packagePrice = `${capitalize(packagingFormat)}: ${price_per_package} ${
      locale.labels.uah
    }`;

    return noPackageFormat ? unitPrice : `${packagePrice} / ${unitPrice}`;
  };

  const getRoomName = (key: string): string => {
    const roomName = selectedStock.locations[key].name;

    return capitalize(roomName === 'unassigned' ? locale.labels.unassigned : roomName);
  };

  const onFieldsChange = (changedValue): void => {
    const [type, key] = changedValue[0].name;

    form.setFieldValue([type, key, 'savedStatus'], SAVED_STATUS.unsaved);
  };

  const ReassignInput = useCallback(
    ({ label, name, required, onChange }: ReassignInputType) => (
      <Col lg={8} md={12} sm={12} xs={24}>
        <Form.Item label={label} name={name} rules={[{ required }]} style={{ marginBottom: 0 }}>
          <VLXInputNumber onChange={onChange} />
        </Form.Item>
      </Col>
    ),
    []
  );

  const PricesList = useCallback(
    ({ id, type, expectedResidual }: PriceListType) => (
      <>
        <Row className='residual-info'>
          {locale.labels.expectedResidual}: {expectedResidual}
        </Row>
        <Row key={id} gutter={[24, 10]}>
          <ReassignInput
            label={`${locale.labels.actualLeftover} (${
              noPackageFormat ? unitOfMeasurement : packagingFormat
            })`}
            name={[id, 'actual_quantity']}
          />
          {!noPackageFormat && (
            <ReassignInput
              label={`${locale.labels.actualLeftover} (${unitOfMeasurement})`}
              name={[id, 'actual_leftover']}
            />
          )}
          <Col
            lg={noPackageFormat ? 12 : 8}
            md={12}
            sm={24}
            xs={24}
            className={`status-column ${noPackageFormat ? 'no-package' : 'package'}`}
          >
            <Form.Item label=' ' name={[id, 'savedStatus']}>
              <SaveAction
                locale={locale}
                saveChanges={() => {
                  saveChanges(id, type);
                }}
                status={selectedStock[type][id].status}
              />
            </Form.Item>
          </Col>
        </Row>
      </>
    ),
    [selectedStock]
  );

  const AuditItemByType = useCallback(
    ({ title, type, info }) => (
      <>
        <Row>
          <h5>{title}</h5>
        </Row>
        <Form.List name={type}>
          {(fields) => (
            <>
              {fields.map(({ key, name }) => (
                <React.Fragment key={key}>
                  <Row className='title'>{info(name)}</Row>
                  <PricesList
                    id={name}
                    type={type}
                    expectedResidual={getResidual(selectedStock[type][name]?.expected_residual)}
                  />
                </React.Fragment>
              ))}
            </>
          )}
        </Form.List>
      </>
    ),
    [selectedStock]
  );

  return (
    <Form
      key={'PerformAuditItemForm'}
      onFinish={onSubmit}
      autoComplete='off'
      layout='vertical'
      onFieldsChange={onFieldsChange}
      initialValues={{
        prices: selectedStock.prices || [],
        locations: selectedStock.locations || []
      }}
      form={form}
    >
      <Row>
        {!noPackageFormat && (
          <Col span={12} xs={24} sm={12}>
            1 {packagingFormat} = {selectedStock.quantity_per_package} {unitOfMeasurement}
          </Col>
        )}
        <Col span={12} xs={24} sm={12}>
          {locale.labels.totalResidual}: {getResidual(selectedStock?.remaining_capacity)}
        </Col>
      </Row>
      <AuditItemByType
        title={locale.labels.locationsAudit}
        type={AUDIT_TYPES.locations}
        info={getRoomName}
      />
      <AuditItemByType
        title={locale.labels.priceAudit}
        type={AUDIT_TYPES.prices}
        info={getPriceExpectedResidual}
      />
      <Row style={{ justifyContent: 'end' }}>
        {locale.labels.actualLocationResidual}: {getResidual(locationsResidual)}
      </Row>
      <Row style={{ justifyContent: 'end', marginBottom: 20 }}>
        {locale.labels.actualPriceResidual}: {getResidual(priceResidual)}
      </Row>
      <Row style={{ justifyContent: 'end' }}>
        {totalResidualTag()}
        {children}
      </Row>
    </Form>
  );
};
