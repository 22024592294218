import ConfirmCancelModal from 'components/ConfirmCancelModal';
import ConfirmDeleteModal from 'components/ConfirmDeleteModal';
import ViewDetailsButtonsGroup from 'components/ViewDetailsButtonsGroup';
import VLXModal from 'components/VLXModal';
import { useUserAccess } from 'hooks/useUserAccess';
import { IAppState } from 'interfaces/app-state';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { getAccounts, removeAccount, showEditModal } from 'redux/finances/financialAccounts/actions';

import AddFinancialAccountForm from './AddFinancialAccountForm';

const EditFinancialAccount = ({ initialValues, form, locale }: any): JSX.Element => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const userAccess = useUserAccess();
  const { clinicId, financialAccountId }: any = useParams();

  const financialAccounts = useSelector(({ financialAccounts }: IAppState) => financialAccounts);

  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
  const [showCancelModal, setShowCancelModal] = useState<boolean>(false);

  const onConfirmCancelClick = () => {
    form.resetFields();
    dispatch(showEditModal(false));
    setShowCancelModal(false);
  };

  const onConfirmDeleteClick = () => {
    setShowDeleteModal(false);
    dispatch(showEditModal(false));
    dispatch(removeAccount(clinicId, financialAccountId));
    dispatch(getAccounts(clinicId, 0));
    navigate(`/clinic/${clinicId}/finances/financial-accounts`);
  };

  return (
    <>
      {initialValues && (
        <VLXModal
          title={locale.labels.editAccount}
          centered={true}
          form={form}
          open={financialAccounts.showEditModal}
          onModalClose={() => {
            dispatch(showEditModal(false));
          }}
          width={800}
        >
          {' '}
          <AddFinancialAccountForm
            locale={locale}
            form={form}
            onFieldsChange={() => null}
            initialValues={initialValues}
            formEditMode
            editMode
          >
            <ViewDetailsButtonsGroup
              fixed
              accessGroup={userAccess.accesses.services}
              editMode
              onCancelClick={() => {
                if (form.isFieldsTouched()) {
                  setShowCancelModal(true);
                } else {
                  dispatch(showEditModal(false));
                }
              }}
              onDeleteClick={() => {
                setShowCancelModal(false);
                setShowDeleteModal(true);
              }}
            />
          </AddFinancialAccountForm>
        </VLXModal>
      )}
      <ConfirmCancelModal
        open={showCancelModal}
        onOk={onConfirmCancelClick}
        onCancel={() => setShowCancelModal(false)}
      />
      <ConfirmDeleteModal
        type={'device'}
        open={showDeleteModal}
        onOk={onConfirmDeleteClick}
        onCancel={() => setShowDeleteModal(false)}
      />
    </>
  );
};

export default EditFinancialAccount;
