import './ServicesList.scss';

import { Form, Pagination, Row, Spin, Table, TableColumnProps } from 'antd';
import VLXSearch from 'components/VLXSearch';
import { money } from 'helpers/list-helpers';
import useClinicId from 'hooks/useClinicId';
import { useLocale } from 'hooks/useLocale';
import { useSearch } from 'hooks/useSearch';
import { IAppState } from 'interfaces/app-state';
import { IMetaData } from 'interfaces/metadata';
import { IService } from 'interfaces/services';
import AddService from 'layout/modals/addService/AddService';
import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { getServices, resetServices, setSelectedService } from 'redux/services/actions';

const getDuration = (duration: number, durations: Record<string, string>): string => {
  if (duration && duration > 0) {
    const minutes = duration % 60 ? (duration % 60) + durations.m : '';
    const hours =
      (duration - (duration % 60)) / 60 ? (duration - (duration % 60)) / 60 + durations.h : '';
    return hours + ' ' + minutes;
  }

  return '-';
};

const ServicesList = (): JSX.Element => {
  const dispatch = useDispatch();
  const clinicId = useClinicId();
  const navigate = useNavigate();
  const [addServiceForm] = Form.useForm();
  const locale = useLocale('private.services.services-list');
  const currencies = useLocale('private.currencies').labels;
  const durations = useLocale('private.duration').labels;

  const { handleChange, onPageChange, getResults, filterResults } = useSearch(
    {
      action: getServices,
      clinicId,
      defaultSortKey: 'name',
      defaultSortOrder: 'asc',
      customFilters: [
        {
          name: 'deleted',
          value: 'false'
        }
      ]
    });

  const loading = useSelector<IAppState, boolean>(({ services }) => services.loading);
  const data = useSelector<IAppState, IService[]>(({ services }) => services.data);
  const metadata = useSelector<IAppState, IMetaData>(({ services }) => services.metadata);

  useEffect(() => {
    dispatch(setSelectedService(null));

    return () => {
      dispatch(resetServices());
    };
  }, []);

  const redirectToServicsDetails = (record: IService) => ({
    onClick: () => {
      navigate(`/clinic/${clinicId}/services/${record.id}`);
    }
  });

  const columns: TableColumnProps<IService>[] = useMemo(
    () => [
      {
        key: 'code',
        dataIndex: 'code',
        title: locale.labels.code,
        sorter: () => null,
        render: (code) => code || '-',
        onCell: redirectToServicsDetails
      },
      {
        key: 'name',
        dataIndex: 'name',
        title: locale.labels.name,
        sorter: () => null,
        render: (name) => name,
        onCell: redirectToServicsDetails
      },
      {
        key: 'group',
        dataIndex: 'group',
        title: locale.labels.group,
        sorter: () => null,
        render: (group) => group ?? '-',
        onCell: redirectToServicsDetails
      },
      {
        key: 'duration',
        dataIndex: 'duration',
        title: locale.labels.duration,
        sorter: () => null,
        render: (duration) => getDuration(duration, durations),
        onCell: redirectToServicsDetails
      },
      {
        key: 'price',
        dataIndex: 'price',
        title: locale.labels.price,
        sorter: () => null,
        render: (price) => (price ? `${money(price)} ${currencies.uah}` : '-'),
        onCell: redirectToServicsDetails
      }
    ],
    [data]
  );

  return (
    <>
      <VLXSearch filterResults={filterResults} savedFiltersName={'servicesListFilters'}/>
      <Spin spinning={loading}>
        <Table
          pagination={false}
          className='accent-header services-list-table'
          rowKey='id'
          dataSource={data}
          columns={columns}
          onChange={handleChange}
        />
        <Row style={{ justifyContent: 'space-between', marginTop: 10, alignItems: 'center' }}>
          <Pagination
            defaultCurrent={1}
            pageSize={20}
            current={metadata.page}
            total={metadata.total}
            onChange={onPageChange}
            showSizeChanger={false}
            showLessItems
          />
          <AddService form={addServiceForm} locale={locale} callBackAfterCreate={getResults} />
        </Row>
      </Spin>
    </>
  );
};

export default ServicesList;
