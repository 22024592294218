import './PatientDatails.scss';

import { Card, Col, Collapse, Menu, MenuProps, Row, Spin, Typography } from 'antd';
import Avatar from 'components/Avatar';
import DocumentsTab from 'components/DocumentsTab';
import HealthRecordsHistory from 'components/HealthRecordsHistory';
import { animalIdUrl } from 'constants/common';
import { blacklistedStatus } from 'helpers/ViewClientHelper';
import useClinicId from 'hooks/useClinicId';
import { useLocale } from 'hooks/useLocale';
import usePatientId from 'hooks/usePatientId';
import { useUserAccess } from 'hooks/useUserAccess';
import { IAppState } from 'interfaces/app-state';
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { getClientDetails } from 'redux/clients/actions';
import {
  getPatientDetails,
  getPatientDocumentsList,
  mergePatientDocumentsList,
  setAvatar,
  setSelectedPatient,
} from 'redux/patients/actions';
import { localizedPetData } from 'utils/localized-pet-data';

import { PremiumSubscriptionTooltip } from '../../components/PremiumSubscriptionTooltip';
import ClientDetailsPetsTab from '../clients/ClientDetailsPetsTab';
import { aggressiveness } from '../clients/helpers/TagSelector';
import { getAnimalAge, getDefaultAvatar } from './helpers/PatientHelper';
import PatientVaccinationDetails from './PatientVaccinationDetails';

const PatientDetails = (): JSX.Element => {
  const dispatch = useDispatch();
  const clinicId = useClinicId();
  const patientId = usePatientId();
  const locale = useLocale('private.patients.patient-page');
  const editAccess = useUserAccess().accesses.patients.edit;
  const appModules = useUserAccess().availableModules;

  const localeCode = useSelector(({ localeCode }: IAppState) => localeCode);
  const data = useSelector(({ patients }: IAppState) => patients.selectedPatient);
  const loading = useSelector(({ patients }: IAppState) => patients.loading);
  const selectedClient = useSelector(({ clients }: IAppState) => clients.selectedClient);
  const allVaccinationData = useSelector(
    ({ patients }: IAppState) => patients.selectedPatientAllVaccinationsData
  );
  const selectedPatientDocuments = useSelector(
    ({ patients }: IAppState) => patients.selectedPatientDocuments
  );

  const [currentTab, setCurrentTab] = useState('general');
  const [changeAvatarModalVisible, setChangeAvatarModalVisible] = useState(false);

  useEffect(() => {
    return () => {
      dispatch(setSelectedPatient(null));
    };
  }, []);

  useEffect((): void => {
    if (clinicId && patientId) {
      if (appModules.documents) dispatch(getPatientDocumentsList(clinicId, patientId));
      dispatch(getPatientDetails(clinicId, patientId));
    }
  }, [clinicId, patientId]);

  useEffect(() => {
    if (clinicId && data?.primary_contact_id) {
      dispatch(getClientDetails(clinicId, data?.primary_contact_id));
    }
  }, [clinicId, data?.primary_contact_id]);

  const changeTab: MenuProps['onClick'] = (e): void => {
    setCurrentTab(e.key);
  };

  const handleSetAvatar = (data, callBackFnc): void => {
    const avatarData = new FormData();
    avatarData.append('avatar', data);
    dispatch(
      setAvatar(clinicId, patientId, avatarData, (): void => {
        setChangeAvatarModalVisible(false);
        if (callBackFnc) callBackFnc();
      })
    );
  };

  const GeneralTab = useMemo(
    (): JSX.Element =>
      data && (
        <ClientDetailsPetsTab
          accessGroup={editAccess}
          clinicId={clinicId}
          data={data}
          hidePatientTopSection
        />
      ),
    [data, allVaccinationData]
  );

  const items: MenuProps['items'] = [
    {
      label: locale.tabs.general,
      key: 'general'
    },
    {
      label: locale.tabs.vaccinations,
      key: 'vaccinations'
    },
    {
      label: (
        <PremiumSubscriptionTooltip display={!appModules.documents}>
          {locale.tabs.documents}
        </PremiumSubscriptionTooltip>
      ),
      key: 'documents',
      disabled: !appModules.documents
    },
    {
      label: locale.tabs.history,
      key: 'history'
    }
  ];

  const BreedAndSpecies = useMemo((): JSX.Element => {
    if (!data?.species) return;

    const { species, breed } = localizedPetData(data, localeCode);
    return (
      <Row align='middle' className='patient-details__species-container'>
        <Typography.Text
          id='patient-species'
          className='patient-details__species-field'
          style={{ fontSize: 16 }}
        >
          {species}
        </Typography.Text>
        {!!breed && (
          <Typography.Text id='patient-breed' style={{ fontSize: 16 }}>
            ({breed})
          </Typography.Text>
        )}
      </Row>
    );
  }, [data]);

  const setDocumentsList = (data): void => {
    dispatch(mergePatientDocumentsList(data));
  };

  return (
    <Spin spinning={!data}>
      {!!data && (
        <Row className='patient-details'>
          <Col span={24} md={10} lg={7} style={{ padding: '0 12px' }}>
            <Card className='ant-card-bordered personal-info-card with-shadow patient-info-container'>
              <Row justify='center' style={{ marginTop: 10 }}>
                <Avatar
                  editAccess={editAccess}
                  onImageChange={handleSetAvatar}
                  loading={loading}
                  src={data.avatar ? data.avatar : getDefaultAvatar(data.species)}
                  setChangeAvatarModalVisible={setChangeAvatarModalVisible}
                  changeAvatarModalVisible={changeAvatarModalVisible}
                />
              </Row>
              <Col>
                <Row align='middle' className='patient-details__name-container'>
                  <Typography id='patient-name' style={{ fontWeight: 'bold' }}>
                    {data.name}
                  </Typography>
                </Row>
                <Row align='middle' className='patient-details__species-container'>
                  <Typography.Text
                    id='patient-age'
                    className='patient-details__species-field'
                    style={{ fontSize: 16 }}
                  >
                    {getAnimalAge(locale, data.birth_date, data.death_date)}
                  </Typography.Text>
                </Row>
                {BreedAndSpecies}
                <Row
                  id='patient-aggressiveness'
                  align='middle'
                  className='patient-details__species-container'
                >
                  {!!data.aggressiveness && aggressiveness(data.aggressiveness, localeCode)}
                </Row>
                {!!data.chip_number && (
                  <Row align='middle' className='patient-details__species-container'>
                    <Typography.Text
                      id='patient-chip'
                      style={{
                        fontSize: 16,
                        marginTop: 7
                      }}
                    >
                      {locale.labels.chip}:{' '}
                      <Link className={'custom-links'} to={animalIdUrl + data.chip_number}>
                        {data.chip_number}
                      </Link>
                    </Typography.Text>
                  </Row>
                )}
                {data.private_notes && (
                  <Collapse
                    size={'small'}
                    style={{ backgroundColor: 'white', marginBottom: '10px' }}
                    items={[
                      {
                        key: 1,
                        label: (
                          <>
                            <span style={{ marginRight: '5px' }}>{locale.labels.specialNotes}</span>
                            <span
                              className='icofont-warning'
                              style={{
                                color: 'orange',
                                fontSize: '16px'
                              }}
                            ></span>
                          </>
                        ),
                        children: <p>{data?.private_notes}</p>
                      }
                    ]}
                  ></Collapse>
                )}
                <Row justify='center'>
                  <Typography.Title level={4}>{locale.labels.owner}</Typography.Title>
                </Row>
                <Row align='middle' className='m-b-10'>
                  <Col className='icon-style'>
                    <span className='icofont-user info-icon' />
                  </Col>
                  <Col>
                    <Row justify='center' style={{ alignItems: 'center' }}>
                      <Link
                        id='owner-name'
                        className={'custom-links'}
                        to={`/clinic/${clinicId}/clients/${data.primary_contact_id}`}
                        style={{ fontSize: 16 }}
                      >
                        {data.owner_name}
                      </Link>
                      {blacklistedStatus(selectedClient, 20)}
                    </Row>
                  </Col>
                </Row>

                <Row align='middle' className='m-b-10'>
                  <Col className='icon-style'>
                    <span className='icofont-ui-touch-phone info-icon' />
                  </Col>
                  <Col>
                    <Link
                      id='owner-phone'
                      to={`tel:${data.owner_country_code}${data.owner_phone_number}`}
                      className='d-flex align-baseline nowrap'
                      style={{ fontSize: 16 }}
                    >
                      {data.owner_phone_number
                        ? `+${data.owner_country_code}${data.owner_phone_number}`
                        : '-'}
                    </Link>
                  </Col>
                </Row>
                <Row align='middle' className='m-b-10 client-email-row'>
                  <Col className='icon-style'>
                    <span className='icofont-ui-message info-icon' />
                  </Col>
                  <Col id='client-email'>
                    {data.owner_email ? (
                      <Link
                        id='owner-email'
                        to={`mailto:${data.owner_email}`}
                        className='custom-links d-flex align-baseline nowrap'
                        style={{ fontSize: 16 }}
                      >
                        {data.owner_email}
                      </Link>
                    ) : (
                      '-'
                    )}
                  </Col>
                </Row>
                {data.additional_contacts?.length > 0 && (
                  <>
                    <Row justify='center'>
                      <Typography.Title level={4}>
                        {locale.labels.additionalContacts}
                      </Typography.Title>
                    </Row>
                    {JSON.parse(data.additional_contacts)?.map((contact, index) => (
                      <Row gutter={24} key={index} align='middle' style={{ paddingBottom: '10px' }}>
                        <Col span={4} className='icon-style'>
                          <span className='icofont-ui-touch-phone info-icon'></span>
                        </Col>
                        <Col span={20}>
                          <div style={{ fontSize: 15 }}>{contact?.description}</div>
                          <Link
                            id='additional_contacts_number'
                            to={`tel:${data?.owner_country_code}${contact?.phone_number}`}
                            style={{ fontSize: 15 }}
                          >
                            +{data?.owner_country_code}
                            {contact?.phone_number}
                          </Link>
                        </Col>
                      </Row>
                    ))}
                  </>
                )}
              </Col>
            </Card>
          </Col>
          <Col span={24} md={14} lg={17} style={{ padding: '0 12px' }}>
            <Card className='ant-card-bordered personal-info-card with-shadow'>
              <Menu
                onClick={changeTab}
                selectedKeys={[currentTab]}
                mode='horizontal'
                items={items}
                style={{ marginBottom: 20 }}
              />
              {currentTab === 'general' && GeneralTab}
              {currentTab === 'vaccinations' && (
                <PatientVaccinationDetails
                  locale={locale}
                  patientId={patientId}
                  clinicId={clinicId}
                />
              )}
              {currentTab === 'documents' && (
                <DocumentsTab
                  uploadDocumentLink={`/v1/clinics/${clinicId}/patients/${patientId}/documents`}
                  setDocumentsList={setDocumentsList}
                  data={selectedPatientDocuments}
                />
              )}
              {currentTab === 'history' && (
                <HealthRecordsHistory clinicId={clinicId} selectedPatientId={data.id} />
              )}
            </Card>
          </Col>
        </Row>
      )}
    </Spin>
  );
};

export default PatientDetails;
