import { IAppointment, IAppointmentsState } from '../../interfaces/appointment';
import { IBillDetails } from '../../services/clinic/bills/models/IBillDetails';
import {
  AppointmentsActions,
  GET_APPOINTMENTS,
  RESET,
  SET_APPOINTMENT,
  SET_APPOINTMENT_BILL,
  SET_APPOINTMENT_HEALTH_RECORD_INVENTORY,
  SET_APPOINTMENT_HEALTH_RECORDS,
  SET_APPOINTMENT_PATIENT_HEALTH_RECORDS,
  SET_APPOINTMENT_PATIENTS,
  SET_APPOINTMENT_STATUS,
  SET_APPOINTMENTS_LOADING,
  SET_INITIAL_DATA_FOR_APPOINTMENT,
  SET_SELECTED_PATIENT_HEALTH_RECORDS,
  SHOW_BILL_MODAL,
  SHOW_EDIT_APPOINTMENT_MODAL,
  SHOW_MODAL,
  SHOW_PATIENT_MODAL,
  SHOW_PAYMENT_MODAL
} from './types';

interface AppointmentsListState extends IAppointmentsState {
  loading: boolean;
  showModal: boolean;
  showEditAppoinmentModal: boolean;
  showPatientModal: boolean;
  showPaymentModal: boolean;
  showBillModal: boolean;
  selectedAppointment: IAppointment | null;
  selectedAppointmentPatientHealthRecords: IAppointment | null;
  selectedAppointmentHealthRecords: IAppointment | null;
  selectedAppointmentPatientHealthRecordsInventory: IAppointment | null;
  selectedPatientHealthRecordsData: IAppointment | null;
  appointmentBill: IBillDetails | null;
}

const initialState: AppointmentsListState = {
  data: [],
  metadata: {
    total: 0,
    page: 1,
    next: null,
    last: 1,
    prev: null
  },
  loading: true,
  showModal: false,
  showEditAppoinmentModal: false,
  showPatientModal: false,
  showBillModal: false,
  showPaymentModal: false,
  selectedAppointment: null,
  initialDataForAppointment: null,
  selectedAppointmentPatients: null,
  selectedAppointmentPatientHealthRecords: null,
  selectedAppointmentHealthRecords: null,
  selectedAppointmentPatientHealthRecordsInventory: null,
  selectedPatientHealthRecordsData: null,
  appointmentBill: null
};

export function appointmentsReducer(
  state: IAppointmentsState = initialState,
  action: AppointmentsActions
): any {
  switch (action.type) {
    case GET_APPOINTMENTS: {
      return {
        ...state,
        ...action.payload
      };
    }

    case RESET: {
      return initialState;
    }

    case SHOW_MODAL: {
      return {
        ...state,
        showModal: action.payload
      };
    }

    case SHOW_EDIT_APPOINTMENT_MODAL: {
      return {
        ...state,
        showEditAppoinmentModal: action.payload
      };
    }

    case SHOW_PATIENT_MODAL: {
      return {
        ...state,
        showPatientModal: action.payload
      };
    }

    case SHOW_BILL_MODAL: {
      return {
        ...state,
        showBillModal: action.payload
      };
    }

    case SHOW_PAYMENT_MODAL: {
      return {
        ...state,
        showPaymentModal: action.payload
      };
    }

    case SET_APPOINTMENTS_LOADING: {
      return {
        ...state,
        loading: action.payload
      };
    }

    case SET_APPOINTMENT_STATUS: {
      return {
        ...state,
        status: action.payload
      };
    }

    case SET_APPOINTMENT: {
      return {
        ...state,
        selectedAppointment: action.payload
      };
    }

    case SET_APPOINTMENT_BILL: {
      return {
        ...state,
        appointmentBill: action.payload
      };
    }

    case SET_APPOINTMENT_PATIENTS: {
      return {
        ...state,
        selectedAppointmentPatients: action.payload
      };
    }

    case SET_APPOINTMENT_PATIENT_HEALTH_RECORDS: {
      return {
        ...state,
        selectedAppointmentPatientHealthRecords: action.payload
      };
    }

    case SET_APPOINTMENT_HEALTH_RECORDS: {
      return {
        ...state,
        selectedAppointmentHealthRecords: action.payload
      };
    }

    case SET_INITIAL_DATA_FOR_APPOINTMENT: {
      return {
        ...state,
        initialDataForAppointment: action.payload
      };
    }

    case SET_APPOINTMENT_HEALTH_RECORD_INVENTORY: {
      return {
        ...state,
        selectedAppointmentPatientHealthRecordsInventory: action.payload
      };
    }

    case SET_SELECTED_PATIENT_HEALTH_RECORDS: {
      return {
        ...state,
        selectedPatientHealthRecordsData: action.payload
      };
    }

    default: {
      return state;
    }
  }
}
