import { ISaleDetails, ISalesList } from 'interfaces/sales';

export const SET_SALES = 'SET_SALES';
export const SET_SALE_DETAILS = 'SET_SALE_DETAILS';
export const SHOW_SALES_MODAL = 'SHOW_SALES_MODAL';
export const SET_SALES_LOADING = 'SET_SALES_LOADING';
export const RESET = '[sales] RESET';

export interface SalesResetAction {
  type: typeof RESET;
}

export interface ShowModalAction {
  type: typeof SHOW_SALES_MODAL;
  payload: boolean;
}

export interface SetLoadingAction {
  type: typeof SET_SALES_LOADING;
  payload: boolean;
}

export interface SetSalesAction {
  type: typeof SET_SALES;
  payload: ISalesList | null;
}

export interface SetSaleDetailsAction {
  type: typeof SET_SALE_DETAILS;
  payload: ISaleDetails | null;
}

export type SalesActions =
  | ShowModalAction
  | SalesResetAction
  | SetLoadingAction
  | SetSaleDetailsAction
  | SetSalesAction;
