import dayjs from 'dayjs';
import updateLocale from 'dayjs/plugin/updateLocale';
import weekday from 'dayjs/plugin/weekday';

import { CalendarViewType, TimeFilterData } from '../types';

dayjs.extend(weekday);
 
dayjs.extend(updateLocale);
dayjs.updateLocale('en', {
  weekStart: 1
});

export const defineTimeFrameFilter = (
  currentDate?: Date | dayjs.Dayjs | null,
  view?: CalendarViewType
): TimeFilterData[] => {
  const date = currentDate ? dayjs(currentDate) : dayjs();

  switch (view) {
    case 'month':
      return getTimeFilters(date.startOf('month'), date.endOf('month'));
    case 'week':
      return getTimeFilters(date.weekday(0), date.weekday(6));
    case 'day':
    default:
      return getTimeFilters(date, date);
  }
};

const getTimeFilters = (from: dayjs.Dayjs, to: dayjs.Dayjs): TimeFilterData[] => {
  return [
    {
      name: 'end_time_from',
      value: from.hour(0).minute(0).second(0).tz('utc').format()
    },
    {
      name: 'start_time_to',
      value: to.hour(23).minute(59).second(59).tz('utc').format()
    }
  ];
};
