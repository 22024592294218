import { IRoom, IRoomsList } from '../../interfaces/room';
import {
  APPEND_ROOMS,
  GET_ROOMS,
  RESET,
  RoomsActions,
  SET_LOADING,
  SET_ROOM,
  SET_SEARCH_QUERY,
  SHOW_MODAL
} from './types';

interface RoomsListState extends IRoomsList {
  loading: boolean;
  showModal: boolean;
  selectedRoom: IRoom | null;
  searchQuery: any[];
}

const initialState: RoomsListState = {
  data: [],
  metadata: {
    total: 0,
    page: 1,
    next: null,
    last: 1,
    prev: null
  },
  loading: true,
  showModal: false,
  selectedRoom: null,
  searchQuery: []
};

export function roomsReducer(state: IRoomsList = initialState, action: RoomsActions): any {
  switch (action.type) {
    case GET_ROOMS: {
      return {
        ...state,
        ...action.payload
      };
    }

    case RESET: {
      return initialState;
    }

    case SHOW_MODAL: {
      return {
        ...state,
        showModal: action.payload
      };
    }

    case SET_LOADING: {
      return {
        ...state,
        loading: action.payload
      };
    }

    case SET_ROOM: {
      return {
        ...state,
        selectedRoom: action.payload
      };
    }

    case APPEND_ROOMS: {
      return {
        ...state,
        data: [...state.data, ...action.payload.data],
        metadata: action.payload.metadata
      };
    }

    case SET_SEARCH_QUERY: {
      return {
        ...state,
        searchQuery: action.payload
      };
    }

    default: {
      return state;
    }
  }
}
