export const SET_BE_ERROR = '[common] BackEndError';
export const SET_LOADING = '[common] Loading';

export interface SetBackendErrorAction {
  type: typeof SET_BE_ERROR
  payload: boolean
}

export interface SetLoadingAction {
  type: typeof SET_LOADING
  payload: boolean
}

export type CommonActions =
  SetBackendErrorAction |
  SetLoadingAction;
