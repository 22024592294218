import { Form } from 'antd';
import ConfirmCancelModal from 'components/ConfirmCancelModal';
import ViewDetailsButtonsGroup from 'components/ViewDetailsButtonsGroup';
import VLXModal from 'components/VLXModal';
import { useLocale } from 'hooks/useLocale';
import { useUserAccess } from 'hooks/useUserAccess';
import { IAppState } from 'interfaces/app-state';
import { IEvent } from 'interfaces/event';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setShowEditEventModal } from 'redux/events/actions';
import { EventsState } from 'redux/events/reducer';

import { EditEventForm } from './EditEventForm';

interface Props {
  initialValues: IEvent
  callbackFunc: () => void
}

export const EditEvent = ({initialValues, callbackFunc}: Props): JSX.Element => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const locale = useLocale('private.appointments.event'); 
  const userAccess = useUserAccess();

  const [showCancelModal, setShowCancelModal] = useState<boolean>(false); 

  const {showEditEventModal} = useSelector<IAppState, EventsState>((state) => state.events);

  const onConfirmCancelClick = () => {
    form.resetFields();
    dispatch(setShowEditEventModal(false));
    setShowCancelModal(false);
  };

  const mapValues = () => {
    if (initialValues) {
      const data = {
        ...initialValues
      };
      if (initialValues?.attendees?.length) {
        data.attendees = initialValues.attendees.map(el => el.user_id);
      }
      return data;
    } else {
      return null;
    }
  };

  return (
    <>
      <VLXModal
        title={locale.labels.editEvent}
        form={form}
        open={showEditEventModal}
        onModalClose={() => {
          dispatch(setShowEditEventModal(false)); 
        }}
        width={800}
      >
     {initialValues && <EditEventForm
                form={form} 
                initialValues={mapValues()} 
                callbackFunc={callbackFunc} 
              >
                <ViewDetailsButtonsGroup
                  fixed
                  accessGroup={userAccess.accesses.events}
                  editMode={true}
                  hideDeleteBtn={true}
                  onCancelClick={() => {
                    if (form.isFieldsTouched()) {
                      setShowCancelModal(true);
                    } else {
                      dispatch(setShowEditEventModal(false));
                    }
                  }}
                  onDeleteClick={() => {
                    setShowCancelModal(false);
                  }}
                />
              </EditEventForm>}
        <ConfirmCancelModal
          open={showCancelModal}
          onOk={onConfirmCancelClick}
          onCancel={() => setShowCancelModal(false)}
        />
      </VLXModal>
    </>
  );
};
