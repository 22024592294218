import { AxiosResponse } from 'axios';
import { IClient, IClientHistoryList } from 'interfaces/client';
import { IReplenishmentBalance } from 'interfaces/replenishment';

import baseClient from '../../baseClient';
import { generateSearchQuery, sorting } from '../../helpers/commonUtils';
import { CreateClientRequest } from '../../interfaces/clients/create-client-request';
import { ISortObject } from '../../interfaces/ISortObject';

const clinicsUrl: string = '/v1/clinics';

export const getClinicClients = async (
  id: number,
  page: number = 0,
  searchValue?: any[],
  sortObject?: ISortObject
) =>
  baseClient
    .get(
      `${clinicsUrl}/${id}/clients?page=${page}${generateSearchQuery(searchValue)}${sorting(
        sortObject
      )}`
    )
    .then((response: any) => {
      return response.data;
    });

export const getClinicClientDetails = async (id: number, clientId: number) =>
  baseClient.get(`${clinicsUrl}/${id}/clients/${clientId}`).then((response: any) => {
    return response.data;
  });

export const getClinicClientPatients = async (
  id: number,
  clientId: number,
  searchValue?: string,
  page: number = 0
) =>
  baseClient
    .get(
      `${clinicsUrl}/${id}/clients/${clientId}/patients?page=${page}${
        searchValue ? `&q${searchValue}` : ''
      }`
    )
    .then((response: any) => {
      return response.data;
    });

export const createClinicClient = async (clinicId: number, data: CreateClientRequest) =>
  baseClient.post(`/v1/clinics/${clinicId}/clients`, data).then((response: any) => {
    return response.data;
  });

export const updateClinicClient = async (
  clinicId: number,
  data: CreateClientRequest,
  clientId: number
) =>
  baseClient.put(`/v1/clinics/${clinicId}/clients/${clientId}`, data).then((response: any) => {
    return response.data;
  });

export const uploadAvatar = async (clinicId, clientId, avatarData) =>
  baseClient
    .post(`/v1/clinics/${clinicId}/clients/${clientId}/avatar`, avatarData, {
      headers: { 'Content-Type': 'multipart/form-data' }
    })
    .then((response: any) => {
      return response.data;
    });

export const getClinicClientDocumentsList = async (id: number, clientId: number) =>
  baseClient.get(`${clinicsUrl}/${id}/clients/${clientId}/documents`).then((response: any) => {
    return response.data;
  });

export const getClinicClientDocument = async (id: number, clientId: number, documentId: number) =>
  baseClient
    .get(`${clinicsUrl}/${id}/clients/${clientId}/documents/${documentId}`)
    .then((response: any) => {
      return response.data;
    });

export const createClinicClientDocument = async (
  clinicId: number,
  clientId: number,
  data: CreateClientRequest
) =>
  baseClient
    .post(`/v1/clinics/${clinicId}/clients/${clientId}/documents`, data, {
      headers: { 'Content-Type': 'multipart/form-data' }
    })
    .then((response: any) => {
      return response.data;
    });


export const getClinicClientHistory = async (id: number, clientId: number, sortObject: ISortObject = null) =>
  baseClient.get(`${clinicsUrl}/${id}/clients/${clientId}/history?sort_key=${sortObject.sort_key}&sort_order=${sortObject.sort_order}`).then((response: AxiosResponse<IClientHistoryList>) => {
    return response.data;
  });

export const clinicClientBalanceReplenishment = async (clinicId: number, clientId: number, data: IReplenishmentBalance) =>
  baseClient.post(`${clinicsUrl}/${clinicId}/clients/${clientId}/replenishments`, data).then((response: AxiosResponse<IClient>) => {
    return response.data;
  });
