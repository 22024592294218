import { Button, Form, Input, Modal, Spin } from 'antd';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { patternRule, PATTERNS, requiredRule } from 'utils/form-rules';

import { useLocale } from '../../hooks/useLocale';
import { getUser } from '../../redux/user/actions';
import { changeEmail } from '../../services/clinic/users/userService';
import MessageModal from '../components/messageModal/MessageModal';

const ChangeUserEmail = () => {
  const [visible, setVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const locale = useLocale('private.user.user-profile');
  const [form] = Form.useForm();
  const onSubmit = (values: any) => {
    setLoading(true);
    changeEmail(values)
      .then(() => {
        MessageModal('success', locale.messages.checkEmail);
        dispatch(getUser());
        setVisible(false);
      })
      .catch((e) => {
        form.setFields([
          {
            name: 'password',
            errors: [locale.messages.wrongPassword]
          }
        ]);
      })
      .finally(() => setLoading(false));
  };

  return (
    <>
      <Button className='edit-button' type='primary' block onClick={() => setVisible(true)}>
        {locale.buttons.changeEmail}
      </Button>
      <Modal
        title={locale.labels.changeEmailTitle}
        open={visible}
        onCancel={() => setVisible(false)}
        footer={null}
        width={300}
      >
        <Spin spinning={loading}>
          <Form onFinish={onSubmit} autoComplete='off' layout='vertical' form={form}>
            <Form.Item
              label={locale.labels.password}
              name='password'
              rules={[requiredRule(locale.messages.emptyField)]}
            >
              <Input.Password />
            </Form.Item>
            <Form.Item
              label={locale.labels.email}
              name='email'
              rules={[
                requiredRule(locale.messages.emptyField),
                patternRule(PATTERNS.EMAIL, locale.messages.emailNotValid)
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item>
              <Button block type='primary' htmlType='submit'>
                {locale.buttons.submit}
              </Button>
            </Form.Item>
          </Form>
        </Spin>
      </Modal>
    </>
  );
};

export default ChangeUserEmail;
