import React from 'react';
import { List, Modal } from 'antd';
import '../../../assets/scss/list.scss';

interface RecordType {
  key: string
  email: string
  country_code: string
  phone_number: string
}
const list = (cont: RecordType[]) => {
  return (<List
    size="small"
    className='invitations-rejected-list'
    bordered
    dataSource={cont}
    renderItem={(item) =>
      <List.Item>
        <List.Item.Meta
          title={`Email: ${item.email}`}
          description={`Phone_number: +${item.country_code}${item.phone_number}`}
        />
      </List.Item>}
  />);
};

const InvitationsRejectedModal = (
  content
) => {
  Modal.warn({
    title: 'Invitation is skipped for the existing clinic employees',
    content: list(content)
  });
};

export default InvitationsRejectedModal;
