import { Spin } from 'antd';
import ConfirmCancelModal from 'components/ConfirmCancelModal';
import StickyButton from 'components/StickyButton';
import ViewDetailsButtonsGroup from 'components/ViewDetailsButtonsGroup';
import VLXModal from 'components/VLXModal';
import { useUserAccess } from 'hooks/useUserAccess';
import { IAppState } from 'interfaces/app-state';
import { IServicesList } from 'interfaces/services';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { showModal } from 'redux/services/actions';

import AddServiceForm from './AddServiceForm';

const AddService = ({
  hideDefaultCreatePetFormBtn,
  initialValues,
  form,
  locale,
  callBackAfterCreate
}: any): JSX.Element => {
  const dispatch = useDispatch();
  const services = useSelector<IAppState, IServicesList>(({ services }) => services);
  const [showCancelModal, setShowCancelModal] = useState<boolean>(false);
  const userAccess = useUserAccess();

  const onConfirmCancelClick = (): void => {
    form.resetFields();
    dispatch(showModal(false));
    setShowCancelModal(false);
  };

  return (
    <>
      {!hideDefaultCreatePetFormBtn && (
        <StickyButton
          offsetBottom={10}
          onClick={() => dispatch(showModal(true))}
          iconElement={
            <span
              className='icofont icofont-plus mr-2 sticky-btn-icon'
              style={{ fontSize: '1.3em' }}
            />
          }
          textElement={locale.buttons.addService}
        />
      )}
      <VLXModal
        title={locale.labels.createService}
        centered={true}
        form={form}
        open={services.showModal}
        onModalClose={() => dispatch(showModal(false))}
        width={800}
      >
        <Spin spinning={services?.loading}>
          {services.showModal && (
            <AddServiceForm
              locale={locale}
              form={form}
              initialValues={initialValues}
              callBackAfterCreate={callBackAfterCreate}
            >
              <ViewDetailsButtonsGroup
                fixed
                accessGroup={userAccess.accesses.services}
                editMode={true}
                hideDeleteBtn={true}
                onCancelClick={() => {
                  if (form.isFieldsTouched()) {
                    setShowCancelModal(true);
                  } else {
                    dispatch(showModal(false));
                  }
                }}
                onDeleteClick={() => {
                  setShowCancelModal(false);
                }}
              />
            </AddServiceForm>
          )}
        </Spin>
        <ConfirmCancelModal
          open={showCancelModal}
          onOk={onConfirmCancelClick}
          onCancel={() => setShowCancelModal(false)}
        />
      </VLXModal>
    </>
  );
};

export default AddService;
