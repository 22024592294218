import { AxiosResponse } from 'axios';

import baseClient from '../../baseClient';
import { generateSearchQuery, sorting } from '../../helpers/commonUtils';
import { ShowPaymentResponse } from '../payments/models/ShowPaymentResponse.ts';
import { CreateUpdateBillRequest } from './models/CreateUpdateBillRequest';
import { IBillDetails } from './models/IBillDetails';
import { IndexBillsResponse } from './models/IndexBillsResponse';

function url(clinic_id) {
  return `/v1/clinics/${clinic_id}/bills`;
}

export const createClinicBill = async (clinicId: number, data: CreateUpdateBillRequest) =>
  baseClient.post(url(clinicId), data).then((response: AxiosResponse<IBillDetails>) => {
    return response.data;
  });

export const createClinicPaymentBill = async (
  clinicId: number,
  billId: number,
  data: CreateUpdateBillRequest
) =>
  baseClient
    .post(`${url(clinicId)}/${billId}/payments`, data)
    .then((response: AxiosResponse<ShowPaymentResponse>) => {
      return response.data;
    });

export const updateClinicBill = async (
  clinicId: number,
  billId: number,
  data: CreateUpdateBillRequest
) =>
  baseClient
    .put(`${url(clinicId)}/${billId}`, data)
    .then((response: AxiosResponse<IBillDetails>) => {
      return response.data;
    });

export const showClinicBill = async (clinicId: number, billId: number) =>
  baseClient.get(`${url(clinicId)}/${billId}`).then((response: AxiosResponse<IBillDetails>) => {
    return response.data;
  });

export const showClinicBillPayments = async (clinicId: number, billId: number) =>
  baseClient.get(`${url(clinicId)}/${billId}/payments`).then((response: AxiosResponse<any>) => {
    return response.data.data;
  });

export const destroyClinicBill = async (clinicId: number, billId: number) =>
  baseClient.delete(`${url(clinicId)}/${billId}`).then((response: any) => {
    return response.data;
  });

export const indexClinicBills = async (
  clinicId: number,
  page: number = 0,
  searchArray?: any[],
  sortObject?: any
) =>
  baseClient
    .get(`${url(clinicId)}?page=${page}${generateSearchQuery(searchArray)}${sorting(sortObject)}`)
    .then((response: AxiosResponse<IndexBillsResponse>) => {
      return response.data;
    });
