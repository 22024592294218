const breeds = [
  {
    value: 'macaw',
    enGB: 'Macaw',
    ukUA: 'Ара'
  },
  {
    value: 'cockatoo',
    enGB: 'Cockatoo',
    ukUA: 'Какаду'
  },
  {
    value: 'budgerigar',
    enGB: 'Budgerigar',
    ukUA: 'Хвилястий Папуга'
  },
  {
    value: 'lovebird',
    enGB: 'Lovebird',
    ukUA: 'Нерозлучник'
  },
  {
    value: 'african_grey',
    enGB: 'African Grey',
    ukUA: 'Жако (Африканський Сірий Папуга)'
  },
  {
    value: 'conure',
    enGB: 'Conure',
    ukUA: 'Конюр (Зеленощокий червонохвостий)'
  },
  {
    value: 'lorikeet',
    enGB: 'Lorikeet',
    ukUA: 'Лорікет'
  },
  {
    value: 'amazon',
    enGB: 'Amazon',
    ukUA: 'Амазон'
  },
  {
    value: 'pionus',
    enGB: 'Pionus',
    ukUA: 'Папуга-Червоногуз'
  },
  {
    value: 'eclectus',
    enGB: 'Eclectus',
    ukUA: 'Еклектус'
  },
  {
    value: 'senegal',
    enGB: 'Senegal',
    ukUA: 'Сенегальський Папуга'
  },
  {
    value: 'quaker',
    enGB: 'Quaker (Monk Parakeet)',
    ukUA: 'Квакер (Папуга-Монах)'
  },
  {
    value: 'cockatiel',
    enGB: 'Cockatiel',
    ukUA: 'Корела (Німфа)'
  },
  {
    value: 'caique',
    enGB: 'Caique',
    ukUA: 'Каїк'
  },
  {
    value: 'ringneck',
    enGB: 'Ringneck (Rose-Ringed Parakeet, Kramer Parrot)',
    ukUA: 'Ожереловий (Кільцехвостий, Папуга Крамера)'
  },
  {
    value: 'parrotlet',
    enGB: 'Parrotlet',
    ukUA: 'Папуга-Горобець Еквадорський'
  },
  {
    value: 'amadina',
    enGB: 'Amadina',
    ukUA: 'Амадина'
  },
  {
    value: 'canary',
    enGB: 'Canary',
    ukUA: 'Канарка'
  },
  {
    value: 'other',
    enGB: 'Other',
    ukUA: 'Інша'
  }
];

export default breeds;
