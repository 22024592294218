import { HomeFilled } from '@ant-design/icons';
import { Button } from 'antd';
import { useLocale } from 'hooks/useLocale';
import React from 'react';
import { useNavigateHome } from 'utils/use-navigate-home';

export const NotFound = (): JSX.Element => {
  const navigateHome = useNavigateHome();
  const locale = useLocale('public.error-pages');

  return (
    <div style={{ textAlign: 'center' }}>
      <h1 style={{ fontSize: '6rem' }} className='text-color-300 mb-2'>
        <i className='icofont-radio-active color-error mr-2' style={{ opacity: 0.5 }} />
        404
      </h1>
      <h6 className='text-md'>{locale.messages.notExist}</h6>
      <Button
        type='primary'
        onClick={navigateHome}
        style={{ width: 'auto' }}
        icon={<HomeFilled className='ml-0 mr-2' style={{ fontSize: '1em' }} />}
      >
        {locale.buttons.backHome}
      </Button>
    </div>
  );
};
