import { Tooltip } from 'antd';
import React from 'react';

const ListFieldWithTooltip = (inputTxt: any, length?: any) => {
  const allowedStringLength = length || 15;
  const inputTxtLength = inputTxt?.toString().length;
  if (inputTxt && inputTxtLength > allowedStringLength) {
    return (
      <Tooltip title={inputTxt}>{inputTxt.toString().slice(0, allowedStringLength)}...</Tooltip>
    );
  } else if (Array.isArray(inputTxt) && inputTxt.length === 0) {
    return '-';
  }
  return <span style={{ whiteSpace: 'nowrap' }}>{inputTxt || '-'}</span>;
};

export default ListFieldWithTooltip;
