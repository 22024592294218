import {
  CommonActions,
  SET_BE_ERROR, SET_LOADING
} from './types';

export interface CommonState {
  loading: boolean
  backendError: boolean
}

const initialState: CommonState = {
  loading: false,
  backendError: false
};

export function commonReducer (
  state: CommonState = initialState,
  action: CommonActions
): any {
  switch (action.type) {
    case SET_LOADING: {
      return {
        ...state,
        loading: action.payload
      };
    }

    case SET_BE_ERROR: {
      return {
        ...state,
        backendError: action.payload
      };
    }

    default: {
      return state;
    }
  }
}
