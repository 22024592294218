import { Button, Row } from 'antd';
import { useLocale } from 'hooks/useLocale';

export const AddButton = ({ add, fields, label }): JSX.Element => {
  const locale = useLocale('private.administration.general');

  return (
    <Row>
      <Button
        className='icofont icofont-plus'
        type={'primary'}
        style={{
          borderRadius: 30,
          fontWeight: 'bold'
        }}
        onClick={() => add()}
      >
        {fields.length ? locale.buttons.addMore : label}
      </Button>
    </Row>
  );
};
