import { IEmployeeDetails, IEmployeesList } from '../../interfaces/employee';

export const GET_EMPLOYEES = '[employees] Set';
export const SET_LOADING = '[employees] Loading';
export const SET_SELECTED_EMPLOYEE = '[employees] SelectedEmployee';
export const SET_EDIT_MODE = '[employees] EditMode';
export const APPEND_EMPLOYEES = '[employees] Append';
export const SET_LAST_METADATA = '[employees] SetLastMetaData';
export const SET_SEARCH_QUERY = '[employees] SetSearchQuery';

export const RESET = '[employees] RESET';

export interface EmployeesResetAction {
  type: typeof RESET
}

export interface GetEmployeesAction {
  type: typeof GET_EMPLOYEES
  payload: IEmployeesList
}

export interface SetLoadingAction {
  type: typeof SET_LOADING
  payload: boolean
}

export interface SetSelectedEmployeeAction {
  type: typeof SET_SELECTED_EMPLOYEE
  payload: IEmployeeDetails
}

export interface SetEditModeAction {
  type: typeof SET_EDIT_MODE
  payload: boolean
}

export interface AppendEmployeesAction {
  type: typeof APPEND_EMPLOYEES
  payload: IEmployeesList
}

export interface SetSearchQueryAction {
  type: typeof SET_SEARCH_QUERY
  payload: any[]
}

export type EmployeesActions =
  | EmployeesResetAction
  | GetEmployeesAction
  | SetSearchQueryAction
  | AppendEmployeesAction
  | SetLoadingAction
  | SetEditModeAction
  | SetSelectedEmployeeAction;
