import baseClient from '../../baseClient';
import { generateSearchQuery, sorting } from '../../helpers/commonUtils';
import { ISortObject } from '../../interfaces/ISortObject';
import { CreateServiceRequest } from '../../interfaces/services/create-service-request';

const clinicsUrl: string = '/v1/clinics';

export const getClinicServices = async (
  id: number,
  page?: number,
  searchValue?: any[],
  sortObject: ISortObject = null
) =>
  baseClient
    .get(
      `${clinicsUrl}/${id}/services?page=${page}${generateSearchQuery(searchValue)}${sorting(
        sortObject
      )}`
    )
    .then((response: any) => {
      return response.data;
    });

export const getClinicServiceDetails = async (id: number, serviceId: number) =>
  baseClient.get(`${clinicsUrl}/${id}/services/${serviceId}`).then((response: any) => {
    return response.data;
  });

export const createClinicService = async (clinicId: number, data: CreateServiceRequest) =>
  baseClient.post(`/v1/clinics/${clinicId}/services`, data).then((response: any) => {
    return response.data;
  });

export const updateClinicService = async (
  clinicId: number,
  data: CreateServiceRequest,
  serviceId: number
) =>
  baseClient.put(`/v1/clinics/${clinicId}/services/${serviceId}`, data).then((response: any) => {
    return response.data;
  });

export const removeClinicService = async (clinicId: number, serviceId: number) =>
  baseClient.delete(`/v1/clinics/${clinicId}/services/${serviceId}`).then((response: any) => {
    return response.data;
  });
