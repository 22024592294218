import { Select, Spin } from 'antd';
import debounce from 'lodash/debounce';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import useClinicId from '../hooks/useClinicId';
import { IAppState } from '../interfaces/app-state';

const SelectInfiniteScroll = ({
  excludeIds = null,
  mode,
  disabled,
  optionsFn,
  onChange,
  onSelect,
  searchByField,
  placeholder,
  value,
  className,
  allowClear,
  style,
  appendAction,
  setAction,
  type,
  id
}: any) => {
  const dispatch = useDispatch();
  const clinicId = useClinicId();
  const data = useSelector<IAppState, any>((state) => state[type]);

  function fetchData(pageNumber: number) {
    dispatch(appendAction(clinicId, pageNumber, data.searchQuery));
  }

  function performSearch(pageNumber: number, query?: any[]) {
    dispatch(setAction(clinicId, pageNumber, query));
  }

  const onPopupScroll = async (e) => {
    if (!data.metadata.next) return;
    e.persist();
    const target = e.target;
    if (target.scrollTop + target.offsetHeight === target.scrollHeight) {
      fetchData(data.metadata.next);
    }
  };

  function handleSearch(newValue: string) {
    const searchValues = newValue.split(' ');
    const searchParams = [];
    searchValues.forEach((value) => {
      searchParams.push({
        name: searchByField,
        value
      });
    });
    performSearch(1, searchParams);
  }

  function options() {
    if (!excludeIds || excludeIds?.length === 0) return data?.data.map(optionsFn);
    return data?.data.filter((item) => !excludeIds.includes(item.id)).map(optionsFn);
  }

  return (
    <Select
      mode={mode}
      disabled={disabled}
      id={id}
      className={className}
      style={style}
      showSearch
      allowClear={allowClear}
      value={value}
      placeholder={placeholder}
      onSearch={debounce(handleSearch, 1000)}
      filterOption={false}
      onPopupScroll={onPopupScroll}
      loading={data.loading}
      onChange={onChange}
      onSelect={onSelect}
      options={options()}
      dropdownRender={(menu) => (
        <>
          {menu}
          {
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                color: 'lightgrey',
                margin: '-11px -11px',
                maxHeight: '50px'
              }}
            >
              <Spin spinning={data.loading}>
                {data.data.length === data.metadata.total ? (
                  <h6> ...all {options().length} items are loaded...</h6>
                ) : (
                  <h6>
                    {' '}
                    ...loaded {data.data.length} of {data.metadata.total} available items...
                  </h6>
                )}
              </Spin>
            </div>
          }
        </>
      )}
    />
  );
};

export default SelectInfiniteScroll;
