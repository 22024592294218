import { IClinic } from 'interfaces/clinic';
import {
  createClinicDocument,
  editClinicDetails,
  getClinicDetails,
  getClinicDocumentsList,
  removeClinicDocument,
  uploadAvatar,
} from 'services/clinic/clinicsService';

import { getUser } from '../user/actions';
import {
  ClinicsResetAction,
  GET_CLINIC,
  GetClinicAction,
  MERGE_CLINIC_DOCUMENTS_LIST,
  MergeClinicDocumentsListAction,
  RESET,
  SET_CLINIC_DOCUMENTS_LIST,
  SET_CLINIC_LOADING,
  SET_DOCS_LOADING,
  SET_PERMISSIONS,
  SetDocsLoadingAction,
  SetLoadingAction,
  SetPermissionsAction,
  SetSelectedClinicDocumentsListAction,
  SHOW_MODAL,
  ShowModalAction,
} from './types';

export const setClinic = (data: IClinic): GetClinicAction => ({
  type: GET_CLINIC,
  payload: data
});

export const resetClinics = (): ClinicsResetAction => ({
  type: RESET
});

export const showModal = (value: boolean): ShowModalAction => ({
  type: SHOW_MODAL,
  payload: value
});

export const setLoading = (value: boolean): SetLoadingAction => ({
  type: SET_CLINIC_LOADING,
  payload: value
});

export const setDocsLoading = (value: boolean): SetDocsLoadingAction => ({
  type: SET_DOCS_LOADING,
  payload: value
});

export const setPermissions = (value: string[]): SetPermissionsAction => ({
  type: SET_PERMISSIONS,
  payload: value
});

export const setClinicDocumentsList = (data: any): SetSelectedClinicDocumentsListAction => ({
  type: SET_CLINIC_DOCUMENTS_LIST,
  payload: data
});

export const mergeClinicDocumentsList = (data: any): MergeClinicDocumentsListAction => ({
  type: MERGE_CLINIC_DOCUMENTS_LIST,
  payload: data
});

export const getClinic = (clinicId: number, callBackFnc?: () => void): any => {
  return (dispatch) => {
    dispatch(setLoading(true));
    getClinicDetails(clinicId)
      .then((data) => {
        dispatch(setClinic(data));
        dispatch(setLoading(false));
        if (callBackFnc) callBackFnc();
      })
      .catch(() => dispatch(setLoading(false)));
  };
};

export const editClinic = (clinicId: number, data: IClinic): any => {
  return (dispatch) => {
    dispatch(setLoading(true));
    editClinicDetails(clinicId, data)
      .then(() => {
        dispatch(getClinic(clinicId));
        dispatch(getUser());
        dispatch(showModal(false));
        dispatch(setLoading(false));
      })
      .catch(() => dispatch(setLoading(false)));
  };
};

export const setAvatar = (clinicId, formData, callBackFnc?): any => {
  return (dispatch) => {
    uploadAvatar(clinicId, formData)
      .then((data) => {
        dispatch(getClinic(clinicId));
        dispatch(getUser());
        if (callBackFnc) {
          callBackFnc();
        }
      })
      .catch((err) => console.log(err));
  };
};

export const getDocumentsList = (clinicId: number): any => {
  return (dispatch) => {
    dispatch(setDocsLoading(true));
    dispatch(setClinicDocumentsList(null));
    getClinicDocumentsList(clinicId)
      .then((data) => {
        dispatch(setClinicDocumentsList(data.reverse()));
        dispatch(setDocsLoading(false));
      })
      .catch(() => dispatch(setDocsLoading(false)));
  };
};

export const createPatientDocument = (clinicId: number, data: any): any => {
  return (dispatch) => {
    dispatch(setDocsLoading(true));
    createClinicDocument(clinicId, data)
      .then((res) => {
        dispatch(getDocumentsList(clinicId));
        dispatch(setDocsLoading(false));
      })
      .catch(() => dispatch(setDocsLoading(false)));
  };
};

export const removePatientDocument = (clinicId: number, documentId: number): any => {
  return (dispatch) => {
    removeClinicDocument(clinicId, documentId)
      .then((res) => {
        dispatch(getDocumentsList(clinicId));
      })
      .catch((e) => console.log(e));
  };
};
