import { Col } from 'antd';
import { useLocale } from 'hooks/useLocale';
import React from 'react';
import { Link } from 'react-router-dom';

const ClientCard = ({ appointmentClient, clinicId, children }: any) => {
  const locale = useLocale('private.appointments.appointment-bill').labels;

  function getClient() {
    return appointmentClient?.name
      ? appointmentClient.name
      : appointmentClient?.first_name + ' ' + (appointmentClient?.last_name || '');
  }

  return (
    <>
      <div className='card-content' style={{ marginBottom: '15px' }}>
        <Col>
          {(appointmentClient?.first_name || appointmentClient?.name) && (
            <>
              <span style={{ fontWeight: 600 }}>{locale.clientName}: </span>
              <Link
                id='client-name'
                className={'custom-links'}
                to={`/clinic/${clinicId}/clients/${appointmentClient?.id}`}
              >
                {getClient()}
              </Link>
            </>
          )}
        </Col>
        <Col>
          {appointmentClient?.phone_number && (
            <>
              <span style={{ fontWeight: 600 }}>{locale.clientPhoneNumber}: </span>
              <a
                id='client-phone'
                href={`tel:${appointmentClient?.phone_number}`}
                className='d-flex align-baseline nowrap'
              >
                {appointmentClient?.phone_number ? `+${appointmentClient.phone_number}` : '-'}
              </a>
            </>
          )}
        </Col>
        <Col style={{ flexWrap: 'nowrap', display: 'flex' }}>
          {appointmentClient?.email && (
            <>
              <span style={{ fontWeight: 600, minWidth: 55 }}>E-mail: </span>
              <a
                id='client-email'
                href={`mailto:${appointmentClient?.email}`}
                className='custom-links d-flex align-baseline nowrap ellipsis-overflow-text'
              >
                {appointmentClient?.email}
              </a>
            </>
          )}
        </Col>
      </div>
      {children}
    </>
  );
};

export default ClientCard;
