import { IRoom, IRoomsList } from '../../interfaces/room';

export const GET_ROOMS = '[rooms] Set';
export const SHOW_MODAL = '[rooms] Add';
export const SET_LOADING = '[rooms] Edit';
export const SET_ROOM = '[rooms] Select';
export const APPEND_ROOMS = '[rooms] Append';
export const SET_SEARCH_QUERY = '[rooms] SetSearchQuery';
export const RESET = '[rooms] RESET';

export interface RoomsResetAction {
  type: typeof RESET
}

export interface GetRoomsAction {
  type: typeof GET_ROOMS
  payload: IRoomsList
}

export interface ShowModalAction {
  type: typeof SHOW_MODAL
  payload: boolean
}

export interface SetLoadingAction {
  type: typeof SET_LOADING
  payload: boolean
}

export interface SetSelectedRoom {
  type: typeof SET_ROOM
  payload: IRoom | null
}

export interface AppendRoomsAction {
  type: typeof APPEND_ROOMS
  payload: IRoomsList
}

export interface SetSearchQueryAction {
  type: typeof SET_SEARCH_QUERY
  payload: any[]
}

export type RoomsActions =
  | GetRoomsAction
  | RoomsResetAction
  | SetSearchQueryAction
  | AppendRoomsAction
  | ShowModalAction
  | SetLoadingAction
  | SetSelectedRoom;
