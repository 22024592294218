import { IEvent } from 'interfaces/event';
import {
  cancelClinicEvent,
  createClinicEvent,
  editClinicEvent,
  getClinicEmployeeEvents,
} from 'services/clinic/clinicsService';
import { getClinicEventById, getClinicEvents, getUserEvents } from 'services/clinic/events/eventService';
import { EventsIndexResponse } from 'services/interfaces/clinics/events/EventsIndexResponse';

import {
  EventsResetAction,
  GET_EVENTS,
  GetEventsAction,
  RESET,
  SET_BE_ERROR,
  SET_EMPLOYEES_EVENTS,
  SET_EVENT,
  SET_LOADING,
  SetBackendErrorAction,
  SetEmployeesEventsAction,
  SetLoadingAction,
  SetSelectedEvent,
  SHOW_EDIT_EVENT_MODAL,
  SHOW_MODAL,
  ShowEditEventModalAction,
  ShowModalAction,
} from './types';
import { ISearchCriteria } from '../../interfaces/search-criteria.ts';

export const setEvents = (events: EventsIndexResponse[]): GetEventsAction => ({
  type: GET_EVENTS,
  payload: events
});

export const resetEvents = (): EventsResetAction => ({
  type: RESET
});

export const showModal = (value: boolean): ShowModalAction => ({
  type: SHOW_MODAL,
  payload: value
});

export const setShowEditEventModal = (value: boolean): ShowEditEventModalAction => ({
  type: SHOW_EDIT_EVENT_MODAL,
  payload: value
});

export const setLoading = (value: boolean): SetLoadingAction => ({
  type: SET_LOADING,
  payload: value
});

export const setSelectedEvent = (data: IEvent | null): SetSelectedEvent => ({
  type: SET_EVENT,
  payload: data
});

export const setEmployeesEvents = (data: {
  [key: number]: IEvent[];
}): SetEmployeesEventsAction => ({
  type: SET_EMPLOYEES_EVENTS,
  payload: data
});

export const setBackendError = (value: boolean): SetBackendErrorAction => ({
  type: SET_BE_ERROR,
  payload: value
});

export const retrieveClinicEvents = (clinicId: number, searchArray?: any[]): any => {
  return (dispatch) => {
    dispatch(setLoading(true));
    getClinicEvents(clinicId, searchArray)
      .then((data) => {
        dispatch(setEvents(data));
        dispatch(setLoading(false));
      })
      .catch(() => dispatch(setLoading(false)));
  };
};

export const retrieveUserEvents = (searchArray: any): any => {
  return (dispatch) => {
    dispatch(setLoading(true));
    getUserEvents(searchArray)
      .then((data) => {
        dispatch(setEvents(data));
        dispatch(setLoading(false));
      })
      .catch(() => dispatch(setLoading(false)));
  };
};

export const getEmployeeEvents = (id: number, employeeId: number): any => {
  return (dispatch) => {
    dispatch(setLoading(true));
    getClinicEmployeeEvents(id, employeeId)
      .then((data) => {
        dispatch(setEvents(data));
        dispatch(setLoading(false));
      })
      .catch(() => dispatch(setLoading(false)));
  };
};

export const getEventById = (clinicId: number, id: number): any => {
  return (dispatch) => {
    dispatch(setLoading(true));
    getClinicEventById(clinicId, id)
      .then((data) => {
        dispatch(setSelectedEvent(data));
        dispatch(setLoading(false));
      })
      .catch(() => dispatch(setLoading(false)));
  };
};

export const createEvent = (data, clinicId,
  searchItemsArray?: ISearchCriteria[]): any => {
  return (dispatch) => {
    dispatch(setLoading(true));
    createClinicEvent(data, clinicId)
      .then(() => {
        dispatch(retrieveClinicEvents(clinicId, searchItemsArray));
        dispatch(setBackendError(false));
        dispatch(showModal(false));
      })
      .catch(() => dispatch(setBackendError(true)))
      .finally(() => dispatch(setLoading(false)));
  };
};

export const editEvent = (data: IEvent, eventId: number, callbackFunc?: () => void): any => {
  return (dispatch) => {
    dispatch(setLoading(true));
    editClinicEvent(data, eventId)
      .then(() => {
        dispatch(setShowEditEventModal(false));
        if (callbackFunc) {
          callbackFunc();
        }
      })
      .catch(() => dispatch(setBackendError(true)))
      .finally(() => dispatch(setLoading(false)));
  };
};

export const cancelEvent = (eventId: number, callbackFunc: () => void): any => {
  return (dispatch) => {
    dispatch(setLoading(true));
    cancelClinicEvent(eventId)
      .then(() => {
        dispatch(setBackendError(false));
        if (callbackFunc) {
          callbackFunc();
        }
      })
      .catch(() => dispatch(setBackendError(true)))
      .finally(() => dispatch(setLoading(false)));
  };
};

// export const deleteEvent = (clinicId: number, eventId: number) => {
//   return dispatch => {
//     dispatch(setLoading(true));
//     deleteClinicEvent(clinicId, eventId)
//       .then(() => {
//         dispatch(getEvents(clinicId));
//         dispatch(showModal(false));
//       })
//       .catch(() => dispatch(setLoading(false)));
//   };
// };
