import { DatePicker, Form, FormInstance, Input, Select } from 'antd';
import { TextEditor } from 'components/TextEditor.tsx';
import VLXInputNumber from 'components/VLXInputNumber';
import dayjs from 'dayjs';
import useClinicId from 'hooks/useClinicId';
import { IAppState } from 'interfaces/app-state';
import { ILocale } from 'interfaces/locale';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { createBillPayment } from 'redux/finances/bills/actions';
import { getAccounts } from 'redux/finances/financialAccounts/actions';
import { IBillDetails } from 'services/clinic/bills/models/IBillDetails';
import { ShowPaymentResponse } from 'services/clinic/payments/models/ShowPaymentResponse.ts';
import { requiredRule, validatorRule } from 'utils/form-rules';

const { Option } = Select;

type Props = React.PropsWithChildren<{
  bill: IBillDetails;
  form: FormInstance;
  locale?: ILocale;
  onBillPaymentCallback?: (data: ShowPaymentResponse) => void;
}>;

export const AddBillPaymentForm = ({
  form,
  children,
  locale,
  bill,
  onBillPaymentCallback
}: Props): JSX.Element => {
  const dispatch = useDispatch();
  const clinicId = useClinicId();
  const { appointmentId } = useParams();

  const accountsData = useSelector(({ financialAccounts }: IAppState) => financialAccounts.data);

  useEffect(() => {
    if (clinicId) {
      dispatch(getAccounts(clinicId, 0));
    }
  }, [clinicId]);

  useEffect(() => {
    form.resetFields();
  }, []);

  const accountsList = accountsData.map((item) => {
    const accNumber = item?.account_number ? `, ${item?.account_number}` : '';
    return (
      <Option key={item.name} title={item.name} value={item.id}>
        {item?.name}
        {accNumber}
      </Option>
    );
  });

  const onSubmit = (data: any): void => {
    const apiData = { ...data, amount: Number(data.amount).toFixed(2) };

    dispatch(createBillPayment(clinicId, +appointmentId, bill.id, apiData, onBillPaymentCallback));
  };

  return (
    <Form onFinish={onSubmit} autoComplete='off' layout='vertical' form={form}>
      <Form.Item name='clinic_id' hidden>
        <Input />
      </Form.Item>
      <Form.Item name={'date'} initialValue={dayjs()} label={locale.labels.date}>
        <DatePicker format='YYYY-MM-DD' />
      </Form.Item>
      <Form.Item
        className='add-equipment-form__multi-select-field'
        label={locale.labels.account}
        rules={[requiredRule()]}
        name='account_id'
      >
        <Select
          filterOption={(input, option) => {
            return option?.title.toString().toLowerCase().includes(input?.toLowerCase());
          }}
          showSearch
        >
          {accountsList}
        </Select>
      </Form.Item>
      <Form.Item
        label={locale.labels.amount}
        rules={[
          requiredRule(),
          validatorRule(
            (value) => Number(value) <= bill.remaining_amount,
            locale.labels.sumCannotBeMoreThan
          )
        ]}
        initialValue={bill.remaining_amount}
        name='amount'
      >
        <VLXInputNumber />
      </Form.Item>
      <TextEditor label={locale.labels.notes} name={['notes']} rows={2} />
      {children}
    </Form>
  );
};
