import './EventsCalendar.scss';

import {
  CaretLeftOutlined,
  CaretRightOutlined,
  CloseCircleOutlined,
  MenuFoldOutlined,
  MenuUnfoldOutlined
} from '@ant-design/icons';
import { Button, Calendar, Checkbox, Collapse, Divider, Input, Layout } from 'antd';
import ConfirmCancelModal from 'components/ConfirmCancelModal';
import dayjs from 'dayjs';
import useClinicId from 'hooks/useClinicId';
import { useLocale } from 'hooks/useLocale';
import { IAppState } from 'interfaces/app-state';
import { IEmployee } from 'interfaces/employee';
import { IRoom } from 'interfaces/room';
import AddEvent from 'layout/modals/addEvent/AddEvent';
import { EditEvent } from 'layout/modals/editEvent/EditEvent';
import debounce from 'lodash/debounce';
import moment from 'moment';
import React, { useEffect, useMemo, useState } from 'react';
import { Calendar as BigCalendar, Views } from 'react-big-calendar';
import { useDispatch, useSelector } from 'react-redux';
import { getAppointmentDetails, setInitialDataForAppointment } from 'redux/appointments/actions';
import { getEmployees } from 'redux/employees/actions';
import {
  cancelEvent,
  getEventById,
  resetEvents,
  retrieveClinicEvents,
  setShowEditEventModal,
  showModal
} from 'redux/events/actions';
import { getRooms } from 'redux/rooms/actions';

import { EventModal } from './EventModal';
import { CalendarViewType, LocalStorageFilters } from './types';
import { bigCalendarFormats } from './utils/big-calendar-formats';
import { dateLocalizer } from './utils/dateLocalizer';
import { defineTimeFrameFilter } from './utils/define-time-frame-filter';

const USER_COLOR = '#8bd1ca';

const localizer = dateLocalizer();

const EventsCalendar = (): JSX.Element => {
  const mobile = window.innerWidth < 992;
  const dispatch = useDispatch();
  const clinicId = useClinicId();
  const locale = useLocale('private.calendar.calendar');

  const events = useSelector(({ events }: IAppState) => events.data);
  const selectedEventData = useSelector(({ events }: IAppState) => events.selectedEvent);
  const selectedAppointmentData = useSelector(
    ({ appointments }: IAppState) => appointments.selectedAppointment
  );
  const rooms = useSelector(({ rooms }: IAppState) => rooms);
  const employees = useSelector(({ employees }: IAppState) => employees);
  const user = useSelector(({ user }: IAppState) => user);

  const [calendarHeight, setCalendarHeight] = useState(window.innerHeight - 350);
  const [selectedDate, setSelectedDate] = useState<Date>(new Date());
  const [collapsed, setCollapsed] = useState(mobile);
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [selectedEmployees, setSelectedEmployees] = useState([user.id]);
  const [selectedRooms, setSelectedRooms] = useState([]);
  const [employeesFilter, setEmployeesFilter] = useState(null);
  const [roomsFilter, setRoomsFilter] = useState(null);
  const [calendarView, setCalendarView] = useState(null);
  const [hasDefinedFilters, setHasDefinedFilters] = useState(false);
  const [showCalcelEventModal, setShowCalcelEventModal] = useState<boolean>(false);

  useEffect(() => {
    dispatch(getEmployees(clinicId, 0));
    dispatch(getRooms(clinicId, 0));
    dispatch(setInitialDataForAppointment(null));

    window.onresize = () => {
      setCalendarHeight(window.innerHeight - 369);
    };

    return () => {
      window.onresize = null;
      dispatch(resetEvents());
    };
  }, []);

  useEffect((): void => {
    loadData();
  }, []);

  const loadData = () => {
    const calendarFiltersStorage = JSON.parse(localStorage.getItem('eventCalendarFilters'));
    let calendarFilters = null;
    if (calendarFiltersStorage !== null) {
      if (calendarFiltersStorage[clinicId]) {
        calendarFilters = calendarFiltersStorage[clinicId];
      }
    }
    if (calendarFilters) {
      setHasDefinedFilters(true);
    }

    const { selectedEmployees, selectedRooms, view, selectedDate } = calendarFilters ?? {};

    if (selectedEmployees) setSelectedEmployees(selectedEmployees);
    if (selectedRooms) setSelectedRooms(selectedRooms);
    if (selectedDate) setSelectedDate(selectedDate);

    const calendarView = view || (window.innerWidth < 992 ? 'day' : 'week');
    setCalendarView(calendarView);
    const filters = defineTimeFrameFilter(selectedDate, calendarView);
    setLocalStorageFilters({
      selectedDate,
      filters
    });
    fetchData(selectedDate, calendarView);
  };

  const getEmployeeColor = (id: number): string => {
    if (id === user.id) {
      return USER_COLOR;
    }

    return employees.data.find((employee: IEmployee) => employee.id === id)?.color;
  };

  const allEvents = useMemo(
    () =>
      events
        ?.filter(
          (item) =>
            (selectedEmployees.includes(item.user_id) ||
              selectedRooms.includes(item.location?.id)) &&
            item.occupied
        )
        .map((item) => {
          return {
            title: item.title === 'Busy' ? locale.labels.busy : item.title,
            id: item.id,
            allDay: item.all_day,
            start: new Date(item.start_time),
            end: new Date(item.end_time),
            location: item.location,
            resource: {
              ...item,
              color: getEmployeeColor(item.user_id)
            }
          };
        }),
    [user, events, employees, selectedEmployees, selectedRooms]
  );

  const onSelectSlot = (info): void => {
    const duration = moment.duration(moment(info.end).diff(moment(info.start)));
    const hours = duration.asHours();
    let minutes = duration.asMinutes();
    if (minutes) {
      minutes = minutes % 60;
    }
    dispatch(
      setInitialDataForAppointment({
        start_time: info.start,
        end_time: info.end,
        duration: dayjs().hour(hours).minute(minutes)
      })
    );
    dispatch(showModal(true));
  };

  const setLocalStorageFilters = (newVals: LocalStorageFilters): void => {
    setHasDefinedFilters(true);
    const calendarFilters = JSON.parse(localStorage.getItem('eventCalendarFilters'));
    if (calendarFilters) {
      if (calendarFilters[clinicId]) {
        calendarFilters[clinicId] = { ...calendarFilters[clinicId], ...newVals };
      } else {
        calendarFilters[clinicId] = newVals;
      }
      localStorage.setItem('eventCalendarFilters', JSON.stringify(calendarFilters));
    } else {
      localStorage.setItem('eventCalendarFilters', JSON.stringify({ [clinicId]: newVals }));
    }
  };
  const clearSelectedFilters = (): void => {
    const calendarFilters = JSON.parse(localStorage.getItem('eventCalendarFilters'));
    if (calendarFilters) {
      for (const key in calendarFilters) {
        if (+key === +clinicId) {
          calendarFilters[clinicId] = { filters: calendarFilters[clinicId].filters };
        }
      }
      localStorage.setItem('eventCalendarFilters', JSON.stringify(calendarFilters));
    }
    setHasDefinedFilters(false);
    setSelectedEmployees([user.id]);
    setSelectedRooms([]);
    setSelectedDate(new Date());

    const view = window.innerWidth < 992 ? 'day' : 'week';
    setCalendarView(view);

    fetchData(null, view);
  };

  const setCurrentDate = (date, { source }): void => {
    const view = source === 'date' ? 'day' : 'week';

    fetchData(date, view);
    setCalendarView(view);
    setSelectedDate(date);
    const filters = defineTimeFrameFilter(date, view);
    setLocalStorageFilters({
      selectedDate: date,
      view,
      filters
    });
  };

  const onNavigate = (date: Date, view: CalendarViewType): void => {
    fetchData(date, view);
    setSelectedDate(date);
    const filters = defineTimeFrameFilter(date, view);
    setLocalStorageFilters({
      selectedDate: date,
      filters
    });
  };

  const fetchData = (date: Date, view: CalendarViewType): void => {
    const filters = defineTimeFrameFilter(date, view);
    dispatch(retrieveClinicEvents(clinicId, filters));
  };

  const onSelectEvent = (info): void => {
    setSelectedEvent(info);
    if (info?.id && info?.resource?.event_type === 'appointment') {
      dispatch(getAppointmentDetails(info?.resource?.clinic_id, info?.resource?.appointment_id));
    } else if (
      info?.id &&
      info?.resource?.event_type === 'event' &&
      info?.resource?.location?.details !== 'unavailable'
    ) {
      dispatch(getEventById(info?.resource?.clinic_id, info.id));
    }
  };

  function filterEmployees(e): void {
    const data = e.target.value;

    setEmployeesFilter(data);
  }

  function filterRooms(e): void {
    const data = e.target.value;
    setRoomsFilter(data);
  }

  const filteredEmployees = useMemo((): IEmployee[] => {
    if (!employeesFilter) return employees.data;

    return employees.data.filter((item) =>
      item.full_name.toLowerCase().includes(employeesFilter.toLowerCase())
    );
  }, [employeesFilter, employees]);

  const filteredRooms = useMemo((): IRoom[] => {
    if (!roomsFilter) return rooms.data;
    return rooms.data.filter((item) => item.name.toLowerCase().includes(roomsFilter.toLowerCase()));
  }, [roomsFilter, rooms.data]);

  const roomsList = filteredRooms.map(
    (item): JSX.Element => (
      <div key={`room_${item.id}`}>
        <Checkbox
          checked={selectedRooms.includes(item.id)}
          onChange={(e) => onSelectRoom(e.target.checked, item.id)}
        >
          {item.name}
        </Checkbox>
      </div>
    )
  );

  function onWeekClick(): void {
    const type = 'week';
    const filters = defineTimeFrameFilter(selectedDate, type);
    fetchData(selectedDate, type);
    setCalendarView(type);
    setLocalStorageFilters({
      view: type,
      filters
    });
  }

  function onDayClick(): void {
    const type = 'day';
    const filters = defineTimeFrameFilter(selectedDate, type);
    fetchData(selectedDate, type);
    setCalendarView(type);
    setLocalStorageFilters({
      view: type,
      filters
    });
  }

  function onMonthClick(): void {
    const type = 'month';
    const filters = defineTimeFrameFilter(selectedDate, type);
    fetchData(selectedDate, type);
    setCalendarView(type);
    setLocalStorageFilters({
      view: type,
      filters
    });
  }

  const onSelectEmployee = (selected, employeeId: number): void => {
    const newSelectedEmployeesList = selected
      ? [...selectedEmployees, employeeId]
      : selectedEmployees.filter((item) => item !== employeeId);

    setSelectedEmployees(newSelectedEmployeesList);
    setLocalStorageFilters({ selectedEmployees: newSelectedEmployeesList });
  };

  const onSelectRoom = (selected: boolean, roomId: number): void => {
    const res = selected
      ? [...selectedRooms, roomId]
      : selectedRooms.filter((item) => item !== roomId);

    setSelectedRooms(res);
    setLocalStorageFilters({ selectedRooms: res });
  };

  const onSelectAll = (selected: boolean): void => {
    const res = selected ? filteredEmployees.map((item) => item.id) : [];
    setSelectedEmployees(res);
    setLocalStorageFilters({ selectedEmployees: res });
  };

  const onSelectAllRooms = (selected: boolean): void => {
    const roomIds = selected ? filteredRooms.map((item) => item.id) : [];
    setSelectedRooms(roomIds);
    setLocalStorageFilters({ selectedRooms: roomIds });
  };

  const getEmployeeName = (item: IEmployee): string => {
    if (item.id === user.id) {
      return locale.labels.myEvents;
    }
    if (item.title) return `${item.full_name} (${item.title})`;
    return `${item.full_name}`;
  };

  const buttonIcon = useMemo((): JSX.Element => {
    if (collapsed) return <MenuUnfoldOutlined style={{ margin: '0px' }} />;

    return <MenuFoldOutlined style={{ margin: '0px' }} />;
  }, [collapsed]);

  const collapseItems = useMemo(
    () => [
      {
        key: 1,
        label: locale.labels.selectDate,
        children: (
          <Calendar
            style={{ paddingInlineStart: 0 }}
            fullscreen={false}
            value={dayjs(selectedDate)}
            onSelect={setCurrentDate}
          />
        )
      },
      {
        key: 2,
        label: locale.labels.employees,
        children: (
          <>
            <Input
              placeholder={locale.placeholders.search}
              allowClear
              onChange={debounce(filterEmployees, 500)}
            />
            <Checkbox
              onChange={(e) => onSelectAll(e.target.checked)}
              style={{ marginTop: '15px' }}
              checked={selectedEmployees?.length === filteredEmployees.length}
            >
              {locale.labels.selectUnselectAll}
            </Checkbox>
            <Divider style={{ margin: '15px 0' }} />
            {filteredEmployees
              .sort((x: IEmployee, y: IEmployee) =>
                x.id === user.id ? -1 : y.id === user.id ? 1 : 0
              )
              .map(
                (item): JSX.Element => (
                  <div key={item.id} className='colored-label-wrapper'>
                    <span
                      className='colored-label'
                      style={{ background: item.id === user.id ? USER_COLOR : item.color }}
                    />
                    <Checkbox
                      onChange={(e) => onSelectEmployee(e.target.checked, item.id)}
                      checked={selectedEmployees.includes(item.id)}
                    >
                      {getEmployeeName(item)}
                    </Checkbox>
                  </div>
                )
              )}
          </>
        )
      },
      {
        key: 3,
        label: locale.labels.rooms,
        children: (
          <>
            <Input
              placeholder={locale.placeholders.search}
              allowClear={true}
              onChange={debounce(filterRooms, 500)}
            />
            <Checkbox
              onChange={(e) => onSelectAllRooms(e.target.checked)}
              style={{ marginTop: '15px' }}
              checked={selectedRooms?.length === filteredRooms?.length}
            >
              {locale.labels.selectUnselectAll}
            </Checkbox>
            <Divider style={{ margin: '15px 0' }} />
            {roomsList}
          </>
        )
      }
    ],
    [roomsList, selectedEmployees, filteredEmployees, user, selectedRooms, filteredRooms]
  );

  const onConfirmCancelEventClick = (): void => {
    dispatch(
      cancelEvent(selectedEvent?.id, () => {
        setShowCalcelEventModal(false);
        loadData();
        setSelectedEvent(null);
      })
    );
  };

  const onEditEventClick = () => {
    dispatch(setShowEditEventModal(true));
    setSelectedEvent(null);
  };

  return (
    !!calendarView && (
      <>
        <Button
          type='primary'
          size='small'
          icon={buttonIcon}
          onClick={() => setCollapsed(!collapsed)}
        >
          {locale.buttons.filter}
        </Button>
        {hasDefinedFilters && (
          <Button
            style={{ marginLeft: 10 }}
            type='primary'
            size='small'
            icon={<CloseCircleOutlined style={{ margin: '0px' }} />}
            onClick={clearSelectedFilters}
          >
            {locale.buttons.resetFilters}
          </Button>
        )}
        <Layout
          className='ant-layout-has-sider calendar-layout calendar'
          style={{
            background: 'none',
            paddingTop: 10
          }}
        >
          <aside className={collapsed ? 'aside-closed' : 'aside'}>
            <Collapse
              accordion
              defaultActiveKey='1'
              className='calendar-filter-panel'
              items={collapseItems}
            ></Collapse>
          </aside>
          <Layout style={{ background: 'none' }}>
            <BigCalendar
              localizer={localizer}
              culture={user.locale}
              events={allEvents}
              startAccessor='start'
              endAccessor='end'
              style={{
                height: mobile ? 500 : calendarHeight,
                minHeight: '100%'
              }}
              date={selectedDate}
              onNavigate={onNavigate}
              showMultiDayTimes={true}
              onView={setCalendarView}
              onSelectSlot={onSelectSlot}
              onSelectEvent={onSelectEvent}
              selectable
              components={{
                eventWrapper: ({ event, children }) => {
                  const updatedChild = React.cloneElement(children, {
                    style: {
                      ...children.props.style,
                      borderLeftColor: event.resource.color
                    }
                  });
                  return (
                    <div
                      className='custom-event-wrapper'
                      onContextMenu={(e) => {
                        alert(`${event.title} is clicked.`);
                        e.preventDefault();
                      }}
                    >
                      {updatedChild}
                    </div>
                  );
                }
              }}
              views={[Views.MONTH, Views.WEEK, Views.DAY]}
              defaultView={calendarView}
              view={calendarView}
              step={15}
              formats={bigCalendarFormats}
              scrollToTime={new Date()}
              messages={{
                week: (
                  <span onClick={onWeekClick} className='ant-btn ant-btn-primary ant-btn-sm'>
                    {locale.buttons.week}
                  </span>
                ),
                day: (
                  <span onClick={onDayClick} className='ant-btn ant-btn-primary ant-btn-sm'>
                    {locale.buttons.day}
                  </span>
                ),
                month: (
                  <span onClick={onMonthClick} className='ant-btn ant-btn-primary ant-btn-sm'>
                    {locale.buttons.month}
                  </span>
                ),
                previous: (
                  <span className='ant-btn ant-btn-primary ant-btn-sm ant-btn-icon-only'>
                    <CaretLeftOutlined />
                  </span>
                ),
                next: (
                  <span className='ant-btn ant-btn-primary ant-btn-sm ant-btn-icon-only'>
                    <CaretRightOutlined />
                  </span>
                ),
                yesterday: 'Yesterday',
                tomorrow: 'Tomorrow',
                today: (
                  <span className='ant-btn ant-btn-primary ant-btn-sm'>{locale.buttons.today}</span>
                )
              }}
            />
          </Layout>
        </Layout>
        <Divider />
        {!!selectedEvent && (
          <EventModal
            showEventButtons
            selectedEvent={selectedEvent}
            selectedEventData={selectedEventData}
            setSelectedEvent={setSelectedEvent}
            selectedAppointmentData={selectedAppointmentData}
            locale={locale}
            onEditEventClick={onEditEventClick}
            setShowCalcelEventModal={setShowCalcelEventModal}
          />
        )}
        <AddEvent />
        <ConfirmCancelModal
          cancelText={`${locale.labels.cancelEvent} "${selectedEvent?.title}"?`}
          open={showCalcelEventModal}
          onOk={onConfirmCancelEventClick}
          onCancel={() => setShowCalcelEventModal(false)}
        />
        <EditEvent initialValues={selectedEventData} callbackFunc={loadData} />
      </>
    )
  );
};

export default EventsCalendar;
