import baseClient from '../../baseClient';
import { UpdateUserProfileRequest } from '../../interfaces/users/update-user-profile-request';

const usersUrl: string = '/v1/users';

export const userDetails = async () =>
  baseClient.get(`${usersUrl}/profile`).then((response: any) => {
    if (response === undefined) {
      return response;
    }
    return response.data;
  });

export const updateUserProfile = async (updateUserData: UpdateUserProfileRequest) =>
  baseClient.put(`${usersUrl}/profile`, updateUserData).then((response: any) => {
    return response.data;
  });

export const changePassword = async (data: any) =>
  baseClient.post(`${usersUrl}/password/change`, data).then((response: any) => {
    return response.data;
  });

export const changeEmail = async (data: any) =>
  baseClient.post(`${usersUrl}/email/change`, data).then((response: any) => {
    return response.data;
  });

export const changePhoneNumber = async (data: any) =>
  baseClient.post(`${usersUrl}/phone/change`, data).then((response: any) => {
    return response.data;
  });

export const uploadAvatar = async (avatarData) =>
  baseClient
    .post(`${usersUrl}/profile/avatar`, avatarData, {
      headers: { 'Content-Type': 'multipart/form-data' }
    })
    .then((response: any) => {
      return response.data;
    })
    .catch((error) => {
      return error;
    });
