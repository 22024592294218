import { AxiosResponse } from 'axios';

import baseClient from '../../baseClient';
import { generateSearchQuery } from '../../helpers/commonUtils';
import { ToolsCreateUpdateRequest } from '../../interfaces/clinics/inventory/tools/ToolsCreateUpdateRequest';
import { ToolShowResponse } from '../../interfaces/clinics/inventory/tools/ToolShowResponse';
import { ToolsIndexResponse } from '../../interfaces/clinics/inventory/tools/ToolsIndexResponse';

const toolsUrl = (clinicId: number): string => {
  return `/v1/clinics/${clinicId}/tools`;
};

export const getClinicTools = async (clinicId: number, page: number, searchArray?: any[]) =>
  baseClient
    .get(`${toolsUrl(clinicId)}?page=${page}${generateSearchQuery(searchArray)}`)
    .then((response: AxiosResponse<ToolsIndexResponse>) => {
      return response.data;
    });

export const getClinicToolById = async (clinicId: number, toolId: number) =>
  baseClient
    .get(`${toolsUrl(clinicId)}/${toolId}`)
    .then((response: AxiosResponse<ToolShowResponse>) => {
      return response.data;
    });

export const createClinicTool = async (clinicId: number, data: ToolsCreateUpdateRequest) =>
  baseClient
    .post(`${toolsUrl(clinicId)}`, data)
    .then((response: AxiosResponse<ToolShowResponse>) => {
      return response.data;
    });

export const updateClinicTool = async (
  clinicId: number,
  toolId: number,
  data: ToolsCreateUpdateRequest
) =>
  baseClient
    .put(`${toolsUrl(clinicId)}/${toolId}`, data)
    .then((response: AxiosResponse<ToolShowResponse>) => {
      return response.data;
    });

export const removeClinicTool = async (clinicId: number, toolId: number) =>
  baseClient
    .delete(`${toolsUrl(clinicId)}/${toolId}`)
    .then((response: AxiosResponse<ToolShowResponse>) => {
      return response.data;
    });
