import { AxiosResponse } from 'axios';
import { IInvoiceData } from 'services/interfaces/clinics/inventory/invoice/IInvoiceData';
import {
  ISelectedProvidersItem,
  ISelectedProvidersItemsData,
} from 'services/interfaces/clinics/inventory/providers/ISelectedProvidersItemsData';
import { ISortObject } from 'services/interfaces/ISortObject';

import baseClient from '../../baseClient';
import { generateSearchQuery, sorting } from '../../helpers/commonUtils';

const providersUrl = (clinicId: number): string => {
  return `/v1/clinics/${clinicId}/providers`;
};

export const getClinicProviders = async (clinicId: number, page: number, searchArray?: any[], sortObject?: ISortObject) =>
  baseClient
    .get(`${providersUrl(clinicId)}?page=${page}${generateSearchQuery(searchArray)}${sorting(sortObject)}`)
    .then((response: AxiosResponse<ISelectedProvidersItemsData>) => {
      return response.data;
    });

export const getClinicProviderById = async (clinicId: number, providerId: number) =>
  baseClient
    .get(`${providersUrl(clinicId)}/${providerId}`)
    .then((response: AxiosResponse<ISelectedProvidersItem>) => {
      return response.data;
    });
    
export const getClinicProviderByIdInvoices = async (clinicId: number, providerId: number) =>
  baseClient
    .get(`${providersUrl(clinicId)}/${providerId}/invoices`)
    .then((response: AxiosResponse<IInvoiceData>) => {
      return response.data;
    });
  
export const createClinicProvider = async (clinicId: number, data: ISelectedProvidersItem) =>
  baseClient
    .post(`${providersUrl(clinicId)}`, data)
    .then((response: AxiosResponse<ISelectedProvidersItem>) => {
      return response.data;
    });
 
    export const updateClinicProvider = async (
      clinicId: number,
      providerId: number,
      data: ISelectedProvidersItem
    ) =>
      baseClient
        .put(`${providersUrl(clinicId)}/${providerId}`, data)
        .then((response: AxiosResponse<ISelectedProvidersItem>) => {
          return response.data;
        });
    
    export const removeClinicProvider = async (clinicId: number, providerId: number) =>
      baseClient
        .delete(`${providersUrl(clinicId)}/${providerId}`)
        .then((response: AxiosResponse<ISelectedProvidersItem>) => {
          return response.data;
        });
