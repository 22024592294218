import { AuditItemsHeadResponse } from 'services/interfaces/clinics/inventory/audit/AuditItemsHeadResponse';
import { IAuditList, IAuditListItem } from 'services/interfaces/clinics/inventory/audit/IAuditList';
import { ISelectedAuditItemsData } from 'services/interfaces/clinics/inventory/audit/ISelectedAuditItemsData';

import {
  AuditActions,
  RESET,
  SET_AUDIT,
  SET_BE_ERROR,
  SET_GET_REQUEST_LOADING,
  SET_HEAD_ITEMS,
  SET_LOADING,
  SET_SELECTED_AUDIT,
  SET_SELECTED_AUDIT_ITEMS,
  SHOW_CREATE_MODAL,
} from './types';

export interface AuditState extends IAuditList {
  loading: boolean;
  getLoading: boolean;
  showCreateModal: boolean;
  backendError: boolean;
  selectedAudit: IAuditListItem;
  selectedAuditItems: ISelectedAuditItemsData;
  headItems: AuditItemsHeadResponse;
}

const initialState: AuditState = {
  data: [],
  metadata: {
    total: 0,
    page: 1,
    next: null,
    last: 1,
    prev: null
  },
  loading: true,
  getLoading: false,
  showCreateModal: false,
  backendError: false,
  selectedAudit: null,
  headItems: [],
  selectedAuditItems: {
    data: [],
    metadata: {
      total: 0,
      page: 1,
      next: null,
      last: 1,
      prev: null
    }
  }
};

export function auditReducer(state: IAuditList = initialState, action: AuditActions): any {
  switch (action.type) {
    case SET_AUDIT: {
      return {
        ...state,
        ...action.payload
      };
    }

    case RESET: {
      return initialState;
    }

    case SET_SELECTED_AUDIT: {
      return {
        ...state,
        selectedAudit: action.payload
      };
    }

    case SET_SELECTED_AUDIT_ITEMS: {
      return {
        ...state,
        selectedAuditItems: action.payload
      };
    }

    case SET_HEAD_ITEMS: {
      return {
        ...state,
        headItems: action.payload
      };
    }

    case SET_LOADING: {
      return {
        ...state,
        loading: action.payload
      };
    }

    case SET_GET_REQUEST_LOADING: {
      return {
        ...state,
        getLoading: action.payload
      };
    }

    case SHOW_CREATE_MODAL: {
      return {
        ...state,
        showCreateModal: action.payload
      };
    }

    case SET_BE_ERROR: {
      return {
        ...state,
        backendError: action.payload
      };
    }

    default: {
      return state;
    }
  }
}
