import './AppointmentServices.scss';

import { Button, Col, Divider, Form, FormInstance, Input, Row, Typography } from 'antd';
import VLXInputNumber from 'components/VLXInputNumber';
import useClinicId from 'hooks/useClinicId';
import { useLocale } from 'hooks/useLocale';
import { IService } from 'interfaces/services';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { addAppointmentDetailsHealthRecordInventory } from 'redux/appointments/actions';
import { patternRule, PATTERNS, requiredRule } from 'utils/form-rules';

import { useUserAccess } from '../../../hooks/useUserAccess';
import { AppointmentInventoryFormItem } from './AppointmentInventoryFormItem';

type Props = React.PropsWithChildren<{
  form: FormInstance;
  disabled: boolean;
  onFieldsChange: (e) => void;
  setFormTouched: (e) => void;
  healthRecordId: number;
  serviceData: IService;
}>;

export const AppointmentServicesFormContainer = ({
  form,
  children,
  disabled,
  onFieldsChange,
  healthRecordId,
  setFormTouched,
  serviceData
}: Props): JSX.Element => {
  const dispatch = useDispatch();
  const clinicId = useClinicId();
  const { appointmentId } = useParams();
  const currencies = useLocale('private.currencies').labels;
  const locale = useLocale('private.appointments.event');
  const appModules = useUserAccess().availableModules;

  const serviceId = serviceData?.id;
  const [addedItems, setAddedItems] = useState<number[]>(
    serviceData?.stock?.length ? serviceData?.stock.map((_, index) => index) : []
  );
  const [initialServices, setInitialServices] = useState(serviceData?.stock);

  const onSubmit = (data): void => {
    setFormTouched(false);
    const payload = {
      service_id: serviceId,
      price: Number(data.price).toFixed(2),
      total_price: Number(data.total_price).toFixed(2),
      service_count: data.service_count,
      items: data?.stock
        ? data?.stock.map((el) => ({
            ...el,
            stock_id: el.stock.id,
            location_id: el.stock_location_id,
            price_id: el.stock_price_id,
            price_per_item: Number(el.price).toFixed(2)
          }))
        : []
    };
    dispatch(
      addAppointmentDetailsHealthRecordInventory(clinicId, +appointmentId, healthRecordId, payload)
    );
  };

  const selectItemsMessage = (): string =>
    disabled || serviceData?.stock_saved ? locale.errors.noStockUsed : locale.errors.addItemsOrSave;

  const onServicePriceChange = (value: number): void => {
    const serviceCount = form.getFieldValue('service_count');
    const price = Number(value * serviceCount).toFixed(2);
    form.setFieldValue('total_price', price);
  };

  const onServiceQuantityChange = (value: number): void => {
    const pricePerServise = form.getFieldValue('price');
    const price = Number(value * pricePerServise).toFixed(2);
    form.setFieldValue('total_price', price);
  };

  return (
    <Form
      key={serviceData?.id + serviceData.name}
      onFieldsChange={onFieldsChange}
      disabled={disabled}
      onFinish={onSubmit}
      autoComplete='off'
      layout='vertical'
      form={form}
    >
      <Row gutter={[20, 10]}>
        <Col span={8} sm={12} xs={12} md={8}>
          <Form.Item
            key='servicePrice'
            rules={[requiredRule()]}
            name='price'
            label={`${locale.labels.servicePrice} (${currencies.uah})`}
            initialValue={serviceData?.price}
          >
            <VLXInputNumber disabled={disabled} onChange={onServicePriceChange} min={0.01} />
          </Form.Item>
        </Col>
        <Col span={8} sm={12} xs={12} md={8}>
          <Form.Item
            key='service_count'
            rules={[
              requiredRule(),
              patternRule(PATTERNS.DIGITS_1_9, locale.errors.onlyNumberAllowed)
            ]}
            name='service_count'
            label={locale.labels.count}
            initialValue={serviceData?.service_count || 1}
          >
            <VLXInputNumber
              disabled={disabled}
              precision={0}
              step={1}
              min={1}
              onChange={onServiceQuantityChange}
            />
          </Form.Item>
        </Col>
        <Col span={8} sm={12} xs={12} md={8}>
          <Form.Item
            key='servicePrice'
            rules={[requiredRule(locale.errors.enterRoom)]}
            name='total_price'
            label={`${locale.labels.sum} (${currencies.uah})`}
            initialValue={serviceData?.total_price || serviceData?.price}
          >
            <Input disabled type={'number'} />
          </Form.Item>
        </Col>
      </Row>
      <Divider />
      {!appModules.inventory ? (
        <Typography.Text type='secondary'>{locale.messages.inventoryInPremium}</Typography.Text>
      ) : (
        <>
          <Typography.Title style={{ marginBottom: '20px' }} level={5}>
            {locale.labels.stockItems}
          </Typography.Title>
          {!addedItems?.length ? (
            <Typography.Text type='secondary'>{selectItemsMessage()}</Typography.Text>
          ) : null}
          <Form.List name='stock' initialValue={initialServices}>
            {(fields, { add, remove }) => (
              <>
                {fields.map(({ key, name }) => (
                  <AppointmentInventoryFormItem
                    key={key}
                    name={name}
                    initialData={initialServices[name]}
                    remove={() => {
                      setAddedItems(addedItems.filter((el, index) => index !== name));
                      setInitialServices(initialServices.filter((_, index) => index !== name));
                      remove(name);
                    }}
                    form={form}
                    disabled={disabled}
                    setAddedItems={setAddedItems}
                  />
                ))}
                <Row>
                  <Form.Item key='add-more-stock'>
                    {!disabled && (
                      <Button
                        id='add-more-stock'
                        className='icofont icofont-plus'
                        type={'primary'}
                        style={{ borderRadius: 30, marginTop: '10px' }}
                        onClick={() => add()}
                      >
                        {locale.buttons.addNewItem}
                      </Button>
                    )}
                  </Form.Item>
                </Row>
              </>
            )}
          </Form.List>
        </>
      )}
      {children}
    </Form>
  );
};
