const coats = [
  {
    value: 'short',
    enGB: 'Short',
    ukUA: 'Коротка'
  },
  {
    value: 'long',
    enGB: 'Long',
    ukUA: 'Довга'
  },
  {
    value: 'hairless',
    enGB: 'Hairless',
    ukUA: 'Безшерста'
  },
  {
    value: 'rex',
    enGB: 'Rex',
    ukUA: 'Рекс'
  },
  {
    value: 'satin',
    enGB: 'Satin',
    ukUA: 'Сатинова'
  },
  {
    value: 'wavy',
    enGB: 'Wavy',
    ukUA: 'Хвиляста'
  },
  {
    value: 'other',
    enGB: 'Other',
    ukUA: 'Інша'
  }
];

export default coats;
