import { ISkill } from '../../interfaces/skills';

export const SET_SKILLS = '[skills] Set';
export const SET_LOADING = '[skills] Loading';
export const RESET = '[skills] RESET';

export interface SkillsResetAction {
  type: typeof RESET
}

export interface SetSkillsAction {
  type: typeof SET_SKILLS
  payload: ISkill[]
}

export interface SetLoadingAction {
  type: typeof SET_LOADING
  payload: boolean
}

export type SkillsActions =
  | SetSkillsAction
  | SkillsResetAction
  | SetLoadingAction;
