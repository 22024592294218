import { IBillDetails } from 'services/clinic/bills/models/IBillDetails';
import { IndexBillsResponse } from 'services/clinic/bills/models/IndexBillsResponse';

export const SET_BILLS_LOADING = 'SET_BILLS_LOADING';
export const SET_BILLS = 'SET_BILLS';
export const SET_BILL = 'SET_BILL';
export const SHOW_MODAL = 'SHOW_MODAL';
export const SET_BILL_PAYMENTS = 'SET_BILL_PAYMENTS';
export const SHOW_PAYMENTS_MODAL = 'SHOW_PAYMENTS_MODAL';

export const RESET = '[bills] RESET';

export interface BillsResetAction {
  type: typeof RESET;
}

export interface SetBillsAction {
  type: typeof SET_BILLS;
  payload: IndexBillsResponse;
}

export interface SetLoadingAction {
  type: typeof SET_BILLS_LOADING;
  payload: boolean;
}

export interface ShowModalAction {
  type: typeof SHOW_MODAL;
  payload: boolean;
}

export interface ShowPaymentsModalAction {
  type: typeof SHOW_PAYMENTS_MODAL;
  payload: boolean;
}

export interface SetSelectedBillAction {
  type: typeof SET_BILL;
  payload: IBillDetails | null;
}

export interface SetSelectedBillPaymentsAction {
  type: typeof SET_BILL_PAYMENTS;
  payload: IBillDetails[];
}

export type BillsActions =
  | SetBillsAction
  | BillsResetAction
  | ShowModalAction
  | SetSelectedBillAction
  | SetLoadingAction
  | SetSelectedBillPaymentsAction
  | ShowPaymentsModalAction;
