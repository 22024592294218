export const redirectURL = 'redirectURL';

export const animalIdUrl = 'https://animal-id.net/en/animal/search?search=';

export const eventsColors = [
  '#eb2f96',
  '#D22B2B',
  '#478778',
  '#FDDA0D',
  '#820014',
  '#874d00',
  '#135200',
  '#061178',
  '#871400',
  '#873800',
  '#876800',
  '#3f6600',
  '#00474f',
  '#002c8c',
  '#061178',
  '#22075e',
  '#780650'
];

export const languagesList = [
  {
    searchName: 'en',
    antdName: 'enGB'
  },
  {
    searchName: 'uk',
    antdName: 'ukUA'
  }
];

// TODO: set all date/time formats
export const dateFormat = '';

export const DATE_TIME_FORMAT = 'DD.MM.YYYYTHH:mm';

export const timeFormat = '';

export const statusColors = {
  future: '#87d068',
  closed: '#f50'
};

export const roomStatusColor = {
  active: 'success',
  maintenance: 'processing',
  closed: 'error'
};

export const enterKeyCode = 13;
