import '../scss/StockDetails.scss';

import { Button, Checkbox, Col, Form, FormInstance, notification, Row } from 'antd';
import VLXInputNumber from 'components/VLXInputNumber';
import useClinicId from 'hooks/useClinicId';
import { IAppState } from 'interfaces/app-state';
import { ILocale } from 'interfaces/locale';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { reassignPrices } from 'redux/inventory/stock/actions';
import { IReassignedPrice } from 'services/interfaces/clinics/inventory/stock/IReassignPricesRequest';

import { useGetResidual } from '../utils/useGetResidual';

type Props = React.PropsWithChildren<{
  form: FormInstance;
  locale: ILocale;
  setShowReassignPriceModal: (show) => void;
}>;

type ReassignInputType = {
  label: string | JSX.Element;
  name: [number, string];
  required?: boolean;
  onChange?: (val) => void;
};

type PriceListType = {
  id: number;
  remove?: (item) => void;
};

export const ReassignPriceForm = ({
  children,
  form,
  locale,
  setShowReassignPriceModal
}: Props): JSX.Element => {
  const clinicId = useClinicId();
  const dispatch = useDispatch();

  const selectedStock = useSelector(({ stock }: IAppState) => stock.selectedStock);
  const prices = useMemo(
    () => selectedStock.prices.filter((el) => !el.deleted).sort((el) => (el.default ? -1 : 1)),
    [selectedStock?.prices]
  );

  const { getResidual, unitOfMeasurement, packagingFormat, noPackageFormat } =
    useGetResidual(selectedStock);

  const [defaultPrice, setDefaultPrice] = useState<number>();
  const [totalQuantity, setTotalQuantity] = useState<number>(0);

  useEffect((): void => {
    setDefaultPrice(prices.findIndex((price) => price.type === 'default'));
  }, [selectedStock]);

  const onSubmit = async ({ list }: { list: IReassignedPrice[] }): Promise<void> => {
    if (form.getFieldError('residual')[0]) return;

    const prices = list
      .map((item, index) => ({
        ...item,
        default: index === defaultPrice,
        quantity_packages: noPackageFormat ? 0 : item.quantity_packages,
        quantity_units: item.quantity_units || 0,
        price_per_package: noPackageFormat ? item.price_per_unit : item.price_per_package
      }))
      .filter((item) => item.quantity_packages || item.quantity_units);

    dispatch(
      reassignPrices(clinicId, selectedStock.id, { prices }, () => {
        notification.success({ message: locale.messages.pricesReassignedSuccessfully });
        setShowReassignPriceModal(false);
      })
    );
  };

  const getBaseResidual = (list: IReassignedPrice[]): number => {
    let residual = selectedStock.residual;

    list.forEach((item) => {
      if (!item) return;
      residual -=
        (!noPackageFormat ? item.quantity_packages : 0) * selectedStock.quantity_per_package;
      residual -= item.quantity_units || 0;
    });

    return residual;
  };

  const residualValidate = (): void => {
    let residual = getBaseResidual(form.getFieldsValue().list);

    form.setFields([
      {
        name: 'residual',
        errors:
          residual < 0
            ? [locale.errors.notEnoughUnassignedResidual.replace(/%1/, getResidual(residual * -1))]
            : []
      }
    ]);

    setTotalQuantity(residual);
  };

  const ReassignInput = useCallback(
    ({ label, name, required, onChange }: ReassignInputType) => (
      <Col lg={5} md={12} sm={12} xs={24}>
        <Form.Item label={label} name={name} rules={[{ required }]}>
          <VLXInputNumber onChange={onChange} />
        </Form.Item>
      </Col>
    ),
    [selectedStock]
  );

  const PricesList = useCallback(
    ({ id, remove }: PriceListType) => (
      <Row key={id} gutter={24} className='invitation-creation-form-row'>
        <Form.Item name='id' />
        {!noPackageFormat && (
          <ReassignInput
            label={`${locale.labels.priceFor} (${packagingFormat})`}
            name={[id, 'price_per_package']}
            required
          />
        )}
        <ReassignInput
          label={`${locale.labels.priceFor} (${unitOfMeasurement})`}
          name={[id, 'price_per_unit']}
          required
        />
        {!noPackageFormat && (
          <ReassignInput
            label={`${locale.labels.qty} (${packagingFormat})`}
            name={[id, 'quantity_packages']}
            onChange={residualValidate}
          />
        )}
        <ReassignInput
          label={`${locale.labels.qty} (${unitOfMeasurement})`}
          name={[id, 'quantity_units']}
          onChange={residualValidate}
        />
        <Col lg={4} md={8} sm={12} xs={12}>
          <Form.Item label={locale.labels.default} valuePropName='checked'>
            <Checkbox checked={id === defaultPrice} onChange={() => setDefaultPrice(id)} />
            <Button
              disabled={id === defaultPrice}
              onClick={() => {
                remove(id);
                if (id <= defaultPrice) setDefaultPrice(defaultPrice - 1);
                residualValidate();
              }}
              danger
              type='primary'
              shape={'circle'}
              className='remove-btn'
            >
              <span className='icofont icofont-trash mr-2' />
            </Button>
          </Form.Item>
        </Col>
      </Row>
    ),
    [selectedStock?.prices, defaultPrice]
  );

  return (
    <Form
      onFinish={onSubmit}
      autoComplete='off'
      layout='vertical'
      form={form}
      initialValues={{
        list: prices.map((price) => ({
          price_per_package: price.price_per_package,
          price_per_unit: price.price_per_unit,
          quantity_packages: price.quantity,
          quantity_units: noPackageFormat
            ? price.residual
            : price.residual % selectedStock.quantity_per_package
        }))
      }}
    >
      {!noPackageFormat && (
        <Col span={24}>
          <span style={{ fontWeight: 600 }}>
            1 {packagingFormat} = {selectedStock.quantity_per_package} {unitOfMeasurement}
          </span>
        </Col>
      )}
      <Form.Item name='residual' style={{ marginBottom: 40 }}>
        <Row>
          <Col md={24} lg={10}>
            <span>
              {locale.labels.totalResidual}: {getResidual(selectedStock?.residual)}
            </span>
          </Col>
          <Col md={24} lg={10}>
            <span>
              {locale.labels.availableUnassignedResidual}:{' '}
              {getResidual(totalQuantity > 0 ? totalQuantity : 0)}
            </span>
          </Col>
        </Row>
      </Form.Item>

      <Form.List name='list'>
        {(fields, { add, remove }) => (
          <>
            {fields.map(({ key, name }) => (
              <React.Fragment key={key}>
                <PricesList key={key} id={name} remove={remove} />
              </React.Fragment>
            ))}
            <Form.Item>
              <Button type='primary' onClick={() => add()} style={{ borderRadius: 30 }}>
                <span className='icofont icofont-plus mr-2' style={{ fontSize: '1.3em' }} />
                <span>{locale.buttons.add}</span>
              </Button>
            </Form.Item>
          </>
        )}
      </Form.List>
      {children}
    </Form>
  );
};
