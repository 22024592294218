const animalColors = [
  {
    value: 'albino',
    enGB: 'Albino',
    ukUA: 'Альбінос'
  },
  {
    value: 'black',
    enGB: 'Black',
    ukUA: 'Чорний'
  },
  {
    value: 'cinnamon',
    enGB: 'Cinnamon',
    ukUA: 'Коричневий (кориця)'
  },
  {
    value: 'golden',
    enGB: 'Golden',
    ukUA: 'Золотистий'
  },
  {
    value: 'himalayan',
    enGB: 'Himalayan',
    ukUA: 'Гімалайський'
  },
  {
    value: 'calico',
    enGB: 'Calico',
    ukUA: 'Каліко'
  },
  {
    value: 'lilac',
    enGB: 'Lilac',
    ukUA: 'Ліловий'
  },
  {
    value: 'sable',
    enGB: 'Sable',
    ukUA: 'Соболь'
  },
  {
    value: 'gray',
    enGB: 'Gray',
    ukUA: 'Сірий'
  },
  {
    value: 'siamese',
    enGB: 'Siamese',
    ukUA: 'Сіамський'
  },
  {
    value: 'silver',
    enGB: 'Silver',
    ukUA: 'Сріблястий'
  },
  {
    value: 'white',
    enGB: 'White',
    ukUA: 'Білий'
  },
  {
    value: 'blue',
    enGB: 'Blue',
    ukUA: 'Блакитний'
  },
  {
    value: 'tortoiseshell',
    enGB: 'Tortoiseshell',
    ukUA: 'Мозаїчний'
  },
  {
    value: 'dalmatian',
    enGB: 'Dalmatian',
    ukUA: 'Далматинець'
  },
  {
    value: 'satin',
    enGB: 'Satin',
    ukUA: 'Сатиновий'
  },
  {
    value: 'rex',
    enGB: 'Rex',
    ukUA: 'Рекс'
  },
  {
    value: 'other',
    enGB: 'Other',
    ukUA: 'Інший'
  }
];

export default animalColors;
