import { IInvoiceData } from 'services/interfaces/clinics/inventory/invoice/IInvoiceData';
import {
  ISelectedProvidersItem,
  ISelectedProvidersItemsData,
} from 'services/interfaces/clinics/inventory/providers/ISelectedProvidersItemsData';

import {
  ProvidersActions,
  SET_PROVIDERS_DATA,
  SET_PROVIDERS_LOADING,
  SET_SELECTED_PROVIDER_DATA,
  SET_SELECTED_PROVIDER_INVOICES_DATA,
  SET_SHOW_CREATE_PROVIDER_MODAL,
  SET_SHOW_EDIT_PROVIDER_MODAL,
} from './types';

export interface ProvidersListState extends ISelectedProvidersItemsData {
  loading: boolean
  showCreateModal: boolean
  showEditModal: boolean
  selectedProviderData: ISelectedProvidersItem
  selectedProviderInvoicesData: IInvoiceData
}

const initialState: ProvidersListState = {
  data: [],
  metadata: {
    total: 0,
    page: 1,
    next: null,
    last: 1,
    prev: null
  },
  loading: false, 
  showCreateModal: false, 
  showEditModal: false, 
  selectedProviderData: null,
  selectedProviderInvoicesData: null
};

export function providerReducer (
  state: ISelectedProvidersItemsData = initialState,
  action: ProvidersActions
): any {
  switch (action.type) {
    case SET_PROVIDERS_DATA: {
      return {
        ...state,
        ...action.payload
      };
    } 

    case SET_SELECTED_PROVIDER_DATA: {
      return {
        ...state,
        selectedProviderData: action.payload
      };
    } 

    case SET_SELECTED_PROVIDER_INVOICES_DATA: {
      return {
        ...state,
        selectedProviderInvoicesData: action.payload
      };
    } 

    case SET_PROVIDERS_LOADING: {
      return {
        ...state,
        loading: action.payload
      };
    } 

    case SET_SHOW_CREATE_PROVIDER_MODAL: {
      return {
        ...state,
        showCreateModal: action.payload
      };
    }

    case SET_SHOW_EDIT_PROVIDER_MODAL: {
      return {
        ...state,
        showEditModal: action.payload
      };
    }

    default: {
      return state;
    }
  }
}
