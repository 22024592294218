import { Button, Tag } from 'antd';
import { SAVED_STATUS } from 'constants/enums';

import { defineStatus } from '../AuditDetails/utils/defineStatus';

export const SaveAction = (props): JSX.Element => {
  const { value, saveChanges, locale, status } = props;

  return (
    <>
      {value === SAVED_STATUS.unsaved && (
        <>
          <Tag color='red' className='changes-status'>
            {locale.buttons.unsavedChanges}
          </Tag>
          <Button
            id='add-more-stock'
            className={`icofont icofont-save save-btn`}
            type='primary'
            onClick={() => saveChanges()}
          ></Button>
        </>
      )}
      {value === SAVED_STATUS.saved && defineStatus(status, locale)}
    </>
  );
};
