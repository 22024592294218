import {
  GET_STOCK_CATEGORIES,
  GetStockCategoriesAction,
  RESET,
  SET_LOADING,
  SET_SEARCH_QUERY,
  SetLoadingAction,
  SetSearchQueryAction,
  SHOW_MODAL,
  ShowModalAction,
  StockCategoriesResetAction
} from './types';
import {
  IndexDictionaryRecordsResponse
} from '../../../services/clinic/dictionaries/models/IndexDictionaryRecordsResponse';
import {
  CreateDictionaryRecordRequest
} from '../../../services/clinic/dictionaries/models/CreateDictionaryRecordRequest';
import {
  createClinicStockCategory,
  destroyClinicStockCategory,
  indexClinicStockCategories,
  updateClinicStockCategory
} from '../../../services/clinic/dictionaries/stockCategoriesService';

export const setStockCategories = (diagnoses: IndexDictionaryRecordsResponse): GetStockCategoriesAction => ({
  type: GET_STOCK_CATEGORIES,
  payload: diagnoses
});

export const resetStockCategories = (): StockCategoriesResetAction => ({
  type: RESET
});

export const showModal = (value: boolean): ShowModalAction => ({
  type: SHOW_MODAL,
  payload: value
});

export const setLoading = (value: boolean): SetLoadingAction => ({
  type: SET_LOADING,
  payload: value
});

export const setSearchQuery = (data: any[]): SetSearchQueryAction => ({
  type: SET_SEARCH_QUERY,
  payload: data
});

export const indexStockCategories = (clinicId: number, page: number = 1, searchValue?: any, callBackFnc?: any, pageSize?: number): any => {
  return dispatch => {
    dispatch(setLoading(true));
    indexClinicStockCategories(clinicId, page, searchValue, pageSize)
      .then(data => {
        if (callBackFnc) {
          callBackFnc();
        }
        dispatch(setStockCategories(data));
        dispatch(setLoading(false));
        dispatch(setSearchQuery(searchValue));
      })
      .catch(() => dispatch(setLoading(false)));
  };
};

export const createStockCategory = (clinicId: number, data: CreateDictionaryRecordRequest,
  page = null,
  searchArray = null, callBackFnc?: any): any => {
  return dispatch => {
    dispatch(setLoading(true));
    createClinicStockCategory(clinicId, data)
      .then(data => {
        if (callBackFnc) {
          callBackFnc(data);
        }
        dispatch(indexStockCategories(clinicId, page, searchArray));
        dispatch(setLoading(false));
      })
      .catch(() => dispatch(setLoading(false)));
  };
};

export const updateStockCategory = (clinicId: number, diagnosisId: number, data: CreateDictionaryRecordRequest,
  page = null,
  searchArray = null): any => {
  return dispatch => {
    dispatch(setLoading(true));
    updateClinicStockCategory(clinicId, diagnosisId, data)
      .then(data => {
        dispatch(indexStockCategories(clinicId, page, searchArray));
        dispatch(setLoading(false));
      })
      .catch(() => dispatch(setLoading(false)));
  };
};

export const destroyStockCategory = (clinicId: number, diagnosisId: number,
  page = null,
  searchArray = null): any => {
  return dispatch => {
    dispatch(setLoading(true));
    destroyClinicStockCategory(clinicId, diagnosisId)
      .then(data => {
        dispatch(indexStockCategories(clinicId, page, searchArray));
        dispatch(setLoading(false));
      })
      .catch(() => dispatch(setLoading(false)));
  };
};
