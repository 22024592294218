import React from 'react';
import { IAppointment } from '../interfaces/appointment.ts';
import { Tooltip } from 'antd';
import warningIcon from '../assets/img/warning.svg';
import { ILocale } from '../interfaces/locale.ts';

type Props = {
  appointment: IAppointment;
  locale: ILocale;
};

export const IncompleteHealthRecordMarker = ({
  appointment,
  locale
}: Props): JSX.Element => {
  if (!appointment.health_records_incomplete) return null;

  return (
    <Tooltip trigger={'hover'} placement='topLeft' title={locale.messages.incompleteHealthRecords}>
      <img
        style={{
          height: 20,
          width: 20,
          verticalAlign: -4
        }}
        src={warningIcon}
      />
    </Tooltip>
  );
};
