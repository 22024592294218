import '../scss/ToolForm.scss';
import './ToolForm.scss';

import { Col, DatePicker, Form, Input, Row, Select } from 'antd';
import VLXInputNumber from 'components/VLXInputNumber';
import { toolStatuses, toolTypes } from 'constants/dictionary/default/inventoryOptions';
import { locationTypes } from 'constants/dictionary/default/selectOptions';
import dayjs from 'dayjs';
import { useLocale } from 'hooks/useLocale';
import useLocalizedList from 'hooks/useLocalizedList';
import { IAppState } from 'interfaces/app-state';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getEmployees } from 'redux/employees/actions';
import { getRooms } from 'redux/rooms/actions';
import { patternRule, PATTERNS, requiredRule } from 'utils/form-rules';

import useClinicId from '../../../../hooks/useClinicId';

const { Option } = Select;

const ToolForm = ({
  formKey,
  onFieldsChange,
  onFinish,
  form,
  initialValues,
  initialRoom,
  initialLocationType,
  initialLocationDetails,
  children
}: any): JSX.Element => {
  const [locationType, setLocationType] = useState<string>(initialLocationType || 'room');
  const dispatch = useDispatch();
  const clinicId = useClinicId();
  const locale = useLocale('private.inventory.tools');
  const rooms = useSelector<IAppState, any>((state) => state.rooms.data);
  const locations = useLocalizedList(locationTypes);

  function onLocationTypeSelect(value: any) {
    setLocationType(value);
  }

  useEffect(() => {
    dispatch(getEmployees(clinicId, 1));
    dispatch(getRooms(clinicId, 0));
  }, []);

  const roomsList = rooms
    .filter((room) => !room.deleted || room.id === initialRoom?.value)
    .map((item) => {
      return (
        <Option key={`${item.name}${item.id}`} title={item.name} value={item.id}>
          {item.name}
        </Option>
      );
    });

  return (
    <Form
      key={formKey}
      onFieldsChange={onFieldsChange}
      onFinish={onFinish}
      autoComplete='off'
      layout='vertical'
      form={form}
      initialValues={initialValues}
      className='add-tool-form'
    >
      <Row gutter={[40, 0]} className={'form-row'}>
        <Col span={24} sm={20} md={12} xl={12} xxl={12} className={'form-col'}>
          <Form.Item label={<h5>{locale.labels.deviceDetails}</h5>}>
            <Form.Item
              name='name'
              label={locale.labels.name}
              rules={[
                requiredRule(locale.messages.nameNotValid),
                patternRule(PATTERNS.CHAR_100_MAX, locale.messages.nameNotValid)
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item name='manufacturer' label={locale.labels.manufacturer}>
              <Input />
            </Form.Item>
            <Form.Item name='tool_type' label={locale.labels.toolType}>
              <Select filterOption={false} options={useLocalizedList(toolTypes)} />
            </Form.Item>
            <Form.Item
              label={locale.labels.warrantyExpirationDate}
              name={'warranty_expiration_date'}
            >
              <DatePicker />
            </Form.Item>
          </Form.Item>
          <Form.Item label={<h5>{locale.labels.location}</h5>}>
            <Form.Item
              name={['location', 'type']}
              id='location_type'
              label={locale.labels.locationType}
              rules={[requiredRule(locale.messages.emptyLocation)]}
              initialValue={initialLocationType || 'room'}
            >
              <Select filterOption={false} onSelect={onLocationTypeSelect} options={locations} />
            </Form.Item>
            <Form.Item
              name={['location', 'details']}
              label={locale.labels.other}
              hidden={locationType !== 'other'}
              rules={locationType === 'other' ? [requiredRule(locale.messages.emptyLocation)] : []}
              initialValue={initialLocationDetails}
            >
              <Input />
            </Form.Item>
            <Form.Item
              name={['location', 'id']}
              hidden={locationType !== 'room'}
              label={locale.labels.room}
              className='add-tool-form__single-select-field'
              initialValue={initialRoom?.value}
            >
              <Select
                allowClear={true}
                filterOption={(input, option) => {
                  return option.key.toLowerCase().indexOf(input.toLowerCase()) >= 0;
                }}
                showSearch
              >
                {roomsList}
              </Select>
            </Form.Item>
          </Form.Item>
        </Col>
        <Col span={24} sm={20} md={12} xl={12} xxl={12} className={'form-col'}>
          <Form.Item label={<h5>{locale.labels.purchaseInformation}</h5>}>
            <Form.Item name='purchase_date' label={locale.labels.purchaseDate}>
              <DatePicker disabledDate={(d) => !d || d.isAfter(dayjs())} format='YYYY-MM-DD' />
            </Form.Item>
            <Form.Item
              className={'purchase-price-form-item'}
              name='purchase_price'
              label={locale.labels.purchasePrice}
            >
              <VLXInputNumber />
            </Form.Item>
          </Form.Item>
          <Form.Item label={<h5>{locale.labels.conditionAndInspectionDetails}</h5>}>
            <Form.Item name={'condition'} label={locale.labels.toolCondition}>
              <Select filterOption={false} options={useLocalizedList(toolStatuses)} />
            </Form.Item>
          </Form.Item>
          <Form.Item label={<h5>{locale.labels.lifetimeInfo}</h5>}>
            <Form.Item
              label={locale.labels.deviceUsefulLifetime}
              name='useful_life'
              rules={[patternRule(PATTERNS.DIGITS, locale.messages.onlyNumberAllowed)]}
            >
              <Input type={'number'} />
            </Form.Item>
            <Form.Item
              label={locale.labels.numberOfTimesToolsWasUsed}
              name='number_of_uses'
              rules={[patternRule(PATTERNS.DIGITS, locale.messages.onlyNumberAllowed)]}
            >
              <Input type={'number'} />
            </Form.Item>
          </Form.Item>
        </Col>
      </Row>
      {children}
    </Form>
  );
};

export default ToolForm;
