import { Tag } from 'antd';
import React from 'react';

const getStatusTag = (appointmentStatus) => {
  let color = null;

  switch (appointmentStatus?.value.toLowerCase()) {
    case 'future':
      color = 'grey';
      break;
    case 'in_progress':
      color = 'blue';
      break;
    case 'finished':
      color = 'green';
      break;
    case 'cancelled':
      color = 'red';
      break;
    case 'paid':
      color = 'green';
      break;
    case 'partially_paid':
      color = 'yellow';
      break;
    case 'unpaid':
      color = 'yellow';
      break;
  }

  return tag(color, appointmentStatus?.label);
};

const tag = (color: string, value: string) => {
  return (
    <Tag id='appointment-status' color={color} style={{ userSelect: 'none', fontWeight: 'bold' }}>
      {value}
    </Tag>
  );
};

export default getStatusTag;
