import 'moment/locale/uk';
import './scss/DocumentsTab.scss';

import { UploadOutlined } from '@ant-design/icons';
import { Button, Progress, Upload, UploadFile } from 'antd';
import ConfirmDeleteModal from 'components/ConfirmDeleteModal';
import { useLocale } from 'hooks/useLocale';
import { IDocumentDetails } from 'interfaces/document-details';
import React, { useEffect, useState } from 'react';
import baseClient from 'services/baseClient';

type Props = {
  data: IDocumentDetails[];
  setDocumentsList: (data: any) => void;
  uploadDocumentLink: string;
  removeDocument?: (e: UploadFile) => void;
};

const DocumentsTab = ({
  data,
  setDocumentsList,
  uploadDocumentLink,
  removeDocument
}: Props): JSX.Element => {
  const locale = useLocale('private.clients.client');
  const [progress, setProgress] = useState(0);
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
  const [documentToRemove, setDocumentToRemove] = useState(null);
  const [filesList, setFilesList] = useState(
    data?.length
      ? data.map((el) => {
          return { ...el, url: el?.file, status: 'done', uid: el?.file };
        })
      : []
  );

  const uploadImage = async (options): Promise<void> => {
    const { onSuccess, onError, file, onProgress } = options;
    const createClinicClientDocument = async (uploadDocumentLink: string, data: any) =>
      baseClient.post(uploadDocumentLink, data, config).then((response: any) => {
        return response.data;
      });

    const config = {
      headers: { 'content-type': 'multipart/form-data' },
      onUploadProgress: (event) => {
        const percent = Math.floor((event.loaded / event.total) * 100);
        setProgress(percent);
        if (percent === 100) {
          setTimeout(() => setProgress(0), 1000);
        }
        onProgress({ percent: (event.loaded / event.total) * 100 });
      }
    };

    const form_data = new FormData();
    form_data.append('document_file', file);
    form_data.append('name', file.name);
    form_data.append('document_type', file.type);
    try {
      createClinicClientDocument(uploadDocumentLink, form_data).then((res) => {
        setDocumentsList(res);
      });

      onSuccess('Ok');
    } catch (err) {
      onError({ err });
    }
  };

  const downloadFile = (url: string, filename: string): void => {
    fetch(url)
      .then(async (response) => response.blob())
      .then((blob) => {
        const link = document.createElement('a');
        link.href = URL.createObjectURL(blob);
        link.download = filename;
        link.click();
      })
      .catch(console.error);
  };

  useEffect(() => {
    setFilesList(
      data?.length
        ? data.map((el) => {
            return { ...el, url: el?.file, status: 'done', uid: el?.file };
          })
        : []
    );
  }, [data]);

  const handleOnChange = ({ file }) => {
    if (filesList.some((el) => el?.uid === file?.uid)) {
      setFilesList(filesList.filter((el) => el?.uid !== file?.uid));
    } else {
      setFilesList([file, ...filesList]);
    }
  };

  const onConfirmDeleteClick = () => {
    setShowDeleteModal(false);
    removeDocument(documentToRemove);
  };

  return (
    <>
      <div className='documents-tab'>
        <Upload
          multiple
          accept='image/png, image/jpeg, application/pdf, .doc, .docx'
          fileList={filesList as any}
          onChange={handleOnChange}
          customRequest={uploadImage}
          listType='picture'
          onDownload={async (e: any) => downloadFile(e.file, e.name)}
          showUploadList={{ showDownloadIcon: true, showRemoveIcon: !!removeDocument }}
          onRemove={(e) => {
            setDocumentToRemove(e);
            setShowDeleteModal(true);
            return false;
          }}
        >
          <Button type='primary' icon={<UploadOutlined />}>
            {locale.labels.selectFile}
          </Button>
          {progress > 0 ? <Progress style={{ padding: '20px 0' }} percent={progress} /> : null}
        </Upload>
      </div>
      <ConfirmDeleteModal
        type={'room'}
        open={showDeleteModal}
        onOk={onConfirmDeleteClick}
        onCancel={() => setShowDeleteModal(false)}
        customTitle={`${locale.messages.removeDocumentTxt} "${documentToRemove?.name}"?`}
      />
    </>
  );
};

export default DocumentsTab;
