import {
  RESET,
  SET_BE_ERROR,
  SET_LOADING,
  SET_SELECTED_TOOL,
  SET_TOOLS,
  SetBeError,
  SetLoadingAction,
  SetSelectedToolAction,
  SetToolsAction,
  SHOW_CREATE_MODAL,
  ShowCreateModal,
  ToolsResetAction
} from './types';

import { notification } from 'antd';
import { ToolsIndexResponse } from '../../../services/interfaces/clinics/inventory/tools/ToolsIndexResponse';
import { ToolShowResponse } from '../../../services/interfaces/clinics/inventory/tools/ToolShowResponse';
import {
  createClinicTool,
  getClinicToolById,
  getClinicTools,
  removeClinicTool,
  updateClinicTool
} from '../../../services/clinic/inventory/toolsService';
import {
  ToolsCreateUpdateRequest
} from '../../../services/interfaces/clinics/inventory/tools/ToolsCreateUpdateRequest';

export const setTools = (data: ToolsIndexResponse): SetToolsAction => ({
  type: SET_TOOLS,
  payload: data
});

export const resetTools = (): ToolsResetAction => ({
  type: RESET
});

export const setSelectedTool = (data: ToolShowResponse): SetSelectedToolAction => ({
  type: SET_SELECTED_TOOL,
  payload: data
});

export const setLoading = (value: boolean): SetLoadingAction => ({
  type: SET_LOADING,
  payload: value
});

export const setBackendError = (value: boolean): SetBeError => ({
  type: SET_BE_ERROR,
  payload: value
});

export const showCreateModal = (value: boolean): ShowCreateModal => ({
  type: SHOW_CREATE_MODAL,
  payload: value
});

export const getTools = (clinicId: number, page: number, searchArray?: any[]): any => {
  return (dispatch) => {
    dispatch(setLoading(true));
    getClinicTools(clinicId, page, searchArray)
      .then((data) => {
        dispatch(setTools(data));
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => dispatch(setLoading(false)));
  };
};

export const getToolById = (clinicId: number, deviceId: number): any => {
  return (dispatch) => {
    dispatch(setLoading(true));
    getClinicToolById(clinicId, deviceId)
      .then((data) => {
        dispatch(setSelectedTool(data));
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => dispatch(setLoading(false)));
  };
};

export const createTool = (clinicId: number, data: ToolsCreateUpdateRequest): any => {
  return (dispatch) => {
    dispatch(setLoading(true));
    createClinicTool(clinicId, data)
      .then((data) => {
        dispatch(getTools(clinicId, 1));
        dispatch(showCreateModal(false));
      })
      .catch((err) => {
        dispatch(setBackendError(true));
        console.log(err);
      })
      .finally(() => dispatch(setLoading(false)));
  };
};

export const updateTool = (clinicId: number, deviceId: number, data: ToolsCreateUpdateRequest): any => {
  return (dispatch) => {
    dispatch(setLoading(true));
    updateClinicTool(clinicId, deviceId, data)
      .then((data) => {
        dispatch(getToolById(clinicId, deviceId));
      })
      .catch((err) => {
        dispatch(setBackendError(true));
        console.log(err);
      })
      .finally(() => dispatch(setLoading(false)));
  };
};

export const removeTool = (clinicId: number, deviceId: number, locale): any => {
  return (dispatch) => {
    dispatch(setLoading(true));
    removeClinicTool(clinicId, deviceId)
      .then((data) => {
        notification.success({ message: locale.messages.toolRemovedSuccessfully });
        dispatch(setSelectedTool(null));
        dispatch(getTools(clinicId, 1));
      })
      .catch((err) => {
        dispatch(setBackendError(true));
        console.log(err);
      })
      .finally(() => dispatch(setLoading(false)));
  };
};
