import { AxiosResponse } from 'axios';

import baseClient from '../../baseClient';
import { generateSearchQuery } from '../../helpers/commonUtils';
import { EquipmentCreateUpdateRequest } from '../../interfaces/clinics/inventory/equipment/equipment-create-update-request';
import { EquipmentIndexResponse } from '../../interfaces/clinics/inventory/equipment/equipment-index-response';
import { EquipmentShowResponse } from '../../interfaces/clinics/inventory/equipment/equipment-show-response';

const equipmentUrl = (clinicId: number): string => {
  return `/v1/clinics/${clinicId}/equipment`;
};

export const getClinicEquipment = async (clinicId: number, page: number, searchArray?: any[]) =>
  baseClient
    .get(`${equipmentUrl(clinicId)}?page=${page}${generateSearchQuery(searchArray)}`)
    .then((response: AxiosResponse<EquipmentIndexResponse>) => {
      return response.data;
    });

export const getClinicEquipmentById = async (clinicId: number, equipmentId: number) =>
  baseClient
    .get(`${equipmentUrl(clinicId)}/${equipmentId}`)
    .then((response: AxiosResponse<EquipmentShowResponse>) => {
      return response.data;
    });

export const createClinicEquipment = async (clinicId: number, data: EquipmentCreateUpdateRequest) =>
  baseClient
    .post(`${equipmentUrl(clinicId)}`, data)
    .then((response: AxiosResponse<EquipmentShowResponse>) => {
      return response.data;
    });

export const updateClinicEquipment = async (
  clinicId: number,
  deviceId: number,
  data: EquipmentCreateUpdateRequest
) =>
  baseClient
    .put(`${equipmentUrl(clinicId)}/${deviceId}`, data)
    .then((response: AxiosResponse<EquipmentShowResponse>) => {
      return response.data;
    });

export const removeClinicEquipment = async (clinicId: number, deviceId: number) =>
  baseClient
    .delete(`${equipmentUrl(clinicId)}/${deviceId}`)
    .then((response: AxiosResponse<EquipmentShowResponse>) => {
      return response.data;
    });
