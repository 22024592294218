import { IndexAccountsResponse } from '../../../services/clinic/accounts/models/IndexAccountsResponse';
import { ShowAccountResponse } from '../../../services/clinic/accounts/models/ShowAccountResponse';

export const SET_LOADING = 'SET_LOADING';
export const SET_ACCOUNTS = 'SET_ACCOUNTS';
export const SET_ACCOUNT = 'SET_ACCOUNT';
export const SHOW_MODAL = 'SHOW_MODAL';
export const SHOW_EDIT_MODAL = 'SHOW_EDIT_MODAL';
export const SHOW_TRANSFER_MODAL = 'SHOW_TRANSFER_MODAL';
export const SET_ACCOUNT_PAYMENTS = 'SET_ACCOUNT_PAYMENTS';
export const RESET = '[accounts] RESET';

export interface AccountsResetAction {
  type: typeof RESET
}

export interface SetAccountsAction {
  type: typeof SET_ACCOUNTS
  payload: IndexAccountsResponse
}

export interface SetLoadingAction {
  type: typeof SET_LOADING
  payload: boolean
}

export interface ShowModalAction {
  type: typeof SHOW_MODAL
  payload: boolean
}

export interface ShowEditModalAction {
  type: typeof SHOW_EDIT_MODAL
  payload: boolean
}

export interface SetSelectedAccountAction {
  type: typeof SET_ACCOUNT
  payload: ShowAccountResponse | null
}

export interface SetSelectedAccountPaymentsAction {
  type: typeof SET_ACCOUNT_PAYMENTS
  payload: ShowAccountResponse[]
}

export interface ShowTransferModalAction {
  type: typeof SHOW_TRANSFER_MODAL
  payload: boolean
}

export type AccountsActions =
  | AccountsResetAction
  | SetAccountsAction
  | ShowModalAction
  | SetSelectedAccountAction
  | SetLoadingAction
  | SetSelectedAccountPaymentsAction
  | ShowEditModalAction
  | ShowTransferModalAction;
