import baseClient from '../../../baseClient';

export const addPatientHealthRecord = async (
  clinicId: number,
  appointmentId: number,
  patientId: number,
  data: any
) =>
  baseClient
    .post(`/v1/clinics/${clinicId}/appointments/${appointmentId}/patients/${patientId}/health_records`, data)
    .then((response: any) => {
      return response.data;
    });

export const getPatientHealthRecordById = async (
  clinicId: number,
  patientId: number,
  healthRecordsId: number
) =>
  baseClient
    .get(`/v1/clinics/${clinicId}/patients/${patientId}/health_records/${healthRecordsId}`)
    .then((response: any) => {
      return response.data;
    });

export const getClinicPatientHealthRecord = async (
  clinicId: number,
  patientId: number
) =>
  baseClient
    .get(`/v1/clinics/${clinicId}/patients/${patientId}/health_records`)
    .then((response: any) => {
      return response.data;
    });

export const getAppointmentPatientHealthRecords = async (
  clinicId: number,
  appointmentId: number,
  patientId: number
) =>
  baseClient
    .get(`/v1/clinics/${clinicId}/appointments/${appointmentId}/patients/${patientId}/health_records`)
    .then((response: any) => {
      return response.data;
    });

export const deletePatientHealthRecord = async (
  clinicId: number,
  patientId: number,
  healthRecordsId: number
) =>
  baseClient
    .delete(`/v1/clinics/${clinicId}/patients/${patientId}/health_records/${healthRecordsId}`)
    .then((response: any) => {
      return response.data;
    });

export const putPatientHealthRecord = async (
  clinicId: number,
  patientId: number,
  healthRecordsId: number,
  data: any
) =>
  baseClient
    .put(`/v1/clinics/${clinicId}/patients/${patientId}/health_records/${healthRecordsId}`, data)
    .then((response: any) => {
      return response.data;
    });

export const getPatientHealthRecordInventory = async (
  clinicId: number,
  appointmentId: number,
  healthRecordId: number
) =>
  baseClient
    .get(`/v1/clinics/${clinicId}/appointments/${appointmentId}/health_records/${healthRecordId}/inventory`)
    .then((response: any) => {
      return response.data;
    });

export const addPatientHealthRecordInventory = async (
  clinicId: number,
  appointmentId: number,
  healthRecordsId: number,
  data: any
) =>
  baseClient
    .post(`/v1/clinics/${clinicId}/appointments/${appointmentId}/health_records/${healthRecordsId}/inventory`, data)
    .then((response: any) => {
      return response.data;
    });

export const updatePatientHealthRecordInventory = async (
  clinicId: number,
  appointmentId: number,
  healthRecordsId: number,
  inventoryId: number,
  data: any
) =>
  baseClient
    .put(`/v1/clinics/${clinicId}/appointments/${appointmentId}/health_records/${healthRecordsId}/inventory/${inventoryId}`, data)
    .then((response: any) => {
      return response.data;
    });

export const deletePatientHealthRecordInventory = async (
  clinicId: number,
  appointmentId: number,
  healthRecordsId: number,
  inventoryId: number
) =>
  baseClient
    .delete(`/v1/clinics/${clinicId}/appointments/${appointmentId}/health_records/${healthRecordsId}/inventory/${inventoryId}`)
    .then((response: any) => {
      return response.data;
    });
