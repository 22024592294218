import './RoomsList.scss';

import { Pagination, Row, Spin, Table, TableColumnProps, Tag } from 'antd';
import ListFieldWithTooltip from 'components/ListFieldWithTooltip';
import VLXSearch from 'components/VLXSearch';
import { roomStatuses } from 'constants/dictionary/default/selectOptions';
import useClinicId from 'hooks/useClinicId';
import { useLocale } from 'hooks/useLocale';
import { useSearch } from 'hooks/useSearch';
import { IAppState } from 'interfaces/app-state';
import { IMetaData } from 'interfaces/metadata';
import { IRoom } from 'interfaces/room';
import StatusSelect from 'layout/components/statusSelect/StatusSelect';
import AddRoom from 'layout/modals/addRoom/AddRoom';
import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { editRoom, getRooms, setLoading, setSelectedRoom, showModal } from 'redux/rooms/actions';

const getEquipmentList = (e): string => {
  if (e?.equipment?.length) {
    let res = '';
    e.equipment.forEach((el) => {
      res += res + res ? ', ' + el : el;
    });
    return res;
  }
  return '-';
};

const RoomsList = (): JSX.Element => {
  const dispatch = useDispatch();
  const clinicId = useClinicId();
  const locale = useLocale('private.administration.rooms');

  const rooms = useSelector(({ rooms }: IAppState) => rooms);
  const loading = useSelector(({ rooms }: IAppState) => rooms.loading);
  const metadata = useSelector<IAppState, IMetaData>(({ services }) => rooms.metadata);

  const {
    handleChange,
    onPageChange,
    getResults,
    filterResults
  } = useSearch({
    action: getRooms,
    clinicId,
    defaultSortKey: 'name',
    defaultSortOrder: 'asc',
    customFilters: [
      {
        name: 'deleted',
        value: 'false'
      }
    ]
  });

  useEffect(() => {
    dispatch(setSelectedRoom(null));
  }, []);

  useEffect(() => {
    return () => {
      dispatch(setLoading(true));
    };
  }, [clinicId]);

  const openRoomDetails = (record: any): { onClick: () => void } => ({
    onClick: () => {
      dispatch(setSelectedRoom(record));
      dispatch(showModal(true));
    }
  });

  const onStatusChange = (newStatus, room): void => {
    dispatch(editRoom(clinicId, room.id, {
      ...room,
      status: newStatus
    }));
  };

  const columns = useMemo(
    (): TableColumnProps<IRoom>[] => [
      {
        key: 'name',
        dataIndex: 'name',
        title: locale.labels.name,
        onCell: openRoomDetails,
        sorter: () => null,
        render: (name) => name
      },
      {
        key: 'status',
        title: locale.labels.status,
        sorter: () => null,
        render: (e) => (
          <div style={{ minWidth: '162px' }}>
            <StatusSelect
              status={e.status}
              service={e}
              statusesList={roomStatuses}
              onStatusChange={onStatusChange}
            />
          </div>
        )
      },
      {
        key: 'not_for_appointment',
        title: locale.labels.forAppointments,
        sorter: () => null,
        render: (e) =>
          e.not_for_appointment ? (
            <Tag color='red'>{locale.labels.no}</Tag>
          ) : (
            <Tag color='green'>{locale.labels.yes}</Tag>
          )
      },
      {
        key: 'location',
        title: locale.labels.location,
        sorter: () => null,
        onCell: openRoomDetails,
        render: (e) => (e?.location ? ListFieldWithTooltip(e.location, 25) : '-')
      },
      {
        key: 'equipment',
        title: locale.labels.equipment,
        sorter: () => null,
        onCell: openRoomDetails,
        render: getEquipmentList
      }
    ],
    []
  );

  return (
    <>
      <VLXSearch filterResults={filterResults} savedFiltersName={'roomsListFilters'} />
      <Spin spinning={loading}>
        <Table
          pagination={false}
          className='accent-header rooms-table'
          rowKey='id'
          dataSource={rooms.data}
          onChange={handleChange}
          columns={columns}
        />
        <Row style={{
          justifyContent: 'space-between',
          marginTop: 10,
          alignItems: 'center'
        }}>
          <Pagination
            defaultCurrent={1}
            pageSize={20}
            showSizeChanger={false}
            showLessItems
            current={metadata.page}
            total={rooms.metadata.total}
            onChange={onPageChange}
          />
          <AddRoom clinicId={clinicId} callbackFn={getResults} />
        </Row>
      </Spin>
    </>
  );
};

export default RoomsList;
