import {
  GET_STOCK_SUB_CATEGORIES,
  GetStockSubCategoriesAction,
  RESET,
  SET_LOADING,
  SET_SEARCH_QUERY,
  SetLoadingAction,
  SetSearchQueryAction,
  SHOW_MODAL,
  ShowModalAction,
  StockSubCategoriesResetAction
} from './types';
import {
  IndexDictionaryRecordsResponse
} from '../../../services/clinic/dictionaries/models/IndexDictionaryRecordsResponse';
import {
  CreateDictionaryRecordRequest
} from '../../../services/clinic/dictionaries/models/CreateDictionaryRecordRequest';
import {
  createClinicStockSubCategory,
  destroyClinicStockSubCategory,
  indexClinicStockSubCategories,
  updateClinicStockSubCategory
} from '../../../services/clinic/dictionaries/stockSubCategoriesService';

export const setStockSubCategories = (diagnoses: IndexDictionaryRecordsResponse): GetStockSubCategoriesAction => ({
  type: GET_STOCK_SUB_CATEGORIES,
  payload: diagnoses
});

export const resetStockSubCategories = (): StockSubCategoriesResetAction => ({
  type: RESET
});

export const showModal = (value: boolean): ShowModalAction => ({
  type: SHOW_MODAL,
  payload: value
});

export const setLoading = (value: boolean): SetLoadingAction => ({
  type: SET_LOADING,
  payload: value
});

export const setSearchQuery = (data: any[]): SetSearchQueryAction => ({
  type: SET_SEARCH_QUERY,
  payload: data
});

export const indexStockSubCategories = (clinicId: number, page: number = 1, searchValue?: any, callBackFnc?: any, pageSize?: number): any => {
  return dispatch => {
    dispatch(setLoading(true));
    indexClinicStockSubCategories(clinicId, page, searchValue, pageSize)
      .then(data => {
        if (callBackFnc) {
          callBackFnc();
        }
        dispatch(setStockSubCategories(data));
        dispatch(setLoading(false));
        dispatch(setSearchQuery(searchValue));
      })
      .catch(() => dispatch(setLoading(false)));
  };
};

export const createStockSubCategory = (clinicId: number, data: CreateDictionaryRecordRequest,
  page = null,
  searchArray = null, callBackFnc?: any): any => {
  return dispatch => {
    dispatch(setLoading(true));
    createClinicStockSubCategory(clinicId, data)
      .then(data => {
        if (callBackFnc) {
          callBackFnc(data);
        }
        dispatch(indexStockSubCategories(clinicId, page, searchArray));
        dispatch(setLoading(false));
      })
      .catch(() => dispatch(setLoading(false)));
  };
};

export const updateStockSubCategory = (clinicId: number, diagnosisId: number, data: CreateDictionaryRecordRequest,
  page = null,
  searchArray = null): any => {
  return dispatch => {
    dispatch(setLoading(true));
    updateClinicStockSubCategory(clinicId, diagnosisId, data)
      .then(data => {
        dispatch(indexStockSubCategories(clinicId, page, searchArray));
        dispatch(setLoading(false));
      })
      .catch(() => dispatch(setLoading(false)));
  };
};

export const destroyStockSubCategory = (clinicId: number, diagnosisId: number,
  page = null,
  searchArray = null): any => {
  return dispatch => {
    dispatch(setLoading(true));
    destroyClinicStockSubCategory(clinicId, diagnosisId)
      .then(data => {
        dispatch(indexStockSubCategories(clinicId, page, searchArray));
        dispatch(setLoading(false));
      })
      .catch(() => dispatch(setLoading(false)));
  };
};
