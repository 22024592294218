import { Modal } from 'antd';
import React from 'react';

import { useLocale } from '../hooks/useLocale';

type Props = {
  open: boolean;
  onOk: () => void;
  onCancel: () => void;
  defZindex?: number;
  centered?: boolean;
  cancelText?: string;
};
const ConfirmCancelModal = ({
  open,
  onOk,
  onCancel,
  centered = true,
  defZindex,
  cancelText
}: Props): JSX.Element => {
  const locale = useLocale('public.modals.data-loss-confirmation');

  return (
    <Modal
      zIndex={defZindex || 1000}
      title={cancelText ?? locale.text.dataLossMessage}
      centered={centered}
      open={open}
      onOk={onOk}
      okText={locale.buttons.yes}
      cancelText={locale.buttons.no}
      onCancel={onCancel}
      destroyOnClose={true}
    />
  );
};

export default ConfirmCancelModal;
