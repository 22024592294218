import { GET_PERMISSIONS, PermissionsActions, RESET, SET_LOADING } from './types';
import { IPermissionsList } from '../../interfaces/permission';

interface PermissionsListState extends IPermissionsList {
  loading: boolean
}

const initialState: PermissionsListState = {
  data: [],
  loading: false
};

export function permissionsReducer (
  state: IPermissionsList = initialState,
  action: PermissionsActions
): any {
  switch (action.type) {
    case GET_PERMISSIONS: {
      return {
        ...state,
        data: action.payload
      };
    }

    case RESET: {
      return initialState;
    }

    case SET_LOADING: {
      return {
        ...state,
        loading: action.payload
      };
    }

    default: {
      return state;
    }
  }
}
