import {
  IndexDictionaryRecordsResponse
} from '../../../services/clinic/dictionaries/models/IndexDictionaryRecordsResponse';

export const GET_BILL_PAYMENT_TYPES = '[bill_payment_types] Set';
export const SHOW_MODAL = '[bill_payment_types] SHOW_MODAL';
export const SET_LOADING = '[bill_payment_types] SET_LOADING';
export const SET_SEARCH_QUERY = '[bill_payment_types] SetSearchQuery';
export const RESET = '[bill_payment_types] RESET';

export interface BillPaymentTypesResetAction {
  type: typeof RESET
}

export interface GetBillPaymentTypesAction {
  type: typeof GET_BILL_PAYMENT_TYPES
  payload: IndexDictionaryRecordsResponse
}

export interface ShowModalAction {
  type: typeof SHOW_MODAL
  payload: boolean
}

export interface SetLoadingAction {
  type: typeof SET_LOADING
  payload: boolean
}

export interface SetSearchQueryAction {
  type: typeof SET_SEARCH_QUERY
  payload: any[]
}

export type BillPaymentTypesActions =
  | BillPaymentTypesResetAction
  | GetBillPaymentTypesAction
  | SetSearchQueryAction
  | ShowModalAction
  | SetLoadingAction;
