export const textEditor = {
  ukUA: {
    showLess: 'приховати',
    showMore: 'показати більше',
    length_rule_20000: 'Дозволено максимум 20000 символів'
  },
  enGB: {
    showLess: 'show less',
    showMore: 'show more',
    length_rule_20000: 'Maximum 20000 characters allowed'
  }
};
