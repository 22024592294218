import './DashboardPage.scss';

import { Col, Menu, MenuProps, Row, Typography } from 'antd';
import dayjs from 'dayjs';
import { useLocale } from 'hooks/useLocale';
import useLinkParams from 'hooks/useSearchParams';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { setLoading } from 'redux/appointments/actions';

import { DashboardPageAppointmentsList } from './DashboardPageAppointmentsList';
import DashboardPageCalendar from './DashboardPageCalendar';

const DashboardPage = (): JSX.Element => {
  const dispatch = useDispatch();
  const locale = useLocale('private.dashboard.dashboard');
  const [currentTab, setCurrentTab] = useState(useLinkParams('tab'));

  const activeTabSearchParam = useLinkParams('tab');
  const items: MenuProps['items'] = [
    {
      label: locale.tabs.upcomingEvents,
      key: 'upcomingEvents'
    },
    {
      label: locale.tabs.pendingAction,
      key: 'pendingAction'
    }
  ];

  const pushHistoryState = (tab): void => {
    if (window.history.pushState) {
      const newUrl = `${window.location.pathname}?tab=${tab}`;
      window.history.pushState({ path: newUrl }, '', newUrl);
    }
  };

  useEffect(() => {
    dispatch(setLoading(true));
  }, []);

  const changeTab: MenuProps['onClick'] = (e): void => {
    if (e.key === currentTab) return;
    dispatch(setLoading(true));
    pushHistoryState(e.key);
    setCurrentTab(e.key);
  };

  useEffect((): void => {
    if (activeTabSearchParam) {
      setCurrentTab(activeTabSearchParam);
    }
  }, [activeTabSearchParam]);

  useEffect((): void => {
    if (!currentTab && !activeTabSearchParam) {
      setCurrentTab('upcomingEvents');
    }
  }, [currentTab, activeTabSearchParam]);

  return (
    <div className='dashboard-page'>
      <Typography.Title style={{ fontSize: 22, marginBottom: 10 }}>
        {locale.labels.title}
      </Typography.Title>
      <Row justify={'space-between'} gutter={[50, 10]}>
        <Col span={24} xl={8} style={{ background: 'none', paddingTop: 10 }}>
          <DashboardPageCalendar />
        </Col>
        <Col span={24} xl={16}>
          <Menu
            onClick={changeTab}
            selectedKeys={[currentTab]}
            mode='horizontal'
            items={items}
            style={{ marginBottom: 20 }}
          />
          {currentTab === 'upcomingEvents' && (
            <DashboardPageAppointmentsList
              filter={[
                { name: 'not_status', value: 'cancelled' },
                { name: 'start_time_from', value: dayjs().tz('utc').format('YYYY-MM-DDTHH:mm:ss') }
              ]}
            />
          )}
          {currentTab === 'pendingAction' && (
            <DashboardPageAppointmentsList
              filter={[
                { name: 'action_required', value: 1 },
                { name: 'start_time_to', value: dayjs().tz('utc').format('YYYY-MM-DDTHH:mm:ss') }
              ]}
            />
          )}
        </Col>
      </Row>
    </div>
  );
};

export default DashboardPage;
