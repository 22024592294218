import { Dropdown } from 'antd';
import { locales } from 'constants/locales';
import * as localStorage from 'local-storage';
import React, { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { setLocaleCode } from 'redux/localeCode/actions';
import { AppState } from '../../../redux/store.ts';

export function useQuery (): URLSearchParams {
  const { search } = useLocation();

  return useMemo(() => new URLSearchParams(search), [search]);
}

const Localization = (): JSX.Element => {
  const dispatch = useDispatch();
  const localeCode: string = useSelector<AppState, string>(({ localeCode }) => localeCode);

  const onSelect = (e): void => {
    localStorage.set('locale', e.key);
    dispatch(setLocaleCode(e.key));
  };

  return (
    <Dropdown
      menu={{
        items: locales,
        onClick: onSelect
      }}
      trigger={['click']}
      placement='bottom'
    >
      <span className='locale-menu'>
        {locales.find((lang) => lang.key === localeCode)?.label || 'UA'}
      </span>
    </Dropdown>
  );
};

export default Localization;
