import './scss/Provider.scss';

import { Spin, Table, TableColumnProps } from 'antd';
import ListFieldWithTooltip from 'components/ListFieldWithTooltip';
import { money } from 'helpers/list-helpers';
import { useLocale } from 'hooks/useLocale';
import { IAppState } from 'interfaces/app-state';
import moment from 'moment';
import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, Params, useParams } from 'react-router-dom';
import { getProviderByIdInvoices } from 'redux/inventory/providers/actions';
import { ProvidersListState } from 'redux/inventory/providers/reducer';
import { IInvoiceDataItem } from 'services/interfaces/clinics/inventory/invoice/IInvoiceData';

 export const ProviderHistoryTab = (): JSX.Element => {
  const dispatch = useDispatch();
  const { clinicId, providerId }: Readonly<Params<string>> = useParams();
  const locale = useLocale('private.inventory.invoices');
  
  const { selectedProviderInvoicesData } = useSelector<IAppState, ProvidersListState>(({ providers }) => providers);
  const { loading } = useSelector<IAppState, ProvidersListState>(({ providers }) => providers);
    const currencies = useLocale('private.currencies').labels;

  useEffect((): void => {
    if (clinicId) {
      dispatch(getProviderByIdInvoices(Number(clinicId), Number(providerId)));
    }
  }, [clinicId]);

    const columns = useMemo(
    (): TableColumnProps<IInvoiceDataItem>[] => [
      {
      key: 'invoice_number',
      title: locale.labels.invoiceNumber,
      render: (e) =>
        e.id
          ? ListFieldWithTooltip(
              <Link
                className={'custom-links'}
                to={`/clinic/${clinicId}/inventory/invoices/${e.id}`}
              >
                {e.invoice_number}
              </Link>
            )
          : '-'
      },
      {
        key: 'purchase_date',
        title: locale.labels.date,
        render: (e) => `${e.purchase_date ? moment(e.purchase_date).format('DD.MM.YYYY') : '-'}`
      },
      {
        key: 'bill',
        title: locale.labels.bill,
        render: (e) =>
          e.id
            ? ListFieldWithTooltip(
              <Link
                className={'custom-links'}
                to={`/clinic/${clinicId}/finances/bills/${e.bill_id}`}
              >
                {e.bill_id}
              </Link>
            )
          : '-'
      },
      {
        key: 'number_of_items',
        title: locale.labels.purchaseQuantity,
        render: (e) => e.number_of_items
      },
      {
        key: 'total_price',
        title: locale.labels.sum,
        render: (e) => `${money(e.total_price || 0)} ${currencies.uah}`
      }
    ],
    [selectedProviderInvoicesData]
  );

  return (
    <div>
      <Spin spinning={loading}>
        {Boolean(selectedProviderInvoicesData?.data) && (
          <Table
            pagination={false}
            className='accent-header stock-list'
            rowKey='id'
            dataSource={selectedProviderInvoicesData.data}
            columns={columns}
          />
        )}
      </Spin>
    </div>
  );
}; 