import './Logo.scss';

import React from 'react';

interface Props {
  src: string;
  alt?: string;
  className?: string;
}

const Logo = ({ alt = '', src, className }: Props) => {
  return (
    <div className='logo'>
      <img src={src} alt={alt} className={`logo-img ${className}`} />
    </div>
  );
};

export default Logo;
