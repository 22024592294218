import { Modal } from 'antd';
import { ReactNode } from 'react';

export interface MessageModalProps {
  type: 'info' | 'success' | 'error' | 'warning' | 'confirm';
  content?: ReactNode | null;
}

const MessageModal = (type = 'info', content) => {
  Modal[type]({
    content
  });
};

export default MessageModal;
