import { Form } from 'antd';
import ConfirmCancelModal from 'components/ConfirmCancelModal';
import StickyButton from 'components/StickyButton';
import ViewDetailsButtonsGroup from 'components/ViewDetailsButtonsGroup';
import VLXModal from 'components/VLXModal';
import { useLocale } from 'hooks/useLocale';
import { useUserAccess } from 'hooks/useUserAccess';
import { IAppState } from 'interfaces/app-state';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { showCreateModal } from 'redux/roles/actions';

import AddRoleForm from './AddRoleForm';

const AddRole = ({ clinicId }: any) => {
  const dispatch = useDispatch();
  const roles = useSelector<IAppState, any>((state) => state.roles);
  const [addRoleForm] = Form.useForm();
  const [showCancelModal, setShowCancelModal] = useState<boolean>(false);
  const userAccess = useUserAccess();
  const locale = useLocale('private.administration.roles');

  const onConfirmCancelClick = () => {
    addRoleForm.resetFields();
    dispatch(showCreateModal(false));
    setShowCancelModal(false);
  };

  const onCancel = () => {
    dispatch(showCreateModal(false));
  };

  const onClick = () => {
    dispatch(showCreateModal(true));
  };

  return (
    <>
      <StickyButton
        offsetBottom={10}
        onClick={onClick}
        iconElement={
          <span
            className='icofont icofont-plus mr-2 sticky-btn-icon'
            style={{ fontSize: '1.3em' }}
          />
        }
        textElement={locale.buttons.addRole}
      />
      <VLXModal
        form={addRoleForm}
        title={locale.labels.createRole}
        open={roles.showCreateModal}
        onModalClose={onCancel}
        width={800}
      >
        <AddRoleForm form={addRoleForm} clinicId={clinicId}>
          <ViewDetailsButtonsGroup
            fixed
            accessGroup={userAccess.accesses.roles}
            editMode={true}
            hideDeleteBtn={true}
            onCancelClick={() => {
              if (addRoleForm.isFieldsTouched()) {
                setShowCancelModal(true);
              } else {
                dispatch(showCreateModal(false));
              }
            }}
            onDeleteClick={() => {
              setShowCancelModal(false);
            }}
          />
        </AddRoleForm>
        <ConfirmCancelModal
          open={showCancelModal}
          onOk={onConfirmCancelClick}
          onCancel={() => setShowCancelModal(false)}
        />
      </VLXModal>
    </>
  );
};

export default AddRole;
