import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IAppState } from '../../../interfaces/app-state';
import {
  Spin
} from 'antd';
import ToolForm from './components/ToolForm';
import { createTool } from '../../../redux/inventory/tools/actions';
import {
  ToolsCreateUpdateRequest
} from '../../../services/interfaces/clinics/inventory/tools/ToolsCreateUpdateRequest';

const AddToolForm = ({ clinicId, form, children }: any) => {
  const dispatch = useDispatch();
  const tools = useSelector<IAppState, any>((state) => state.tools);
  const [locationType, setLocationType] = useState<string>(null);

  useEffect(() => {
    form.resetFields();
  }, []);

  const onSubmit = (data: ToolsCreateUpdateRequest) => {
    dispatch(createTool(clinicId, data));
  };

  function onLocationTypeSelect (value: any) {
    setLocationType(value);
  };

  return (
    <>
      <Spin spinning={tools.loading}>
        <ToolForm
          editMode={true}
          onFinish={onSubmit}
          form={form}
          clinicId={clinicId}
          locationType={locationType}
          onLocationTypeSelect={onLocationTypeSelect}
        >
          {children}
        </ToolForm>
      </Spin>
    </>
  );
};

export default AddToolForm;
