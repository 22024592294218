import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IAppState } from '../../../interfaces/app-state';
import {
  Spin
} from 'antd';
import { createEquipment } from '../../../redux/inventory/equipment/actions';
import {
  EquipmentCreateUpdateRequest
} from '../../../services/interfaces/clinics/inventory/equipment/equipment-create-update-request';
import EquipmentForm from './components/EquipmentForm';

const AddEquipmentForm = ({ clinicId, form, children }: any) => {
  const dispatch = useDispatch();
  const equipment = useSelector<IAppState, any>((state) => state.equipment);
  const [locationType, setLocationType] = useState<string>(null);

  useEffect(() => {
    form.resetFields();
  }, []);

  const onSubmit = (data: EquipmentCreateUpdateRequest) => {
    dispatch(createEquipment(clinicId, data));
  };

  function onLocationTypeSelect (value: any) {
    setLocationType(value);
  }

  return (
    <>
      <Spin spinning={equipment.loading}>
        <EquipmentForm
          editMode={true}
          onFinish={onSubmit}
          form={form}
          clinicId={clinicId}
          locationType={locationType}
          onLocationTypeSelect={onLocationTypeSelect}
        >
          {children}
        </EquipmentForm>
      </Spin>
    </>
  );
};

export default AddEquipmentForm;
