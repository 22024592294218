import envConfig from './env.json';

export const env: string = process.env.REACT_APP_ENV || 'develop';
export const baseUrl: string = process.env.REACT_APP_BASE_URL || envConfig[env].baseUrl;
export const highlightProjectId: string = envConfig[env].highlightProjectId || '';
export const disabledForProd: boolean = envConfig[env].disabledForProd || false;
export const apiKey: string = envConfig[env].apiKey || "some_token";
export const selectSearchDelay: number = 500;
export const selectSearchMinSymbols: number = 3;
export const sentryDSN: string = process.env.sentryDSN || '';
