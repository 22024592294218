import {
  APPEND_EMPLOYEES,
  AppendEmployeesAction,
  GET_EMPLOYEES,
  GetEmployeesAction,
  SET_EDIT_MODE,
  SET_LOADING,
  SET_SELECTED_EMPLOYEE,
  SET_SEARCH_QUERY,
  SetSearchQueryAction,
  SetEditModeAction,
  SetLoadingAction,
  SetSelectedEmployeeAction, EmployeesResetAction, RESET
} from './types';

import { IEmployeeDetails, IEmployeesList } from '../../interfaces/employee';
import { editClinicEmployee, getClinicEmployeeById, getClinicEmployees } from '../../services/clinic/clinicsService';

export const setEmployees = (data: IEmployeesList): GetEmployeesAction => ({
  type: GET_EMPLOYEES,
  payload: data
});

export const resetEmployees = (): EmployeesResetAction => ({
  type: RESET
});

export const setLoading = (value: boolean): SetLoadingAction => ({
  type: SET_LOADING,
  payload: value
});

export const setEditMode = (value: boolean): SetEditModeAction => ({
  type: SET_EDIT_MODE,
  payload: value
});

export const setSelectedEmployee = (data: IEmployeeDetails): SetSelectedEmployeeAction => ({
  type: SET_SELECTED_EMPLOYEE,
  payload: data
});

export const appendClinicEmployees = (data: IEmployeesList): AppendEmployeesAction => ({
  type: APPEND_EMPLOYEES,
  payload: data
});

export const setSearchQuery = (data: any[]): SetSearchQueryAction => ({
  type: SET_SEARCH_QUERY,
  payload: data
});

export const getEmployees = (
  clinicId: number,
  page?: number,
  searchValue?: any,
  itemsPerPage?: number
): any => {
  return (dispatch) => {
    dispatch(setLoading(true));
    getClinicEmployees(clinicId, page, searchValue, itemsPerPage)
      .then((data) => {
        dispatch(setEmployees(data));
        dispatch(setLoading(false));
        dispatch(setSearchQuery(searchValue));
      })
      .catch(() => dispatch(setLoading(false)));
  };
};

export const appendEmployees = (
  clinicId: number,
  page?: number,
  searchValue?: any,
  itemsPerPage?: number
): any => {
  return (dispatch) => {
    dispatch(setLoading(true));
    getClinicEmployees(clinicId, page, searchValue, itemsPerPage)
      .then((data) => {
        dispatch(appendClinicEmployees(data));
        dispatch(setLoading(false));
        dispatch(setSearchQuery(searchValue));
      })
      .catch(() => dispatch(setLoading(false)));
  };
};

export const getEmployeeById = (clinicId: number, employeeId: number): any => {
  return (dispatch) => {
    dispatch(setLoading(true));
    getClinicEmployeeById(clinicId, employeeId)
      .then((data) => {
        dispatch(setSelectedEmployee(data));
        dispatch(setLoading(false));
      })
      .catch(() => dispatch(setLoading(false)));
  };
};

export const editEmployee = (clinicId: number, employeeId: number, payload: any): any => {
  return (dispatch) => {
    dispatch(setLoading(true));
    editClinicEmployee(clinicId, employeeId, payload)
      .then((data) => {
        dispatch(setSelectedEmployee(data));
        dispatch(setLoading(false));
      })
      .catch(() => dispatch(setLoading(false)));
  };
};
