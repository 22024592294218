import 'components/scss/TextEditor.scss';
import 'react-quill-new/dist/quill.snow.css';

import { Form } from 'antd';
import { useI18n } from 'hooks/usei18n';
import { textEditor } from 'i18n/textEditor';
import { useEffect, useRef, useState } from 'react';
import ReactQuill from 'react-quill-new';
import { maxRule } from 'utils/form-rules';

type Props = {
  name: string | string[];
  label: string;
  help?: string;
  rows?: number;
  initialValue?: string;
};

const toolbarOptions = [
  [{ size: ['small', false, 'large'] }], // text size
  [{ list: 'ordered' }, { list: 'bullet' }, { list: 'check' }],
  [{ indent: '-1' }, { indent: '+1' }, { align: [] }], // outdent/indent
  [{ color: [] }, 'bold', 'italic', 'underline', 'blockquote', 'link'],
  // [{ background: [] }]
  // [{ font: [] }],
  ['clean'] // remove formatting button
];

const theme = 'snow';
const MAX_LEN = 20000;
const DEFAULT_ROWS = 5;

export const TextEditor = ({ name, label, help, rows, initialValue }: Props): JSX.Element => {
  const locale = useI18n<typeof textEditor['ukUA']>(textEditor);
  const [value, setValue] = useState('');
  const quillRef = useRef<ReactQuill>();

  useEffect(() => {
    const el = quillRef.current?.editor.root;

    if (quillRef.current?.editor.getLines().length > 20) {
      el.classList.add('scrollable');
    } else {
      el.classList.remove('remove');
    }
  }, [quillRef.current, value]);

  const maxLengthValidate = (event): void => {
    if (event.key !== 'Backspace' && value.length >= MAX_LEN) event.preventDefault();
  };

  const onChange = (value): void => {
    setValue(value);
  };

  return (
    <Form.Item
      label={label}
      name={name}
      help={help}
      rules={[maxRule(MAX_LEN, locale.length_rule_20000)]}
      initialValue={initialValue}
    >
      <ReactQuill
        ref={quillRef}
        className={`row-${rows || DEFAULT_ROWS}`}
        modules={{ toolbar: toolbarOptions }}
        onKeyDown={maxLengthValidate}
        theme={theme}
        value={value}
        onChange={onChange}
      />
    </Form.Item>
  );
};
