import { notification } from 'antd';
import { useEffect } from 'react';

import { useLocale } from './useLocale';

export const useGetNetworkStatus = (): void => {
  const locale = useLocale('public.error-pages');

  const setOnline = (): void => {
    notification.destroy();
  };

  const setOffline = (): void => {
    notification.warning({
      message: locale.labels.noConnection,
      duration: null
    });
  };

  useEffect(() => {
    window.addEventListener('online', setOnline);
    window.addEventListener('offline', setOffline);

    return () => {
      window.removeEventListener('online', setOnline);
      window.removeEventListener('offline', setOffline);
    };
  }, []);
};
