import React from 'react';
import { Tag } from 'antd';

export function deviceStatus (device_status) {
  let color = null;
  switch (device_status?.value) {
    case 'maintenance':
      color = 'orange';
      break;
    case 'repair_required':
      color = 'red';
      break;
    case 'maintenance_required_soon':
      color = 'red';
      break;
    case 'operating':
      color = 'green';
      break;
  }
  return tag(color, device_status?.label);
}

function tag (color: string, status: string) {
  return <Tag color={color}>{status}</Tag>;
}
