import { CheckCircleTwoTone, CloseCircleTwoTone } from '@ant-design/icons';
import { Button, Checkbox, Col, DatePicker, Form, Input, Row, Spin } from 'antd';
import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { requiredRule } from 'utils/form-rules';

import {
  acceptInvitation,
  confirmInvitation,
  registerUserByInvitation
} from '../../services/invitationsService';

const AcceptInvitation = () => {
  const { token } = useParams() as any;
  const [status, setStatus] = useState({
    invitation_status: 'processing',
    invitation_token: null,
    confirmation_code: null
  });
  const [loading, setLoading] = useState(false);

  const onConfirmation = (data) => {
    setLoading(true);
    confirmInvitation(data)
      .then((data) => {
        setLoading(false);
        setStatus(data);
      })
      .catch(() => {
        setLoading(false);
        setStatus({
          ...status,
          invitation_status: 'error'
        });
      });
  };

  const onRegistration = (data) => {
    setLoading(true);
    registerUserByInvitation({
      invitation_token: status.invitation_token,
      user_data: data
    })
      .then(() => {
        setLoading(false);
        setStatus({
          ...status,
          invitation_status: 'completed'
        });
      })
      .catch(() => {
        setLoading(false);
        setStatus({
          ...status,
          invitation_status: 'error'
        });
      });
  };

  useEffect(() => {
    acceptInvitation({ token })
      .then(setStatus)
      .catch(() => {
        setStatus({
          ...status,
          invitation_status: 'error'
        });
      });
  }, []);

  return (
    <>
      {status.invitation_status === 'processing' && (
        <div className='text-center'>
          <h1 style={{ fontSize: '6rem' }} className='text-color-300 mb-2'>
            <Spin size='large' />
          </h1>
          <h6 className='text-md'>E-mail verification</h6>
        </div>
      )}
      {status.invitation_status === 'error' && (
        <div className='text-center'>
          <h1 style={{ fontSize: '6rem' }} className='text-color-300 mb-2'>
            <CloseCircleTwoTone twoToneColor='red' />
          </h1>
          <h6 className='text-md'>Failed</h6>
        </div>
      )}
      {status.invitation_status === 'completed' && (
        <div className='text-center'>
          <h1 style={{ fontSize: '6rem' }} className='text-color-300 mb-2'>
            <CheckCircleTwoTone twoToneColor='#52c41a' />
          </h1>
          <h6 className='text-md'>Added</h6>
        </div>
      )}
      <div style={{ maxWidth: 690, margin: '0 auto' }}>
        {status.invitation_status === 'accepted' && (
          <Spin spinning={loading}>
            <Form onFinish={onConfirmation} autoComplete='off' layout='vertical'>
              <Form.Item name='invitation_token' initialValue={status.invitation_token} hidden>
                <Input />
              </Form.Item>
              <Form.Item
                label='Confirmation code'
                name='confirmation_code'
                rules={[requiredRule('Please input your username')]}
                initialValue={status.confirmation_code}
              >
                <Input />
              </Form.Item>
              <Form.Item>
                <Button block type='primary' htmlType='submit'>
                  Send
                </Button>
              </Form.Item>
            </Form>
          </Spin>
        )}
        {status.invitation_status === 'confirmed' && (
          <Spin spinning={loading}>
            <Form onFinish={onRegistration} autoComplete='off' layout='vertical'>
              <Row>
                <Col span={24} md={12}>
                  <Form.Item
                    label='First Name'
                    name='first_name'
                    rules={[requiredRule('This field is required')]}
                  >
                    <Input />
                  </Form.Item>
                  <Form.Item
                    label='Last Name'
                    name='last_name'
                    rules={[requiredRule('This field is required')]}
                  >
                    <Input />
                  </Form.Item>
                  <Form.Item
                    label='Date of Birth'
                    name='birth_date'
                    rules={[requiredRule('Please input your date of birth')]}
                  >
                    <DatePicker
                      disabledDate={(d) => !d || d.isAfter(dayjs())}
                      format='YYYY-MM-DD'
                    />
                  </Form.Item>
                  <Form.Item
                    label='Enter Password'
                    name='password'
                    rules={[requiredRule('Please input your password')]}
                  >
                    <Input.Password />
                  </Form.Item>
                  <Form.Item
                    label='Repeat Password'
                    name='repeatPassword'
                    dependencies={['password']}
                    hasFeedback
                    rules={[
                      requiredRule('Please confirm your password'),
                      ({ getFieldValue }) => ({
                        async validator(_, value) {
                          if (!value || getFieldValue('password') === value) {
                            return await Promise.resolve();
                          }
                          return await Promise.reject(
                            new Error('The two passwords that you entered do not match')
                          );
                        }
                      })
                    ]}
                  >
                    <Input.Password />
                  </Form.Item>
                </Col>
                <Col span={24} md={12}>
                  <Form.Item label='Home Address' style={{ marginBottom: 0 }}>
                    <Form.Item
                      name='country'
                      style={{
                        display: 'inline-block',
                        width: 'calc(50% - 8px)',
                        marginRight: '16px'
                      }}
                    >
                      <Input placeholder='Country' />
                    </Form.Item>
                    <Form.Item
                      name='region'
                      style={{ display: 'inline-block', width: 'calc(50% - 8px)' }}
                    >
                      <Input placeholder='Region' />
                    </Form.Item>
                  </Form.Item>
                  <Form.Item name='city'>
                    <Input placeholder='City' />
                  </Form.Item>
                  <Form.Item name='address'>
                    <Input placeholder='Address' />
                  </Form.Item>
                </Col>
              </Row>
              <Form.Item
                name='agree'
                valuePropName='checked'
                rules={[requiredRule('Please input your username')]}
              >
                <Checkbox>
                  I agree to the
                  <Button type='link' size={'small'}>
                    Terms and Privacy.
                  </Button>
                </Checkbox>
              </Form.Item>
              <Form.Item>
                <Button block type='primary' htmlType='submit'>
                  Send
                </Button>
              </Form.Item>
            </Form>
          </Spin>
        )}
      </div>
    </>
  );
};

export default AcceptInvitation;
