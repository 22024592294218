import { IInvitation, IInvitationsList } from '../../interfaces/invitation';

export const GET_INVITATIONS = '[invitation] Set';
export const SHOW_MODAL = '[invitation] Add';
export const SET_INVITATIONS_LOADING = '[invitation] Edit';
export const SET_INVITATION = '[invitation] Select';

export interface GetInvitationsAction {
  type: typeof GET_INVITATIONS;
  payload: IInvitationsList;
}

export interface ShowModalAction {
  type: typeof SHOW_MODAL;
  payload: boolean;
}

export interface SetLoadingAction {
  type: typeof SET_INVITATIONS_LOADING;
  payload: boolean;
}

export interface SetSelectedInvitation {
  type: typeof SET_INVITATION;
  payload: IInvitation | null;
}

export type InvitationsActions =
  | GetInvitationsAction
  | ShowModalAction
  | SetLoadingAction
  | SetSelectedInvitation;
