import { IClinic } from 'interfaces/clinic';

import {
  ClinicActions,
  GET_CLINIC,
  MERGE_CLINIC_DOCUMENTS_LIST,
  RESET,
  SET_CLINIC_DOCUMENTS_LIST,
  SET_CLINIC_LOADING,
  SET_DOCS_LOADING,
  SET_PERMISSIONS,
  SHOW_MODAL
} from './types';

export interface ClinicState extends IClinic {
  loading: boolean;
  docsLoading: boolean;
  showModal: boolean;
}

const initialState: ClinicState = {
  id: null,
  name: '',
  email: '',
  country_code: '',
  phone_number: '',
  status: '',
  role: '',
  avatar: '',
  created_at: '',
  subscription_id: null,
  updated_at: '',
  company_type: null,
  description: null,
  location: null,
  working_hours: null,
  loading: true,
  docsLoading: true,
  showModal: false,
  markup_package: 0,
  markup_unit: 0,
  social_media_links: null,
  additional_contacts: null,
  selectedClinicDocuments: []
};

export function clinicReducer(state: IClinic = initialState, action: ClinicActions): any {
  switch (action.type) {
    case GET_CLINIC: {
      return {
        ...state,
        ...action.payload
      };
    }

    case RESET: {
      return initialState;
    }

    case SHOW_MODAL: {
      return {
        ...state,
        showModal: action.payload
      };
    }

    case SET_DOCS_LOADING: {
      return {
        ...state,
        docsLoading: action.payload
      };
    }

    case SET_CLINIC_LOADING: {
      return {
        ...state,
        loading: action.payload
      };
    }

    case SET_PERMISSIONS: {
      return {
        ...state,
        permissions: action.payload
      };
    }

    case SET_CLINIC_DOCUMENTS_LIST: {
      return {
        ...state,
        selectedClinicDocuments: action.payload
      };
    }

    case MERGE_CLINIC_DOCUMENTS_LIST: {
      return {
        ...state,
        selectedClinicDocuments: [action.payload, ...state.selectedClinicDocuments]
      };
    }

    default: {
      return state;
    }
  }
}
