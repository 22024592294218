import './ClientDetails.scss';

import { Button, Card, Col, Form, Menu, MenuProps, Row, Spin, Typography } from 'antd';
import ClientHistory from 'components/ClientHistory';
import DocumentsTab from 'components/DocumentsTab';
import useClientId from 'hooks/useClientId';
import useClinicId from 'hooks/useClinicId';
import { useLocale } from 'hooks/useLocale';
import { useUserAccess } from 'hooks/useUserAccess';
import { IAppState } from 'interfaces/app-state';
import AddNewPatient from 'layout/modals/editPatient/AddNewPatient';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import {
  getClientDetails,
  getClientDocumentsList,
  getClientPatients,
  mergeClientDocumentsList,
  setClientPatients,
  setSelectedClient,
} from 'redux/clients/actions';
import { getPatientDetails, getPatientVaccinations, resetPatients, showCreateModal } from 'redux/patients/actions';

import { PremiumSubscriptionTooltip } from '../../components/PremiumSubscriptionTooltip';
import ClientDetailsOverview from './ClientDetailsOverview';
import ClientDetailsPetsList from './ClientDetailsPetsList';
import ClientDetailsPetsTab from './ClientDetailsPetsTab';

const ClientDetails = (): JSX.Element => {
  const dispatch = useDispatch();
  const clinicId = useClinicId();
  const clientId = useClientId();
  const appModules = useUserAccess().availableModules;
  const [searchParams, setSearchParams] = useSearchParams();
  const currentPatientId = searchParams.get('patient');

  const data = useSelector(({ clients }: IAppState) => clients.selectedClient);
  const clientPatients = useSelector(({ clients }: IAppState) => clients.clientPatients);
  const editAccess = useUserAccess().accesses.clinics.edit;
  const selectedPatientData = useSelector(({ patients }: IAppState) => patients.selectedPatient);

  const selectedClientDocuments = useSelector(
    ({ clients }: IAppState) => clients.selectedClientDocuments
  );

  const [currentTab, setCurrentTab] = useState('pets');
  const [selectedPatient, setSelectedPatient] = useState(null);
  const [addPatientForm] = Form.useForm();
  const locale = useLocale('private.clients.client');

  useEffect(() => {
    if (selectedPatient && currentPatientId !== selectedPatient?.id) {
      setSearchParams({ patient: selectedPatient?.id.toString() });
    }
  }, [selectedPatient, currentPatientId]);

  const items: MenuProps['items'] = [
    {
      label: locale.tabs.pets,
      key: 'pets'
    },
    {
      label: (
        <PremiumSubscriptionTooltip display={!appModules.documents}>
          {locale.tabs.documents}
        </PremiumSubscriptionTooltip>
      ),
      key: 'documents',
      disabled: !appModules.documents
    },
    {
      label: locale.tabs.history,
      key: 'history'
    }
  ];

  useEffect((): void => {
    if (currentTab === 'documents' && clinicId && clientId) {
      dispatch(getClientDocumentsList(clinicId, clientId));
    }
  }, [currentTab, selectedPatient]);

  useEffect((): void => {
    if (clientPatients && !selectedPatient) {
      setSelectedPatient(clientPatients.find(el => el?.id === +currentPatientId) || clientPatients[0]);
    }
  }, [clientPatients, selectedPatient]);

  useEffect((): void => {
    if (clinicId && selectedPatient && selectedPatient?.id) {
      dispatch(getPatientDetails(clinicId, selectedPatient.id));
      dispatch(getPatientVaccinations(clinicId, selectedPatient.id, 1));
    }
  }, [selectedPatient]);

  useEffect((): void => {
    if (clinicId && clientId) {
      dispatch(getClientDetails(clinicId, clientId));
      dispatch(getClientPatients(clinicId, clientId, null, 0));
    }
  }, [clinicId, clientId]);

  useEffect(() => {
    return () => {
      dispatch(setSelectedClient(null));
      dispatch(setClientPatients(null));
      dispatch(resetPatients());
    };
  }, []);

  const addNewPetAction = (): void => {
    dispatch(showCreateModal(true));
  };

  const onPetsSearch = (value: string): void => {
    dispatch(getClientPatients(clinicId, clientId, `[name]=${value}`));
  };

  const changeTab: MenuProps['onClick'] = (e): void => {
    setCurrentTab(e.key);
  };

  const setDocumentsList = (data): void => {
    dispatch(mergeClientDocumentsList(data));
  };

  const clientNoPets = () => {
    return (
      <Col className='client-details__no-pets-list-container'>
        <Typography>{locale.labels.clientNoPets}</Typography>
        <Button
          type='primary'
          onClick={() => dispatch(showCreateModal(true))}
          className='icofont icofont-plus'
          style={{ borderRadius: 30 }}
        >
          {locale.buttons.addNewPet}
        </Button>
      </Col>
    );
  };

  const callbackAfterPatientEdit = () => {
    dispatch(
      getClientPatients(clinicId, clientId, null, 0)
    );
  };

  return (
    <Spin spinning={!data}>
      <Row className='client-details'>
        <Col span={24} md={10} lg={7} style={{ padding: '0 12px' }}>
          <ClientDetailsOverview
            clinicId={clinicId}
            clientId={clientId}
            data={data}
            editAccess={editAccess}
            locale={locale}
          />
        </Col>
        <Col span={24} md={14} lg={17} style={{ padding: '0 12px' }}>
          <Card
            className='ant-card-bordered personal-info-card with-shadow'
            id='client-pets-history-card'
          >
            <Menu onClick={changeTab} selectedKeys={[currentTab]} mode='horizontal' items={items} />
            {currentTab === 'pets' && (
              <Spin spinning={!clientPatients}>
                {clientPatients?.length ? (
                  <Row>
                    <ClientDetailsPetsList
                      onPetsSearch={onPetsSearch}
                      clientPatients={clientPatients}
                      clinicId={clinicId}
                      clientId={clientId}
                      selectedPatient={selectedPatient}
                      setSelectedPatient={setSelectedPatient}
                      addNewPetAction={addNewPetAction}
                    />
                    <Col span={24} lg={14} className='client-details__patient-details'>
                      {!!selectedPatientData && (
                        <ClientDetailsPetsTab
                          accessGroup={editAccess}
                          clinicId={clinicId}
                          data={selectedPatientData}
                          callbackAfterPatientEdit={callbackAfterPatientEdit}
                        />
                      )}
                    </Col>
                  </Row>
                ) : (
                  clientNoPets()
                )}
              </Spin>
            )}
            {currentTab === 'documents' && (
              <>
                {
                  <Row className='documents-tab'>
                    <Col span={24} lg={24} className='client-details__patient-details'>
                      <DocumentsTab
                        uploadDocumentLink={`/v1/clinics/${clinicId}/clients/${clientId}/documents`}
                        setDocumentsList={setDocumentsList}
                        data={selectedClientDocuments}
                      />
                    </Col>
                  </Row>
                }
              </>
            )}
            {currentTab === 'history' && (
              <>
                <Row className='history-tab'>
                  <Col span={24} lg={24} className='client-details__patient-details'>
                    <ClientHistory clientId={clientId} clinicId={clinicId} />
                  </Col>
                </Row>
              </>
            )}
          </Card>
        </Col>
      </Row>
      <AddNewPatient
        hideDefaultCreatePetFormBtn
        initialValues={{
          owner: data?.id,
          country_code: data?.country_code,
          phone_number: data?.phone_number,
          vaccination_notification: true,
          appointments_notification: true
        }}
        form={addPatientForm}
        getClientPetsAfterSuccess
        disableOwnerSelect
      />
    </Spin>
  );
};

export default ClientDetails;
