import { IndexShiftsResponse } from '../../services/clinic/shifts/models/IndexShiftsResponse';
import { ShowShiftResponse } from '../../services/clinic/shifts/models/ShowShiftResponse';

export const SET_LOADING = 'SET_LOADING';
export const SET_SHIFTS = 'SET_SHIFTS';
export const SET_OPENED_SHIFT = 'SET_OPENED_SHIFT';
export const SET_SELECTED_SHIFT = 'SET_SELECTED_SHIFT';
export const SHOW_MODAL = 'SHOW_MODAL';
export const RESET = '[shifts] RESET';

export interface ShiftsResetAction {
  type: typeof RESET;
}

export interface SetShiftsAction {
  type: typeof SET_SHIFTS;
  payload: IndexShiftsResponse;
}

export interface SetLoadingAction {
  type: typeof SET_LOADING;
  payload: boolean;
}

export interface ShowModalAction {
  type: typeof SHOW_MODAL;
  payload: boolean;
}

export interface SetOpenedShiftAction {
  type: typeof SET_OPENED_SHIFT;
  payload: ShowShiftResponse | null;
}

export interface SetSelectedShiftAction {
  type: typeof SET_SELECTED_SHIFT;
  payload: ShowShiftResponse | null;
}

export type ServicesActions =
  | SetShiftsAction
  | ShiftsResetAction
  | ShowModalAction
  | SetOpenedShiftAction
  | SetSelectedShiftAction
  | SetLoadingAction;
