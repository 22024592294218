import { Col, DatePicker, Form, Row } from 'antd';
import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import React from 'react';
import { requiredRule } from 'utils/form-rules';

import { useLocale } from '../hooks/useLocale';

dayjs.extend(utc);
dayjs.extend(timezone);

const { RangePicker } = DatePicker;

export const AllDayPicker = (): JSX.Element => {
  const locale = useLocale('private.appointments.event');

  return (
    <>
      <Row
        gutter={8}
        style={{
          width: '100%',
          alignItems: 'flex-end'
        }}
      >
        <Col>
          <Form.Item
            label={locale.labels.startTime}
            name='date_range'
            rules={[requiredRule(locale.errors.startDateNotValid)]}
          >
            <RangePicker />
          </Form.Item>
        </Col>
      </Row>
    </>
  );
};
