import 'assets/scss/row.scss';
import './components/EmployeeProfile.scss';

import { Card, Col, Row, Spin } from 'antd';
import userAvatar from 'assets/img/user-avatar.svg';
import Avatar from 'components/Avatar';
import { useLocale } from 'hooks/useLocale';
import { useUserAccess } from 'hooks/useUserAccess';
import { IAppState } from 'interfaces/app-state';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { getEmployeeById } from 'redux/employees/actions';
import { getRoles } from 'redux/roles/actions';

import EmployeeProfileForm from './components/EmployeeProfileForm';

const EmployeeProfilePage = () => {
  const dispatch = useDispatch();
  const employee = useSelector<IAppState, any>((state) => state.employees.selectedEmployee);
  const loading = useSelector<IAppState, any>((state) => state.employees.loading);
  const roles = useSelector<IAppState, any>((state) => state.roles.data);
  const rolesAccess = useUserAccess().accesses.roles.showList;
  const userLoading = useSelector<IAppState, any>((state) => state.user.loading);
  const locale = useLocale('private.administration.employee-page');
  const [changeAvatarModalVisible, setChangeAvatarModalVisible] = useState(false);
  const { clinicId, employeeId }: any = useParams();

  useEffect(() => {
    if (rolesAccess) dispatch(getRoles(clinicId));
  }, [clinicId]);

  useEffect(() => {
    dispatch(getEmployeeById(clinicId, employeeId));
  }, [employeeId]);

  const handleSetEmployeeAvatar = (file): void => {
    const avatarData = new FormData();

    avatarData.append('avatar', file);
  };

  return (
    <Spin spinning={loading} className='profile-page-spin-container'>
      {employee && (
        <Row>
          <Col span={24} md={12}>
            <div className='header'>
              <Card>
                <Avatar
                  loading={userLoading}
                  editAccess={true}
                  onImageChange={handleSetEmployeeAvatar}
                  src={employee?.avatar ? employee.avatar : userAvatar}
                  setChangeAvatarModalVisible={setChangeAvatarModalVisible}
                  changeAvatarModalVisible={changeAvatarModalVisible}
                />
              </Card>
            </div>
            <div className='info'>
              {employee && roles && (
                <EmployeeProfileForm
                  locale={locale}
                  employee={employee}
                  clinicId={clinicId}
                  employeeId={employeeId}
                  roles={roles}
                />
              )}
            </div>
          </Col>
          {/* <Col span={24} md={12}> */}
          {/*  <Card> */}
          {/*    <EmployeeActionsTimeline /> */}
          {/*  </Card> */}
          {/* </Col> */}
        </Row>
      )}
    </Spin>
  );
};

export default EmployeeProfilePage;
