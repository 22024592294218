import {
  EquipmentResetAction,
  RESET,
  SET_BE_ERROR,
  SET_EQUIPMENT,
  SET_LOADING,
  SET_SELECTED_DEVICE,
  SetBeError,
  SetEquipmentAction,
  SetLoadingAction,
  SetSelectedDeviceAction,
  SHOW_CREATE_MODAL,
  ShowCreateModal
} from './types';

import {
  createClinicEquipment,
  getClinicEquipment,
  getClinicEquipmentById,
  removeClinicEquipment,
  updateClinicEquipment
} from '../../../services/clinic/inventory/equipmentService';
import {
  EquipmentIndexResponse
} from '../../../services/interfaces/clinics/inventory/equipment/equipment-index-response';
import {
  EquipmentCreateUpdateRequest
} from '../../../services/interfaces/clinics/inventory/equipment/equipment-create-update-request';
import {
  EquipmentShowResponse
} from '../../../services/interfaces/clinics/inventory/equipment/equipment-show-response';
import { notification } from 'antd';

export const setEquipment = (data: EquipmentIndexResponse): SetEquipmentAction => ({
  type: SET_EQUIPMENT,
  payload: data
});

export const resetEquipment = (): EquipmentResetAction => ({
  type: RESET
});

export const setSelectedDevice = (data: EquipmentShowResponse): SetSelectedDeviceAction => ({
  type: SET_SELECTED_DEVICE,
  payload: data
});

export const setLoading = (value: boolean): SetLoadingAction => ({
  type: SET_LOADING,
  payload: value
});

export const setBackendError = (value: boolean): SetBeError => ({
  type: SET_BE_ERROR,
  payload: value
});

export const showCreateModal = (value: boolean): ShowCreateModal => ({
  type: SHOW_CREATE_MODAL,
  payload: value
});

export const getEquipment = (clinicId: number, page: number, searchArray?: any[]): any => {
  return (dispatch) => {
    dispatch(setLoading(true));
    getClinicEquipment(clinicId, page, searchArray)
      .then((data) => {
        dispatch(setEquipment(data));
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => dispatch(setLoading(false)));
  };
};

export const getEquipmentById = (clinicId: number, deviceId: number): any => {
  return (dispatch) => {
    dispatch(setLoading(true));
    getClinicEquipmentById(clinicId, deviceId)
      .then((data) => {
        dispatch(setSelectedDevice(data));
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => dispatch(setLoading(false)));
  };
};

export const createEquipment = (clinicId: number, data: EquipmentCreateUpdateRequest): any => {
  return (dispatch) => {
    dispatch(setLoading(true));
    createClinicEquipment(clinicId, data)
      .then((data) => {
        dispatch(getEquipment(clinicId, 1));
        dispatch(showCreateModal(false));
      })
      .catch((err) => {
        dispatch(setBackendError(true));
        console.log(err);
      })
      .finally(() => dispatch(setLoading(false)));
  };
};

export const updateEquipment = (clinicId: number, deviceId: number, data: EquipmentCreateUpdateRequest): any => {
  return (dispatch) => {
    dispatch(setLoading(true));
    updateClinicEquipment(clinicId, deviceId, data)
      .then((data) => {
        dispatch(getEquipmentById(clinicId, deviceId));
      })
      .catch((err) => {
        dispatch(setBackendError(true));
        console.log(err);
      })
      .finally(() => dispatch(setLoading(false)));
  };
};

export const removeEquipment = (clinicId: number, deviceId: number, locale): any => {
  return (dispatch) => {
    dispatch(setLoading(true));
    removeClinicEquipment(clinicId, deviceId)
      .then((data) => {
        notification.success({ message: locale.messages.deviceRemovedSuccessfully });
        dispatch(setSelectedDevice(null));
        dispatch(getEquipment(clinicId, 1));
      })
      .catch((err) => {
        dispatch(setBackendError(true));
        console.log(err);
      })
      .finally(() => dispatch(setLoading(false)));
  };
};
