import './Contact.scss';

import userAvatar from 'assets/img/7aS.svg';
import { useUserAccess } from 'hooks/useUserAccess';
import { IEmployee } from 'interfaces/employee';
import React from 'react';
import { NavLink } from 'react-router-dom';

interface OwnProps {
  className?: string;
  clinicId: number;
  locale?: any;
}

type Props = IEmployee & OwnProps;

const Contact = ({
  id,
  email,
  full_name,
  avatar,
  role,
  title,
  country_code,
  phone_number,
  className,
  clinicId,
  locale
}: Props) => {
  const viewAccess = useUserAccess().accesses.employees.showItem;

  const viewDetailsButton = () => {
    if (!viewAccess) return null;
    return (
      <NavLink
        className='ant-btn ant-btn-primary'
        to={`/clinic/${clinicId}/administration/employees/${id}`}
      >
        <span>{locale.buttons.viewProfile}</span>
      </NavLink>
    );
  };

  return (
    <div className={`contact ${className}`}>
      <div className='img-box'>
        <img width='300' src={avatar ? avatar : userAvatar} height='250' alt='avatar' />
      </div>

      <div className='info-box'>
        <h5 className='name'>{full_name}</h5>
        <h6 className='name'>{title}</h6>
        <p className='address'>
          {locale.labels.phone}: +{country_code}
          {phone_number}
        </p>
        <p className='address'>
          {' '}
          {locale.labels.email}: {email}
        </p>
        <div className='button-box'>{viewDetailsButton()}</div>
      </div>
    </div>
  );
};

export default Contact;
