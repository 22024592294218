import '../scss/StockDetails.scss';

import { Form, notification } from 'antd';
import ConfirmCancelModal from 'components/ConfirmCancelModal';
import ConfirmDeleteModal from 'components/ConfirmDeleteModal';
import ViewDetailsButtonsGroup from 'components/ViewDetailsButtonsGroup';
import VLXModal from 'components/VLXModal';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import useClinicId from 'hooks/useClinicId';
import { useUserAccess } from 'hooks/useUserAccess';
import { ILocale } from 'interfaces/locale';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { getStockById, removeStock, updateStock } from 'redux/inventory/stock/actions';
import { ISelectedStock } from 'services/interfaces/clinics/inventory/stock/ISelectedStock';
import { IStockItemCreateEditRequest } from 'services/interfaces/clinics/inventory/stock/IStockItemCreateEditRequest';

import { StockItemForm } from '../forms/StockItemForm';
import { getStockItemPayload } from '../utils/getStockItemPayload';

dayjs.extend(customParseFormat);

type Props = {
  locale: ILocale;
  selectedStock: ISelectedStock;
  setShowEditStockItemModal: (val) => void;
};

export const EditStockItemModal = ({
  locale,
  selectedStock,
  setShowEditStockItemModal
}: Props): JSX.Element => {
  const clinicId = useClinicId();
  const dispatch = useDispatch();
  const userAccess = useUserAccess();
  const [form] = Form.useForm();
  const navigate = useNavigate();

  const [showCancelModal, setShowCancelModal] = useState<boolean>(false);
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);

  const onSubmit = (data: IStockItemCreateEditRequest): void => {
    const payload = getStockItemPayload(data);

    dispatch(updateStock(clinicId, selectedStock.id, payload));
    setShowEditStockItemModal(false);
    notification.success({ message: locale.messages.dataEditedSuccessfully });
  };

  const onConfirmCancelClick = (): void => {
    dispatch(getStockById(clinicId, selectedStock.id));
    setShowCancelModal(false);
    setShowEditStockItemModal(false);
  };

  const onConfirmDeleteClick = (): void => {
    dispatch(removeStock(clinicId, selectedStock.id, locale));
    setShowDeleteModal(false);
    navigate(`/clinic/${clinicId}/inventory/stock`);
  };

  return (
    <>
      <VLXModal
        title={locale.labels.editStock}
        centered
        open
        form={form}
        onModalClose={() => {
          setShowEditStockItemModal(false);
        }}
      >
        <StockItemForm onFinish={onSubmit} form={form} initialValues={selectedStock}>
          <ViewDetailsButtonsGroup
            accessGroup={userAccess.accesses.stock}
            fixed
            hideDeleteBtn
            editMode
            onCancelClick={() => {
              if (form.isFieldsTouched()) {
                setShowDeleteModal(false);
                setShowCancelModal(true);
              } else {
                setShowEditStockItemModal(false);
              }
            }}
            onDeleteClick={() => {
              setShowCancelModal(false);
              setShowDeleteModal(true);
            }}
          />
        </StockItemForm>
      </VLXModal>
      <ConfirmDeleteModal
        type={'device'}
        open={showDeleteModal}
        onOk={onConfirmDeleteClick}
        onCancel={() => setShowDeleteModal(false)}
      />
      <ConfirmCancelModal
        open={showCancelModal}
        onOk={onConfirmCancelClick}
        onCancel={() => setShowCancelModal(false)}
      />
    </>
  );
};
