import { Form } from 'antd';
import ConfirmCancelModal from 'components/ConfirmCancelModal';
import ConfirmDeleteModal from 'components/ConfirmDeleteModal';
import StickyButton from 'components/StickyButton';
import ViewDetailsButtonsGroup from 'components/ViewDetailsButtonsGroup';
import VLXModal from 'components/VLXModal';
import { useLocale } from 'hooks/useLocale';
import { useUserAccess } from 'hooks/useUserAccess';
import { IAppState } from 'interfaces/app-state';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { deleteRoom, setSelectedRoom, showModal } from 'redux/rooms/actions';

import AddRoomForm from './AddRoomForm';

const AddRoom = ({ clinicId, callbackFn }: any): JSX.Element => {
  const dispatch = useDispatch();
  const rooms = useSelector<IAppState, any>((state) => state.rooms);
  const [roomForm] = Form.useForm();
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
  const [showCancelModal, setShowCancelModal] = useState<boolean>(false);
  const [editMode, setEditMode] = useState<boolean>(false);
  const userAccess = useUserAccess();
  const locale = useLocale('private.administration.rooms');

  useEffect(() => {
    if (rooms.showModal && !rooms.selectedRoom) {
      setEditMode(true);
    }
  }, [rooms]);

  const onConfirmCancelClick = () => {
    setEditMode(false);
    setShowCancelModal(false);
    dispatch(showModal(false));
  };

  const onConfirmDeleteClick = () => {
    setShowDeleteModal(false);
    dispatch(deleteRoom(clinicId, rooms.selectedRoom.id));
    setEditMode(false);
  };

  const onFieldsChange = (e: any) => {
    if (e.length < 2 && !editMode) {
      setEditMode(true);
    }
  };

  return (
    <>
      <StickyButton
        offsetBottom={10}
        onClick={() => {
          dispatch(showModal(true));
        }}
        iconElement={
          <span
            className='icofont icofont-plus mr-2 sticky-btn-icon'
            style={{ fontSize: '1.3em' }}
          />
        }
        textElement={locale.buttons.addRoom}
      />

      <VLXModal
        title={`${rooms.selectedRoom ? locale.labels.editRoom : locale.labels.createRoom}`}
        form={roomForm}
        open={rooms.showModal}
        onModalClose={() => {
          dispatch(showModal(false));
          dispatch(setSelectedRoom(null));
          setEditMode(false);
        }}
        width={800}
      >
        {rooms.showModal && <AddRoomForm clinicId={clinicId} form={roomForm} onFieldsChange={onFieldsChange} callbackFn={callbackFn}>
          <ViewDetailsButtonsGroup
            accessGroup={userAccess.accesses.rooms}
            editMode={editMode}
            hideDeleteBtn={!rooms.selectedRoom}
            onCancelClick={() => {
              if (roomForm.isFieldsTouched()) {
                setShowDeleteModal(false);
                setShowCancelModal(true);
              } else {
                dispatch(showModal(false));
              }
            }}
            onEditClick={() => setEditMode(true)}
            onDeleteClick={() => {
              setShowCancelModal(false);
              setShowDeleteModal(true);
            }}
          />
        </AddRoomForm>}
        <ConfirmDeleteModal
          type={'room'}
          open={showDeleteModal}
          onOk={onConfirmDeleteClick}
          onCancel={() => setShowDeleteModal(false)}
          customTitle={locale.messages.roomDeletionConfirmation}
        />
        <ConfirmCancelModal
          open={showCancelModal}
          onOk={onConfirmCancelClick}
          onCancel={() => {
            setShowCancelModal(false);
          }}
        />
      </VLXModal>
    </>
  );
};

export default AddRoom;
