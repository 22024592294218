import { IEmployee, IEmployeeDetails } from 'interfaces/employee';
import { IPatient } from 'interfaces/patient';
import { IService } from 'interfaces/services';

export const getParticipants = (participants: IEmployeeDetails[]): string => {
  if (!participants?.length) return '-';

  return Object.values(participants)
    .map((participant: IEmployeeDetails) => `${participant.first_name} ${participant.last_name}`)
    .join(', ');
};

export const getAtendees = (attendees: IEmployee[]): string => {
  if (!attendees?.length) return '-';

  return Object.values(attendees)
    .map((attendee: IEmployee) => attendee.full_name)
    .join(', ');
};

export const getPatients = (patients: IPatient[]): string => {
  if (!patients?.length) return '-';

  return Object.values(patients)
    .map((patient: IPatient) => patient.name)
    .join(', ');
};

export const getServices = (services: IService[]): string => {
  if (!services?.length) return '-';

  return Object.values(services)
    .map((service: IService) => service.name)
    .join(', ');
};

export const parseDiagnosis = (val: string): string => {
  const valsArray = JSON.parse(val);
  let res = '';
  valsArray?.forEach((el: string) => {
      res = res ? res + ', ' + el : el;
  });
  return res;
};