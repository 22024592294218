export const routesConfig = (clinicId, clinicName, breadcrumbsLocale) => {
  const labels = breadcrumbsLocale.labels;
  return {
    userProfile: {
      pageData: {
        title: labels.userProfile,
        breadcrumbs: []
      }
    },
    clinicInfo: {
      pageData: {
        title: clinicName,
        breadcrumbs: []
      }
    },
    inventoryInfo: {
      pageData: {
        title: labels.inventory,
        breadcrumbs: [{
          title: clinicName
        }]
      }
    },
    employeesList: {
      pageData: {
        title: labels.employees,
        breadcrumbs: [{
          title: clinicName
        }]
      }
    },
    employeeProfile: {
      pageData: {
        title: labels.details,
        breadcrumbs: [
          {
            title: clinicName
          }, {
            title: labels.employees,
            route: `/clinic/${clinicId}/administration/employees`
          }
        ]
      }
    },
    toolsList: {
      pageData: {
        title: labels.tools,
        breadcrumbs: [{
          title: clinicName
        }]
      }
    },
    stockList: {
      pageData: {
        title: labels.stock,
        breadcrumbs: [{
          title: clinicName
        }]
      }
    },
    auditList: {
      pageData: {
        title: labels.audit,
        breadcrumbs: [{
          title: clinicName
        }]
      }
    },
    providersList: {
      pageData: {
        title: labels.providers,
        breadcrumbs: [{
          title: clinicName
        }]
      }
    },
    invoiceList: {
      pageData: {
        title: labels.invoices,
        breadcrumbs: [{
          title: clinicName
        }]
      }
    },
    invoiceDetails: {
      pageData: {
        title: labels.details,
        breadcrumbs: [
          {
            title: clinicName
          }, {
            title: labels.invoices,
            route: `/clinic/${clinicId}/inventory/invoices`
          }
        ]
      }
    },
    stockDetails: {
      pageData: {
        title: labels.details,
        breadcrumbs: [
          {
            title: clinicName
          }, {
            title: labels.stock,
            route: `/clinic/${clinicId}/inventory/stock`
          }
        ]
      }
    },
    auditDetails: {
      pageData: {
        title: labels.details,
        breadcrumbs: [
          {
            title: clinicName
          }, {
            title: labels.audit,
            route: `/clinic/${clinicId}/inventory/audit`
          }
        ]
      }
    },
    toolDetails: {
      pageData: {
        title: labels.details,
        breadcrumbs: [
          {
            title: clinicName
          },
          {
            title: labels.tools,
            route: `/clinic/${clinicId}/inventory/tools`
          }
        ]
      }
    },
    equipmentList: {
      pageData: {
        title: labels.equipment,
        breadcrumbs: [{
          title: clinicName
        }]
      }
    },
    equipmentDetails: {
      pageData: {
        title: labels.details,
        breadcrumbs: [
          {
            title: clinicName
          }, {
            title: labels.equipment,
            route: `/clinic/${clinicId}/inventory/equipment`
          }
        ]
      }
    },
    invitationsList: {
      pageData: {
        title: labels.invitations,
        breadcrumbs: [{
          title: clinicName
        }]
      }
    },
    roomsList: {
      pageData: {
        title: labels.rooms,
        breadcrumbs: [{
          title: clinicName
        }]
      }
    },
    rolesList: {
      pageData: {
        title: labels.roles,
        breadcrumbs: [{
          title: clinicName
        }]
      }
    },
    appointmentsList: {
      pageData: {
        title: labels.appointments,
        breadcrumbs: [{
          title: clinicName
        }]
      }
    },
    patientsList: {
      pageData: {
        title: labels.patients,
        breadcrumbs: [{
          title: clinicName
        }]
      }
    },
    providersDetails: {
      pageData: {
        title: labels.details,
        breadcrumbs: [{
          title: clinicName
        }, {
          title: labels.providers,
          route: `/clinic/${clinicId}/inventory/providers`
        }]
      }
    },
    patientsDetails: {
      pageData: {
        title: labels.details,
        breadcrumbs: [{
          title: clinicName
        }, {
          title: labels.patients,
          route: `/clinic/${clinicId}/patients`
        }]
      }
    },
    servicesList: {
      pageData: {
        title: labels.services,
        breadcrumbs: [{
          title: clinicName
        }]
      }
    },
    servicesDetails: {
      pageData: {
        title: labels.details,
        breadcrumbs: [
          {
            title: clinicName
          }, {
            title: labels.services,
            route: `/clinic/${clinicId}/services`
          }
        ]
      }
    },
    appointmentDetails: {
      pageData: {
        title: labels.details,
        breadcrumbs: [
          {
            title: clinicName
          }, {
            title: labels.appointments,
            route: `/clinic/${clinicId}/appointments`
          }
        ]
      }
    },
    events: {
      pageData: {
        title: labels.events,
        breadcrumbs: [{
          title: clinicName
        }]
      }
    },
    clientsList: {
      pageData: {
        title: labels.clients,
        breadcrumbs: [{
          title: clinicName
        }]
      }
    },
    clientDetails: {
      pageData: {
        title: labels.details,
        breadcrumbs: [{
          title: clinicName
        },
        {
          title: labels.clients,
          route: `/clinic/${clinicId}/clients`
        }
        ]
      }
    },
    financesInfo: {
      pageData: {
        title: clinicName,
        breadcrumbs: []
      }
    },
    billsList: {
      pageData: {
        title: labels.bills,
        breadcrumbs: [{
          title: clinicName
        }]
      }
    },
    shiftsList: {
      pageData: {
        title: labels.shifts,
        breadcrumbs: [{
          title: clinicName
        }]
      }
    },
    shiftDetails: {
      pageData: {
        title: labels.details,
        breadcrumbs: [
          {
            title: clinicName
          }, {
            title: labels.shifts,
            route: `/clinic/${clinicId}/finances/shifts`
          }
        ]
      }
    },
    paymentsList: {
      pageData: {
        title: labels.payments,
        breadcrumbs: [{
          title: clinicName
        }]
      }
    },
    financialAccountsList: {
      pageData: {
        title: labels.financialAccounts,
        breadcrumbs: [{
          title: clinicName
        }]
      }
    },
    financialAccountsDetails: {
      pageData: {
        title: labels.details,
        breadcrumbs: [
          {
            title: clinicName
          }, {
            title: labels.financialAccounts,
            route: `/clinic/${clinicId}/finances/financial-accounts`
          }
        ]
      }
    },
    billsDetails: {
      pageData: {
        title: labels.details,
        breadcrumbs: [
          {
            title: clinicName
          }, {
            title: labels.bills,
            route: `/clinic/${clinicId}/finances/bills`
          }
        ]
      }
    },
    paymentsDetails: {
      pageData: {
        title: labels.details,
        breadcrumbs: [
          {
            title: clinicName
          }, {
            title: labels.payments,
            route: `/clinic/${clinicId}/finances/payments`
          }
        ]
      }
    },
    clinicDocuments: {
      pageData: {
        title: labels.documents,
        breadcrumbs: [{
          title: clinicName
        }]
      }
    },
    clinicDictionaries: {
      pageData: {
        title: labels.dictionaries,
        breadcrumbs: [{
          title: clinicName
        }]
      }
    },
    generalSettings: {
      pageData: {
        title: labels.generalSettings,
        breadcrumbs: [{
          title: clinicName
        }]
      }
    },
    shopFarmacyList: {
      pageData: {
        title: labels.shopFarmacy,
        breadcrumbs: [{
          title: clinicName
        }]
      }
    },
    shopFarmacyDetails: {
      pageData: {
        title: labels.details,
        breadcrumbs: [{
          title: clinicName
        },
        {
          title: labels.shopFarmacy,
          route: `/clinic/${clinicId}/shop-farmacy`
        }
        ]
      }
    }
  };
};
