import { IInvoiceData } from 'services/interfaces/clinics/inventory/invoice/IInvoiceData';
import {
  ISelectedProvidersItem,
  ISelectedProvidersItemsData,
} from 'services/interfaces/clinics/inventory/providers/ISelectedProvidersItemsData';

export const SET_PROVIDERS_DATA = 'SET_PROVIDERS_DATA';
export const SET_PROVIDERS_LOADING = 'SET_PROVIDERS_LOADING';
export const SET_SELECTED_PROVIDER_DATA = 'SET_SELECTED_PROVIDER_DATA';
export const SET_SELECTED_PROVIDER_INVOICES_DATA = 'SET_SELECTED_PROVIDER_INVOICES_DATA';
export const SET_SHOW_CREATE_PROVIDER_MODAL = 'SET_SHOW_CREATE_PROVIDER_MODAL';
export const SET_SHOW_EDIT_PROVIDER_MODAL = 'SET_SHOW_EDIT_PROVIDER_MODAL';

export interface SetProvidersDataAction {
  type: typeof SET_PROVIDERS_DATA
  payload: ISelectedProvidersItemsData
}

export interface SetLoadingAction {
  type: typeof SET_PROVIDERS_LOADING
  payload: boolean
}

export interface SetShowCreateProviderModalAction {
  type: typeof SET_SHOW_CREATE_PROVIDER_MODAL
  payload: boolean
}

export interface SetShowEditProviderModalAction {
  type: typeof SET_SHOW_EDIT_PROVIDER_MODAL
  payload: boolean
}

export interface SetSelectedProviderDataAction {
  type: typeof SET_SELECTED_PROVIDER_DATA
  payload: ISelectedProvidersItem
} 

export interface SetSelectedProviderInvoicesDataAction {
  type: typeof SET_SELECTED_PROVIDER_INVOICES_DATA
  payload: IInvoiceData
} 

export type ProvidersActions =
  | SetProvidersDataAction
  | SetLoadingAction
  | SetSelectedProviderDataAction
  | SetShowCreateProviderModalAction
  | SetShowEditProviderModalAction
  | SetSelectedProviderInvoicesDataAction;
