import { ISortObject } from 'services/interfaces/ISortObject';

import { IRoom, IRoomsList } from '../../interfaces/room';
import {
  createClinicRooms,
  deleteClinicRoom,
  editClinicRoom,
  getClinicRooms,
} from '../../services/clinic/clinicsService';
import {
  APPEND_ROOMS,
  AppendRoomsAction,
  GET_ROOMS,
  GetRoomsAction,
  RESET,
  RoomsResetAction,
  SET_LOADING,
  SET_ROOM,
  SET_SEARCH_QUERY,
  SetLoadingAction,
  SetSearchQueryAction,
  SetSelectedRoom,
  SHOW_MODAL,
  ShowModalAction,
} from './types';

export const setRooms = (rooms: IRoomsList): GetRoomsAction => ({
  type: GET_ROOMS,
  payload: rooms
});

export const resetRooms = (): RoomsResetAction => ({
  type: RESET
});

export const appendClinicRooms = (rooms: IRoomsList): AppendRoomsAction => ({
  type: APPEND_ROOMS,
  payload: rooms
});

export const showModal = (value: boolean): ShowModalAction => ({
  type: SHOW_MODAL,
  payload: value
});

export const setLoading = (value: boolean): SetLoadingAction => ({
  type: SET_LOADING,
  payload: value
});

export const setSelectedRoom = (data: IRoom | null): SetSelectedRoom => ({
  type: SET_ROOM,
  payload: data
});

export const setSearchQuery = (data: any[]): SetSearchQueryAction => ({
  type: SET_SEARCH_QUERY,
  payload: data
});

export const getRooms = (clinicId: number, page?: number, searchValue?: any, sortObject?: ISortObject): any => {
  return dispatch => {
    dispatch(setLoading(true));
    getClinicRooms(clinicId, page, searchValue, sortObject)
      .then(data => {
        dispatch(setRooms(data));
        dispatch(setLoading(false));
        dispatch(setSearchQuery(searchValue));
      })
      .catch(() => dispatch(setLoading(false)));
  };
};

export const appendRooms = (clinicId: number, page?: number, searchValue?: any): any => {
  return dispatch => {
    dispatch(setLoading(true));
    getClinicRooms(clinicId, page, searchValue)
      .then(data => {
        dispatch(appendClinicRooms(data));
        dispatch(setLoading(false));
        dispatch(setSearchQuery(searchValue));
      })
      .catch(() => dispatch(setLoading(false)));
  };
};

export const createRoom = (clinicId: number, name: string, callbackFn: () => void): any => {
  return dispatch => {
    dispatch(setLoading(true));
    createClinicRooms(clinicId, name)
      .then(() => {
        if (callbackFn) {
          callbackFn();
        } else {
          dispatch(getRooms(clinicId));
        }
        dispatch(showModal(false));
      })
      .catch(() => dispatch(setLoading(false)));
  };
};

export const editRoom = (clinicId: number, roomId: number, data: IRoom): any => {
  return dispatch => {
    dispatch(setLoading(true));
    editClinicRoom(clinicId, roomId, data)
      .then(() => {
        dispatch(getRooms(clinicId));
        dispatch(showModal(false));
        dispatch(setSelectedRoom(null));
      })
      .catch(() => dispatch(setLoading(false)));
  };
};

export const deleteRoom = (clinicId: number, roomId: number): any => {
  return dispatch => {
    dispatch(setLoading(true));
    deleteClinicRoom(clinicId, roomId)
      .then(() => {
        dispatch(getRooms(clinicId));
        dispatch(showModal(false));
        dispatch(setSelectedRoom(null));
      })
      .catch(() => dispatch(setLoading(false)));
  };
};
