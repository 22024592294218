import './AddRoom.scss';

import { Checkbox, Form, Input, Select, Spin } from 'antd';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { requiredRule } from 'utils/form-rules';

import { roomStatuses } from '../../../constants/dictionary/default/selectOptions';
import { useLocale } from '../../../hooks/useLocale';
import useLocalizedList from '../../../hooks/useLocalizedList';
import { IAppState } from '../../../interfaces/app-state';
import { createRoom, editRoom } from '../../../redux/rooms/actions';

const AddRoomForm = ({ clinicId, form, children, onFieldsChange, callbackFn }: any) => {
  const dispatch = useDispatch();
  const rooms = useSelector<IAppState, any>((state) => state.rooms);
  const locale = useLocale('private.administration.rooms');

  const onSubmit = (data: any) => {
    const apiData = { ...data, not_for_appointment: !data.not_for_appointment };
    if (rooms.selectedRoom) dispatch(editRoom(clinicId, rooms.selectedRoom.id, apiData));
    else dispatch(createRoom(clinicId, apiData, callbackFn));
  };

  useEffect(() => {
    form.resetFields();
  }, [rooms.selectedRoom]);

  return (
    <Spin spinning={rooms.loading}>
      <Form
        onFinish={onSubmit}
        autoComplete='off'
        layout='vertical'
        form={form}
        onFieldsChange={onFieldsChange}
      >
        <Form.Item
          label={locale.labels.name}
          name='name'
          rules={[requiredRule(locale.messages.nameEmpty)]}
          initialValue={rooms.selectedRoom?.name}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label={locale.labels.status}
          name='status'
          rules={[requiredRule(locale.messages.roomStatusEmpty)]}
          initialValue={rooms.selectedRoom?.status ? rooms.selectedRoom?.status : 'active'}
        >
          <Select className='patient-select' options={useLocalizedList(roomStatuses)} />
        </Form.Item>
        <Form.Item
          label={locale.labels.location}
          name='location'
          initialValue={rooms.selectedRoom?.location}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label={locale.labels.forAppointments}
          name='not_for_appointment'
          className='flat-checkbox-form-item'
          valuePropName='checked'
          initialValue={!rooms.selectedRoom?.not_for_appointment}
        >
          <Checkbox defaultChecked={true} />
        </Form.Item>
        {children}
      </Form>
    </Spin>
  );
};

export default AddRoomForm;
