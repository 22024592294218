import {
  GET_BILL_PAYMENT_TYPES,
  GetBillPaymentTypesAction,
  RESET,
  SET_LOADING,
  SET_SEARCH_QUERY,
  SetLoadingAction,
  SetSearchQueryAction,
  SHOW_MODAL,
  ShowModalAction,
  BillPaymentTypesResetAction
} from './types';
import {
  IndexDictionaryRecordsResponse
} from '../../../services/clinic/dictionaries/models/IndexDictionaryRecordsResponse';
import {
  CreateDictionaryRecordRequest
} from '../../../services/clinic/dictionaries/models/CreateDictionaryRecordRequest';
import {
  indexClinicBillPaymentTypes,
  createClinicBillPaymentType,
  updateClinicBillPaymentType,
  destroyClinicBillPaymentType
} from '../../../services/clinic/dictionaries/billPaymentTypesService';

export const setBillPaymentTypes = (diagnoses: IndexDictionaryRecordsResponse): GetBillPaymentTypesAction => ({
  type: GET_BILL_PAYMENT_TYPES,
  payload: diagnoses
});

export const resetBillPaymentTypes = (): BillPaymentTypesResetAction => ({
  type: RESET
});

export const showModal = (value: boolean): ShowModalAction => ({
  type: SHOW_MODAL,
  payload: value
});

export const setLoading = (value: boolean): SetLoadingAction => ({
  type: SET_LOADING,
  payload: value
});

export const setSearchQuery = (data: any[]): SetSearchQueryAction => ({
  type: SET_SEARCH_QUERY,
  payload: data
});

export const indexBillPaymentTypes = (clinicId: number, page: number = 1, searchValue?: any, callBackFnc?: any, pageSize?: number): any => {
  return dispatch => {
    dispatch(setLoading(true));
    indexClinicBillPaymentTypes(clinicId, page, searchValue, pageSize)
      .then(data => {
        if (callBackFnc) {
          callBackFnc();
        }
        dispatch(setBillPaymentTypes(data));
        dispatch(setLoading(false));
        dispatch(setSearchQuery(searchValue));
      })
      .catch(() => dispatch(setLoading(false)));
  };
};

export const createBillPaymentType = (clinicId: number, data: CreateDictionaryRecordRequest,
  page = null,
  searchArray = null, callBackAfterCreate?: any): any => {
  return dispatch => {
    dispatch(setLoading(true));
    createClinicBillPaymentType(clinicId, data)
      .then(data => {
        if (callBackAfterCreate) {
          callBackAfterCreate(data);
        } else {
          dispatch(indexBillPaymentTypes(clinicId));
        }
        dispatch(setLoading(false));
      })
      .catch(() => dispatch(setLoading(false)));
  };
};

export const updateBillPaymentType = (clinicId: number, diagnosisId: number, data: CreateDictionaryRecordRequest,
  page = null,
  searchArray = null): any => {
  return dispatch => {
    dispatch(setLoading(true));
    updateClinicBillPaymentType(clinicId, diagnosisId, data)
      .then(data => {
        dispatch(indexBillPaymentTypes(clinicId));
        dispatch(setLoading(false));
      })
      .catch(() => dispatch(setLoading(false)));
  };
};

export const destroyBillPaymentType = (clinicId: number, diagnosisId: number,
  page = null,
  searchArray = null): any => {
  return dispatch => {
    dispatch(setLoading(true));
    destroyClinicBillPaymentType(clinicId, diagnosisId)
      .then(data => {
        dispatch(indexBillPaymentTypes(clinicId));
        dispatch(setLoading(false));
      })
      .catch(() => dispatch(setLoading(false)));
  };
};
