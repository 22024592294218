import {
  GET_SERVICE_GROUPS,
  GetServiceGroupsAction,
  RESET,
  ServiceGroupsResetAction,
  SET_LOADING,
  SET_SEARCH_QUERY,
  SetLoadingAction,
  SetSearchQueryAction,
  SHOW_MODAL,
  ShowModalAction
} from './types';
import {
  IndexDictionaryRecordsResponse
} from '../../../services/clinic/dictionaries/models/IndexDictionaryRecordsResponse';
import {
  CreateDictionaryRecordRequest
} from '../../../services/clinic/dictionaries/models/CreateDictionaryRecordRequest';
import {
  createClinicServiceGroup,
  destroyClinicServiceGroup,
  indexClinicServiceGroups,
  updateClinicServiceGroup
} from '../../../services/clinic/dictionaries/serviceGroupsService';

export const setServiceGroups = (diagnoses: IndexDictionaryRecordsResponse): GetServiceGroupsAction => ({
  type: GET_SERVICE_GROUPS,
  payload: diagnoses
});

export const resetServiceGroups = (): ServiceGroupsResetAction => ({
  type: RESET
});

export const showModal = (value: boolean): ShowModalAction => ({
  type: SHOW_MODAL,
  payload: value
});

export const setLoading = (value: boolean): SetLoadingAction => ({
  type: SET_LOADING,
  payload: value
});

export const setSearchQuery = (data: any[]): SetSearchQueryAction => ({
  type: SET_SEARCH_QUERY,
  payload: data
});

export const indexServiceGroups = (clinicId: number, page: number = 1, searchValue?: any, callBackFunc?: any, pageSize?: number): any => {
  return dispatch => {
    dispatch(setLoading(true));
    indexClinicServiceGroups(clinicId, page, searchValue, pageSize)
      .then(data => {
        if (callBackFunc) {
          callBackFunc(data);
        }
        dispatch(setServiceGroups(data));
        dispatch(setLoading(false));
        dispatch(setSearchQuery(searchValue));
      })
      .catch(() => dispatch(setLoading(false)));
  };
};

export const createServiceGroup = (clinicId: number, data: CreateDictionaryRecordRequest,
  page = null,
  searchArray = null, callBackFunc?: any): any => {
  return dispatch => {
    dispatch(setLoading(true));
    createClinicServiceGroup(clinicId, data)
      .then(data => {
        if (callBackFunc) {
          callBackFunc(data);
        }
        dispatch(indexServiceGroups(clinicId, page, searchArray));
        dispatch(setLoading(false));
      })
      .catch(() => dispatch(setLoading(false)));
  };
};

export const updateServiceGroup = (clinicId: number, diagnosisId: number, data: CreateDictionaryRecordRequest,
  page = null,
  searchArray = null): any => {
  return dispatch => {
    dispatch(setLoading(true));
    updateClinicServiceGroup(clinicId, diagnosisId, data)
      .then(data => {
        dispatch(indexServiceGroups(clinicId, page, searchArray));
        dispatch(setLoading(false));
      })
      .catch(() => dispatch(setLoading(false)));
  };
};

export const destroyServiceGroup = (clinicId: number, diagnosisId: number,
  page = null,
  searchArray = null): any => {
  return dispatch => {
    dispatch(setLoading(true));
    destroyClinicServiceGroup(clinicId, diagnosisId)
      .then(data => {
        dispatch(indexServiceGroups(clinicId, page, searchArray));
        dispatch(setLoading(false));
      })
      .catch(() => dispatch(setLoading(false)));
  };
};
