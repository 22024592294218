import { Form } from 'antd';
import ConfirmCancelModal from 'components/ConfirmCancelModal';
import ViewDetailsButtonsGroup from 'components/ViewDetailsButtonsGroup';
import VLXModal from 'components/VLXModal';
import { useLocale } from 'hooks/useLocale';
import { useUserAccess } from 'hooks/useUserAccess';
import { IAppState } from 'interfaces/app-state';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { showPatientModal } from 'redux/appointments/actions';

import AddAppointmentPatientForm from './AddAppointmentPatientForm';

const AddAppointmentPatient = ({ clientId, appointmentId, appointmentPatients }: any) => {
  const dispatch = useDispatch();
  const appointments = useSelector<IAppState, any>((state) => state.appointments);
  const locale = useLocale('private.appointments.event');
  const [showCancelModal, setShowCancelModal] = useState<boolean>(false);
  const userAccess = useUserAccess();
  const [form] = Form.useForm();

  const onConfirmCancelClick = () => {
    form.resetFields();
    dispatch(showPatientModal(false));
    setShowCancelModal(false);
  };

  return (
    <>
      <VLXModal
        title={locale.labels.addNewPatientToAppointment}
        centered={true}
        form={form}
        open={appointments.showPatientModal}
        onModalClose={() => dispatch(showPatientModal(false))}
        width={800}
      >
        {appointments.showPatientModal && (
          <AddAppointmentPatientForm
            form={form}
            locale={locale}
            clientId={clientId}
            appointmentId={appointmentId}
            appointmentPatients={appointmentPatients}
          >
            <ViewDetailsButtonsGroup
              fixed
              accessGroup={userAccess.accesses.appointments}
              editMode={true}
              hideDeleteBtn={true}
              onCancelClick={() => {
                if (form.isFieldsTouched()) {
                  setShowCancelModal(true);
                } else {
                  dispatch(showPatientModal(false));
                }
              }}
            />
          </AddAppointmentPatientForm>
        )}
        <ConfirmCancelModal
          open={showCancelModal}
          onOk={onConfirmCancelClick}
          onCancel={() => setShowCancelModal(false)}
        />
      </VLXModal>
    </>
  );
};

export default AddAppointmentPatient;
