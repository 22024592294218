import baseClient from '../../../baseClient';
import { CreatePatientRequest } from '../../../interfaces/patients/create-patient-request';

const clinicsUrl: string = '/v1/clinics';

export const getClinicPatientVaccinations = async (id: number, patientId: number, page: number) =>
  baseClient
    .get(`${clinicsUrl}/${id}/patients/${patientId}/vaccinations?page=${page}`)
    .then((response: any) => {
      return response.data;
    });

export const getClinicPatientVaccination = async (
  id: number,
  patientId: number,
  vaccinationId: number
) =>
  baseClient
    .get(`${clinicsUrl}/${id}/patients/${patientId}/vaccinations/${vaccinationId}`)
    .then((response: any) => {
      return response.data;
    });

export const updateClinicPatientVaccination = async (
  clinicId: number,
  patientId: number,
  vaccinationId: number,
  data: CreatePatientRequest
) =>
  baseClient
    .put(`/v1/clinics/${clinicId}/patients/${patientId}/vaccinations/${vaccinationId}`, data)
    .then((response: any) => {
      return response.data;
    });

export const deleteClinicPatientVaccination = async (
  clinicId: number,
  patientId: number,
  vaccinationId: number
) =>
  baseClient
    .delete(`/v1/clinics/${clinicId}/patients/${patientId}/vaccinations/${vaccinationId}`)
    .then((response: any) => {
      return response.data;
    });

export const addClinicPatientVaccination = async (
  clinicId: number,
  patientId: number,
  data: CreatePatientRequest
) =>
  baseClient
    .post(`/v1/clinics/${clinicId}/patients/${patientId}/vaccinations`, data)
    .then((response: any) => {
      return response.data;
    });
