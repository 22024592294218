import { Button, Form, Input, Modal, Spin } from 'antd';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { requiredRule } from 'utils/form-rules';

import PhoneFieldFormItem from '../../components/PhoneFieldFormItem';
import { useLocale } from '../../hooks/useLocale';
import { getUser } from '../../redux/user/actions';
import { changePhoneNumber } from '../../services/clinic/users/userService';
import MessageModal from '../components/messageModal/MessageModal';

interface PhoneNumber {
  phone_number: string;
  country_code: string;
}

type Props = {
  phoneNumber: PhoneNumber;
};

const ChangeUserPhoneNumber = ({ phoneNumber }: Props) => {
  const [visible, setVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();
  const locale = useLocale('private.user.user-profile');
  const dispatch = useDispatch();

  const onSubmit = (values: any) => {
    setLoading(true);
    changePhoneNumber(values)
      .then(() => {
        MessageModal('success', locale.messages.phoneNumberChanged);
        dispatch(getUser());
        setVisible(false);
        form.resetFields();
      })
      .catch((e) => {
        form.setFields([
          {
            name: 'password',
            errors: [locale.messages.wrongPassword]
          }
        ]);
      })
      .finally(() => setLoading(false));
  };

  return (
    <>
      <Button className='edit-button' type='primary' block onClick={() => setVisible(true)}>
        {locale.buttons.changePhone}
      </Button>
      <Modal
        title={locale.labels.changePhoneNumberTitle}
        open={visible}
        onCancel={() => setVisible(false)}
        footer={null}
        width={300}
        destroyOnClose
      >
        <Spin spinning={loading}>
          <Form form={form} onFinish={onSubmit} autoComplete='off' layout='vertical'>
            <Form.Item
              label={locale.labels.password}
              name='password'
              rules={[requiredRule(locale.messages.emptyField)]}
            >
              <Input.Password />
            </Form.Item>
            <PhoneFieldFormItem />
            <Form.Item>
              <Button block type='primary' htmlType='submit'>
                {locale.buttons.submit}
              </Button>
            </Form.Item>
          </Form>
        </Spin>
      </Modal>
    </>
  );
};

export default ChangeUserPhoneNumber;
