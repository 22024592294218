import { AxiosResponse } from 'axios';

import baseClient from '../../baseClient';
import { generateSearchQuery } from '../../helpers/commonUtils';
import { CreateDictionaryRecordRequest } from './models/CreateDictionaryRecordRequest';
import { IndexDictionaryRecordsResponse } from './models/IndexDictionaryRecordsResponse';
import { ShowDictionaryRecordResponse } from './models/ShowDictionaryRecordResponse';

function url(clinic_id) {
  return `/v1/clinics/${clinic_id}/dictionaries/bill_payment_types`;
}

export const createClinicBillPaymentType = async (
  clinicId: number,
  data: CreateDictionaryRecordRequest
) =>
  baseClient
    .post(url(clinicId), data)
    .then((response: AxiosResponse<ShowDictionaryRecordResponse>) => {
      return response.data;
    });

export const updateClinicBillPaymentType = async (
  clinicId: number,
  recordId: number,
  data: CreateDictionaryRecordRequest
) =>
  baseClient
    .put(`${url(clinicId)}/${recordId}`, data)
    .then((response: AxiosResponse<ShowDictionaryRecordResponse>) => {
      return response.data;
    });

export const showClinicBillPaymentType = async (clinicId: number, recordId: number) =>
  baseClient
    .get(`${url(clinicId)}/${recordId}`)
    .then((response: AxiosResponse<ShowDictionaryRecordResponse>) => {
      return response.data;
    });

export const destroyClinicBillPaymentType = async (clinicId: number, recordId: number) =>
  baseClient.delete(`${url(clinicId)}/${recordId}`).then((response: any) => {
    return response.data;
  });

export const indexClinicBillPaymentTypes = async (
  clinicId: number,
  page: number = 0,
  searchArray?: any[],
  pageSize: number = 10
) =>
  baseClient
    .get(`${url(clinicId)}?page=${page}${generateSearchQuery(searchArray)}${pageSize ? `&items=${pageSize}` : ''}`)
    .then((response: AxiosResponse<IndexDictionaryRecordsResponse>) => {
      return response.data;
    });
