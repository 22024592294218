import baseClient from '../../baseClient';
import { CreateAppointmentRequest } from '../../interfaces/appointments/create-appointment-request';

export const createClinicAppointment = async (
  clinicId: number,
  appointmentData: CreateAppointmentRequest
) =>
  baseClient
    .post(`/v1/clinics/${clinicId}/appointments`, appointmentData)
    .then((response: any) => {
      return response.data;
    });

export const editClinicAppointment = async (
  clinicId: number,
  appointmentId: number,
  appointmentData: CreateAppointmentRequest
) =>
  baseClient
    .put(`/v1/clinics/${clinicId}/appointments/${appointmentId}`, appointmentData)
    .then((response: any) => {
      return response.data;
    });

export const getClinicAppointmentDetails = async (
  clinicId: number,
  appointmentId: number
) =>
  baseClient
    .get(`/v1/clinics/${clinicId}/appointments/${appointmentId}`)
    .then((response: any) => {
      return response.data;
    });

export const updateClinicAppointment = async (
  clinicId: number,
  appointmentData: CreateAppointmentRequest,
  appointmentId: number
) =>
  baseClient
    .put(`/v1/clinics/${clinicId}/appointments/${appointmentId}`, appointmentData)
    .then((response: any) => {
      return response.data;
    });

export const patchClinicAppointment = async (
  clinicId: number,
  data: any,
  appointmentId: number
) =>
  baseClient
    .patch(`/v1/clinics/${clinicId}/appointments/${appointmentId}`, data)
    .then((response: any) => {
      return response.data;
    });

export const cancelClinicAppointment = async (
  clinicId: number,
  data: any,
  appointmentId: number
) =>
  baseClient
    .post(`/v1/clinics/${clinicId}/appointments/${appointmentId}`, data)
    .then((response: any) => {
      return response.data;
    });
