import { LocaleCodeActions, SET_LOCALE_CODE } from './types';

const initialState = 'ukUA';

export function localeCodeReducer(state: string = initialState, action: LocaleCodeActions): any {
  switch (action.type) {
    case SET_LOCALE_CODE: {
      return action.payload;
    }

    default: {
      return state;
    }
  }
}
