import '../Finances.scss';
import './ShiftsDetails.scss';
import './ShiftsList.scss';

import {
  Card,
  Col,
  Collapse,
  Row,
  Spin,
  Statistic,
  Table,
  TableColumnProps,
  Typography
} from 'antd';
import dayjs from 'dayjs';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

import useClinicId from '../../../hooks/useClinicId';
import { useLocale } from '../../../hooks/useLocale';
import { IAppState } from '../../../interfaces/app-state';
import { showShiftDetails } from '../../../redux/shifts/actions';
import ShiftStatusCard from '../components/ShiftStatusCard';
import FinanceIcon from '../helpers/FinanceIcon';
import { currentEarnings, getShiftDuration, userData } from './ShiftsBaseHelper';

const ShiftDetails = () => {
  const dispatch = useDispatch();
  const clinicId = useClinicId();
  const { shiftId }: any = useParams();
  const loading = useSelector<IAppState, any>((state) => state.shifts.loading);
  const shift = useSelector<IAppState, any>((state) => state.shifts.selectedShift);
  const navigate = useNavigate();
  const paymentsLocale = useLocale('private.finances.payments');
  const locale = useLocale('private.finances.shifts');
  const currencies = useLocale('private.currencies').labels;

  useEffect(() => {
    if (clinicId) dispatch(showShiftDetails(clinicId, shiftId));
  }, [clinicId, shiftId]);

  const redirectToPaymentDetails = (record: any) => {
    return {
      onClick: () => {
        navigate(`/clinic/${clinicId}/finances/payments/${record.id}`);
      }
    };
  };

  const getClosedBy = (closedBy: any) => {
    const closedByName = closedBy?.name || '-';
    const closedByTitle = closedBy?.title ? `(${closedBy.title})` : '';
    return `${closedByName} ${closedByTitle}`;
  };

  const columns: Array<TableColumnProps<any>> = [
    {
      key: 'payment_type',
      dataIndex: 'payment_type',
      title: '',
      sorter: (a, b) => (a.payment_type > b.payment_type ? 1 : -1),
      render: (payment_type, e) => <FinanceIcon type={payment_type} />
    },
    {
      key: 'name',
      dataIndex: 'name',
      title: paymentsLocale.labels.name,
      sorter: (a, b) => (a.name > b.name ? 1 : -1),
      render: (name, e) => name || '-'
    },
    {
      key: 'date',
      dataIndex: 'date',
      title: paymentsLocale.labels.date,
      sorter: (a, b) => (a.date > b.date ? 1 : -1),
      render: (date) => `${date ? dayjs(date).format('DD.MM.YYYY HH:mm') : '-'}`
    },
    {
      key: 'amount',
      dataIndex: 'amount',
      title: paymentsLocale.labels.amount,
      sorter: (a, b) => (a.amount > b.amount ? 1 : -1),
      render: (amount, e) => (amount !== undefined ? `${amount} ${currencies.uah}` : '-')
    },
    {
      key: 'created_by',
      dataIndex: 'created_by',
      title: paymentsLocale.labels.createdBy,
      sorter: (a, b) => (a.closed_by.name > b.closed_by.name ? 1 : -1),
      render: (closed_by, e) => getClosedBy(closed_by)
    }
  ];

  const collapseHeader = (account: any) => {
    return (
      <Row gutter={[24, 12]} justify={'space-between'} className={'collapse-header-row'}>
        <Col span={6} style={{ minWidth: '112px' }}>
          <Typography.Title level={5}>{account?.name}</Typography.Title>
        </Col>
        <Col span={12}>
          <Row justify={'space-between'} style={{ display: 'flex', width: '100%' }}>
            <Col
              span={6}
              style={{
                display: 'flex',
                justifyContent: 'flex-end',
                minWidth: '95px',
                marginBottom: '5px'
              }}
            >
              <Typography>
                {`${locale.labels.incomeOperations}: ${account?.income_payments_number}`}
              </Typography>
            </Col>
            <Col
              span={6}
              style={{
                display: 'flex',
                justifyContent: 'flex-end',
                minWidth: '95px',
                marginBottom: '5px'
              }}
            >
              <Typography>
                {`${locale.labels.outcomeOperations}: ${account?.outcome_payments_number}`}
              </Typography>
            </Col>
            <Col
              span={6}
              style={{
                display: 'flex',
                justifyContent: 'flex-end',
                minWidth: '95px',
                marginBottom: '5px'
              }}
            >
              <Typography.Title level={5}>
                {currentEarnings(account.diff, currencies)}
              </Typography.Title>
            </Col>
          </Row>
        </Col>
      </Row>
    );
  };

  return (
    <Spin spinning={loading} className='bills-list-page-spin-container'>
      {shift && (
        <>
          <Row>
            <Col span={12} xs={24} md={18} xl={15} xxl={10} style={{ marginRight: '10px' }}>
              <Card
                className='ant-card-bordered personal-info-card with-shadow'
                style={{ minHeight: '261px' }}
              >
                <ShiftStatusCard shift={shift} showInfo={false} />
              </Card>
            </Col>
            <Col span={12} xs={24} md={18} xl={15} xxl={10} className={'shift-details-status-card'}>
              <Card className='ant-card-bordered personal-info-card with-shadow'>
                <Row justify={'space-evenly'} style={{ marginBottom: '10px' }}>
                  <Statistic
                    title={locale.labels.duration}
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center'
                    }}
                    value={getShiftDuration(shift, locale)}
                  />
                </Row>
                <Row justify={'space-evenly'} style={{ marginBottom: '10px' }}>
                  <Col>
                    <Statistic
                      title={locale.labels.openedBy}
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center'
                      }}
                      value={userData(shift?.opened_by)}
                    />
                    <Statistic
                      title={locale.labels.closedBy}
                      style={{
                        marginTop: '10px',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center'
                      }}
                      value={!shift?.open ? userData(shift?.closed_by) : '-'}
                    />
                  </Col>
                  <Col>
                    <Statistic
                      title={locale.labels.openedAt}
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center'
                      }}
                      value={dayjs(shift.opened_at).local().format('DD.MM.YYYY HH:mm')}
                    />
                    <Statistic
                      title={locale.labels.closedAt}
                      style={{
                        marginTop: '10px',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center'
                      }}
                      value={
                        !shift?.open
                          ? dayjs(shift.closed_at).local().format('DD.MM.YYYY HH:mm')
                          : '-'
                      }
                    />
                  </Col>
                </Row>
                {/* <Row justify={'space-evenly'}> */}
                {/*   <Statistic */}
                {/*     title={locale.labels.closedBy} */}
                {/*     style={{ */}
                {/*       display: 'flex', */}
                {/*       flexDirection: 'column', */}
                {/*       alignItems: 'center' */}
                {/*     }} */}
                {/*     value={!shift?.open ? userData(shift?.closed_by) : '-'} */}
                {/*   /> */}
                {/*   <Statistic */}
                {/*     title={locale.labels.closedAt} */}
                {/*     style={{ */}
                {/*       display: 'flex', */}
                {/*       flexDirection: 'column', */}
                {/*       alignItems: 'center' */}
                {/*     }} */}
                {/*     value={!shift?.open ? dayjs(shift.closed_at).local().format('DD.MM.YYYY HH:mm') : '-'} */}
                {/*   /> */}
                {/* </Row> */}
              </Card>
            </Col>
          </Row>
          <Row style={{ width: '100%' }}>
            <Collapse
              style={{ width: '100%' }}
              items={shift?.accounts.map((account: any, index: number) => {
                return {
                  key: account.id,
                  label: collapseHeader(account),
                  children: (
                    <Table
                      pagination={false}
                      className='accent-header payments-list-table'
                      rowKey='id'
                      onRow={redirectToPaymentDetails}
                      dataSource={account.payments}
                      columns={columns}
                    />
                  ),
                  className: 'shits-collapse-header'
                };
              })}
            ></Collapse>
          </Row>
        </>
      )}
    </Spin>
  );
};

export default ShiftDetails;
