import { Form } from 'antd';
import ConfirmCancelModal from 'components/ConfirmCancelModal';
import ConfirmDeleteModal from 'components/ConfirmDeleteModal';
import ViewDetailsButtonsGroup from 'components/ViewDetailsButtonsGroup';
import { useUserAccess } from 'hooks/useUserAccess';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { removeService, showModal } from 'redux/services/actions';

import AddServiceForm from './AddServiceForm';

const EditService = ({
  initialValues,
  locale,
  showCancelModal,
  setShowCancelModal,
  editMode,
  setEditMode,
  blocker
}: any): JSX.Element => {
  const dispatch = useDispatch();
  const userAccess = useUserAccess();
  const [form] = Form.useForm();
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
  const { clinicId, serviceId }: any = useParams();
  const navigate = useNavigate();

  const onConfirmCancelClick = () => {
    form.resetFields();
    form.setFieldValue('stock', initialValues?.stock);
    dispatch(showModal(false));
    setShowCancelModal(false);
    setEditMode(false);
    if (blocker.state === 'blocked') {
      blocker.proceed();
    }
  };

  const onFieldsChange = (e: any) => {
    if (e.length < 2 && e.length !== 0 && !editMode) {
      setEditMode(true);
    }
  };

  const onConfirmDeleteClick = () => {
    dispatch(removeService(clinicId, serviceId));
    navigate(`/clinic/${clinicId}/services`);
  };

  const values = Form.useWatch([], form);

  useEffect(() => {
    form.validateFields({ validateOnly: true });
  }, [values]);

  return (
    <>
      {initialValues && (
        <AddServiceForm
          locale={locale}
          form={form}
          setEditMode={setEditMode}
          onFieldsChange={onFieldsChange}
          initialValues={initialValues}
          formEditMode
        >
          <ViewDetailsButtonsGroup
            fixed
            accessGroup={userAccess.accesses.services}
            editMode={editMode}
            onEditClick={() => setEditMode(true)}
            onCancelClick={() => {
              if (form.isFieldsTouched()) {
                setShowCancelModal(true);
              } else {
                setEditMode(false);
              }
            }}
            onDeleteClick={() => {
              setShowCancelModal(false);
              setShowDeleteModal(true);
            }}
          />
        </AddServiceForm>
      )}
      <ConfirmCancelModal
        open={showCancelModal}
        onOk={onConfirmCancelClick}
        onCancel={() => {
          setShowCancelModal(false);
          blocker.reset();
        }}
      />
      <ConfirmDeleteModal
        type={'device'}
        open={showDeleteModal}
        onOk={onConfirmDeleteClick}
        onCancel={() => setShowDeleteModal(false)}
      />
    </>
  );
};

export default EditService;
