import baseClient from '../../../baseClient';

export const getClinicAppointmentDetailsPatients = async (
  clinicId: number,
  appointmentId: number
) =>
  baseClient
    .get(`/v1/clinics/${clinicId}/appointments/${appointmentId}/patients`)
    .then((response: any) => {
      return response.data;
    });

export const deleteClientAppointmentDetailsPatient = async (
  clinicId: number,
  appointmentId: number,
  patientId: number
) =>
  baseClient
    .delete(`/v1/clinics/${clinicId}/appointments/${appointmentId}/patients/${patientId}`)
    .then((response: any) => {
      return response.data;
    });

export const addNewExistingPatientToClinicAppointment = async (
  clinicId: number,
  appointmentId: number,
  data: any
) =>
  baseClient
    .post(`/v1/clinics/${clinicId}/appointments/${appointmentId}/patients_batch`, data)
    .then((response: any) => {
      return response.data;
    });
