export const locales = [
  {
    key: 'enGB',
    label: 'EN'
  },
  {
    key: 'ukUA',
    label: 'UA'
  }
];
