import { Form } from 'antd';
import ConfirmCancelModal from 'components/ConfirmCancelModal';
import StickyButton from 'components/StickyButton';
import ViewDetailsButtonsGroup from 'components/ViewDetailsButtonsGroup';
import VLXModal from 'components/VLXModal';
import { useUserAccess } from 'hooks/useUserAccess';
import { IAppState } from 'interfaces/app-state';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { showModal } from 'redux/invitations/actions';

import InvitationForm from './InvitationForm';

const Invitation = (): JSX.Element => {
  const dispatch = useDispatch();
  const userAccess = useUserAccess();
  const [form] = Form.useForm();

  const invitations = useSelector(({ invitations }: IAppState) => invitations);

  const [showCancelModal, setShowCancelModal] = useState<boolean>(false);

  const onConfirmCancelClick = () => {
    setShowCancelModal(false);
    form.resetFields();
    dispatch(showModal(false));
  };

  return (
    <>
      <StickyButton
        offsetBottom={10}
        onClick={() => dispatch(showModal(true))}
        iconElement={
          <span
            className='icofont icofont-plus mr-2 sticky-btn-icon'
            style={{ fontSize: '1.3em' }}
          />
        }
        textElement={'Invite'}
      />
      <VLXModal
        title={'Invite employees'}
        centered={true}
        form={form}
        open={invitations.showModal}
        onModalClose={() => dispatch(showModal(false))}
        width={800}
      >
        <InvitationForm form={form}>
          <ViewDetailsButtonsGroup
            fixed
            accessGroup={userAccess.accesses.invitations}
            editMode={true}
            hideDeleteBtn={true}
            onCancelClick={() => {
              if (form.isFieldsTouched()) {
                setShowCancelModal(true);
              } else {
                dispatch(showModal(false));
              }
            }}
            onDeleteClick={() => {
              setShowCancelModal(false);
            }}
          />
        </InvitationForm>
        <ConfirmCancelModal
          open={showCancelModal}
          onOk={onConfirmCancelClick}
          onCancel={() => setShowCancelModal(false)}
        />
      </VLXModal>
    </>
  );
};

export default Invitation;
