import {
  RESET,
  ServicesActions,
  SET_LOADING,
  SET_OPENED_SHIFT,
  SET_SELECTED_SHIFT,
  SET_SHIFTS,
  SHOW_MODAL
} from './types';
import { IndexShiftsResponse } from '../../services/clinic/shifts/models/IndexShiftsResponse';
import { ShowShiftResponse } from '../../services/clinic/shifts/models/ShowShiftResponse';

export interface ShiftsState extends IndexShiftsResponse{
  loading: boolean
  selectedShift: ShowShiftResponse | null
  openedShift: ShowShiftResponse | null
  showModal: boolean
}

const initialState: ShiftsState = {
  data: [],
  metadata: {
    total: 0,
    page: 1,
    next: null,
    last: 1,
    prev: null
  },
  loading: false,
  selectedShift: null,
  openedShift: null,
  showModal: false
};

export function shiftsReducer (
  state: ShiftsState = initialState,
  action: ServicesActions
): any {
  switch (action.type) {
    case SET_SHIFTS: {
      return {
        ...state,
        ...action.payload
      };
    }

    case RESET: {
      return initialState;
    }

    case SET_LOADING: {
      return {
        ...state,
        loading: action.payload
      };
    }

    case SHOW_MODAL: {
      return {
        ...state,
        showModal: action.payload
      };
    }

    case SET_OPENED_SHIFT: {
      return {
        ...state,
        openedShift: action.payload
      };
    }

    case SET_SELECTED_SHIFT: {
      return {
        ...state,
        selectedShift: action.payload
      };
    }

    default: {
      return state;
    }
  }
}
