import './AuditList.scss';

import { Pagination, Row, Spin, Table, TableColumnProps } from 'antd';
import VLXSearch from 'components/VLXSearch';
import { auditStatuses } from 'constants/dictionary/default/selectOptions';
import useClinicId from 'hooks/useClinicId';
import { useLocale } from 'hooks/useLocale';
import useLocalizedList from 'hooks/useLocalizedList';
import { useSearch } from 'hooks/useSearch';
import { useUserAccess } from 'hooks/useUserAccess';
import { IAppState } from 'interfaces/app-state';
import moment from 'moment';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { getAudit, setSelectedAudit, setSelectedAuditItems } from 'redux/inventory/audit/actions';
import { IAuditListItem } from 'services/interfaces/clinics/inventory/audit/IAuditList';
import valueFromList from 'utils/value-from-list';

import { Stat } from '../components/Stat';
import AddAuditModal from './AddAuditModal';

const AuditList = (): JSX.Element => {
  const dispatch = useDispatch();
  const clinicId = useClinicId();
  const navigate = useNavigate();
  const { accesses } = useUserAccess();
  const locale = useLocale('private.inventory.audit');
  const currencies = useLocale('private.currencies').labels;
  const localizedAuditStatuses = useLocalizedList(auditStatuses);

  const { data, metadata } = useSelector(({ audit }: IAppState) => audit);
  const loading = useSelector(({ audit }: IAppState) => audit.loading);

  const [expanded, setExpended] = useState([]);

  const { handleChange, onPageChange, getResults, filterResults } = useSearch({
    action: getAudit,
    clinicId,
    defaultSortKey: 'created_at',
    defaultSortOrder: 'desc'
  });

  useEffect(() => {
    dispatch(setSelectedAudit(null));
  }, []);

  const redirectToAuditDetails = (record: IAuditListItem): { onClick: () => void } =>
    accesses.audit.showItem && {
      onClick: () => {
        navigate(`/clinic/${clinicId}/inventory/audit/${record.id}`);
      }
    };

  const displayTotalStats = useCallback(
    (audit: IAuditListItem): JSX.Element => (
      <>
        {Number(audit.items_surplus) !== 0 && (
          <>
            <Stat color='green'>
              {`+${audit.items_surplus} ${locale.labels.itemsLower} (~${audit.money_surplus} ${currencies.uah})`}
            </Stat>
            <br />
          </>
        )}
        {Number(audit.items_shortage) !== 0 && (
          <>
            <Stat color='red'>
              {`-${audit.items_shortage} ${locale.labels.itemsLower} (~${audit.money_shortage} ${currencies.uah})`}
            </Stat>
            <br />
          </>
        )}
        {Number(audit.achieved) !== 0 && (
          <Stat color='grey'>
            {`${locale.labels.archivedItems} - ${audit.achieved} (${locale.labels.depletedItems})`}
          </Stat>
        )}
      </>
    ),
    [data]
  );

  const displayStats = useCallback(
    (audit): JSX.Element => {
      if (+audit.items_surplus !== 0 || +audit.items_shortage !== 0 || +audit.achieved !== 0) {
        return displayTotalStats(audit);
      }

      return <Stat color='green'>{locale.labels.allMatches}</Stat>;
    },
    [data]
  );

  const columns = useMemo(
    (): TableColumnProps<IAuditListItem>[] => [
      {
        key: 'name',
        title: locale.labels.name,
        sorter: () => null,
        onCell: redirectToAuditDetails,
        render: (e) => `${e.name || '-'}`
      },
      {
        key: 'date',
        title: locale.labels.date,
        sorter: () => null,
        onCell: redirectToAuditDetails,
        render: (e) => `${e.date ? moment(e.date).format('DD.MM.YYYY') : '-'}`
      },
      {
        key: 'status',
        title: locale.labels.status,
        sorter: () => null,
        onCell: redirectToAuditDetails,
        render: (e) => `${e.status ? valueFromList(localizedAuditStatuses, e?.status) : '-'}`
      },
      {
        key: 'stats',
        title: locale.labels.summary,
        sorter: () => null,
        onCell: redirectToAuditDetails,
        render: (e) => (e.status === 'finished' ? displayStats(e) : '-')
      }
    ],
    [data]
  );

  return (
    <>
      <VLXSearch filterResults={filterResults} savedFiltersName={'auditsListFilters'} />
      <Spin spinning={loading}>
        <Table
          pagination={false}
          className='accent-header audit-list'
          rowKey='id'
          dataSource={data}
          columns={columns}
          onChange={handleChange}
          expandable={{
            onExpand: (expanded, record) => {
              dispatch(setSelectedAuditItems(null));
              const current = expanded ? [record.id] : [];
              setExpended(current);
            },
            expandedRowKeys: expanded
          }}
        />
        <Row
          style={{
            justifyContent: 'space-between',
            marginTop: 10,
            alignItems: 'center'
          }}
        >
          <Pagination
            className={'equipment-pagination'}
            defaultCurrent={1}
            current={metadata.page}
            showSizeChanger={false}
            showLessItems
            pageSize={20}
            total={metadata.total}
            onChange={onPageChange}
          />
          {accesses.audit.create && <AddAuditModal clinicId={clinicId} callbackFn={getResults} />}
        </Row>
      </Spin>
    </>
  );
};

export default AuditList;
