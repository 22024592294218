import { Button, Carousel, Col, Collapse, Form, Row, Steps, Typography } from 'antd';
import breakpoint from 'assets/img/landing/breakpoint.png';
import Calendar2 from 'assets/img/landing/Calendar-h1700.png';
import Doctors from 'assets/img/landing/Doctors.png';
import appointmentListPage from 'assets/img/landing/features/Appointments_list.png';
import clientPage from 'assets/img/landing/features/Client_page.png';
import patientPage from 'assets/img/landing/features/Patient_page.png';
import stockPage from 'assets/img/landing/features/Stock_page.png';
import providerPage from 'assets/img/landing/features/Provider_page.png';
import Finances from 'assets/img/landing/Finances.png';
import Medicines2 from 'assets/img/landing/Medicines-h1700.png';
import Patients2 from 'assets/img/landing/Patients-h1700.png';
import Vet from 'assets/img/landing/Vet.png';
import VetCenter from 'assets/img/landing/Veterinary_center.png';
import LogoSvg from 'assets/img/logoBlack.svg';
import VLXModal from 'components/VLXModal';
import { useLocale } from 'hooks/useLocale';
import { IAppState } from 'interfaces/app-state';
import ForgotPassword from 'layout/components/actions/ForgotPassword';
import Login from 'layout/components/actions/Login';
import RegistrationRequestForm from 'layout/components/actions/RegistrationRequestForm';
import UserRegistration from 'layout/components/actions/UserRegistration';
import Logo from 'layout/components/logo/Logo';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';

const HomeLanding = (): JSX.Element => {
  const location = useLocation();
  const locale = useLocale('public.landing-page');
  const modalLocale = useLocale('public.landing-page').labels;
  const [registrationForm] = Form.useForm();
  const [registrationRequest] = Form.useForm();
  const [visible, changeVisible] = useState(false);
  const userId = useSelector<IAppState, number>((state) => state.user.id);
  const navigate = useNavigate();

  useEffect(() => {
    // if not a hash link, scroll to top
    const { pathname, hash } = location;
    if (pathname === '/' && userId) {
      navigate('/dashboard');
    }
    if (hash === '') {
      window.scrollTo(0, 0);
    }
    // else scroll to id
    else {
      setTimeout(() => {
        const id = hash.replace('#', '');
        const element = document.getElementById(id);
        if (element) {
          element.scrollIntoView({
            behavior: 'smooth'
          });
        }
      }, 0);
    }
  }, [location]); // do this on route change

  const showModal = (modalName) => {
    changeVisible(true);
    changeModal(modals[modalName]);
  };

  const modals = {
    login: {
      title: modalLocale.loginModalTitle,
      form: null,
      content: <Login onNavigate={showModal} changeVisible={changeVisible} />,
      width: 320
    },
    userRegistration: {
      title: modalLocale.singUpButton,
      form: registrationForm,
      content: <UserRegistration form={registrationForm} closeModal={() => changeVisible(false)} />,
      width: 800
    },
    forgotPassword: {
      title: locale.forgotPasswordModal,
      form: null,
      content: <ForgotPassword closeModal={() => changeVisible(false)} />,
      width: 300
    },
    registrationRequest: {
      title: locale.registrationRequest,
      form: registrationRequest,
      content: (
        <RegistrationRequestForm form={registrationForm} closeModal={() => changeVisible(false)} />
      ),
      width: 800
    }
  };

  const [modal, changeModal] = useState(modals.login);

  useEffect(() => {
    const headings = document.querySelectorAll('h3');
    document.addEventListener('scroll', (e) => {
      headings.forEach((ha: any) => {
        const rect = ha.getBoundingClientRect();
        if (rect.top > 0 && rect.top < 250) {
          const location = window.location.toString().split('#')[0];
          window.history.replaceState(null, null, location + '#' + ha.id);
        }
      });
    });
  }, []);

  return (
    <div className='landing-page'>
      <Row className='landing-page__block landing-page__block-intro' justify={'space-around'}>
        <Col lg={11}>
          <Logo src={LogoSvg} />
          <p>{locale.content.slogan}</p>
          <Typography>{locale.content.sloganText1}</Typography>
          <Typography>{locale.content.sloganText2}</Typography>
          <Row className='slogan-btns-container'>
            <Button onClick={() => showModal('login')}>{locale.labels.loginButton}</Button>
            <Button type='primary' onClick={() => showModal('registrationRequest')}>
              {locale.labels.registrationRequest}
            </Button>
            {/* <Button size='small' onClick={() => showModal('registrationRequest')}>{locale.registrationRequest}</Button> */}
          </Row>
        </Col>
        <Col md={18} lg={10} xl={8} className='landing-page__block-intro__right'>
          <img src={VetCenter} alt='clinic' className='clinic-img' />
        </Col>
      </Row>

      <>
        <Row
          gutter={[30, 0]}
          className='landing-page__block landing-page__block__m-t-90'
          justify={'space-between'}
        >
          <Col xl={8} className='basic-block'>
            <div className='basic-block__img-block'>
              <img src={Calendar2} alt='Calendar' />
            </div>
            <div>
              <Typography>{locale.basicDesc.organizationManagement}</Typography>
              <Typography>{locale.basicDesc.organizationManagement2}</Typography>
            </div>
          </Col>
          <Col xl={8} className='basic-block'>
            <div className='basic-block__img-block'>
              <img src={Vet} alt='Vet' />
            </div>
            <div>
              <Typography>{locale.basicDesc.patientsClientsManagement}</Typography>
            </div>
          </Col>
          <Col xl={8} className='basic-block'>
            <div className='basic-block__img-block'>
              <img src={Patients2} alt='Patients' />
            </div>
            <div>
              <Typography>{locale.basicDesc.patientHealthRecords}</Typography>
              <Typography>{locale.basicDesc.billingHistory}</Typography>
            </div>
          </Col>
          <Col xl={8} className='basic-block'>
            <div className='basic-block__img-block'>
              <img src={Medicines2} alt='Medicines' />
            </div>
            <div>
              <Typography>{locale.basicDesc.stockManagement}</Typography>
              <Typography>{locale.basicDesc.invoicesManagement}</Typography>
            </div>
          </Col>
          <Col xl={8} className='basic-block'>
            <div className='basic-block__img-block'>
              <img src={Finances} alt='Finances' />
            </div>
            <div>
              <Typography>{locale.basicDesc.financesManagement1}</Typography>
              <Typography>{locale.basicDesc.financesManagement2}</Typography>
            </div>
          </Col>

          <Col xl={8} className='basic-block'>
            <div className='basic-block__img-block'>
              <img src={Doctors} alt='Doctors' />
            </div>
            <div>
              <Typography>{locale.basicDesc.employeesManagement}</Typography>
            </div>
          </Col>
        </Row>
      </>

      <div className='landing-page__block'>
        <h3 id='features'>{locale.labels.features}</h3>
        <Carousel className='landing-page__carousel' autoplay={false}>
          <Row className='landing-page__carousel__item' justify={'space-between'}>
            <Col span={24} lg={12}>
              <img alt='carousel1' src={appointmentListPage} />
            </Col>
            <Col span={24} lg={11} className='landing-page__carousel__item__text-block'>
              <div className='landing-page__carousel__item__text-block__row'>
                <img src={breakpoint} />
                <span>{locale.carousel.appointmentsRow1}</span>
              </div>
              <div className='landing-page__carousel__item__text-block__row'>
                <img src={breakpoint} />
                <span>{locale.carousel.appointmentsRow2}</span>
              </div>
              <div className='landing-page__carousel__item__text-block__row'>
                <img src={breakpoint} />
                <span>{locale.carousel.appointmentsRow3}</span>
              </div>
            </Col>
          </Row>
          <Row className='landing-page__carousel__item' justify={'space-between'}>
            <Col span={24} lg={12} className='landing-page__carousel__item__text-block'>
              <img alt='carousel2' src={clientPage} />
            </Col>
            <Col span={10} className='landing-page__carousel__item__text-block'>
              <div className='landing-page__carousel__item__text-block__row'>
                <img src={breakpoint} />
                <span>{locale.carousel.clientsRow1}</span>
              </div>
              <div className='landing-page__carousel__item__text-block__row'>
                <img src={breakpoint} />
                <span>{locale.carousel.clientsRow2}</span>
              </div>
            </Col>
          </Row>
          <Row className='landing-page__carousel__item' justify={'space-between'}>
            <Col span={24} lg={12} className='landing-page__carousel__item__text-block'>
              <img alt='carousel2' src={patientPage} />
            </Col>
            <Col span={10} className='landing-page__carousel__item__text-block'>
              <div className='landing-page__carousel__item__text-block__row'>
                <img src={breakpoint} />
                <span>{locale.carousel.patientsRow1}</span>
              </div>
              <div className='landing-page__carousel__item__text-block__row'>
                <img src={breakpoint} />
                <span>{locale.carousel.patientsRow2}</span>
              </div>
              <div className='landing-page__carousel__item__text-block__row'>
                <img src={breakpoint} />
                <span>{locale.carousel.patientsRow3}</span>
              </div>
            </Col>
          </Row>
          <Row className='landing-page__carousel__item' justify={'space-between'}>
            <Col span={24} lg={12} className='landing-page__carousel__item__text-block'>
              <img alt='carousel2' src={stockPage} />
            </Col>
            <Col span={10} className='landing-page__carousel__item__text-block'>
              <div className='landing-page__carousel__item__text-block__row'>
                <img src={breakpoint} />
                <span>{locale.carousel.stockRow1}</span>
              </div>
              <div className='landing-page__carousel__item__text-block__row'>
                <img src={breakpoint} />
                <span>{locale.carousel.stockRow2}</span>
              </div>
              <div className='landing-page__carousel__item__text-block__row'>
                <img src={breakpoint} />
                <span>{locale.carousel.stockRow3}</span>
              </div>
            </Col>
          </Row>
          <Row className='landing-page__carousel__item' justify={'space-between'}>
            <Col span={24} lg={12} className='landing-page__carousel__item__text-block'>
              <img alt='carousel2' src={providerPage} />
            </Col>
            <Col span={10} className='landing-page__carousel__item__text-block'>
              <div className='landing-page__carousel__item__text-block__row'>
                <img src={breakpoint} />
                <span>{locale.carousel.providerRow1}</span>
              </div>
              <div className='landing-page__carousel__item__text-block__row'>
                <img src={breakpoint} />
                <span>{locale.carousel.providerRow2}</span>
              </div>
            </Col>
          </Row>
        </Carousel>
      </div>

      <Row className='landing-page__block'>
        <h3 id='getting_started'>{locale.labels.gettingStarted}</h3>
        <Steps
          responsive={false}
          current={0}
          items={[
            {
              title: `${locale.labels.step} 1`,
              description: locale.steps.step1
            },
            {
              title: `${locale.labels.step} 2`,
              description: locale.steps.step2
            },
            {
              title: `${locale.labels.step} 3`,
              description: locale.steps.step3
            },
            {
              title: `${locale.labels.step} 4`,
              description: locale.steps.step5
            }
          ]}
        />
      </Row>
      <Row className='landing-page__block'>
        <h3 id='faq'>{locale.labels.faq}</h3>
        <Collapse
          style={{ width: '100%', whiteSpace: 'pre-line'}}
          items={[
            {
              key: 1,
              label: locale.faq.whoWeAreQ,
              children: locale.faq.whoWeAreA
            }, {
              key: 2,
              label: locale.faq.modulesQ,
              children: locale.faq.modulesA
            },
            {
              key: 3,
              label: locale.faq.languagesQ,
              children: locale.faq.languagesA
            },
            {
              key: 4,
              label: locale.faq.howToRegisterQ,
              children: locale.faq.howToRegisterA
            },
            {
              key: 5,
              label: locale.faq.pricesQ,
              children: (
                <div>
                  {locale.faq.pricesA}{' '}
                  <Link target='_blank' className={'custom-links'} to={`/pricing`}>
                    {locale.labels.prices}
                  </Link>
                </div>
              )
            }
          ]}
        ></Collapse>
      </Row>
      <VLXModal
        title={modal.title}
        form={modal.form}
        open={visible}
        onModalClose={() => changeVisible(false)}
        width={modal.width}
      >
        {modal.content}
      </VLXModal>
    </div>
  );
};

export default HomeLanding;
