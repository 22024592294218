import baseClient from '../../baseClient';
import { EventsIndexResponse } from '../../interfaces/clinics/events/EventsIndexResponse';
import { IEvent } from '../../../../src/interfaces/event';
import { AxiosResponse } from 'axios';
import { generateSearchQuery } from '../../helpers/commonUtils';

const eventsUrl = (clinicId: number): string => { return `/v1/clinics/${clinicId}/events`; };

export const getClinicEvents = async (clinicId: number, searchArray?: any[]) => baseClient
  .get(`${eventsUrl(clinicId)}?${generateSearchQuery(searchArray)}`)
  .then((response: AxiosResponse<EventsIndexResponse[]>) => {
    return response.data;
  });

export const getUserEvents = async (searchArray?: any[]) => baseClient
  .get(`/v1/events?${generateSearchQuery(searchArray)}`)
  .then((response: AxiosResponse<EventsIndexResponse[]>) => {
    return response.data;
  });

export const getClinicEventById = async (clinicId: number, eventId) => baseClient
  .get(`/v1/events/${eventId}`)
  .then((response: AxiosResponse<IEvent>) => {
    return response.data;
  });
