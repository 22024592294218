import '../../../assets/scss/permissions-search.scss';

import { Button, Form, Input, List, Space, Spin } from 'antd';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { useLocale } from '../../../hooks/useLocale';
import { useUserAccess } from '../../../hooks/useUserAccess';
import { IAppState } from '../../../interfaces/app-state';
import { getPermissions } from '../../../redux/permissions/actions';
import { setEditMode } from '../../../redux/roles/actions';

interface RecordType {
  key: string;
  title: string;
  description: string;
  chosen: boolean;
}

const { Search } = Input;

const RoleDetails = ({ clinicId }: { clinicId: number }): JSX.Element => {
  const dispatch = useDispatch();
  const roles = useSelector<IAppState, any>((state) => state.roles);
  const [form] = Form.useForm();
  const [permissionsData, setPermissionsData] = useState<RecordType[]>([]);
  const editAccess = useUserAccess().accesses.roles.edit;
  const locale = useLocale('private.administration.roles');

  const getData = () => {
    const data = roles.selectedRole?.permissions?.map((item) => {
      return {
        key: item.id,
        title: item.name,
        description: '',
        chosen: false
      };
    });
    setPermissionsData(data);
  };

  const editDeleteButtons = () => {
    if (!editAccess) return null;
    return (
      <>
        <Button type='primary' onClick={onClick} shape='circle'>
          <span className='icofont icofont-edit-alt' />
        </Button>
        <Button type='primary' onClick={onClick} shape='circle' danger>
          <span className='icofont icofont-ui-delete' />
        </Button>
      </>
    );
  };

  const onClick = () => {
    dispatch(setEditMode(true));
    dispatch(getPermissions(clinicId));
  };

  useEffect(() => {
    form.setFieldsValue(roles.selectedRole);
  }, [form, roles.selectedRole]);

  useEffect(() => {
    getData();
  }, [roles.selectedRole]);

  const onSearch = (input: string) => {
    if (input === '') {
      getData();
    }
    const data = permissionsData.filter((item) => item.title.includes(input));
    setPermissionsData(data);
  };

  return (
    <Spin spinning={roles.loading}>
      <Form
        autoComplete='off'
        form={form}
        layout={'vertical'}
        initialValues={roles.selectedRole}
        style={{ marginLeft: 20 }}
      >
        <div className='row' style={{ margin: '10px 20px' }}>
          <div className='col-md-4  col-sm-12'>
            <Form.Item label={locale.labels.name} name='name'>
              <Input disabled={true} variant='borderless' />
            </Form.Item>
          </div>
          <div className='col-md-6  col-sm-12'>
            <Form.Item label={locale.labels.permissions}>
              <Search
                className={'permissions-search'}
                placeholder={locale.labels.inputSearchText}
                onSearch={onSearch}
                onChange={getData}
              />
              <List
                size='large'
                style={{
                  overflow: 'auto',
                  maxHeight: 300,
                  marginTop: 10
                }}
                bordered
                dataSource={permissionsData}
                renderItem={(item) => <List.Item>{item.title}</List.Item>}
              />
            </Form.Item>
          </div>
          <div className='col-md-2  col-sm-12'>
            <Form.Item>
              <Space style={{ marginTop: 30 }}>{editDeleteButtons()}</Space>
            </Form.Item>
          </div>
        </div>
      </Form>
    </Spin>
  );
};

export default RoleDetails;
