import { ISpeciesListSerialized } from '../../interfaces/species';
import { GET_SPECIES_LIST, SpeciesActions } from './types';

const initialState: ISpeciesListSerialized[] = [];

export const speciesReducer = (
  state: ISpeciesListSerialized[] = initialState,
  action: SpeciesActions
): any => {
  switch (action.type) {
    case GET_SPECIES_LIST: {
      return [...state, ...action.payload];
    }

    default: {
      return state;
    }
  }
};
