import './ServicesDatails.scss';

import { Col, Row, Spin, Typography } from 'antd';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useBlocker } from 'react-router-dom';

import useClinicId from '../../hooks/useClinicId';
import { useLocale } from '../../hooks/useLocale';
import useServiceId from '../../hooks/useServiceId';
import { IAppState } from '../../interfaces/app-state';
import EditService from '../../layout/modals/addService/EditService';
import { getServicesDetails } from '../../redux/services/actions';

const { Title } = Typography;

const ServicesDatails = () => {
  const dispatch = useDispatch();
  const clinicId = useClinicId();
  const serviceId = useServiceId();
  const services = useSelector<IAppState, any>((state) => state.services);
  const data = services.selectedService;
  const loading = services.loading;
  const locale = useLocale('private.services.services-list');
  const [showCancelModal, setShowCancelModal] = useState<boolean>(false);
  const [editMode, setEditMode] = useState<boolean>(false);

  useEffect(() => {
    if (clinicId && serviceId) dispatch(getServicesDetails(clinicId, serviceId));
  }, [clinicId]);

  const blocker = useBlocker(({ currentLocation, nextLocation }) => {
    if (nextLocation) {
      setShowCancelModal(true);
    }
    return editMode;
  });

  return (
    <>
      <Row>
        <Col span={24}>
          <Title level={4}>{data?.name}</Title>
        </Col>
      </Row>
      <Spin spinning={loading} className='services-details-page-spin-container'>
        <EditService
          locale={locale}
          initialValues={data}
          showCancelModal={showCancelModal}
          setShowCancelModal={setShowCancelModal}
          editMode={editMode}
          setEditMode={setEditMode}
          blocker={blocker}
        />
      </Spin>
    </>
  );
};

export default ServicesDatails;
