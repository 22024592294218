import {
  GET_STOCK_TYPES,
  GetStockTypesAction,
  RESET,
  SET_LOADING,
  SET_SEARCH_QUERY,
  SetLoadingAction,
  SetSearchQueryAction,
  SHOW_MODAL,
  ShowModalAction,
  StockTypesResetAction
} from './types';
import {
  IndexDictionaryRecordsResponse
} from '../../../services/clinic/dictionaries/models/IndexDictionaryRecordsResponse';
import {
  CreateDictionaryRecordRequest
} from '../../../services/clinic/dictionaries/models/CreateDictionaryRecordRequest';
import {
  indexClinicStockTypes,
  createClinicStockType,
  updateClinicStockType,
  destroyClinicStockType
} from '../../../services/clinic/dictionaries/stockTypesService';

export const setStockTypes = (diagnoses: IndexDictionaryRecordsResponse): GetStockTypesAction => ({
  type: GET_STOCK_TYPES,
  payload: diagnoses
});

export const resetStockTypes = (): StockTypesResetAction => ({
  type: RESET
});

export const showModal = (value: boolean): ShowModalAction => ({
  type: SHOW_MODAL,
  payload: value
});

export const setLoading = (value: boolean): SetLoadingAction => ({
  type: SET_LOADING,
  payload: value
});

export const setSearchQuery = (data: any[]): SetSearchQueryAction => ({
  type: SET_SEARCH_QUERY,
  payload: data
});

export const indexStockTypes = (clinicId: number, page: number = 1, searchValue?: any, callBackFnc?: any, pageSize?: number): any => {
  return dispatch => {
    dispatch(setLoading(true));
    indexClinicStockTypes(clinicId, page, searchValue, pageSize)
      .then(data => {
        if (callBackFnc) {
          callBackFnc();
        }
        dispatch(setStockTypes(data));
        dispatch(setLoading(false));
        dispatch(setSearchQuery(searchValue));
      })
      .catch(() => dispatch(setLoading(false)));
  };
};

export const createStockType = (clinicId: number, data: CreateDictionaryRecordRequest,
  page = null,
  searchArray = null, callBackFnc?: any): any => {
  return dispatch => {
    dispatch(setLoading(true));
    createClinicStockType(clinicId, data)
      .then(data => {
        if (callBackFnc) {
          callBackFnc(data);
        }
        dispatch(indexStockTypes(clinicId, page, searchArray));
        dispatch(setLoading(false));
      })
      .catch(() => dispatch(setLoading(false)));
  };
};

export const updateStockType = (clinicId: number, diagnosisId: number, data: CreateDictionaryRecordRequest,
  page = null,
  searchArray = null): any => {
  return dispatch => {
    dispatch(setLoading(true));
    updateClinicStockType(clinicId, diagnosisId, data)
      .then(data => {
        dispatch(indexStockTypes(clinicId, page, searchArray));
        dispatch(setLoading(false));
      })
      .catch(() => dispatch(setLoading(false)));
  };
};

export const destroyStockType = (clinicId: number, diagnosisId: number,
  page = null,
  searchArray = null): any => {
  return dispatch => {
    dispatch(setLoading(true));
    destroyClinicStockType(clinicId, diagnosisId)
      .then(data => {
        dispatch(indexStockTypes(clinicId, page, searchArray));
        dispatch(setLoading(false));
      })
      .catch(() => dispatch(setLoading(false)));
  };
};
