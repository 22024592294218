import { useSelector } from 'react-redux';
import { AppState } from 'redux/store';

export function useLocale(namespace: string, customLocale = null): any {
  const localeCode = useSelector<AppState, string>(({ localeCode }) => localeCode);
  const path = namespace.replaceAll('.', '/');
  // eslint-disable-next-line @typescript-eslint/no-var-requires
  return require(`../../public/data/localization/${
    customLocale === null ? localeCode : customLocale
  }/${path}.json`);
}
