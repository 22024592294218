import { Form } from 'antd';
import ConfirmCancelModal from 'components/ConfirmCancelModal';
import ViewDetailsButtonsGroup from 'components/ViewDetailsButtonsGroup';
import VLXModal from 'components/VLXModal';
import { useLocale } from 'hooks/useLocale';
import { useUserAccess } from 'hooks/useUserAccess';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { createStock, showCreateModal } from 'redux/inventory/stock/actions';
import { IStockItem } from 'services/interfaces/clinics/inventory/stock/IStockItem';
import { IStockItemCreateEditRequest } from 'services/interfaces/clinics/inventory/stock/IStockItemCreateEditRequest';

import { StockItemForm } from '../forms/StockItemForm';
import { getStockItemPayload } from '../utils/getStockItemPayload';

type Props = {
  clinicId: number;
  callbackFn: (payload?: IStockItem) => void;
};

export const CreateStockItemModal = ({ clinicId, callbackFn }: Props): JSX.Element => {
  const dispatch = useDispatch();
  const userAccess = useUserAccess();
  const [createToolForm] = Form.useForm();

  const locale = useLocale('private.inventory.stock');
  const [showCancelModal, setShowCancelModal] = useState<boolean>(false);

  useEffect((): void => {
    createToolForm.resetFields();
  }, []);

  const onCancel = (): void => {
    dispatch(showCreateModal(false));
  };

  const onConfirmCancelClick = (): void => {
    dispatch(showCreateModal(false));
    setShowCancelModal(false);
  };

  const onSubmit = (data: IStockItemCreateEditRequest): void => {
    const payload = getStockItemPayload(data);

    dispatch(createStock(clinicId, payload, callbackFn));
  };

  return (
    <VLXModal
      form={createToolForm}
      title={locale.labels.createNewStock}
      onModalClose={onCancel}
      open
    >
      <StockItemForm onFinish={onSubmit} form={createToolForm}>
        <ViewDetailsButtonsGroup
          fixed
          accessGroup={userAccess.accesses.stock}
          editMode
          hideDeleteBtn
          onCancelClick={() => {
            if (createToolForm.isFieldsTouched()) {
              setShowCancelModal(true);
            } else {
              dispatch(showCreateModal(false));
            }
          }}
          onDeleteClick={() => {
            setShowCancelModal(false);
          }}
        />
      </StockItemForm>
      <ConfirmCancelModal
        open={showCancelModal}
        onOk={onConfirmCancelClick}
        onCancel={() => setShowCancelModal(false)}
      />
    </VLXModal>
  );
};
