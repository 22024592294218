import { ISelectedStock } from 'services/interfaces/clinics/inventory/stock/ISelectedStock';
import { StockIndexResponse } from 'services/interfaces/clinics/inventory/stock/StockIndexResponse';
import { StockItemsShowResponse } from 'services/interfaces/clinics/inventory/stock/StockItemsShowResponse';

export const SET_STOCK = '[stock] Set';
export const SET_STOCK_LOADING = '[stock] Loading';
export const SET_STOCK_ITEMS_LOADING = '[stock] Stock Items Loading';
export const SHOW_CREATE_MODAL = '[stock] Create Modal';
export const SET_BE_ERROR = '[stock] BE Error';
export const SET_SELECTED_STOCK = '[stock] Selected Device';
export const SET_SELECTED_STOCK_ITEMS = '[stock] Selected Device items';
export const RESET = '[stock] RESET';
export const SET_STOCK_STATS = '[stock] Stats';

export interface StockResetAction {
  type: typeof RESET;
}

export interface SetStockAction {
  type: typeof SET_STOCK;
  payload: StockIndexResponse;
}

export interface SetSelectedStockAction {
  type: typeof SET_SELECTED_STOCK;
  payload: ISelectedStock;
}

export interface SetSelectedStockItemsAction {
  type: typeof SET_SELECTED_STOCK_ITEMS;
  payload: StockItemsShowResponse;
}

export interface SetLoadingAction {
  type: typeof SET_STOCK_LOADING;
  payload: boolean;
}

export interface SetStockItemsLoadingAction {
  type: typeof SET_STOCK_ITEMS_LOADING;
  payload: boolean;
}

export interface ShowCreateModal {
  type: typeof SHOW_CREATE_MODAL;
  payload: boolean;
}

export interface SetBeError {
  type: typeof SET_BE_ERROR;
  payload: boolean;
}

export interface SetStockStatsAction {
  type: typeof SET_STOCK_STATS;
  payload: any;
}

export type StockActions =
  | SetStockAction
  | StockResetAction
  | SetSelectedStockAction
  | SetStockItemsLoadingAction
  | ShowCreateModal
  | SetBeError
  | SetLoadingAction
  | SetSelectedStockItemsAction
  | SetStockStatsAction;
