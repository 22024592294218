import 'assets/sass/components/_table.scss';

import { Row, Spin, Table, TableColumnProps } from 'antd';
import useClinicId from 'hooks/useClinicId';
import { useLocale } from 'hooks/useLocale';
import { useUserAccess } from 'hooks/useUserAccess';
import AddRole from 'layout/modals/Role/AddRole';
import EditRoleForm from 'layout/modals/Role/EditRoleForm';
import RoleDetails from 'layout/modals/Role/RoleDetails';
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getRoleById, getRoles, setEditMode } from 'redux/roles/actions';

const RolesList = (): JSX.Element => {
  const dispatch = useDispatch();
  const clinicId = useClinicId();
  const roles = useSelector(({ roles }) => roles);
  const createAccess = useUserAccess().accesses.roles.create;
  const view = useMemo(() => <RoleDetails clinicId={clinicId} />, []);
  const edit = useMemo(() => <EditRoleForm clinicId={clinicId} />, []);
  const [expended, setExpended] = useState([]);
  const [content, setContent] = useState(view);
  const locale = useLocale('private.administration.roles');

  const columns: Array<TableColumnProps<any>> = [
    {
      key: 'name',
      dataIndex: 'name',
      title: locale.labels.name,
      sorter: (a, b) => (a.name > b.name ? 1 : -1),
      render: (name) => name
    }
  ];

  useEffect(() => {
    if (clinicId) dispatch(getRoles(clinicId));
  }, [clinicId]);

  useEffect(() => {
    roles.editMode ? setContent(edit) : setContent(view);
  }, [roles]);

  const updateExpandedRecord = (current): void => {
    if (current.length === 0 || current[0] === roles?.selectedRole?.id) {
      return;
    }
    dispatch(getRoleById(clinicId, current[0]));
  };

  return (
    <Spin spinning={!roles.data.length}>
      <Table
        pagination={false}
        className='accent-header ant-table-improved-pagination'
        rowKey='id'
        dataSource={roles.data}
        columns={columns}
        expandable={{
          expandRowByClick: true,
          onExpand: (expanded, record) => {
            dispatch(setEditMode(false));
            const current = expanded ? [record.id] : [];
            setExpended(current);
            updateExpandedRecord(current);
          },
          expandedRowKeys: expended,
          expandedRowRender: () => <Row>{content}</Row>
        }}
      />
      {createAccess && (
        <Row style={{ justifyContent: 'flex-end', marginTop: 10, alignItems: 'flex-end' }}>
          <AddRole clinicId={clinicId} />
        </Row>
      )}
    </Spin>
  );
};

export default RolesList;
