import React from 'react';
import { Link } from 'react-router-dom';

export const getMailView = (listData: string): JSX.Element[] | string => {
  if (!listData || listData?.length === 0) return '-';
  const parsedListData = JSON.parse(listData);
  return parsedListData.map((el) => (
    <div key={el?.email}>{el?.email ? <a href={`mailto:${el?.email}`}>{el?.email}</a> : '-'}</div>
  ));
};

export const getPhoneView = (listData: string): JSX.Element[] | string => {
  if (!listData) return '-';
  const parsedListData = JSON.parse(listData);
  return parsedListData.map((el) => (
    <div key={el.phone_number}>
      <Link
        to={`tel:${el.country_code}${el.phone_number}`}
        className='d-flex align-baseline nowrap'
      >
        {`+${el.country_code}${el.phone_number}`}
      </Link>
    </div>
  ));
};
