import './scss/ToolsList.scss';

import { Form, Pagination, Row, Spin, Table, TableColumnProps } from 'antd';
import SearchFiltersHorizontal from 'components/SearchFiltersHorizontal';
import StickyButton from 'components/StickyButton';
import { toolStatuses, toolTypes } from 'constants/dictionary/default/inventoryOptions';
import { scrollToTop } from 'helpers/list-helpers';
import useClinicId from 'hooks/useClinicId';
import { useLocale } from 'hooks/useLocale';
import useLocalizedList from 'hooks/useLocalizedList';
import { useUserAccess } from 'hooks/useUserAccess';
import { IAppState } from 'interfaces/app-state';
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { getTools, setSelectedTool, showCreateModal as toogleShowCreateModal } from 'redux/inventory/tools/actions';
import { ToolsDataItem } from 'services/interfaces/clinics/inventory/tools/ToolsIndexResponse';

import AddToolModal from './AddToolModal';
import { conditionStatus } from './helpers/TagSelector';

function getSearchItems(
  placeholders: Record<string, string>,
  labels: Record<string, string>
): { name: string; placeholder: string }[] {
  return [
    {
      name: 'name',
      placeholder: labels.name
    },
    {
      name: 'condition',
      placeholder: labels.toolCondition
    },
    {
      name: 'location',
      placeholder: labels.location
    },
    {
      name: 'first_name',
      placeholder: placeholders.responsibleFirstName
    },
    {
      name: 'last_name',
      placeholder: placeholders.responsibleLastName
    },
    {
      name: 'tool_type',
      placeholder: labels.toolType
    },
    {
      name: 'manufacturer',
      placeholder: labels.manufacturer
    }
  ];
}

const ToolsList = (): JSX.Element => {
  const dispatch = useDispatch();
  const clinicId = useClinicId();
  const userAccess = useUserAccess();
  const navigate = useNavigate();
  const locale = useLocale('private.inventory.tools');
  const [form] = Form.useForm();

  const [currentPage, setCurrentPage] = useState(1);
  const [searchItemsArray, setSearchItemsArray] = useState([]);
  const { data, metadata, showCreateModal } = useSelector(({ tools }: IAppState) => tools);
  const loading = useSelector(({ tools }: IAppState) => tools.loading);

  const searchItems = useMemo(() => getSearchItems(locale.labels, locale.placeholders), [locale]);

  const localizedToolStatuses = useLocalizedList(toolStatuses);
  const localizedToolTypes = useLocalizedList(toolTypes);

  const getToolStatus = (e: ToolsDataItem): JSX.Element | '' => {
    const res = localizedToolStatuses.find((el) => el?.value === e);
    return res ? conditionStatus(res) : '';
  };

  const onFinish = (): void => {
    const searchArray = [];
    const object = form.getFieldsValue();
    Object.entries(object).forEach(([key, value]) => {
      if (value && value !== '') {
        searchArray.push({
          name: key,
          value
        });
      }
    });
    setCurrentPage(1);
    setSearchItemsArray(searchArray);
    dispatch(getTools(clinicId, 1, searchArray));
  };

  const redirectToToolDetails = (record: ToolsDataItem) =>
    userAccess.accesses.tools.showItem && {
      onClick: () => {
        navigate(`/clinic/${clinicId}/inventory/tools/${record.id}`);
      }
    };

  const getToolType = (e: ToolsDataItem): string => {
    const res = localizedToolTypes.find((el) => el?.value === e);
    return res?.label ?? '-';
  };

  const columns = useMemo(
    (): TableColumnProps<ToolsDataItem>[] => [
      {
        key: 'name',
        dataIndex: 'name',
        title: locale.labels.name,
        sorter: (a, b) => (a.name > b.name ? 1 : -1),
        onCell: redirectToToolDetails,
        render: (name, record) => name
      },
      {
        key: 'condition',
        dataIndex: 'condition',
        title: locale.labels.toolCondition,
        sorter: (a, b) => (a.condition > b.condition ? 1 : -1),
        onCell: redirectToToolDetails,
        render: (condition) => getToolStatus(condition)
      },
      {
        key: 'uses_left',
        dataIndex: 'uses_left',
        title: locale.labels.usesLeft,
        sorter: (a, b) => (a.uses_left > b.uses_left ? 1 : -1),
        onCell: redirectToToolDetails,
        render: (uses_left) => uses_left
      },
      {
        key: 'tool_type',
        dataIndex: 'tool_type',
        title: locale.labels.toolType,
        sorter: (a, b) => (a.tool_type > b.tool_type ? 1 : -1),
        onCell: redirectToToolDetails,
        render: (tool_type) => getToolType(tool_type)
      },
      {
        key: 'location',
        dataIndex: 'location',
        title: locale.labels.location,
        sorter: (a, b) => (a.location > b.location ? 1 : -1),
        onCell: redirectToToolDetails,
        render: (location) => location || '-'
      }
    ],
    [data]
  );

  const onPageChange = (page: number): void => {
    dispatch(getTools(clinicId, page, searchItemsArray));
    scrollToTop();
  };

  useEffect((): void => {
    if (clinicId) dispatch(getTools(clinicId, 1));
  }, [clinicId]);

  useEffect((): void => {
    dispatch(setSelectedTool(null));
  }, []);

  return (
    <>
      <SearchFiltersHorizontal onFinish={onFinish} formInstance={form} object={searchItems} />
      <Spin spinning={loading}>
        <Table
          pagination={false}
          className='accent-header tools-list'
          rowKey='id'
          dataSource={data}
          columns={columns}
        />
        <Row style={{ justifyContent: 'space-between', marginTop: 10, alignItems: 'center' }}>
          <Pagination
            className={'equipment-pagination'}
            defaultCurrent={1}
            current={+currentPage}
            showSizeChanger={false}
            showLessItems
            pageSize={20}
            total={metadata.total}
            onChange={(current) => {
              setCurrentPage(current);
              onPageChange(current);
            }}
          />
          <StickyButton
            offsetBottom={10}
            onClick={() => dispatch(toogleShowCreateModal(true))}
            iconElement={
              <span
                className='icofont icofont-plus mr-2 sticky-btn-icon'
                style={{ fontSize: '1.3em' }}
              />
            }
            textElement={locale.buttons.create}
          />
          {userAccess.accesses.tools.create && showCreateModal && <AddToolModal clinicId={clinicId} />}
        </Row>
      </Spin>
    </>
  );
};

export default ToolsList;
