import { Modal } from 'antd';
import ConfirmCancelModal from 'components/ConfirmCancelModal';
import ConfirmDeleteModal from 'components/ConfirmDeleteModal';
import ViewDetailsButtonsGroup from 'components/ViewDetailsButtonsGroup';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { useUserAccess } from 'hooks/useUserAccess';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { createAuditItem, headAuditItems, setHeadAuditItems } from 'redux/inventory/audit/actions';

import AuditItemsForm from '../AuditForms/AuditItemsForm';

dayjs.extend(customParseFormat);

type Props = {
  locale: any;
  clinicId: number;
  auditId: number;
  currentPage: number;
  setShowAddItemModal: (show) => void;
  showAddItemModal: boolean;
};
const AddAuditItemModal = ({
  locale,
  clinicId,
  auditId,
  currentPage,
  setShowAddItemModal,
  showAddItemModal
}: Props): JSX.Element => {
  const dispatch = useDispatch();
  const userAccess = useUserAccess();
  const [showItemCancelModal, setShowItemCancelModal] = useState<boolean>(false);
  const [showDeleteItemModal, setShowDeleteItemModal] = useState<boolean>(false);
  const [selectedIds, setSelectedIds] = useState<number[]>([]);

  useEffect(() => {
    dispatch(headAuditItems(clinicId, auditId));

    return () => {
      dispatch(setHeadAuditItems(null));
    };
  }, []);

  const onFormEditingFinish = (): void => {
    dispatch(createAuditItem(clinicId, selectedIds, auditId, true, currentPage));
    setShowAddItemModal(false);
  };

  const onConfirmCancelClick = (): void => {
    setShowItemCancelModal(false);
    setShowAddItemModal(false);
  };

  const onConfirmDeleteItemClick = (): void => {
    setShowDeleteItemModal(false);
    setShowAddItemModal(false);
  };

  const onCancel = () => {
    if (selectedIds.length) {
      setShowDeleteItemModal(false);
      setShowItemCancelModal(true);
    } else {
      setShowAddItemModal(false);
    }
  };

  return (
    <>
      <Modal
        forceRender
        zIndex={1000}
        title={locale.labels.createAuditItem}
        centered
        open={showAddItemModal}
        onCancel={onCancel}
        footer={null}
        width={800}
        destroyOnClose
      >
        <AuditItemsForm
          currentPage={currentPage}
          onFormEditingFinish={onFormEditingFinish}
          clinicId={clinicId}
          selectedIds={selectedIds}
          setSelectedIds={setSelectedIds}
        >
          <ViewDetailsButtonsGroup
            fixed
            accessGroup={userAccess.accesses.audit}
            editMode
            hideDeleteBtn
            onCancelClick={onCancel}
            onDeleteClick={() => {
              setShowItemCancelModal(false);
              setShowDeleteItemModal(true);
            }}
          />
        </AuditItemsForm>
      </Modal>
      {showItemCancelModal && (
        <ConfirmCancelModal
          open={showItemCancelModal}
          onOk={onConfirmCancelClick}
          onCancel={() => setShowItemCancelModal(false)}
        />
      )}
      {showDeleteItemModal && (
        <ConfirmDeleteModal
          type={'item'}
          open={showDeleteItemModal}
          onOk={onConfirmDeleteItemClick}
          onCancel={() => setShowDeleteItemModal(false)}
        />
      )}
    </>
  );
};

export default AddAuditItemModal;
