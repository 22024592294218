import './UserRegistration.scss';

import { Button, Col, Form, Input, InputNumber, Row, Spin } from 'antd';
import * as localStorage from 'local-storage';
import React, { useEffect, useState } from 'react';
import { patternRule, PATTERNS, requiredRule } from 'utils/form-rules';

import PhoneFieldFormItem from '../../../components/PhoneFieldFormItem';
import { useLocale } from '../../../hooks/useLocale';
import { createRegistrationRequest } from '../../../services/publicService';
import MessageModal from '../messageModal/MessageModal';

const { TextArea } = Input;
const RegistrationRequestForm = ({ closeModal, form }) => {
  const [loading, setLoading] = useState(false);
  const locale = useLocale('public.modals.registration-request');
  const labels = locale.labels;
  const messages = locale.messages;
  const buttons = locale.buttons;

  useEffect(() => {
    form.resetFields();
  }, []);

  const onSubmit = (values: any) => {
    setLoading(true);
    createRegistrationRequest(values)
      .then(() => {
        setLoading(false);
        closeModal();
        MessageModal('success', messages.requestSuccess);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  return (
    <Spin spinning={loading}>
      <Form form={form} onFinish={onSubmit} autoComplete='off' layout='vertical'>
        <Row gutter={[20, 10]}>
          <Col span={24} md={12}>
            <Form.Item
              label={labels.organisationName}
              name='organization_name'
              rules={[requiredRule(messages.organisationNameEmpty)]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label={labels.country}
              name='country'
              rules={[requiredRule(messages.countryEmpty)]}
            >
              <Input />
            </Form.Item>
            <Form.Item label={labels.city} name='city'>
              <Input />
            </Form.Item>
            <Form.Item
              label={labels.numberOfEmployees}
              name='number_of_employees'
              rules={[requiredRule(messages.numberOfEmployeesEmpty)]}
            >
              <InputNumber placeholder='0' type='number' precision={0} step={1} />
            </Form.Item>
            <Form.Item label={labels.questions} name='comments'>
              <TextArea rows={5} />
            </Form.Item>
          </Col>
          <Col span={24} md={12}>
            <Form.Item
              label={labels.firstName}
              name='first_name'
              rules={[
                requiredRule(messages.firstNameEmpty),
                patternRule(PATTERNS.NAME, messages.firstNameNotValid)
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label={labels.middleName}
              name='middle_name'
              rules={[
                patternRule(PATTERNS.NAME, messages.firstNameNotValid)
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label={labels.lastName}
              name='last_name'
              rules={[
                requiredRule(messages.lastNameEmpty),
                patternRule(PATTERNS.NAME, messages.lastNameNotValid)
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label={labels.title}
              name='title'
              rules={[requiredRule(messages.titleEmpty)]}
            >
              <Input />
            </Form.Item>
            <PhoneFieldFormItem />
            <Form.Item
              label={labels.email}
              name='email'
              rules={[
                requiredRule(messages.emailNotValid),
                patternRule(PATTERNS.EMAIL, messages.emailNotValid)
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>
        <Form.Item hidden={true} name='locale' initialValue={localStorage.get('locale')}>
          <Input />
        </Form.Item>
        <Form.Item>
          <Button block type='primary' htmlType='submit'>
            {buttons.submit}
          </Button>
        </Form.Item>
      </Form>
    </Spin>
  );
};

export default RegistrationRequestForm;
