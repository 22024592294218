import { IInvitation, IInvitationsList } from '../../interfaces/invitation';
import {
  GET_INVITATIONS,
  InvitationsActions,
  SET_INVITATION,
  SET_INVITATIONS_LOADING,
  SHOW_MODAL
} from './types';

export interface InvitationsListState extends IInvitationsList {
  loading: boolean;
  showModal: boolean;
  selectedInvitation: IInvitation | null;
}

const initialState: InvitationsListState = {
  data: [],
  metadata: {
    total: 0,
    page: 1,
    next: null,
    last: 1,
    prev: null
  },
  loading: false,
  showModal: false,
  selectedInvitation: null
};

export function invitationsReducer(
  state: IInvitationsList = initialState,
  action: InvitationsActions
): any {
  switch (action.type) {
    case GET_INVITATIONS: {
      return {
        ...state,
        ...action.payload
      };
    }

    case SHOW_MODAL: {
      return {
        ...state,
        showModal: action.payload
      };
    }

    case SET_INVITATIONS_LOADING: {
      return {
        ...state,
        loading: action.payload
      };
    }

    case SET_INVITATION: {
      return {
        ...state,
        selectedInvitation: action.payload
      };
    }

    default: {
      return state;
    }
  }
}
