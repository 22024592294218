import { ISpeciesListSerialized } from '../../interfaces/species';

export const GET_SPECIES_LIST = '[speciesList] Get';

export interface GetSpeciesListAction {
  type: typeof GET_SPECIES_LIST
  payload: ISpeciesListSerialized[]
}

export type SpeciesActions =
  | GetSpeciesListAction;
