import { DictionaryActions, SET_LOADING, SHOW_DICTIONARY_MODAL } from './types';

export interface DictionaryState {
  loading: boolean
  showModal: boolean
}

const initialState: DictionaryState = {
  loading: false,
  showModal: false
};

export function dictionaryReducer (
  state: DictionaryState = initialState,
  action: DictionaryActions
): any {
  switch (action.type) {
    case SET_LOADING: {
      return {
        ...state,
        loading: action.payload
      };
    }

    case SHOW_DICTIONARY_MODAL: {
      return {
        ...state,
        showModal: action.payload
      };
    }

    default: {
      return state;
    }
  }
}
