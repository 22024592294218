import {
  RESET,
  SET_BE_ERROR,
  SET_LOADING,
  SET_SELECTED_TOOL,
  SET_TOOLS,
  SHOW_CREATE_MODAL,
  ToolsActions
} from './types';
import { ToolsIndexResponse } from '../../../services/interfaces/clinics/inventory/tools/ToolsIndexResponse';
import { ToolShowResponse } from '../../../services/interfaces/clinics/inventory/tools/ToolShowResponse';

export interface ToolsListState extends ToolsIndexResponse {
  loading: boolean
  showCreateModal: boolean
  backendError: boolean
  selectedTool: ToolShowResponse
}

const initialState: ToolsListState = {
  data: [],
  metadata: {
    total: 0,
    page: 1,
    next: null,
    last: 1,
    prev: null
  },
  loading: false,
  showCreateModal: false,
  backendError: false,
  selectedTool: null
};

export function toolsReducer (
  state: ToolsIndexResponse = initialState,
  action: ToolsActions
): any {
  switch (action.type) {
    case SET_TOOLS: {
      return {
        ...state,
        ...action.payload
      };
    }

    case RESET: {
      return initialState;
    }

    case SET_SELECTED_TOOL: {
      return {
        ...state,
        selectedTool: action.payload
      };
    }

    case SET_LOADING: {
      return {
        ...state,
        loading: action.payload
      };
    }

    case SHOW_CREATE_MODAL: {
      return {
        ...state,
        showCreateModal: action.payload
      };
    }

    case SET_BE_ERROR: {
      return {
        ...state,
        backendError: action.payload
      };
    }

    default: {
      return state;
    }
  }
}
