import { Params, useLocation, useParams } from 'react-router-dom';

export default function useClientId(): number | null {
  const location = useLocation();
  const { clientId }: Readonly<Params<string>> = useParams();

  if (!location.pathname.includes('clients') && !clientId) {
    return null;
  }

  return Number(clientId) || Number(location.pathname.split('/')[4]);
}
