import { Typography } from 'antd';

type Props = React.PropsWithChildren<{ color: string }>;

export const Stat = ({ color, children }: Props): JSX.Element => (
  <Typography.Text
    style={{
      color,
      opacity: '80%'
    }}
    strong
  >
    {children}
  </Typography.Text>
);
