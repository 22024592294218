import 'assets/sass/components/_table.scss';

import { Col, Row } from 'antd';
import { useLocale } from 'hooks/useLocale';
import { IAppState } from 'interfaces/app-state';
import React from 'react';
import { useSelector } from 'react-redux';
import {
  createBillPaymentType,
  destroyBillPaymentType,
  indexBillPaymentTypes,
  updateBillPaymentType
} from 'redux/dictionaries/bill-payment-types/actions';
import { BillPaymentTypesState } from 'redux/dictionaries/bill-payment-types/reducer';
import {
  createDiagnosis,
  destroyDiagnosis,
  indexDiagnoses,
  updateDiagnosis
} from 'redux/dictionaries/diagnoses/actions';
import { DiagnosesState } from 'redux/dictionaries/diagnoses/reducer';
import {
  createServiceGroup,
  destroyServiceGroup,
  indexServiceGroups,
  updateServiceGroup
} from 'redux/dictionaries/service-groups/actions';
import { ServiceGroupsState } from 'redux/dictionaries/service-groups/reducer';
import {
  createStockCategory,
  destroyStockCategory,
  indexStockCategories,
  updateStockCategory
} from 'redux/dictionaries/stock-categories/actions';
import { StockCategoriesState } from 'redux/dictionaries/stock-categories/reducer';
import {
  createStockSubCategory,
  destroyStockSubCategory,
  indexStockSubCategories,
  updateStockSubCategory
} from 'redux/dictionaries/stock-sub-categories/actions';
import { StockSubCategoriesState } from 'redux/dictionaries/stock-sub-categories/reducer';
import {
  createStockType,
  destroyStockType,
  indexStockTypes,
  updateStockType
} from 'redux/dictionaries/stock-types/actions';
import { StockTypesState } from 'redux/dictionaries/stock-types/reducer';

import Dictionary from './components/Dictionary';

const colStyles = {
  minWidth: '350px',
  maxWidth: '400px',
  marginBottom: '10px',
  marginLeft: '10px'
};

const ClinicDictionaries = (): JSX.Element => {
  const diagnoses = useSelector<IAppState, DiagnosesState>(({ diagnoses }) => diagnoses);
  const serviceGroups = useSelector<IAppState, ServiceGroupsState>(
    ({ serviceGroups }) => serviceGroups
  );
  const stockTypes = useSelector<IAppState, StockTypesState>(({ stockTypes }) => stockTypes);
  const stockCategories = useSelector<IAppState, StockCategoriesState>(
    ({ stockCategories }) => stockCategories
  );
  const stockSubCategories = useSelector<IAppState, StockSubCategoriesState>(
    ({ stockSubCategories }) => stockSubCategories
  );
  const billPaymentTypes = useSelector<IAppState, BillPaymentTypesState>(
    ({ billPaymentTypes }) => billPaymentTypes
  );
  const locale = useLocale('private.administration.dictionaries');

  return (
    <>
      <Row style={{ marginTop: '20px' }}>
        <Col span={8} style={colStyles}>
          <Dictionary
            id='diagnosis'
            dictionaryName={locale.labels.diagnoses}
            dictionary={diagnoses}
            createAction={createDiagnosis}
            updateAction={updateDiagnosis}
            indexAction={indexDiagnoses}
            destroyAction={destroyDiagnosis}
          />
        </Col>
        <Col span={8} style={colStyles}>
          <Dictionary
            id='bill-payment-types'
            dictionaryName={locale.labels.billPaymentTypes}
            dictionary={billPaymentTypes}
            createAction={createBillPaymentType}
            updateAction={updateBillPaymentType}
            indexAction={indexBillPaymentTypes}
            destroyAction={destroyBillPaymentType}
          />
        </Col>
      </Row>
      <Row>
        <Col span={8} style={colStyles}>
          <Dictionary
            id='service-groups'
            dictionaryName={locale.labels.serviceGroups}
            dictionary={serviceGroups}
            createAction={createServiceGroup}
            updateAction={updateServiceGroup}
            indexAction={indexServiceGroups}
            destroyAction={destroyServiceGroup}
          />
        </Col>
        <Col span={8} style={colStyles}>
          <Dictionary
            id='stock-types'
            dictionaryName={locale.labels.stockTypes}
            dictionary={stockTypes}
            createAction={createStockType}
            updateAction={updateStockType}
            indexAction={indexStockTypes}
            destroyAction={destroyStockType}
          />
        </Col>
      </Row>
      <Row>
        <Col span={8} style={colStyles}>
          <Dictionary
            id='stock-categories'
            dictionaryName={locale.labels.stockCategories}
            dictionary={stockCategories}
            createAction={createStockCategory}
            updateAction={updateStockCategory}
            indexAction={indexStockCategories}
            destroyAction={destroyStockCategory}
          />
        </Col>
        <Col span={8} style={colStyles}>
          <Dictionary
            id='stock-sub-categories'
            dictionaryName={locale.labels.stockSubCategories}
            dictionary={stockSubCategories}
            createAction={createStockSubCategory}
            updateAction={updateStockSubCategory}
            indexAction={indexStockSubCategories}
            destroyAction={destroyStockSubCategory}
          />
        </Col>
      </Row>
    </>
  );
};

export default ClinicDictionaries;
