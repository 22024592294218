import { Form } from 'antd';
import ConfirmCancelModal from 'components/ConfirmCancelModal';
import ViewDetailsButtonsGroup from 'components/ViewDetailsButtonsGroup';
import VLXModal from 'components/VLXModal';
import useClinicId from 'hooks/useClinicId';
import { useUserAccess } from 'hooks/useUserAccess';
import { ILocale } from 'interfaces/locale';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { getRooms } from 'redux/rooms/actions';

import { ReassignLocationForm } from '../forms/ReassignLocationForm';

type Props = {
  locale: ILocale;
  setShowReassignLocationsModal: (show: boolean) => void;
};

export const ReassignLocationModal = ({
  locale,
  setShowReassignLocationsModal
}: Props): JSX.Element => {
  const dispatch = useDispatch();
  const clinicId = useClinicId();
  const userAccess = useUserAccess();
  const [form] = Form.useForm();

  const [showCancelModal, setShowCancelModal] = useState<boolean>(false);

  useEffect(() => {
    dispatch(getRooms(clinicId, 0));
  }, []);

  const onConfirmCancelClick = () => {
    form.resetFields();
    setShowCancelModal(false);
    setShowReassignLocationsModal(false);
  };

  return (
    <VLXModal
      title={locale.labels.locationReassign}
      centered
      open
      form={form}
      width={1000}
      onModalClose={() => setShowReassignLocationsModal(false)}
    >
      <ReassignLocationForm
        form={form}
        locale={locale}
        setShowReassignLocationsModal={setShowReassignLocationsModal}
      >
        <ViewDetailsButtonsGroup
          accessGroup={userAccess.accesses.invitations}
          fixed
          editMode
          hideDeleteBtn
          onCancelClick={() => {
            if (form.isFieldsTouched()) {
              setShowCancelModal(true);
            } else {
              setShowReassignLocationsModal(false);
            }
          }}
          onDeleteClick={() => {
            setShowCancelModal(false);
          }}
        />
      </ReassignLocationForm>
      <ConfirmCancelModal
        open={showCancelModal}
        onOk={onConfirmCancelClick}
        onCancel={() => setShowCancelModal(false)}
      />
    </VLXModal>
  );
};
