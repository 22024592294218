import { notification } from 'antd';
import {
  createClinicAudit,
  createClinicAuditItem,
  createClinicAuditReport,
  destroyClinicAuditItem,
  getClinicAudit,
  getClinicAuditById,
  getClinicAuditItemsById,
  headClinicAuditItemsById,
  removeClinicAudit,
  updateClinicAudit,
  updateClinicAuditItem,
} from 'services/clinic/inventory/auditService';
import { AuditItemsHeadResponse } from 'services/interfaces/clinics/inventory/audit/AuditItemsHeadResponse';
import { AuditItemUpdateRequest } from 'services/interfaces/clinics/inventory/audit/AuditItemUpdateRequest';
import { CreateAuditRequest } from 'services/interfaces/clinics/inventory/audit/CreateAuditRequest';
import { IAuditList, IAuditListItem } from 'services/interfaces/clinics/inventory/audit/IAuditList';
import { ISelectedAuditItemsData } from 'services/interfaces/clinics/inventory/audit/ISelectedAuditItemsData';
import { ISortObject } from 'services/interfaces/ISortObject';

import {
  AuditsResetAction,
  RESET,
  SET_AUDIT,
  SET_BE_ERROR,
  SET_GET_REQUEST_LOADING,
  SET_HEAD_ITEMS,
  SET_LOADING,
  SET_SELECTED_AUDIT,
  SET_SELECTED_AUDIT_ITEMS,
  SetAuditAction,
  SetBeError,
  SetGetRequestLoadingAction,
  SetHeadItemsAction,
  SetLoadingAction,
  SetSelectedAuditAction,
  SetSelectedAuditItemsAction,
  SHOW_CREATE_MODAL,
  ShowCreateModal,
} from './types';

export const setAudit = (data: IAuditList): SetAuditAction => ({
  type: SET_AUDIT,
  payload: data
});

export const resetAudits = (): AuditsResetAction => ({
  type: RESET
});

export const setSelectedAudit = (data: IAuditListItem): SetSelectedAuditAction => ({
  type: SET_SELECTED_AUDIT,
  payload: data
});

export const setHeadAuditItems = (data: AuditItemsHeadResponse): SetHeadItemsAction => ({
  type: SET_HEAD_ITEMS,
  payload: data
});

export const setSelectedAuditItems = (
  payload: ISelectedAuditItemsData
): SetSelectedAuditItemsAction => ({
  type: SET_SELECTED_AUDIT_ITEMS,
  payload
});

export const setGetRequestLoading = (value: boolean): SetGetRequestLoadingAction => ({
  type: SET_GET_REQUEST_LOADING,
  payload: value
});

export const setLoading = (value: boolean): SetLoadingAction => ({
  type: SET_LOADING,
  payload: value
});

export const setBackendError = (value: boolean): SetBeError => ({
  type: SET_BE_ERROR,
  payload: value
});

export const showCreateModal = (value: boolean): ShowCreateModal => ({
  type: SHOW_CREATE_MODAL,
  payload: value
});

export const getAudit = (clinicId: number, page: number, searchArray?: any[], sortObject?: ISortObject): any => {
  return (dispatch) => {
    dispatch(setLoading(true));
    getClinicAudit(clinicId, page, searchArray, sortObject)
      .then((data) => {
        dispatch(setAudit(data));
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => dispatch(setLoading(false)));
  };
};

export const getAuditById = (clinicId: number, deviceId: number): any => {
  return (dispatch) => {
    dispatch(setLoading(true));
    getClinicAuditById(clinicId, deviceId)
      .then((data: IAuditListItem): void => {
        dispatch(setSelectedAudit(data));
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => dispatch(setLoading(false)));
  };
};

export const createAuditReport = (clinicId: number, auditId: number): any => {
  return async (dispatch) => {
    dispatch(setLoading(true));
    return createClinicAuditReport(clinicId, auditId)
      .then(() => {
        dispatch(getAuditById(clinicId, auditId));
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => dispatch(setLoading(false)));
  };
};

export const getAuditItemsById = (clinicId: number, deviceId: number, page = 1): any => {
  return (dispatch) => {
    dispatch(setGetRequestLoading(true));
    getClinicAuditItemsById(clinicId, deviceId, page)
      .then((data: ISelectedAuditItemsData) => {
        dispatch(setSelectedAuditItems(data));
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => dispatch(setGetRequestLoading(false)));
  };
};

export const headAuditItems = (clinicId: number, auditId: number): any => {
  return (dispatch) => {
    dispatch(setLoading(true));
    headClinicAuditItemsById(clinicId, auditId)
      .then((data) => {
        dispatch(setHeadAuditItems(data));
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => dispatch(setLoading(false)));
  };
};

export const createAudit = (clinicId: number, data: CreateAuditRequest, callbackFn: () => void): any => {
  return (dispatch) => {
    dispatch(setLoading(true));
    createClinicAudit(clinicId, data)
      .then((data) => {
        if (callbackFn) {
          callbackFn();
        } else {
          dispatch(getAudit(clinicId, 1));
        }
        dispatch(showCreateModal(false));
      })
      .catch((err) => {
        dispatch(setBackendError(true));
        console.log(err);
      })
      .finally(() => dispatch(setLoading(false)));
  };
};

export const updateAudit = (clinicId: number, deviceId: number, data: CreateAuditRequest): any => {
  return (dispatch) => {
    updateClinicAudit(clinicId, deviceId, data)
      .then(() => {
        dispatch(getAuditById(clinicId, deviceId));
      })
      .catch((err) => {
        dispatch(setBackendError(true));
        console.log(err);
      });
  };
};

export const removeAudit = (clinicId: number, deviceId: number, locale): any => {
  return (dispatch) => {
    removeClinicAudit(clinicId, deviceId)
      .then(() => {
        notification.success({ message: locale.messages.auditRemovedSuccessfully });
        dispatch(setSelectedAudit(null));
      })
      .catch((err) => {
        dispatch(setBackendError(true));
        console.log(err);
      });
  };
};

export const createAuditItem = (
  clinicId: number,
  selectedIds: number[],
  auditId: number,
  getUpdatedItemsList: boolean,
  page = 1
): any => {
  return (dispatch) => {
    dispatch(setLoading(true));
    createClinicAuditItem(clinicId, { items: selectedIds }, auditId)
      .then(() => {
        if (getUpdatedItemsList) {
          dispatch(headAuditItems(clinicId, auditId));
          dispatch(getAuditById(clinicId, auditId));
          dispatch(getAuditItemsById(clinicId, auditId, page));
        }
        dispatch(showCreateModal(false));
      })
      .catch((err) => {
        dispatch(setBackendError(true));
        console.log(err);
      })
      .finally(() => dispatch(setLoading(false)));
  };
};

export const updateAuditItem = (
  clinicId: number,
  auditId: number,
  auditItemId: number,
  data: AuditItemUpdateRequest,
  page = 1
): any => {
  return (dispatch) => {
    updateClinicAuditItem(clinicId, auditId, auditItemId, data)
      .then((data) => {
        dispatch(getAuditById(clinicId, auditId));
        dispatch(getAuditItemsById(clinicId, auditId, page));
        dispatch(showCreateModal(false));
      })
      .catch((err) => {
        dispatch(setBackendError(true));
        console.log(err);
      });
  };
};

export const destroyAuditItem = (
  clinicId: number,
  auditId: number,
  stockId: number,
  page = 1
): any => {
  return (dispatch) => {
    dispatch(setLoading(true));
    destroyClinicAuditItem(clinicId, auditId, stockId)
      .then((data) => {
        dispatch(headAuditItems(clinicId, auditId));
        dispatch(getAuditById(clinicId, auditId));
        dispatch(getAuditItemsById(clinicId, auditId, page));
        dispatch(showCreateModal(false));
      })
      .catch((err) => {
        dispatch(setBackendError(true));
        console.log(err);
      })
      .finally(() => dispatch(setLoading(false)));
  };
};
