import {
  IndexDictionaryRecordsResponse
} from '../../../services/clinic/dictionaries/models/IndexDictionaryRecordsResponse';

export const GET_STOCK_CATEGORIES = '[stock_categories] Set';
export const SHOW_MODAL = '[stock_categories] SHOW_MODAL';
export const SET_LOADING = '[stock_categories] SET_LOADING';
export const SET_SEARCH_QUERY = '[stock_categories] SetSearchQuery';
export const RESET = '[stock_categories] RESET';

export interface StockCategoriesResetAction {
  type: typeof RESET
}

export interface GetStockCategoriesAction {
  type: typeof GET_STOCK_CATEGORIES
  payload: IndexDictionaryRecordsResponse
}

export interface ShowModalAction {
  type: typeof SHOW_MODAL
  payload: boolean
}

export interface SetLoadingAction {
  type: typeof SET_LOADING
  payload: boolean
}

export interface SetSearchQueryAction {
  type: typeof SET_SEARCH_QUERY
  payload: any[]
}

export type StockCategoriesActions =
  | StockCategoriesResetAction
  | GetStockCategoriesAction
  | SetSearchQueryAction
  | ShowModalAction
  | SetLoadingAction;
