import { IRole, IRolesList } from '../../interfaces/role';
import {
  GET_ROLES,
  RESET,
  RolesActions,
  SET_BE_ERROR,
  SET_EDIT_MODE,
  SET_ROLES_LOADING,
  SET_SELECTED_ROLE,
  SET_SELECTED_ROLE_ID,
  SHOW_CREATE_MODAL,
  SHOW_EDIT_MODAL,
  SHOW_ROLE_DETAILS_MODAL
} from './types';

interface RolesListState extends IRolesList {
  loading: boolean;
  showCreateModal: boolean;
  showEditModal: boolean;
  showRoleDetailsModal: boolean;
  backendError: boolean;
  editMode: boolean;
  selectedRole: IRole;
  selectedRoleId: number;
}

const initialState: RolesListState = {
  data: [],
  loading: false,
  showCreateModal: false,
  showEditModal: false,
  showRoleDetailsModal: false,
  backendError: false,
  selectedRole: null,
  selectedRoleId: null,
  editMode: false
};

export function rolesReducer(state: IRolesList = initialState, action: RolesActions): any {
  switch (action.type) {
    case GET_ROLES: {
      return {
        ...state,
        data: action.payload
      };
    }

    case RESET: {
      return initialState;
    }

    case SET_ROLES_LOADING: {
      return {
        ...state,
        loading: action.payload
      };
    }

    case SHOW_CREATE_MODAL: {
      return {
        ...state,
        showCreateModal: action.payload
      };
    }

    case SHOW_ROLE_DETAILS_MODAL: {
      return {
        ...state,
        showRoleDetailsModal: action.payload
      };
    }

    case SHOW_EDIT_MODAL: {
      return {
        ...state,
        showEditModal: action.payload
      };
    }

    case SET_BE_ERROR: {
      return {
        ...state,
        backendError: action.payload
      };
    }

    case SET_EDIT_MODE: {
      return {
        ...state,
        editMode: action.payload
      };
    }

    case SET_SELECTED_ROLE: {
      return {
        ...state,
        selectedRole: action.payload
      };
    }

    case SET_SELECTED_ROLE_ID: {
      return {
        ...state,
        selectedRoleId: action.payload
      };
    }

    default: {
      return state;
    }
  }
}
