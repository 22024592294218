import { IMetaData } from 'interfaces/metadata';
import { IBillDetails } from 'services/clinic/bills/models/IBillDetails';
import { IndexBillsResponse } from 'services/clinic/bills/models/IndexBillsResponse';

import {
  BillsActions,
  RESET,
  SET_BILL,
  SET_BILL_PAYMENTS,
  SET_BILLS,
  SET_BILLS_LOADING,
  SHOW_MODAL,
  SHOW_PAYMENTS_MODAL
} from './types';

export interface IBillsListState extends IndexBillsResponse {
  loading: boolean;
  showModal: boolean;
  showPaymentsModal: boolean;
  selectedBill: IBillDetails;
  metadata: IMetaData;
  data: IBillDetails[];
  billPayments: IBillDetails[];
}

const initialState: IBillsListState = {
  data: [],
  metadata: {
    total: 0,
    page: 1,
    next: null,
    last: 1,
    prev: null
  },
  loading: false,
  selectedBill: null,
  showModal: false,
  showPaymentsModal: false,
  billPayments: null
};

export function billsReducer(state: IBillsListState = initialState, action: BillsActions): any {
  switch (action.type) {
    case SET_BILLS: {
      return {
        ...state,
        ...action.payload,
        data: action.payload.data
      };
    }

    case RESET: {
      return initialState;
    }

    case SET_BILLS_LOADING: {
      return {
        ...state,
        loading: action.payload
      };
    }

    case SHOW_MODAL: {
      return {
        ...state,
        showModal: action.payload
      };
    }

    case SHOW_PAYMENTS_MODAL: {
      return {
        ...state,
        showPaymentsModal: action.payload
      };
    }

    case SET_BILL: {
      return {
        ...state,
        selectedBill: action.payload
      };
    }

    case SET_BILL_PAYMENTS: {
      return {
        ...state,
        billPayments: action.payload
      };
    }

    default: {
      return state;
    }
  }
}
