const breeds = [
  {
    value: 'affenpinscher',
    enGB: 'Affenpinscher',
    ukUA: 'Афенпінчер'
  },
  {
    value: 'afghan_hound',
    enGB: 'Afghan Hound',
    ukUA: 'Афганська Борза'
  },
  {
    value: 'african_hunting_dog',
    enGB: 'African Hunting Dog',
    ukUA: 'Африканська Мисливська'
  },
  {
    value: 'airedale_terrier',
    enGB: 'Airedale Terrier',
    ukUA: 'Ердель-Тер\'єр'
  },
  {
    value: 'akbash_dog',
    enGB: 'Akbash Dog',
    ukUA: 'Акбаш'
  },
  {
    value: 'akita',
    enGB: 'Akita',
    ukUA: 'Акіта'
  },
  {
    value: 'alapaha_blue_blood_bulldog',
    enGB: 'Alapaha Blue Blood Bulldog',
    ukUA: 'Алапаський Бульдог'
  },
  {
    value: 'alaskan_husky',
    enGB: 'Alaskan Husky',
    ukUA: 'Аляскинський Хаскі'
  },
  {
    value: 'alaskan_malamute',
    enGB: 'Alaskan Malamute',
    ukUA: 'Аляскинський Маламут'
  },
  {
    value: 'american_bulldog',
    enGB: 'American Bulldog',
    ukUA: 'Американський Бульдог'
  },
  {
    value: 'american_bully',
    enGB: 'American Bully',
    ukUA: 'Американський Буллі'
  },
  {
    value: 'american_eskimo_dog',
    enGB: 'American Eskimo Dog',
    ukUA: 'Американський Ескімоський Шпіц'
  },
  {
    value: 'american_foxhound',
    enGB: 'American Foxhound',
    ukUA: 'Американський Фоксхаунд (Фоксгаунд)'
  },
  {
    value: 'american_pit_bull_terrier',
    enGB: 'American Pit Bull Terrier',
    ukUA: 'Американський Пітбультер\'єр'
  },
  {
    value: 'american_staffordshire_terrier',
    enGB: 'American Staffordshire Terrier',
    ukUA: 'Американський Стафордширський Тер\'єр'
  },
  {
    value: 'american_water_spaniel',
    enGB: 'American Water Spaniel',
    ukUA: 'Американський Водяний Спанієль (Спаніель)'
  },
  {
    value: 'anatolian_shepherd_dog',
    enGB: 'Anatolian Shepherd Dog',
    ukUA: 'Анатолійська Вівчарка'
  },
  {
    value: 'appenzeller_sennenhund',
    enGB: 'Appenzeller Sennenhund',
    ukUA: 'Аппенцеллер Зенненхунд'
  },
  {
    value: 'australian_cattle_dog',
    enGB: 'Australian Cattle Dog',
    ukUA: 'Австралійський Скотар'
  },
  {
    value: 'australian_kelpie',
    enGB: 'Australian Kelpie',
    ukUA: 'Австралійський Келпі'
  },
  {
    value: 'australian_shepherd',
    enGB: 'Australian Shepherd',
    ukUA: 'Австралійська Вівчарка'
  },
  {
    value: 'australian_terrier',
    enGB: 'Australian Terrier',
    ukUA: 'Австралійський Тер\'єр'
  },
  {
    value: 'azawakh',
    enGB: 'Azawakh',
    ukUA: 'Азавак'
  },
  {
    value: 'barbet',
    enGB: 'Barbet',
    ukUA: 'Барбет'
  },
  {
    value: 'basenji',
    enGB: 'Basenji',
    ukUA: 'Басенджі'
  },
  {
    value: 'basset_bleu_de_gascogne',
    enGB: 'Basset Bleu de Gascogne',
    ukUA: 'Блакитний Гасконський Басет'
  },
  {
    value: 'basset_hound',
    enGB: 'Basset Hound',
    ukUA: 'Басет-Хаунд'
  },
  {
    value: 'bavarian_mountain_hound',
    enGB: 'Bavarian Mountain Hound',
    ukUA: 'Баварський Гірський Гончак'
  },
  {
    value: 'beagle',
    enGB: 'Beagle',
    ukUA: 'Бігль'
  },
  {
    value: 'bearded_collie',
    enGB: 'Bearded Collie',
    ukUA: 'Бородатий Коллі'
  },
  {
    value: 'bedlington_terrier',
    enGB: 'Bedlington Terrier',
    ukUA: 'Бедлінгтон Тер\'єр'
  },
  {
    value: 'belgian_malinois',
    enGB: 'Belgian Malinois',
    ukUA: 'Бельгійська Вівчарка Малінуа'
  },
  {
    value: 'belgian_sheepdog',
    enGB: 'Belgian Sheepdog',
    ukUA: 'Бельгійська Вівчарка'
  },
  {
    value: 'belgian_tervuren',
    enGB: 'Belgian Tervuren',
    ukUA: 'Бельгійський Тервюрен'
  },
  {
    value: 'berger_picard',
    enGB: 'Berger Picard',
    ukUA: 'Пікардійська Вівчарка'
  },
  {
    value: 'bernedoodle',
    enGB: 'Bernedoodle',
    ukUA: 'Берндудль'
  },
  {
    value: 'bernese_mountain_dog',
    enGB: 'Bernese Mountain Dog',
    ukUA: 'Бернський Зенненхунд'
  },
  {
    value: 'bichon_frise',
    enGB: 'Bichon Frise',
    ukUA: 'Бішон Фрізе'
  },
  {
    value: 'black_and_tan_coonhound',
    enGB: 'Black and Tan Coonhound',
    ukUA: 'Чорно-підпалий Кунхаунд'
  },
  {
    value: 'black_russian_terrier',
    enGB: 'Black Russian Terrier',
    ukUA: 'Російський Чорний Тер\'єр'
  },
  {
    value: 'bloodhound',
    enGB: 'Bloodhound',
    ukUA: 'Бладгаунд (Бладхаунд)'
  },
  {
    value: 'bluetick_coonhound',
    enGB: 'Bluetick Coonhound',
    ukUA: 'Крапчасто-блакитний Кунгаунд (Кунхаунд)'
  },
  {
    value: 'boerboel',
    enGB: 'Boerboel',
    ukUA: 'Бурбуль'
  },
  {
    value: 'bohemian_shepherd',
    enGB: 'Bohemian Shepherd',
    ukUA: 'Богемська Вівчарка'
  },
  {
    value: 'bolognese',
    enGB: 'Bolognese',
    ukUA: 'Болонка'
  },
  {
    value: 'bichon_havanais',
    enGB: 'Bichon Havanais',
    ukUA: 'Гаванський Бішон (Гаванез, Гаванська Болонка)'
  },
  {
    value: 'bouledogue_francais',
    enGB: 'Bouledogue Français',
    ukUA: 'Французький Бульдог'
  },
  {
    value: 'boxer',
    enGB: 'Boxer',
    ukUA: 'Боксер'
  },
  {
    value: 'brabancon',
    enGB: 'Brabancon',
    ukUA: 'Брабансон'
  },
  {
    value: 'braque_d_auvergne',
    enGB: 'Braque d\'Auvergne',
    ukUA: 'Брак-Д\'Овернь'
  },
  {
    value: 'briard',
    enGB: 'Briard',
    ukUA: 'Бріард'
  },
  {
    value: 'brittany_spaniel',
    enGB: 'Brittany Spaniel',
    ukUA: 'Бретонський Спанієль (Спаніель)'
  },
  {
    value: 'broholmer',
    enGB: 'Broholmer',
    ukUA: 'Брогольмер (Брохольмер)'
  },
  {
    value: 'brussels_griffon',
    enGB: 'Brussels Griffon',
    ukUA: 'Брюссельський Грифон'
  },
  {
    value: 'bull_terrier',
    enGB: 'Bull Terrier',
    ukUA: 'Бультер\'єр'
  },
  {
    value: 'bulldog',
    enGB: 'Bulldog',
    ukUA: 'Бульдог'
  },
  {
    value: 'bullmastiff',
    enGB: 'Bullmastiff',
    ukUA: 'Бульмастиф'
  },
  {
    value: 'cairn_terrier',
    enGB: 'Cairn Terrier',
    ukUA: 'Керн Тер\'єр'
  },
  {
    value: 'canaan_dog',
    enGB: 'Canaan Dog',
    ukUA: 'Ханаанський Собака'
  },
  {
    value: 'cane_corso',
    enGB: 'Cane Corso',
    ukUA: 'Кане-Корсо'
  },
  {
    value: 'catalan_sheepdog',
    enGB: 'Catalan Sheepdog',
    ukUA: 'Каталонська Вівчарка'
  },
  {
    value: 'caucasian_shepherd_dog',
    enGB: 'Caucasian Shepherd Dog',
    ukUA: 'Кавказька Вівчарка'
  },
  {
    value: 'cavalier_king_charles_spaniel',
    enGB: 'Cavalier King Charles Spaniel',
    ukUA: 'Кавалер Кінг Чарльз Спанієль (Спаніель)'
  },
  {
    value: 'central_asian_shepherd_dog',
    enGB: 'Central Asian Shepherd Dog',
    ukUA: 'Середньоазійська Вівчарка'
  },
  {
    value: 'cesky_terrier',
    enGB: 'Cesky Terrier',
    ukUA: 'Чеський Тер\'єр'
  },
  {
    value: 'chesapeake_bay_retriever',
    enGB: 'Chesapeake Bay Retriever',
    ukUA: 'Чесапік-Бей Ретривер'
  },
  {
    value: 'chihuahua',
    enGB: 'Chihuahua',
    ukUA: 'Чихуахуа'
  },
  {
    value: 'chow_chow',
    enGB: 'Chow Chow',
    ukUA: 'Чау-Чау'
  },
  {
    value: 'cirneco_dell_etna',
    enGB: 'Cirneco dell\'Etna',
    ukUA: 'Чірнеко Дель Етна (Сицилійська Борза)'
  },
  {
    value: 'clumber_spaniel',
    enGB: 'Clumber Spaniel',
    ukUA: 'Кламбер-Спанієль (Спаніель)'
  },
  {
    value: 'cockapoo',
    enGB: 'Cockapoo',
    ukUA: 'Кокапу'
  },
  {
    value: 'cocker_spaniel',
    enGB: 'Cocker Spaniel',
    ukUA: 'Кокер-Спанієль (Спаніель)'
  },
  {
    value: 'collie',
    enGB: 'Collie',
    ukUA: 'Колі'
  },
  {
    value: 'border_collie',
    enGB: 'Border Collie',
    ukUA: 'Бордер Колі'
  },
  {
    value: 'curly_coated_retriever',
    enGB: 'Curly Coated Retriever',
    ukUA: 'Курчавошерстий Ретривер'
  },
  {
    value: 'dachshund',
    enGB: 'Dachshund',
    ukUA: 'Такса'
  },
  {
    value: 'dalmatian',
    enGB: 'Dalmatian',
    ukUA: 'Далматин'
  },
  {
    value: 'dandie_dinmont_terrier',
    enGB: 'Dandie Dinmont Terrier',
    ukUA: 'Денді-Динмонт Тер\'єр'
  },
  {
    value: 'danish_swedish_farmdog',
    enGB: 'Danish Swedish Farmdog',
    ukUA: 'Дансько-Шведський Фермерський'
  },
  {
    value: 'deerhound',
    enGB: 'Deerhound',
    ukUA: 'Діргаунд (Дірхаунд)'
  },
  {
    value: 'dutch_shepherd',
    enGB: 'Dutch Shepherd',
    ukUA: 'Голландська Вівчарка'
  },
  {
    value: 'english_cocker_spaniel',
    enGB: 'English Cocker Spaniel',
    ukUA: 'Англійський Кокер-Спанієль (Спаніель)'
  },
  {
    value: 'english_foxhound',
    enGB: 'English Foxhound',
    ukUA: 'Англійський Фоксгаунд (Фоксхаунд)'
  },
  {
    value: 'english_setter',
    enGB: 'English Setter',
    ukUA: 'Англійський Сетер'
  },
  {
    value: 'english_springer_spaniel',
    enGB: 'English Springer Spaniel',
    ukUA: 'Англійський Спринґер-Спанієль (Спаніель)'
  },
  {
    value: 'english_toy_spaniel',
    enGB: 'English Toy Spaniel',
    ukUA: 'Англійський Той Спанієль (Кінг Чарльз Спанієль) (Спаніель)'
  },
  {
    value: 'entlebucher_mountain_dog',
    enGB: 'Entlebucher Mountain Dog',
    ukUA: 'Швейцарський Гірський (Ентельбухер Зенненхунд)'
  },
  {
    value: 'eskimo_dog',
    enGB: 'Eskimo Dog',
    ukUA: 'Ескімоський Собака'
  },
  {
    value: 'estrela_mountain_dog',
    enGB: 'Estrela Mountain Dog',
    ukUA: 'Естрельський Гірський'
  },
  {
    value: 'field_spaniel',
    enGB: 'Field Spaniel',
    ukUA: 'Філд-Спанієль (Спаніель)'
  },
  {
    value: 'finnish_lapphund',
    enGB: 'Finnish Lapphund',
    ukUA: 'Фінський Лапхунд (Лапгунд)'
  },
  {
    value: 'finnish_spitz',
    enGB: 'Finnish Spitz',
    ukUA: 'Фінський Шпіц'
  },
  {
    value: 'flat_coated_retriever',
    enGB: 'Flat Coated Retriever',
    ukUA: 'Прямошерстий Ретривер'
  },
  {
    value: 'french_bulldog',
    enGB: 'French Bulldog',
    ukUA: 'Французький Бульдог'
  },
  {
    value: 'french_spaniel',
    enGB: 'French Spaniel',
    ukUA: 'Французький Спанієль (Спаніель)'
  },
  {
    value: 'german_longhaired_pointer',
    enGB: 'German Longhaired Pointer',
    ukUA: 'Німецький Лангхар (Німецький Довгошерстий Лягавий Собака)'
  },
  {
    value: 'german_pinscher',
    enGB: 'German Pinscher',
    ukUA: 'Німецький Пінчер'
  },
  {
    value: 'german_shepherd',
    enGB: 'German Shepherd',
    ukUA: 'Німецька Вівчарка'
  },
  {
    value: 'german_shorthaired_pointer',
    enGB: 'German Shorthaired Pointer',
    ukUA: 'Німецький Короткошерстий Пойнтер'
  },
  {
    value: 'german_spitz',
    enGB: 'German Spitz',
    ukUA: 'Німецький Шпіц'
  },
  {
    value: 'german_wirehaired_pointer',
    enGB: 'German Wirehaired Pointer',
    ukUA: 'Німецький Жорсткошерстий Пойнтер'
  },
  {
    value: 'giant_schnauzer',
    enGB: 'Giant Schnauzer',
    ukUA: 'Гігантський Шнауцер (Різеншнауцер)'
  },
  {
    value: 'glen_of_imaal_terrier',
    enGB: 'Glen of Imaal Terrier',
    ukUA: 'Глен оф Імаал Тер\'єр'
  },
  {
    value: 'golden_retriever',
    enGB: 'Golden Retriever',
    ukUA: 'Золотистий Ретривер'
  },
  {
    value: 'gordon_setter',
    enGB: 'Gordon Setter',
    ukUA: 'Ґордон-Сетер'
  },
  {
    value: 'great_dane',
    enGB: 'Great Dane',
    ukUA: 'Німецький Дог'
  },
  {
    value: 'greenland_dog',
    enGB: 'Greenland Dog',
    ukUA: 'Гренландський Собака'
  },
  {
    value: 'greyhound',
    enGB: 'Greyhound',
    ukUA: 'Ґрейгаунд (Грейхаунд)'
  },
  {
    value: 'groenendael',
    enGB: 'Groenendael',
    ukUA: 'Ґрюнендаль'
  },
  {
    value: 'harrier',
    enGB: 'Harrier',
    ukUA: 'Гаріер'
  },
  {
    value: 'havanese',
    enGB: 'Havanese',
    ukUA: 'Гаванський Бішон (Гаванез)'
  },
  {
    value: 'hovawart',
    enGB: 'Hovawart',
    ukUA: 'Ховаварт'
  },
  {
    value: 'ibizan_hound',
    enGB: 'Ibizan Hound',
    ukUA: 'Ібіцанська Гонча'
  },
  {
    value: 'icelandic_sheepdog',
    enGB: 'Icelandic Sheepdog',
    ukUA: 'Ісландська Вівчарка'
  },
  {
    value: 'irish_red_and_white_setter',
    enGB: 'Irish Red and White Setter',
    ukUA: 'Ірландський Червоно-Білий Сетер'
  },
  {
    value: 'irish_setter',
    enGB: 'Irish Setter',
    ukUA: 'Ірландський Сетер'
  },
  {
    value: 'irish_water_spaniel',
    enGB: 'Irish Water Spaniel',
    ukUA: 'Ірландський Водяний Спанієль (Спаніель)'
  },
  {
    value: 'irish_wolfhound',
    enGB: 'Irish Wolfhound',
    ukUA: 'Ірландський Вольфгаунд (Вольфхаунд)'
  },
  {
    value: 'italian_greyhound',
    enGB: 'Italian Greyhound',
    ukUA: 'Італійський Ґрейгаунд (Хрейхаунд)'
  },
  {
    value: 'jack_russell_terrier',
    enGB: 'Jack Russell Terrier',
    ukUA: 'Джек-Расел-Тер\'єр'
  },
  {
    value: 'japanese_chin',
    enGB: 'Japanese Chin',
    ukUA: 'Японський Хін'
  },
  {
    value: 'japanese_spitz',
    enGB: 'Japanese Spitz',
    ukUA: 'Японський Шпіц'
  },
  {
    value: 'japanese_terrier',
    enGB: 'Japanese Terrier',
    ukUA: 'Японський Тер\'єр'
  },
  {
    value: 'karelian_bear_dog',
    enGB: 'Karelian Bear Dog',
    ukUA: 'Карельський Ведмежий Собака'
  },
  {
    value: 'keeshond',
    enGB: 'Keeshond',
    ukUA: 'Кеесхонд'
  },
  {
    value: 'kerry_blue_terrier',
    enGB: 'Kerry Blue Terrier',
    ukUA: 'Кері Блю Тер\'єр'
  },
  {
    value: 'komondor',
    enGB: 'Komondor',
    ukUA: 'Комондор'
  },
  {
    value: 'kooikerhondje',
    enGB: 'Kooikerhondje',
    ukUA: 'Коїкерхондьє'
  },
  {
    value: 'korean_jindo',
    enGB: 'Korean Jindo',
    ukUA: 'Корейський Джиндо'
  },
  {
    value: 'kuvasz',
    enGB: 'Kuvasz',
    ukUA: 'Кувас'
  },
  {
    value: 'labrador_retriever',
    enGB: 'Labrador Retriever',
    ukUA: 'Лабрадор-Ретривер'
  },
  {
    value: 'lagotto_romagnolo',
    enGB: 'Lagotto Romagnolo',
    ukUA: 'Лаготто Романьоло'
  },
  {
    value: 'lakeland_terrier',
    enGB: 'Lakeland Terrier',
    ukUA: 'Лейкленд-Тер\'єр'
  },
  {
    value: 'lancashire_heeler',
    enGB: 'Lancashire Heeler',
    ukUA: 'Ланкашир-Хілер'
  },
  {
    value: 'lhasa_apso',
    enGB: 'Lhasa Apso',
    ukUA: 'Лхаса-Апсо'
  },
  {
    value: 'leonberger',
    enGB: 'Leonberger',
    ukUA: 'Леонбергер'
  },
  {
    value: 'maltese',
    enGB: 'Maltese',
    ukUA: 'Мальтійська Болонка (Мальтезе)'
  },
  {
    value: 'maltipoo',
    enGB: 'Maltipoo',
    ukUA: 'Мальтіпу'
  },
  {
    value: 'miniature_american_shepherd',
    enGB: 'Miniature American Shepherd',
    ukUA: 'Мініатюрна Американська Вівчарка'
  },
  {
    value: 'miniature_pinscher',
    enGB: 'Miniature Pinscher',
    ukUA: 'Мініатюрний Пінчер'
  },
  {
    value: 'miniature_schnauzer',
    enGB: 'Miniature Schnauzer',
    ukUA: 'Мініатюрний Шнауцер'
  },
  {
    value: 'newfoundland',
    enGB: 'Newfoundland',
    ukUA: 'Ньюфаундленд'
  },
  {
    value: 'norfolk_terrier',
    enGB: 'Norfolk Terrier',
    ukUA: 'Норфолк Тер\'єр'
  },
  {
    value: 'norwich_terrier',
    enGB: 'Norwich Terrier',
    ukUA: 'Норвіч Тер\'єр'
  },
  {
    value: 'nova_scotia_duck_tolling_retriever',
    enGB: 'Nova Scotia Duck Tolling Retriever',
    ukUA: 'Новошотландський Ретривер'
  },
  {
    value: 'old_english_sheepdog',
    enGB: 'Old English Sheepdog',
    ukUA: 'Староанглійська Вівчарка'
  },
  {
    value: 'olde_english_bulldogge',
    enGB: 'Olde English Bulldogge',
    ukUA: 'Староанглійський Бульдог'
  },
  {
    value: 'papillon',
    enGB: 'Papillon',
    ukUA: 'Папільйон'
  },
  {
    value: 'pekingese',
    enGB: 'Pekingese',
    ukUA: 'Пекінес (Пекінез)'
  },
  {
    value: 'pembroke_welsh_corgi',
    enGB: 'Pembroke Welsh Corgi',
    ukUA: 'Вельш-Корґі Пемброк'
  },
  {
    value: 'perro_de_presa_canario',
    enGB: 'Perro de Presa Canario',
    ukUA: 'Доґо-Канаріо'
  },
  {
    value: 'pharaoh_hound',
    enGB: 'Pharaoh Hound',
    ukUA: 'Фараоновий Собака'
  },
  {
    value: 'plott',
    enGB: 'Plott',
    ukUA: 'Плотт'
  },
  {
    value: 'pomeranian',
    enGB: 'Pomeranian',
    ukUA: 'Померанський Шпіц'
  },
  {
    value: 'poodle_(miniature)',
    enGB: 'Poodle (Miniature)',
    ukUA: 'Мініатюрний Пудель'
  },
  {
    value: 'poodle',
    enGB: 'Poodle',
    ukUA: 'Пудель'
  },
  {
    value: 'poodle_(toy)',
    enGB: 'Poodle (Toy)',
    ukUA: 'Той-Пудель'
  },
  {
    value: 'pug',
    enGB: 'Pug',
    ukUA: 'Мопс'
  },
  {
    value: 'puli',
    enGB: 'Puli',
    ukUA: 'Пулі'
  },
  {
    value: 'pumi',
    enGB: 'Pumi',
    ukUA: 'Пумі'
  },
  {
    value: 'rat_terrier',
    enGB: 'Rat Terrier',
    ukUA: 'Рет-Тер’єр'
  },
  {
    value: 'redbone_coonhound',
    enGB: 'Redbone Coonhound',
    ukUA: 'Редбон-Кунхаунд'
  },
  {
    value: 'rhodesian_ridgeback',
    enGB: 'Rhodesian Ridgeback',
    ukUA: 'Родезійський Ріджбек'
  },
  {
    value: 'rottweiler',
    enGB: 'Rottweiler',
    ukUA: 'Ротвейлер'
  },
  {
    value: 'russian_toy',
    enGB: 'Russian Toy',
    ukUA: 'Російський Той'
  },
  {
    value: 'saint_bernard',
    enGB: 'Saint Bernard',
    ukUA: 'Сенбернар'
  },
  {
    value: 'saluki',
    enGB: 'Saluki',
    ukUA: 'Салукі'
  },
  {
    value: 'samoyed',
    enGB: 'Samoyed',
    ukUA: 'Самоїд'
  },
  {
    value: 'schipperke',
    enGB: 'Schipperke',
    ukUA: 'Шипперке'
  },
  {
    value: 'scottish_deerhound',
    enGB: 'Scottish Deerhound',
    ukUA: 'Шотландський Діргаунд (Дірхаунд)'
  },
  {
    value: 'scottish_terrier',
    enGB: 'Scottish Terrier',
    ukUA: 'Шотландський Тер\'єр'
  },
  {
    value: 'shetland_sheepdog',
    enGB: 'Shetland Sheepdog',
    ukUA: 'Шелті (Шетландська Вівчарка)'
  },
  {
    value: 'shiba_inu',
    enGB: 'Shiba Inu',
    ukUA: 'Сіба-Іну (Шиба-Іну)'
  },
  {
    value: 'shih_tzu',
    enGB: 'Shih Tzu',
    ukUA: 'Ши-Тсу (Ши-Цу)'
  },
  {
    value: 'shiloh_shepherd',
    enGB: 'Shiloh Shepherd',
    ukUA: 'Шилонська Вівчарка'
  },
  {
    value: 'siberian_husky',
    enGB: 'Siberian Husky',
    ukUA: 'Сибірський Хаскі'
  },
  {
    value: 'silky_terrier',
    enGB: 'Silky Terrier',
    ukUA: 'Шовковий Тер\'єр'
  },
  {
    value: 'smooth_fox_terrier',
    enGB: 'Smooth Fox Terrier',
    ukUA: 'Гладкошерстий Фокс-Тер\'єр'
  },
  {
    value: 'soft_coated_wheaten_terrier',
    enGB: 'Soft Coated Wheaten Terrier',
    ukUA: 'Ірландський М\'якошерстий Пшеничний Тер\'єр'
  },
  {
    value: 'spanish_water_dog',
    enGB: 'Spanish Water Dog',
    ukUA: 'Іспанський Водяний Собака'
  },
  {
    value: 'spinone_italiano',
    enGB: 'Spinone Italiano',
    ukUA: 'Італійський Спіноне'
  },
  {
    value: 'staffordshire_bull_terrier',
    enGB: 'Staffordshire Bull Terrier',
    ukUA: 'Стаффордширський Бультер’єр'
  },
  {
    value: 'standard_schnauzer',
    enGB: 'Standard Schnauzer',
    ukUA: 'Стандартний Шнауцер'
  },
  {
    value: 'swedish_vallhund',
    enGB: 'Swedish Vallhund',
    ukUA: 'Шведський Волхунд (Волгунд)'
  },
  {
    value: 'thai_ridgeback',
    enGB: 'Thai Ridgeback',
    ukUA: 'Тайський Ріджбек'
  },
  {
    value: 'tibetan_mastiff',
    enGB: 'Tibetan Mastiff',
    ukUA: 'Тибетський Мастиф'
  },
  {
    value: 'tibetan_spaniel',
    enGB: 'Tibetan Spaniel',
    ukUA: 'Тибетський Спанієль (Спаніель)'
  },
  {
    value: 'tibetan_terrier',
    enGB: 'Tibetan Terrier',
    ukUA: 'Тибетський Тер’єр'
  },
  {
    value: 'toy_fox_terrier',
    enGB: 'Toy Fox Terrier',
    ukUA: 'Той-Фокстер\'єр'
  },
  {
    value: 'treeing_walker_coonhound',
    enGB: 'Treeing Walker Coonhound',
    ukUA: 'Деревний Єнотовий Гончак'
  },
  {
    value: 'vizsla',
    enGB: 'Vizsla',
    ukUA: 'Вижла'
  },
  {
    value: 'weimaraner',
    enGB: 'Weimaraner',
    ukUA: 'Веймаранер'
  },
  {
    value: 'welsh_springer_spaniel',
    enGB: 'Welsh Springer Spaniel',
    ukUA: 'Вельш-Спринґер-Спанієль (Спаніель)'
  },
  {
    value: 'west_highland_white_terrier',
    enGB: 'West Highland White Terrier',
    ukUA: 'Вест-Хайленд-Вайт-Тер\'єр'
  },
  {
    value: 'whippet',
    enGB: 'Whippet',
    ukUA: 'Уіппет'
  },
  {
    value: 'white_shepherd',
    enGB: 'White Shepherd (White Swiss Shepherd Dog)',
    ukUA: 'Швейцарська Біла Вівчарка'
  },
  {
    value: 'wire_fox_terrier',
    enGB: 'Wire Fox Terrier',
    ukUA: 'Жорсткошерстий Фокс-Тер\'єр'
  },
  {
    value: 'wirehaired_pointing_griffon',
    enGB: 'Wirehaired Pointing Griffon',
    ukUA: 'Жорсткошерстий Пойнтер Грифон'
  },
  {
    value: 'wirehaired_vizsla',
    enGB: 'Wirehaired Vizsla',
    ukUA: 'Жорсткошерстий Пойнтер'
  },
  {
    value: 'xoloitzcuintli',
    enGB: 'Xoloitzcuintli',
    ukUA: 'Шолоїцквінтлі'
  },
  {
    value: 'yorkshire_terrier',
    enGB: 'Yorkshire Terrier',
    ukUA: 'Йоркшир-Тер\'єр (Йоркширський Тер\'єр)'
  },
  {
    value: 'mixed',
    enGB: 'Mixed',
    ukUA: 'Метис'
  },
  {
    value: 'other',
    enGB: 'Other',
    ukUA: 'Інша'
  }
];

export default breeds;
