import './AuditDetails.scss';

import { Button, Col, Row, Tooltip } from 'antd';
import { TextEditorView } from 'components/TextEditorView';
import { Title } from 'components/Title';
import { auditStatuses } from 'constants/dictionary/default/selectOptions';
import useLocalizedList from 'hooks/useLocalizedList';
import { ILocale } from 'interfaces/locale';
import moment from 'moment';
import React, { useState } from 'react';
import { IAuditListItem } from 'services/interfaces/clinics/inventory/audit/IAuditList';
import { ISelectedAuditItem } from 'services/interfaces/clinics/inventory/audit/ISelectedAuditItemsData';
import valueFromList from 'utils/value-from-list';

import CloseAuditModal from '../AuditModals/CloseAuditModal';

type Props = {
  selectedAuditItems: ISelectedAuditItem[];
  selectedAudit: IAuditListItem;
  locale: ILocale;
};

export const AuditDetailsHeader = ({
  selectedAuditItems,
  selectedAudit,
  locale
}: Props): JSX.Element => {
  const localizedAuditStatuses = useLocalizedList(auditStatuses);
  const [showCloseModal, setShowCloseModal] = useState(false);

  const onCloseAudit = (): void => {
    setShowCloseModal(true);
  };

  const unfinishedItems = (): boolean =>
    !selectedAuditItems.length ||
    selectedAuditItems?.some((item) => item?.progress_status !== 'finished');

  return (
    <div
      style={{
        borderBottom: '1px solid rgba(5, 5, 5, 0.06)',
        marginBottom: 20
      }}
    >
      <div className='appointment-details-top-container' style={{ marginBottom: 10 }}>
        <Row gutter={[24, 0]}>
          <Col
            lg={12}
            md={12}
            sm={24}
            xs={24}
            className='appointment-details-top-container__left-column'
          >
            <Col>
              {selectedAudit?.name && (
                <>
                  <Title title={`${locale.labels.name}: `} />
                  <span id='name'>{selectedAudit?.name}</span>
                </>
              )}
            </Col>
            <Col>
              {selectedAudit?.created_at && (
                <>
                  <Title title={`${locale.labels.date}: `} />
                  <span id='audit-date'>{moment(selectedAudit.date).format('DD.MM.YYYY')}</span>
                </>
              )}
            </Col>
            <Col>
              {selectedAudit?.status && (
                <>
                  <Title title={`${locale.labels.status}: `} />
                  <span id='audit-status'>
                    {valueFromList(localizedAuditStatuses, selectedAudit?.status)}
                  </span>
                </>
              )}
            </Col>
          </Col>
          <Col
            lg={12}
            md={12}
            sm={24}
            xs={24}
            className='appointment-details-top-container__right-column'
          >
            <Col>
              <Title title={`${locale.labels.description}: `} />
              <TextEditorView value={selectedAudit?.description} id='description' />
            </Col>
            <Col>
              <Title title={`${locale.labels.notes}: `} />
              <TextEditorView value={selectedAudit?.notes} id='notes' />
            </Col>
            <Col>
              {selectedAudit?.status === 'in_progress' && (
                <Tooltip
                  trigger={'hover'}
                  placement='topRight'
                  title={unfinishedItems() ? locale.messages.finishDisabled : null}
                >
                  <Row style={{ justifyContent: 'end' }}>
                    <Button type='primary' disabled={unfinishedItems()} onClick={onCloseAudit}>
                      {locale.buttons.finish}
                    </Button>
                  </Row>
                </Tooltip>
              )}
              {selectedAudit?.status === 'finished' && (
                <Button type='primary' onClick={onCloseAudit}>
                  {locale.labels.auditReport}
                </Button>
              )}
            </Col>
          </Col>
        </Row>
      </div>
      {showCloseModal && (
        <CloseAuditModal
          locale={locale}
          auditFinished={selectedAudit?.status === 'finished'}
          showCloseModal={showCloseModal}
          setShowCloseModal={setShowCloseModal}
          selectedAuditItems={selectedAuditItems}
        />
      )}
    </div>
  );
};
