import { ArrowDownOutlined, ArrowUpOutlined } from '@ant-design/icons';
import { Statistic } from 'antd';
import dayjs from 'dayjs';
import React from 'react';

export function currentEarnings(totalDiff, currencies) {
  if (totalDiff > 0) {
    return currentEarningsStats(totalDiff, '#3f8600', <ArrowUpOutlined />, currencies);
  } else if (totalDiff < 0) {
    return currentEarningsStats(totalDiff, '#cf1322', <ArrowDownOutlined />, currencies);
  } else {
    return currentEarningsStats(totalDiff, '#595959', '', currencies);
  }
}

export function userData(user: any) {
  const title = user?.title ? `(${user.title})` : '';
  return user?.name ? `${user.name} ${title}` : '-';
}

export function currentEarningsStats(totalDiff, color, prefix, currencies) {
  return (
    <Statistic
      className='shift-earnings-status'
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
      }}
      value={totalDiff}
      precision={2}
      valueStyle={{ color }}
      prefix={prefix}
      suffix={currencies.uah}
    />
  );
}

export function getShiftDuration(shift, locale) {
  const endTime = shift.closed_at ? dayjs(shift.closed_at).local() : dayjs().local();
  const seconds = endTime.diff(dayjs(shift.opened_at).local(), 'second');
  const totalHours = Math.floor(seconds / (60 * 60)); // How many hours?
  const totalSeconds = seconds - totalHours * 60 * 60; // Pull those hours out of totalSeconds
  const totalMinutes = Math.floor(totalSeconds / 60);
  return `${totalHours}${locale.labels.hours} ${totalMinutes}${locale.labels.minutes}`;
}
