const breeds = [
  {
    value: 'hedgehog',
    enGB: 'Hedgehog',
    ukUA: 'Їжак'
  },
  {
    value: 'ferret',
    enGB: 'Ferret',
    ukUA: 'Фретка (Тхір)'
  },
  {
    value: 'gecko',
    enGB: 'Gecko',
    ukUA: 'Гекон'
  },
  {
    value: 'turtle',
    enGB: 'Turtle',
    ukUA: 'Черепаха'
  },
  {
    value: 'snail',
    enGB: 'Snail',
    ukUA: 'Равлик'
  },
  {
    value: 'iguana',
    enGB: 'Iguana',
    ukUA: 'Ігуана'
  },
  {
    value: 'frog',
    enGB: 'Frog',
    ukUA: 'Жаба'
  },
  {
    value: 'python',
    enGB: 'Python',
    ukUA: 'Пітон'
  },
  {
    value: 'mini_pig',
    enGB: 'Mini Pig',
    ukUA: 'Міні-Піг'
  },
  {
    value: 'monkey',
    enGB: 'Monkey',
    ukUA: 'Мавпа'
  },
  {
    value: 'other',
    enGB: 'Other',
    ukUA: 'Інша'
  }
];

export default breeds;
