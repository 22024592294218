import { Button, Form, Spin } from 'antd';
import ConfirmCancelModal from 'components/ConfirmCancelModal';
import ViewDetailsButtonsGroup from 'components/ViewDetailsButtonsGroup';
import VLXModal from 'components/VLXModal';
import { useLocale } from 'hooks/useLocale';
import { useUserAccess } from 'hooks/useUserAccess';
import { IAppState } from 'interfaces/app-state';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { showModal } from 'redux/dictionaries/dictionary/actions';

import AddDictionaryForm from './AddDictionaryForm';

const AddDictionary = ({
  initialValues,
  callBackAfterCreate,
  popupTitle,
  actionType,
  hideCreateBtn,
  defaultDictionaryNameValue
}: any): JSX.Element => {
  const dispatch = useDispatch();
  const dictionary = useSelector<IAppState, any>((state) => state.dictionary);
  const [showCancelModal, setShowCancelModal] = useState<boolean>(false);
  const userAccess = useUserAccess();
  const locale = useLocale('private.administration.dictionaries');
  const [form] = Form.useForm();

  const onConfirmCancelClick = () => {
    form.resetFields();
    dispatch(showModal(false));
    setShowCancelModal(false);
  };

  return (
    <>
      {!hideCreateBtn && (
        <Button
          id='add-new-dictionary'
          className='icofont icofont-plus'
          type={'primary'}
          style={{ borderRadius: 30 }}
          onClick={() => dispatch(showModal(true))}
        >
          {popupTitle || locale.buttons.addNew}
        </Button>
      )}
      {dictionary.showModal && (
        <VLXModal
          defZindex={9999}
          title={popupTitle || locale.labels.addServiceGroup}
          centered={true}
          form={form}
          open={dictionary.showModal}
          onModalClose={() => dispatch(showModal(false))}
          width={800}
        >
          <Spin spinning={dictionary?.loading}>
            <AddDictionaryForm
              defaultDictionaryNameValue={defaultDictionaryNameValue}
              locale={locale}
              form={form}
              initialValues={initialValues}
              callBackAfterCreate={(data) => {
                callBackAfterCreate(data);
                dispatch(showModal(false));
              }}
              actionType={actionType}
            >
              <ViewDetailsButtonsGroup
                fixed
                accessGroup={userAccess.accesses.services}
                editMode={true}
                hideDeleteBtn={true}
                onCancelClick={() => {
                  if (form.isFieldsTouched()) {
                    setShowCancelModal(true);
                  } else {
                    dispatch(showModal(false));
                  }
                }}
                onDeleteClick={() => {
                  setShowCancelModal(false);
                }}
              />
            </AddDictionaryForm>
          </Spin>
        </VLXModal>
      )}
      <ConfirmCancelModal
        defZindex={99999}
        open={showCancelModal}
        onOk={onConfirmCancelClick}
        onCancel={() => setShowCancelModal(false)}
      />
    </>
  );
};

export default AddDictionary;
