import { Form } from 'antd';
import ConfirmCancelModal from 'components/ConfirmCancelModal';
import StickyButton from 'components/StickyButton';
import ViewDetailsButtonsGroup from 'components/ViewDetailsButtonsGroup';
import VLXModal from 'components/VLXModal';
import { useLocale } from 'hooks/useLocale';
import { useUserAccess } from 'hooks/useUserAccess';
import { IAppState } from 'interfaces/app-state';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { showEditAppoinmentModal } from 'redux/appointments/actions';

import EditAppointmentForm from './EditAppointmentForm';

const EditAppointment = ({
  hideDefaultCreatePetFormBtn,
  initialValues,
  disableTimeEditing
}: any) => {
  const dispatch = useDispatch();
  const appointments = useSelector<IAppState, any>((state) => state.appointments);
  const [editMode, setEditMode] = useState(false);
  const [editAppointmentForm] = Form.useForm();
  const [showCancelModal, setShowCancelModal] = useState<boolean>(false);
  const userAccess = useUserAccess();
  const locale = useLocale('private.appointments.event');

  const onConfirmCancelClick = () => {
    editAppointmentForm.resetFields();
    dispatch(showEditAppoinmentModal(false));
    setShowCancelModal(false);
    setEditMode(false);
  };

  const onFieldsChange = (e: any) => {
    if (e.length < 2 && !editMode) {
      setEditMode(true);
    }
  };

  const mapValues = () => {
    if (initialValues) {
      const data = {
        ...initialValues
      };
      if (initialValues?.services?.length) {
        data.services = initialValues.services.map((el: any) => el.id);
      }
      if (initialValues?.participants?.length) {
        data.attendees = initialValues.participants.map((el: any) => el.id);
      }
      if (initialValues?.location?.type) {
        data.event = {};
        data.event.location = initialValues.location;
      }
      if (initialValues?.doctor?.id) {
        data.doctor_id = initialValues.doctor?.id;
      }
      return data;
    } else {
      return null;
    }
  };

  return (
    <>
      {!hideDefaultCreatePetFormBtn ? (
        <StickyButton
          offsetBottom={10}
          onClick={() => dispatch(showEditAppoinmentModal(true))}
          iconElement={
            <span
              className='icofont icofont-plus mr-2 sticky-btn-icon'
              style={{ fontSize: '1.3em' }}
            />
          }
          textElement={locale.buttons.addAppointment}
        />
      ) : (
        hideDefaultCreatePetFormBtn
      )}
      <VLXModal
        title={locale.labels.editAppointment}
        centered={true}
        form={editAppointmentForm}
        open={appointments.showEditAppoinmentModal}
        onModalClose={() => {
          dispatch(showEditAppoinmentModal(false));
          setEditMode(false);
        }}
        width={800}
      >
        {appointments.showEditAppoinmentModal && (
          <EditAppointmentForm
            disableTimeEditing={disableTimeEditing}
            onFieldsChange={onFieldsChange}
            form={editAppointmentForm}
            initialValues={mapValues()}
            locale={locale}
          >
            <ViewDetailsButtonsGroup
              fixed
              accessGroup={userAccess.accesses.appointments}
              editMode={editMode}
              onEditClick={() => setEditMode(true)}
              hideDeleteBtn={true}
              onCancelClick={() => {
                if (editAppointmentForm.isFieldsTouched()) {
                  setShowCancelModal(true);
                } else {
                  setEditMode(false);
                  dispatch(showEditAppoinmentModal(false));
                }
              }}
              onDeleteClick={() => {
                setShowCancelModal(false);
              }}
            />
          </EditAppointmentForm>
        )}
        <ConfirmCancelModal
          open={showCancelModal}
          onOk={onConfirmCancelClick}
          onCancel={() => setShowCancelModal(false)}
        />
      </VLXModal>
    </>
  );
};

export default EditAppointment;
