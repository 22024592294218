import { Button, Pagination, Row, Spin, Table, TableColumnProps } from 'antd';
import { DebtStatus } from 'components/DebtStatus';
import ListFieldWithTooltip from 'components/ListFieldWithTooltip';
import StickyButton from 'components/StickyButton';
import VLXSearch from 'components/VLXSearch';
import useClinicId from 'hooks/useClinicId';
import { useLocale } from 'hooks/useLocale';
import { useSearch } from 'hooks/useSearch';
import { useUserAccess } from 'hooks/useUserAccess';
import { IAppState } from 'interfaces/app-state';
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
  getProviders,
  setSelectedProviderData,
  setShowCreateProviderModal,
  setShowEditProviderModal
} from 'redux/inventory/providers/actions';
import { ProvidersListState } from 'redux/inventory/providers/reducer';
import { ISelectedProvidersItem } from 'services/interfaces/clinics/inventory/providers/ISelectedProvidersItemsData';

import { AddProvider } from './modal/AddProvider';
import { EditProvider } from './modal/EditProvider';
import { getMailView, getPhoneView } from './utils/utils';

export const ProvidersList = (): JSX.Element => {
  const dispatch = useDispatch();
  const clinicId = useClinicId();
  const userAccess = useUserAccess();
  const navigate = useNavigate();
  const locale = useLocale('private.inventory.providers');
  const appModules = useUserAccess().availableModules;

  const [selectedProvider, setSelectedProvider] = useState<ISelectedProvidersItem>(null);

  const { data, metadata } = useSelector<IAppState, ProvidersListState>(
    ({ providers }) => providers
  );
  const { loading } = useSelector<IAppState, ProvidersListState>(({ providers }) => providers);

  const { handleChange, onPageChange, getResults, filterResults } = useSearch({
    action: getProviders,
    clinicId,
    defaultSortKey: 'name',
    defaultSortOrder: 'asc'
  });

  useEffect(() => {
    dispatch(setSelectedProviderData(null));
  }, []);

  const redirectToProviderDetails = ({ id }: { id: number }): { onClick: () => void } => ({
    onClick: (): void => {
      navigate(`/clinic/${clinicId}/inventory/providers/${id}`);
    }
  });

  const columns = useMemo(
    (): TableColumnProps<ISelectedProvidersItem>[] => [
      {
        key: 'name',
        title: locale.labels.name,
        onCell: redirectToProviderDetails,
        sorter: () => null,
        render: (e) => ListFieldWithTooltip(e.name ?? '-', 30)
      },
      {
        key: 'phone_numbers',
        title: locale.labels.contactPhone,
        sorter: () => null,
        render: (e) => getPhoneView(e.phone_numbers)
      },
      {
        key: 'debt',
        title: locale.labels.paymentsStatus,
        hidden: !appModules.finances,
        sorter: (a, b) => null,
        render: (e) => <DebtStatus debt={e.debt} />
      },
      {
        key: 'emails',
        title: locale.labels.emails,
        sorter: () => null,
        render: (e) => getMailView(e.emails)
      },
      {
        key: 'address',
        title: locale.labels.address,
        onCell: redirectToProviderDetails,
        sorter: () => null,
        render: (e) => e.address ?? '-'
      },
      {
        key: 'actions',
        title: locale.labels.actions,
        render: (e) => (
          <Button
            className='change-appointment-status-button'
            disabled={!userAccess.accesses.providers.edit}
            onClick={() => {
              setSelectedProvider(e);
              dispatch(setShowEditProviderModal(true));
            }}
            shape='circle'
            type='primary'
          >
            <span className='icofont icofont-edit-alt' />
          </Button>
        )
      }
    ],
    [data]
  );

  return (
    <>
      <VLXSearch filterResults={filterResults} savedFiltersName={'providersListFilters'} />
      <Spin spinning={loading}>
        <Table
          pagination={false}
          className='accent-header audit-list'
          rowKey='id'
          onChange={handleChange}
          dataSource={data}
          columns={columns}
        />
        <Row
          style={{
            justifyContent: 'space-between',
            marginTop: 10,
            alignItems: 'center'
          }}
        >
          <Pagination
            className={'equipment-pagination'}
            defaultCurrent={1}
            current={metadata.page}
            showSizeChanger={false}
            showLessItems
            pageSize={20}
            total={metadata.total}
            onChange={onPageChange}
          />
          <StickyButton
            offsetBottom={10}
            onClick={() => dispatch(setShowCreateProviderModal(true))}
            iconElement={
              <span
                className='icofont icofont-plus mr-2 sticky-btn-icon'
                style={{ fontSize: '1.3em' }}
              />
            }
            textElement={locale.buttons.create}
          />
        </Row>
        {userAccess.accesses.providers.create && <AddProvider callBackAfterCreate={getResults} />}
        {userAccess.accesses.providers.edit && <EditProvider initialValues={selectedProvider} />}
      </Spin>
    </>
  );
};
