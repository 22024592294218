import {
  IndexInventoryItemsResponse,
} from 'services/clinic/inventory/invoices/inventoryItems/models/indexInventoryItemsResponse';
import { IInvoiceData, IInvoiceDataItem } from 'services/interfaces/clinics/inventory/invoice/IInvoiceData';

export const SET_INVOICE = '[invoice] Set';
export const SET_LOADING = '[invoice] Loading';
export const SHOW_CREATE_MODAL = '[invoice] Create Modal';
export const SET_BE_ERROR = '[invoice] BE Error';
export const SET_SELECTED_INVOICE = '[invoice] Set Selected Invoice';
export const SET_SELECTED_INVOICE_STOCK_ITEMS = '[invoice] Selected Invoice stock items';
export const RESET = '[invoice] RESET';
export const SET_INVOICE_DOCUMENTS_LIST = 'SET_INVOICE_DOCUMENTS_LIST';
export const MERGE_INVOICE_DOCUMENTS_LIST = 'MERGE_INVOICE_DOCUMENTS_LIST';

export interface InvoicesResetAction {
  type: typeof RESET;
}

export interface SetInvoiceAction {
  type: typeof SET_INVOICE;
  payload: IInvoiceData;
}

export interface SetSelectedInvoiceAction {
  type: typeof SET_SELECTED_INVOICE;
  payload: IInvoiceDataItem;
}

export interface SetSelectedInvoiceStockItemsAction {
  type: typeof SET_SELECTED_INVOICE_STOCK_ITEMS;
  payload: IndexInventoryItemsResponse;
}

export interface SetLoadingAction {
  type: typeof SET_LOADING;
  payload: boolean;
}

export interface ShowCreateModal {
  type: typeof SHOW_CREATE_MODAL;
  payload: boolean;
}

export interface SetBeError {
  type: typeof SET_BE_ERROR;
  payload: boolean;
}

export interface SetSelectedInvoiceDocumentsListAction {
  type: typeof SET_INVOICE_DOCUMENTS_LIST;
  payload: any;
}

export interface MergeInvoiceDocumentsListAction {
  type: typeof MERGE_INVOICE_DOCUMENTS_LIST;
  payload: any;
}

export type InvoiceActions =
  | SetInvoiceAction
  | InvoicesResetAction
  | SetSelectedInvoiceAction
  | SetSelectedInvoiceStockItemsAction
  | ShowCreateModal
  | SetBeError
  | SetLoadingAction
  | SetSelectedInvoiceDocumentsListAction
  | MergeInvoiceDocumentsListAction;
