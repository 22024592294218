import { SET_BE_ERROR, SET_LOADING, SetBackendErrorAction, SetLoadingAction } from './types';

export const setLoading = (value: boolean): SetLoadingAction => ({
  type: SET_LOADING,
  payload: value
});

export const setBackendError = (value: boolean): SetBackendErrorAction => ({
  type: SET_BE_ERROR,
  payload: value
});
