import 'layout/modals/finances/addBill/AddBill.scss';
import 'layout/modals/viewEditBill/ViewEditBillForm.scss';

import { Col, Divider, Form, FormInstance, Row, Table, TableColumnProps } from 'antd';
import VLXInputNumber from 'components/VLXInputNumber';
import useClinicId from 'hooks/useClinicId';
import { useI18n } from 'hooks/usei18n';
import { useLocale } from 'hooks/useLocale';
import { measurementsLocale } from 'i18n/measurements';
import { packagesLocale } from 'i18n/packages';
import { IClient } from 'interfaces/client';
import { ISaleStockItem } from 'interfaces/sales';
import ClientCard from 'pages/appointments/components/ClientCard';
import React, { useEffect, useMemo } from 'react';
import { IBillDetails } from 'services/clinic/bills/models/IBillDetails';

type Props = React.PropsWithChildren<{
  form: FormInstance;
  initialValues: IBillDetails;
  clientDetails: IClient;
  renderTableOnly?: boolean;
}>;

const BillsDetailsSalesSection = ({
  form,
  initialValues,
  children,
  clientDetails,
  renderTableOnly
}: Props): JSX.Element => {
  const clinicId = useClinicId();
  const locale = useLocale('private.finances.bills').labels;
  const currencies = useLocale('private.currencies').labels;
  const measurements = useI18n(measurementsLocale);
  const packages = useI18n(packagesLocale);

  const stockTotalPrice = useMemo(
    () =>
      (Number(initialValues?.amount) - Number(initialValues?.stock_discount?.amount)).toFixed(2) ||
      0,
    [initialValues]
  );

  useEffect(() => {
    form.resetFields();
  }, []);

  const getSalePrice = (e) => {
    return e?.quantity_in_packages
      ? e?.stock_price?.price_per_package
      : e?.stock_price?.price_per_unit;
  };

  const getSaleQuantity = (e) => {
    return e?.quantity_in_packages ? e?.quantity_in_packages : e?.quantity_in_units;
  };

  const getSaleUnitName = (e) => {
    return e?.quantity_in_packages
      ? packages[e?.packaging_format]
      : measurements[e?.unit_of_measurement];
  };

  const columns: TableColumnProps<ISaleStockItem>[] = [
    {
      key: 'name',
      title: locale.stockName,
      render: (e) => `${e.name}${e.manufacturer ? ` (${e.manufacturer})` : ''}`
    },
    {
      key: 'itemQuantity',
      title: locale.qtyItem,
      render: getSaleUnitName
    },
    {
      key: 'price',
      title: locale.price,
      render: (e) => `${getSalePrice(e)} ${currencies.uah}`
    },
    {
      key: 'quantity',
      title: locale.qty,
      render: getSaleQuantity
    },
    {
      key: 'sum',
      title: locale.sum,
      render: (e) => `${e?.price} ${currencies.uah}`
    }
  ];

  return (
    <>
      <ClientCard clientDetails={clientDetails} clinicId={clinicId}>
        <div style={{ margin: '40px 0' }}>
          <Table
            rowKey='id'
            columns={columns}
            dataSource={initialValues?.sale?.stock}
            pagination={false}
            className='simple-view-table'
          />
        </div>
        {!renderTableOnly && (
          <Form autoComplete='off' layout='vertical' form={form} disabled>
            <Row
              justify={'space-between'}
              style={{
                width: '100%',
                display: 'flex',
                flexDirection: 'column'
              }}
            >
              {initialValues?.amount > 0 && (
                <>
                  <Row justify={'space-between'}>
                    <Col
                      sm={24}
                      md={12}
                      style={{
                        display: 'flex',
                        flexDirection: 'row'
                      }}
                    >
                      <Col sm={10} md={10} style={{ marginRight: '10px' }}>
                        <Form.Item
                          name={['stock_discount', 'percentage']}
                          label={`${locale.discount} (%)`}
                          initialValue={Number(initialValues?.stock_discount?.percentage)}
                        >
                          <VLXInputNumber id='stock_discount_percentage' max={100} />
                        </Form.Item>
                      </Col>
                      <Col sm={10} md={10}>
                        <Form.Item
                          name={['stock_discount', 'amount']}
                          label={`${locale.discount} (${currencies.uah})`}
                          initialValue={Number(initialValues?.stock_discount?.amount).toFixed(2)}
                        >
                          <VLXInputNumber
                            id='stock_discount_amount'
                            max={Number(initialValues?.amount)?.toFixed(2) || 0}
                          />
                        </Form.Item>
                      </Col>
                    </Col>
                    <Col sm={24} md={12} className='service-price-container'>
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                          gap: '16px'
                        }}
                      >
                        <div
                          style={{
                            display: 'flex',
                            justifyContent: 'flex-end',
                            alignItems: 'center'
                          }}
                        >
                          <div
                            style={{
                              textAlign: 'right',
                              flex: 1,
                              fontWeight: '600',
                              fontSize: '16px'
                            }}
                          >
                            {locale.sum}:
                          </div>
                          <span
                            id='stock-price'
                            style={{
                              fontWeight: 600,
                              fontSize: '16px',
                              color: '#000',
                              textAlign: 'right',
                              marginLeft: '8px',
                              width: '30%'
                            }}
                          >{`${Number(initialValues?.amount).toFixed(2) || 0} ${
                            currencies.uah
                          }`}</span>
                        </div>
                        <div
                          style={{
                            display: 'flex',
                            justifyContent: 'flex-end',
                            alignItems: 'center'
                          }}
                        >
                          <div
                            style={{
                              textAlign: 'right',
                              flex: 1,
                              fontWeight: '600',
                              fontSize: '16px'
                            }}
                          >
                            {locale.sumWithDiscount}:
                          </div>
                          <span
                            id='total-stock-price'
                            style={{
                              fontWeight: 600,
                              fontSize: '16px',
                              color: '#000',
                              textAlign: 'right',
                              marginLeft: '8px',
                              width: '30%'
                            }}
                          >{`${stockTotalPrice} ${currencies.uah}`}</span>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </>
              )}
            </Row>
            <Divider />
            {children}
          </Form>
        )}
      </ClientCard>
    </>
  );
};

export default BillsDetailsSalesSection;
