import baseClient from '../../../../baseClient';
import { AxiosResponse } from 'axios';
import { ShowInventoryItemResponse } from './models/showInventoryItemResponse';
import { IndexInventoryItemsResponse } from './models/indexInventoryItemsResponse';
import {
  InvoiceStockItemCreateUpdateRequest
} from '../../../../interfaces/clinics/inventory/invoice/InvoiceStockItemCreateUpdateRequest';

function invoiceUrl (clinic_id, invoice_id) {
  return `/v1/clinics/${clinic_id}/invoices/${invoice_id}`;
}

export const assignItemToInvoice = async (
  clinicId: number,
  invoiceId: number,
  item_id: number
) =>
  baseClient
    .post(`${invoiceUrl(clinicId, invoiceId)}/items/${item_id}`, '')
    .then((response: any) => {
      return response.data;
    });

export const unassignItemFromInvoice = async (
  clinicId: number,
  invoiceId: number,
  item_id: number
) =>
  baseClient
    .delete(`${invoiceUrl(clinicId, invoiceId)}/items/${item_id}`)
    .then((response: any) => {
      return response.data;
    });

export const indexInvoiceInventoryItems = async (
  clinicId: number,
  invoice_id: number
) =>
  baseClient
    .get(`${invoiceUrl(clinicId, invoice_id)}/items`)
    .then((response: AxiosResponse<IndexInventoryItemsResponse>) => {
      return response.data;
    });

export const createInventoryItemForInvoice = async (
  clinicId: number,
  invoiceId: number,
  stockId: number,
  data: InvoiceStockItemCreateUpdateRequest
) =>
  baseClient
    .post(`${invoiceUrl(clinicId, invoiceId)}/stock/${stockId}/items`, data)
    .then((response: AxiosResponse<ShowInventoryItemResponse>) => {
      return response.data;
    });
