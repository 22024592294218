import { AxiosResponse } from 'axios';
import { IReassignLocationsRequest } from 'services/interfaces/clinics/inventory/stock/IReassignLocationsRequest';
import { IReassignPricesRequest } from 'services/interfaces/clinics/inventory/stock/IReassignPricesRequest';
import { ISelectedStock } from 'services/interfaces/clinics/inventory/stock/ISelectedStock';
import { IStockItemCreateEditRequest } from 'services/interfaces/clinics/inventory/stock/IStockItemCreateEditRequest';
import { ISortObject } from 'services/interfaces/ISortObject';

import baseClient from '../../baseClient';
import { generateSearchQuery, sorting } from '../../helpers/commonUtils';
import { StockIndexResponse } from '../../interfaces/clinics/inventory/stock/StockIndexResponse';
import { StockItemsShowResponse } from '../../interfaces/clinics/inventory/stock/StockItemsShowResponse';

const stockUrl = (clinicId: number): string => {
  return `/v1/clinics/${clinicId}/stock`;
};

export const getClinicStock = async (
  clinicId: number,
  page: number,
  searchArray?: any[],
  pageSize?: any,
  sortObject?: ISortObject
) =>
  baseClient
    .get(
      `${stockUrl(clinicId)}?page=${page}${
        pageSize ? `&items=${pageSize}` : ''
      }${generateSearchQuery(searchArray)}${sorting(sortObject)}`
    )
    .then((response: AxiosResponse<StockIndexResponse>) => response.data);

export const getClinicStockStats = async (clinicId: number) =>
  baseClient
    .get(`/v1/clinics/${clinicId}/stock_stats`)
    .then((response: AxiosResponse<StockIndexResponse>) => response.data);

export const getClinicStockById = async (clinicId: number, stockId: number) =>
  baseClient
    .get(`${stockUrl(clinicId)}/${stockId}`)
    .then((response: AxiosResponse<ISelectedStock>) => response.data);

export const getClinicStockItemsById = async (clinicId: number, stockId: number, page: number) =>
  baseClient
    .get(`${stockUrl(clinicId)}/${stockId}/items?page=${page}`)
    .then((response: AxiosResponse<StockItemsShowResponse>) => response.data);

export const createClinicStock = async (clinicId: number, data: IStockItemCreateEditRequest) =>
  baseClient
    .post(`${stockUrl(clinicId)}`, data)
    .then((response: AxiosResponse<ISelectedStock>) => response.data);

export const updateClinicStock = async (
  clinicId: number,
  stockId: number,
  data: IStockItemCreateEditRequest
) =>
  baseClient
    .put(`${stockUrl(clinicId)}/${stockId}`, data)
    .then((response: AxiosResponse<ISelectedStock>) => response.data);

export const removeClinicStock = async (clinicId: number, stockId: number) =>
  baseClient
    .delete(`${stockUrl(clinicId)}/${stockId}`)
    .then((response: AxiosResponse<ISelectedStock>) => response.data);

export const removeClinicStockItem = async (clinicId: number, stockId: number, itemId: number) =>
  baseClient
    .delete(`${stockUrl(clinicId)}/${stockId}/items/${itemId}`)
    .then((response: AxiosResponse<void>) => response.data);

export const updateClinicStockItem = async (
  clinicId: number,
  stockId: number,
  data: IStockItemCreateEditRequest,
  itemId: number
) =>
  baseClient
    .put(`${stockUrl(clinicId)}/${stockId}/items/${itemId}`, data)
    .then((response: AxiosResponse<ISelectedStock>) => response.data);

export const createClinicStockItem = async (
  clinicId: number,
  data: IStockItemCreateEditRequest,
  stockId: number
) =>
  baseClient
    .post(`${stockUrl(clinicId)}/${stockId}/items`, data)
    .then((response: AxiosResponse<ISelectedStock>) => response.data);

export const reassignLocationsRequest = async (
  clinicId: number,
  stockId: number,
  payload: IReassignLocationsRequest
): Promise<AxiosResponse<void>> =>
  baseClient.post(`${stockUrl(clinicId)}/${stockId}/locations`, payload);

export const reassignPricesRequest = async (
  clinicId: number,
  stockId: number,
  payload: IReassignPricesRequest
): Promise<AxiosResponse<void>> =>
  baseClient.post(`${stockUrl(clinicId)}/${stockId}/prices`, payload);
