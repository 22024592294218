export const stockStatuses = {
  ukUA: {
    in_stock: 'В наявності',
    critical_level: 'Закінчується',
    out_of_stock: 'Закінчився'
  },
  enGB: {
    in_stock: 'In stock',
    critical_level: 'Running out',
    out_of_stock: 'Out of stock'
  }
};
