import ConfirmCancelModal from 'components/ConfirmCancelModal';
import StickyButton from 'components/StickyButton';
import ViewDetailsButtonsGroup from 'components/ViewDetailsButtonsGroup';
import VLXModal from 'components/VLXModal';
import { useLocale } from 'hooks/useLocale';
import { useUserAccess } from 'hooks/useUserAccess';
import { IAppState } from 'interfaces/app-state';
import { IPatientsList } from 'interfaces/patient';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { showCreateModal } from 'redux/patients/actions';

import PatientForm from './ViewAndEditPatientForm';

const AddPatient = ({
  hideDefaultCreatePetFormBtn,
  initialValues,
  form,
  getClientPetsAfterSuccess,
  callBackAfterCreate,
  disableOwnerSelect
}: any): JSX.Element => {
  const dispatch = useDispatch();
  const patients = useSelector<IAppState, IPatientsList>(({ patients }) => patients);
  const [showCancelModal, setShowCancelModal] = useState<boolean>(false);
  const userAccess = useUserAccess();
  const locale = useLocale('private.patients.patient-page');

  const onConfirmCancelClick = () => {
    form.resetFields();
    dispatch(showCreateModal(false));
    setShowCancelModal(false);
  };

  return (
    <>
      {!hideDefaultCreatePetFormBtn && (
        <StickyButton
          offsetBottom={10}
          onClick={() => dispatch(showCreateModal(true))}
          iconElement={
            <span
              className='icofont icofont-plus mr-2 sticky-btn-icon'
              style={{ fontSize: '1.3em' }}
            />
          }
          textElement={locale.buttons.addPatient}
        />
      )}
      <VLXModal
        title={locale.labels.createNewPatient}
        centered={true}
        form={form}
        open={patients.showCreateModal}
        onModalClose={() => dispatch(showCreateModal(false))}
        width={800}
      >
        {patients.showCreateModal && (
          <PatientForm
            disableOwnerSelect={disableOwnerSelect}
            form={form}
            initialValues={initialValues}
            getClientPetsAfterSuccess={getClientPetsAfterSuccess}
            callBackAfterCreate={callBackAfterCreate}
          >
            <ViewDetailsButtonsGroup
              fixed
              accessGroup={userAccess.accesses.patients}
              editMode={true}
              hideDeleteBtn={true}
              onCancelClick={() => {
                if (form.isFieldsTouched()) {
                  setShowCancelModal(true);
                } else {
                  dispatch(showCreateModal(false));
                }
              }}
              onDeleteClick={() => {
                setShowCancelModal(false);
              }}
            />
          </PatientForm>
        )}
        <ConfirmCancelModal
          open={showCancelModal}
          onOk={onConfirmCancelClick}
          onCancel={() => setShowCancelModal(false)}
        />
      </VLXModal>
    </>
  );
};

export default AddPatient;
