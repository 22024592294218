import { Button, Checkbox, Col, Form, FormInstance, FormItemProps, Row } from 'antd';
import VLXInputNumber from 'components/VLXInputNumber';
import { measurementsLocale } from 'i18n/measurements';
import { IAppState } from 'interfaces/app-state';
import { ILocale } from 'interfaces/locale';
import { IService } from 'interfaces/services';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { StockSelect } from '../../components/stockSelect/StockSelect.tsx';
import useClinicId from '../../../hooks/useClinicId.tsx';

interface Props {
  key: number;
  name: number;
  locale: ILocale;
  remove: (name: number) => void;
  onFieldsChange: (e: any) => void;
  formEditMode: boolean;
  form: FormInstance;
  initialValues: IService;
  restField: FormItemProps<string[]>;
}

export const AddServiceFormStockListItem = ({ key, restField, locale, form, initialValues, formEditMode,
  onFieldsChange, remove, name }: Props) =>{
  const localeCode = useSelector(({ localeCode }: IAppState) => localeCode);
  const clinicId = useClinicId();
  const stock = useSelector<IAppState, any>((state) => state.stock.data);
  const [selectedStockId, setSelectedStockId] = useState<number>(null);

  useEffect(() => {
    const stock = form.getFieldValue('stock')[name];
    if (stock?.id) {
      setSelectedStockId(stock.id);
    }
  }, []);

  const getStockMeasureLabel = (key: number): string => {
    const stockValues = form.getFieldValue('stock');
    if (stockValues[name]?.id) {
      let res = stock.find((el) => el.id === stockValues[key].id);
      return measurementsLocale[localeCode][res?.unit_of_measurement];
    }
    return '';
  };

  const [stockLabel, setStockLabel] = useState(getStockMeasureLabel(name));

  const onStockSelect = (id: number) => {
    let res = stock.find((el) => el.id === id);
    setSelectedStockId(res?.id);
    setStockLabel(measurementsLocale[localeCode][res?.unit_of_measurement]);
  };

  return <Row key={key} className='add-service-form-stock-container__row'>
    <Col span={24} md={10}>
      <Form.Item
        {...restField}
        className='add-service-form-stock__name long-value-form-item'
        label={locale.labels.name}
        name={[name, 'id']}
      >
        <StockSelect
          trackOutOfStock={false}
          onChange={onStockSelect}
          selectedStockId={selectedStockId}
          clinicId={clinicId}
          locale={locale} />
      </Form.Item>
    </Col>
    <Col span={24} md={5} className='measurement-quantity-container'>
      <Form.Item
        {...restField}
        name={[name, 'quantity']}
        label={locale.labels.quantity}
      >
        <VLXInputNumber precision={3} />
      </Form.Item>
      <span className='measurement-label'>{stockLabel}</span>
    </Col>
    <Col className='included-in-price-container'>
      <Form.Item
        {...restField}
        name={[name, 'included_in_price']}
        valuePropName='checked'
        initialValue={
          initialValues?.included_in_price
            ? initialValues.included_in_price
            : true
        }
      >
        <Checkbox defaultChecked>{locale.labels.includedInPrice}</Checkbox>
      </Form.Item>
    </Col>
    <Col span={1} md={1}>
      <Button
        onClick={() => {
          remove(name);
          formEditMode && onFieldsChange([{ deleteAction: 'deleteAction' }]);
        }}
        danger
        type='primary'
        shape={'circle'}
        className={'inventory-update-buttons'}
      >
        <span className='icofont icofont-trash' />
      </Button>
    </Col>
  </Row>;
};
