import {
  GET_PERMISSIONS,
  SET_LOADING,
  SetLoadingAction,
  GetPermissionsAction,
  PermissionsResetAction,
  RESET
} from './types';

import { getClinicPermissions } from '../../services/clinic/clinicsService';
import { IPermissionsList } from '../../interfaces/permission';

export const setPermissions = (data: IPermissionsList): GetPermissionsAction => ({
  type: GET_PERMISSIONS,
  payload: data
});

export const setLoading = (value: boolean): SetLoadingAction => ({
  type: SET_LOADING,
  payload: value
});

export const resetPermissions = (): PermissionsResetAction => ({
  type: RESET
});

export const getPermissions = (clinicId: number): any => {
  return (dispatch) => {
    dispatch(setLoading(true));
    getClinicPermissions(clinicId)
      .then((data) => {
        dispatch(setPermissions(data));
      })
      .catch((err) => console.log(err))
      .finally(dispatch(setLoading(false)));
  };
};
