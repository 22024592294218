import React from 'react';
import { PATTERNS } from 'utils/form-rules';

export const getSocialMediaData = (type: string): typeof socialMediaLinks[0] =>
  socialMediaLinks.find((media) => media.value === type);

export const getIcon = (type: string, size: number): JSX.Element => (
  <span className={`icofont-${type}`} style={{ fontSize: `${size}px`, marginLeft: 6 }} />
);

export const parseSocialLink = (link: string): string => {
  return PATTERNS.SOCIAL_LINK.exec(link)?.[2];
};

export const socialMediaLinks = [
  {
    key: 'facebook',
    value: 'Facebook',
    baseUrl: 'https://www.facebook.com/',
    icon: getIcon('facebook', 30)
  },
  {
    key: 'instagram',
    value: 'Instagram',
    baseUrl: 'https://www.instagram.com/',
    icon: getIcon('instagram', 30)
  },
  {
    key: 'x',
    value: 'X',
    baseUrl: 'https://x.com/',
    icon: getIcon('x', 30)
  },
  {
    key: 'linkedin',
    value: 'LinkedIn',
    baseUrl: 'https://www.linkedin.com/in/',
    icon: getIcon('linkedin', 30)
  },
  {
    key: 'telegram',
    value: 'Telegram',
    baseUrl: 'https://t.me/',
    icon: getIcon('telegram', 30)
  },
  {
    key: 'whatsapp',
    value: 'WhatsApp',
    baseUrl: 'https://wa.me/',
    icon: getIcon('whatsapp', 30)
  },
  {
    key: 'viber',
    value: 'Viber',
    baseUrl: 'viber://chat?number=',
    icon: getIcon('viber', 30)
  },
  {
    key: 'web',
    value: 'Website',
    baseUrl: 'https://',
    icon: getIcon('web', 30)
  },
  {
    key: 'other',
    value: 'Other',
    baseUrl: 'https://',
    icon: getIcon('page', 30)
  }
];
