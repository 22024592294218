import { Form } from 'antd';
import ConfirmCancelModal from 'components/ConfirmCancelModal';
import ViewDetailsButtonsGroup from 'components/ViewDetailsButtonsGroup';
import VLXModal from 'components/VLXModal';
import { useLocale } from 'hooks/useLocale';
import { useUserAccess } from 'hooks/useUserAccess';
import { IAppState } from 'interfaces/app-state';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { showCreateModal } from 'redux/inventory/invoice/actions';
import { InvoiceListState } from 'redux/inventory/invoice/reducer';

import AddInvoiceForm from './AddInvoiceForm';

const AddInvoiceModal = ({ clinicId }: { clinicId: number }): JSX.Element => {
  const dispatch = useDispatch();
  const invoices = useSelector<IAppState, InvoiceListState>(({ invoices }) => invoices);
  const [createInvoiceForm] = Form.useForm();
  const locale = useLocale('private.inventory.invoices');
  const [showCancelModal, setShowCancelModal] = useState<boolean>(false);
  const userAccess = useUserAccess();

  const onCancel = (): void => {
    dispatch(showCreateModal(false));
  };

  const onConfirmCancelClick = (): void => {
    createInvoiceForm.resetFields();
    dispatch(showCreateModal(false));
    setShowCancelModal(false);
  };

  return (
    <VLXModal
      form={createInvoiceForm}
      title={locale.labels.createInvoice}
      open={invoices.showCreateModal}
      onModalClose={onCancel}
      width={800}
    >
      <AddInvoiceForm form={createInvoiceForm} clinicId={clinicId}>
        <ViewDetailsButtonsGroup
          fixed
          accessGroup={userAccess.accesses.invoices}
          editMode={true}
          hideDeleteBtn={true}
          onCancelClick={() => {
            if (createInvoiceForm.isFieldsTouched()) {
              setShowCancelModal(true);
            } else {
              dispatch(showCreateModal(false));
            }
          }}
          onDeleteClick={() => {
            setShowCancelModal(false);
          }}
        />
      </AddInvoiceForm>
      <ConfirmCancelModal
        open={showCancelModal}
        onOk={onConfirmCancelClick}
        onCancel={() => setShowCancelModal(false)}
      />
    </VLXModal>
  );
};

export default AddInvoiceModal;
