import './scss/Provider.scss';

import { Button, Card, Col, Menu, MenuProps, Row, Spin } from 'antd';
import { DebtStatus } from 'components/DebtStatus';
import { TextEditorView } from 'components/TextEditorView';
import { useLocale } from 'hooks/useLocale';
import { useUserAccess } from 'hooks/useUserAccess';
import { IAppState } from 'interfaces/app-state';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, Params, useParams } from 'react-router-dom';
import {
  getProviderById,
  getProviders,
  setSelectedProviderInvoicesData,
  setShowEditProviderModal
} from 'redux/inventory/providers/actions';
import { ProvidersListState } from 'redux/inventory/providers/reducer';

import { EditProvider } from './modal/EditProvider';
import { ProviderHistoryTab } from './ProviderHistoryTab';

export const ProviderDetails = (): JSX.Element => {
  const dispatch = useDispatch();
  const { clinicId, providerId }: Readonly<Params<string>> = useParams();
  const userAccess = useUserAccess();
  const locale = useLocale('private.inventory.providers');

  const [currentTab, setCurrentTab] = useState('history');

  const { selectedProviderData } = useSelector<IAppState, ProvidersListState>(
    ({ providers }) => providers
  );
  const { loading } = useSelector<IAppState, ProvidersListState>(({ providers }) => providers);

  useEffect((): void => {
    if (clinicId) {
      dispatch(getProviders(Number(clinicId), 1));
    }
  }, [clinicId]);

  useEffect(() => {
    dispatch(getProviderById(Number(clinicId), Number(providerId)));

    return () => {
      dispatch(setSelectedProviderInvoicesData(null));
    };
  }, []);

  const items: MenuProps['items'] = [
    {
      label: locale.tabs.history,
      key: 'history'
    }
  ];

  const changeTab: MenuProps['onClick'] = (e) => {
    setCurrentTab(e.key);
  };

  const getParsedContacts = (list: string, key: string, linkTo: string): JSX.Element =>
    JSON.parse(list)?.map((item, index) => (
      <Row gutter={24} key={index} align='middle' style={{ paddingBottom: '10px' }}>
        <Col flex='0 1 100px'>
          <Link
            className='additional_contacts_number'
            to={`${linkTo}:${item?.country_code ? `+${item?.country_code}` : ''}${item?.[key]}`}
            style={{ fontSize: 15, marginRight: 20 }}
          >
            {item?.country_code ? `+${item?.country_code}` : ''}
            {item?.[key]}
          </Link>
        </Col>
        <Col flex='0 1 400px'>
          <span style={{ fontSize: 15 }}>{item?.description}</span>
        </Col>
      </Row>
    ));

  const ProviderDetail = ({
    icon,
    children
  }: React.PropsWithChildren<{ icon: string }>): JSX.Element => (
    <Row align='middle' className='provider-info-container__row'>
      <Col className='provider-info-container__col-left'>
        <span className={`icofont-${icon} info-icon`} />
      </Col>
      <Col className='provider-info-container__col-right' id='provider-phones'>
        {children}
      </Col>
    </Row>
  );

  return (
    <Spin spinning={loading}>
      {!!selectedProviderData && (
        <>
          <Col span={24} style={{ padding: '0 12px' }}>
            <Card className='ant-card-bordered personal-info-card with-shadow patient-info-container stock-details-info-container provider-info-container'>
              <ProviderDetail icon='briefcase-1'>
                <span style={{ marginRight: 15 }}>{selectedProviderData.name}</span>
                <DebtStatus debt={selectedProviderData?.debt} />
              </ProviderDetail>
              <ProviderDetail icon='ui-touch-phone'>
                {getParsedContacts(selectedProviderData.phone_numbers, 'phone_number', 'tel')}
              </ProviderDetail>
              <ProviderDetail icon='ui-message'>
                {getParsedContacts(selectedProviderData.emails, 'email', 'mailto')}
              </ProviderDetail>

              {selectedProviderData.address && (
                <ProviderDetail icon='location-pin'>{selectedProviderData.address}</ProviderDetail>
              )}

              {selectedProviderData.comments && (
                <ProviderDetail icon='comment'>
                  <div style={{ width: 'fit-content' }}>
                    <TextEditorView value={selectedProviderData.comments} />
                  </div>
                </ProviderDetail>
              )}

              <div className='provider-info-container__edit-button-container'>
                <Button
                  type='primary'
                  style={{ borderRadius: 30 }}
                  onClick={() => dispatch(setShowEditProviderModal(true))}
                >
                  <span
                    className='icofont icofont-edit-alt mr-2 sticky-btn-icon'
                    style={{ fontSize: '1.1em' }}
                  />
                  <span>{locale.buttons.edit}</span>
                </Button>
              </div>
            </Card>
          </Col>
          <Col span={24}>
            <Card className='ant-card-bordered personal-info-card with-shadow'>
              <Menu
                onClick={changeTab}
                selectedKeys={[currentTab]}
                mode='horizontal'
                items={items}
                style={{ marginBottom: 20 }}
              />
              {currentTab === 'history' && <ProviderHistoryTab />}
            </Card>
          </Col>
        </>
      )}
      {userAccess.accesses.providers.edit && <EditProvider initialValues={selectedProviderData} />}
    </Spin>
  );
};
