import '../scss/StockDetails.scss';

import { Col, Row } from 'antd';
import { TextEditorView } from 'components/TextEditorView';
import { Title } from 'components/Title';
import { useI18n } from 'hooks/usei18n';
import { measurementsLocale } from 'i18n/measurements';
import { packagesLocale } from 'i18n/packages';
import { ILocale } from 'interfaces/locale';
import React from 'react';
import { ISelectedStock } from 'services/interfaces/clinics/inventory/stock/ISelectedStock';

type Props = {
  selectedStock: ISelectedStock;
  locale: ILocale;
};

export const StockDetailsHeader = ({ selectedStock, locale }: Props): JSX.Element => {
  const unitOfMeasurement = useI18n(measurementsLocale)[selectedStock.unit_of_measurement] || '-';
  const packagingFormat = useI18n(packagesLocale)[selectedStock.packaging_format] || '-';

  return (
    <div style={{ borderBottom: '1px solid rgba(5, 5, 5, 0.06)', marginBottom: 20 }}>
      <div className='appointment-details-top-container' style={{ marginBottom: 10 }}>
        <Row>
          <Col span={24} md={12} className='appointment-details-top-container__left-column'>
            <Col>
              <Title title={`${locale.labels.manufacturer}: `} />
              <span id='manufacturer'>{selectedStock?.manufacturer || '-'}</span>
            </Col>
            {/*<Col>*/}
            {/*  <Title title={`${locale.labels.stockType}: `} />*/}
            {/*  <span id='stock-type'>{selectedStock?.type || '-'}</span>*/}
            {/*</Col>*/}
            {/*<Col>*/}
            {/*  <Title title={`${locale.labels.category}: `} />*/}
            {/*  <span id='stock-category'>{selectedStock?.category || '-'}</span>*/}
            {/*</Col>*/}
            {/*<Col>*/}
            {/*  <Title title={`${locale.labels.subCategory}: `} />*/}
            {/*  <span id='stock-sub-category'>{selectedStock?.sub_category || '-'}</span>*/}
            {/*</Col>*/}
            <Col>
              <Title title={`${locale.labels.uniqueCode}: `} />
              <span id='unique-code'>{selectedStock?.unique_code || '-'}</span>
            </Col>
            <Col>
              <Title title={`${locale.labels.barcode}: `} />
              <span id='barcode'>{selectedStock?.barcode || '-'}</span>
            </Col>
          </Col>
          <Col
            span={24}
            md={12}
            className='appointment-details-top-container__right-column'
            style={{ marginTop: 0 }}
          >
            <Col>
              {selectedStock.packaging_format === 'none' ? (
                <>
                  <Title title={`${locale.labels.remainingQuantity}: `} />
                  <span id='residual'>
                    {selectedStock?.residual} {unitOfMeasurement}
                  </span>
                </>
              ) : (
                <>
                  <Title title={`${locale.labels.remainingQuantity}: `} />
                  <span id='quantity'>{selectedStock?.quantity}</span> (
                  <span id='residual'>
                    {selectedStock?.residual} {unitOfMeasurement}
                  </span>
                  )
                </>
              )}
            </Col>
            <Col>
              <Title title={`${locale.labels.packagingFormat}: `} />
              <span id='packaging-format'>{packagingFormat}</span>
            </Col>
            {selectedStock.packaging_format !== 'none' && (
              <Col>
                <Title title={`${locale.labels.quantityPerPackage}: `} />
                <span id='quantity-per-package'>{selectedStock?.quantity_per_package || '-'} </span>
                <span id='measumenent-unit'>{unitOfMeasurement}</span>
              </Col>
            )}
            <Col>
              {selectedStock?.reorder_point !== null && (
                <>
                  <Title title={`${locale.labels.reorderPoint}: `} />
                  <span id='reorder-point'>{selectedStock?.reorder_point}</span>{' '}
                  <span id='measumenent-unit'>{unitOfMeasurement}</span>
                </>
              )}
            </Col>
          </Col>
        </Row>
        <Row>
          <Title title={`${locale.labels.description}: `} />
          <TextEditorView value={selectedStock?.description} id='description' />
        </Row>
        <Row>
          {selectedStock?.ingredients && (
            <>
              <Title title={`${locale.labels.ingredients}: `} />
              <TextEditorView value={selectedStock?.ingredients} id='ingredients' />
            </>
          )}
        </Row>
        <Row>
          <Title title={`${locale.labels.instructions}: `} />
          <TextEditorView value={selectedStock?.instructions} id='instructions' />
        </Row>
        <Row>
          <Title title={`${locale.labels.notes}: `} />
          <TextEditorView value={selectedStock?.notes} id='notes' />
        </Row>
      </div>
    </div>
  );
};
