import { eventsColors } from '../../constants/common';
import { IEmployeeDetails, IEmployeesList } from '../../interfaces/employee';
import {
  APPEND_EMPLOYEES,
  EmployeesActions,
  GET_EMPLOYEES,
  RESET,
  SET_EDIT_MODE,
  SET_LOADING,
  SET_SEARCH_QUERY,
  SET_SELECTED_EMPLOYEE
} from './types';

export interface EmployeesListState extends IEmployeesList {
  loading: boolean;
  selectedEmployee: IEmployeeDetails;
  editMode: boolean;
  searchQuery: any[];
}

const initialState: EmployeesListState = {
  data: [],
  metadata: {
    total: 0,
    page: 1,
    next: null,
    last: 1,
    prev: null
  },
  loading: false,
  selectedEmployee: null,
  editMode: false,
  searchQuery: []
};

export function employeesReducer(
  state: IEmployeesList = initialState,
  action: EmployeesActions
): any {
  switch (action.type) {
    case GET_EMPLOYEES: {
      let colorIndex = -1;

      action.payload.data.forEach((item) => {
        if (eventsColors[colorIndex]) {
          colorIndex += 1;
        } else {
          colorIndex = 0;
        }
        item.color = eventsColors[colorIndex];
      });
      return {
        ...state,
        ...action.payload
      };
    }

    case RESET: {
      return initialState;
    }

    case SET_LOADING: {
      return {
        ...state,
        loading: action.payload
      };
    }

    case SET_EDIT_MODE: {
      return {
        ...state,
        editMode: action.payload
      };
    }

    case SET_SELECTED_EMPLOYEE: {
      return {
        ...state,
        selectedEmployee: action.payload
      };
    }

    case APPEND_EMPLOYEES: {
      return {
        ...state,
        data: [...state.data, ...action.payload.data],
        metadata: action.payload.metadata
      };
    }

    case SET_SEARCH_QUERY: {
      return {
        ...state,
        searchQuery: action.payload
      };
    }

    default: {
      return state;
    }
  }
}
