import { AuditItemsHeadResponse } from 'services/interfaces/clinics/inventory/audit/AuditItemsHeadResponse';
import { IAuditList, IAuditListItem } from 'services/interfaces/clinics/inventory/audit/IAuditList';
import { ISelectedAuditItemsData } from 'services/interfaces/clinics/inventory/audit/ISelectedAuditItemsData';

export const SET_AUDIT = '[audit] Set';
export const SET_LOADING = '[audit] Loading';
export const SET_GET_REQUEST_LOADING = '[audit] Get Loading';
export const SHOW_CREATE_MODAL = '[audit] Create Modal';
export const SET_BE_ERROR = '[audit] BE Error';
export const SET_SELECTED_AUDIT = '[audit] Selected Device';
export const SET_SELECTED_AUDIT_ITEMS = '[audit] Selected Device items';
export const SET_HEAD_ITEMS = '[audit] Set Head Items';
export const RESET = '[audit] RESET';

export interface AuditsResetAction {
  type: typeof RESET;
}

export interface SetAuditAction {
  type: typeof SET_AUDIT;
  payload: IAuditList;
}

export interface SetSelectedAuditAction {
  type: typeof SET_SELECTED_AUDIT;
  payload: IAuditListItem;
}

export interface SetSelectedAuditItemsAction {
  type: typeof SET_SELECTED_AUDIT_ITEMS;
  payload: ISelectedAuditItemsData;
}

export interface SetHeadItemsAction {
  type: typeof SET_HEAD_ITEMS;
  payload: AuditItemsHeadResponse;
}

export interface SetLoadingAction {
  type: typeof SET_LOADING;
  payload: boolean;
}

export interface SetGetRequestLoadingAction {
  type: typeof SET_GET_REQUEST_LOADING;
  payload: boolean;
}

export interface ShowCreateModal {
  type: typeof SHOW_CREATE_MODAL;
  payload: boolean;
}

export interface SetBeError {
  type: typeof SET_BE_ERROR;
  payload: boolean;
}

export type AuditActions =
  | SetAuditAction
  | SetHeadItemsAction
  | AuditsResetAction
  | SetSelectedAuditAction
  | ShowCreateModal
  | SetBeError
  | SetLoadingAction
  | SetSelectedAuditItemsAction
  | SetGetRequestLoadingAction;
