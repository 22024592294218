import { Tag } from 'antd';
import { patientAggressiveness } from 'constants/dictionary/default/selectOptions';
import React from 'react';

export function aggressiveness(aggressiveness, localeCode: string): JSX.Element {
  const color = {
    friendly: 'green',
    reactive: 'yellow',
    'moderately-aggressive': 'red',
    'highly-aggressive': 'red'
  }[aggressiveness];

  const value = patientAggressiveness.find((el) => el.value === aggressiveness)[localeCode];

  return (
    <Tag id='patient-aggressiveness' color={color} style={{ marginLeft: 5, fontWeight: 'bold' }}>
      {value}
    </Tag>
  );
}
