import {
  DiagnosesResetAction,
  GET_DIAGNOSES,
  GetDiagnosesAction,
  RESET,
  SET_LOADING,
  SET_SEARCH_QUERY,
  SetLoadingAction,
  SetSearchQueryAction,
  SHOW_MODAL,
  ShowModalAction
} from './types';
import {
  IndexDictionaryRecordsResponse
} from '../../../services/clinic/dictionaries/models/IndexDictionaryRecordsResponse';
import {
  createClinicDiagnosis,
  destroyClinicDiagnosis,
  indexClinicDiagnoses,
  updateClinicDiagnosis
} from '../../../services/clinic/dictionaries/diagnosesService';
import {
  CreateDictionaryRecordRequest
} from '../../../services/clinic/dictionaries/models/CreateDictionaryRecordRequest';
import { ISearchCriteria } from '../../../interfaces/search-criteria.ts';

export const setDiagnoses = (diagnoses: IndexDictionaryRecordsResponse): GetDiagnosesAction => ({
  type: GET_DIAGNOSES,
  payload: diagnoses
});

export const resetDiagnoses = (): DiagnosesResetAction => ({
  type: RESET
});

export const showModal = (value: boolean): ShowModalAction => ({
  type: SHOW_MODAL,
  payload: value
});

export const setLoading = (value: boolean): SetLoadingAction => ({
  type: SET_LOADING,
  payload: value
});

export const setSearchQuery = (data: any[]): SetSearchQueryAction => ({
  type: SET_SEARCH_QUERY,
  payload: data
});

export const indexDiagnoses = (
  clinicId: number,
  page: number = 1,
  searchValue?: ISearchCriteria[],
  callBackFnc?: Function,
  pageSize?: number): any => {
  return dispatch => {
    dispatch(setLoading(true));
    indexClinicDiagnoses(clinicId, page, searchValue, pageSize)
      .then(data => {
        if (callBackFnc) {
          callBackFnc();
        }
        dispatch(setDiagnoses(data));
        dispatch(setLoading(false));
        dispatch(setSearchQuery(searchValue));
      })
      .catch(() => dispatch(setLoading(false)));
  };
};

export const createDiagnosis = (
  clinicId: number,
  data: CreateDictionaryRecordRequest,
  page = null,
  searchArray?: ISearchCriteria[]): any => {
  return dispatch => {
    dispatch(setLoading(true));
    createClinicDiagnosis(clinicId, data)
      .then(data => {
        dispatch(indexDiagnoses(clinicId, page, searchArray));
        dispatch(setLoading(false));
      })
      .catch(() => dispatch(setLoading(false)));
  };
};

export const updateDiagnosis = (
  clinicId: number,
  diagnosisId: number,
  data: CreateDictionaryRecordRequest,
  page: number = null,
  searchArray?: ISearchCriteria[]): any => {
  return dispatch => {
    dispatch(setLoading(true));
    updateClinicDiagnosis(clinicId, diagnosisId, data)
      .then(data => {
        dispatch(indexDiagnoses(clinicId, page, searchArray));
        dispatch(setLoading(false));
      })
      .catch(() => dispatch(setLoading(false)));
  };
};

export const destroyDiagnosis = (
  clinicId: number,
  diagnosisId: number,
  page: number = null,
  searchArray?: ISearchCriteria[]): any => {
  return dispatch => {
    dispatch(setLoading(true));
    destroyClinicDiagnosis(clinicId, diagnosisId)
      .then(data => {
        dispatch(indexDiagnoses(clinicId, page, searchArray));
        dispatch(setLoading(false));
      })
      .catch(() => dispatch(setLoading(false)));
  };
};
