export function scrollToTop(): void {
  window.scrollTo({
    top: 0
  });
}

export function money(value: string | number): string {
  if (isNaN(+value)) return '-';

  return Number(value).toFixed(2);
}

export function weight(value: number): string {
  return value.toFixed(3);
}
