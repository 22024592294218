import ConfirmCancelModal from 'components/ConfirmCancelModal';
import ConfirmDeleteModal from 'components/ConfirmDeleteModal';
import ViewDetailsButtonsGroup from 'components/ViewDetailsButtonsGroup';
import VLXModal from 'components/VLXModal';
import { useUserAccess } from 'hooks/useUserAccess';
import { IAppState } from 'interfaces/app-state';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { removeBill, showModal } from 'redux/finances/bills/actions';

import AddBillForm from './AddBillForm';
import EditBillFormAppointmentView from './EditBillFormAppointmentView';

const EditBill = ({ initialValues, form, locale }: any): JSX.Element => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { clinicId, billId }: any = useParams();
  const userAccess = useUserAccess();

  const bills = useSelector(({ bills }: IAppState) => bills);

  const [showCancelModal, setShowCancelModal] = useState<boolean>(false);
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
  const [formIsTouched, setFormIsTouched] = useState(false);

  const onConfirmCancelClick = () => {
    form.resetFields();
    dispatch(showModal(false));
    setShowCancelModal(false);
    setFormIsTouched(false);
  };

  const onSaveModalClick = () => {
    setFormIsTouched(false);
  };

  const onConfirmDeleteClick = () => {
    setShowDeleteModal(false);
    dispatch(showModal(false));
    dispatch(removeBill(clinicId, billId));
    navigate(`/clinic/${clinicId}/finances/bills`);
    setFormIsTouched(false);
  };

  const buttonsGroup = (): JSX.Element => {
    return (
      <ViewDetailsButtonsGroup
        fixed
        accessGroup={userAccess.accesses.services}
        onSaveModalClick={onSaveModalClick}
        editMode
        hideDeleteBtn={!initialValues?.can_be_destroyed}
        onCancelClick={() => {
          if (form.isFieldsTouched() || formIsTouched) {
            setShowCancelModal(true);
          } else {
            dispatch(showModal(false));
          }
        }}
        onDeleteClick={() => {
          setShowCancelModal(false);
          setShowDeleteModal(true);
        }}
      />
    );
  };

  return (
    <>
      {initialValues && (
        <VLXModal
          title={locale.labels.editBill}
          formIsTouched={formIsTouched}
          centered={true}
          form={form}
          open={bills.showModal}
          onModalClose={() => {
            dispatch(showModal(false));
            setFormIsTouched(false);
          }}
          width={800}
        >
          {initialValues?.appointment ? (
            <EditBillFormAppointmentView
              locale={locale}
              form={form}
              setFormIsTouched={setFormIsTouched}
              initialValues={initialValues}
              formEditMode
            >
              {buttonsGroup()}
            </EditBillFormAppointmentView>
          ) : (
            <AddBillForm
              locale={locale}
              form={form}
              setFormIsTouched={setFormIsTouched}
              initialValues={initialValues}
              formEditMode
            >
              {buttonsGroup()}
            </AddBillForm>
          )}
        </VLXModal>
      )}
      <ConfirmCancelModal
        open={showCancelModal}
        onOk={onConfirmCancelClick}
        onCancel={() => setShowCancelModal(false)}
      />
      <ConfirmDeleteModal
        type={'device'}
        open={showDeleteModal}
        onOk={onConfirmDeleteClick}
        onCancel={() => setShowDeleteModal(false)}
      />
    </>
  );
};

export default EditBill;
