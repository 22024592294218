import React from 'react';

const Loader = () => {
  return (
    <div className='app-loader main-loader'>
      <div className='loader-box'>
        <div className='bounceball'></div>
        <div className='text'>
          Vetlyx
        </div>
      </div>
    </div>
  );
};

export default Loader;
