import { IRole, IRolesList } from '../../interfaces/role';

export const GET_ROLES = '[roles] Set';
export const SET_ROLES_LOADING = '[roles] Loading';
export const SHOW_CREATE_MODAL = '[roles] Add';
export const SHOW_EDIT_MODAL = '[roles] Edit';
export const SET_BE_ERROR = '[roles] BackEndError';
export const SET_SELECTED_ROLE = '[roles] SelectedRole';
export const SET_SELECTED_ROLE_ID = '[roles] SelectedRoleId';
export const SHOW_ROLE_DETAILS_MODAL = '[roles] Details';
export const SET_EDIT_MODE = '[roles] EditMode';
export const RESET = '[roles] RESET';

export interface RolesResetAction {
  type: typeof RESET;
}

export interface GetRolesAction {
  type: typeof GET_ROLES;
  payload: IRolesList;
}

export interface SetLoadingAction {
  type: typeof SET_ROLES_LOADING;
  payload: boolean;
}

export interface ShowCreateModalAction {
  type: typeof SHOW_CREATE_MODAL;
  payload: boolean;
}

export interface ShowEditModalAction {
  type: typeof SHOW_EDIT_MODAL;
  payload: boolean;
}

export interface ShowRoleDetailsModalAction {
  type: typeof SHOW_ROLE_DETAILS_MODAL;
  payload: boolean;
}

export interface SetBackendErrorAction {
  type: typeof SET_BE_ERROR;
  payload: boolean;
}

export interface SetEditModeAction {
  type: typeof SET_EDIT_MODE;
  payload: boolean;
}

export interface SetSelectedRole {
  type: typeof SET_SELECTED_ROLE;
  payload: IRole;
}

export interface SetSelectedRoleId {
  type: typeof SET_SELECTED_ROLE_ID;
  payload: number;
}

export type RolesActions =
  | GetRolesAction
  | RolesResetAction
  | ShowCreateModalAction
  | ShowEditModalAction
  | ShowRoleDetailsModalAction
  | SetBackendErrorAction
  | SetSelectedRole
  | SetEditModeAction
  | SetSelectedRoleId
  | SetLoadingAction;
