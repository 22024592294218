import { ITranferAccount } from 'interfaces/transferAccount.ts';

import {
  createClinicAccount,
  destroyClinicAccount,
  indexClinicAccounts,
  showClinicAccount,
  showClinicAccountPayments,
  transferClinicAccounts,
  updateClinicAccount,
} from '../../../services/clinic/accounts/accountsService';
import { CreateUpdateAccountRequest } from '../../../services/clinic/accounts/models/CreateUpdateAccountRequest';
import { IndexAccountsResponse } from '../../../services/clinic/accounts/models/IndexAccountsResponse';
import { ShowAccountResponse } from '../../../services/clinic/accounts/models/ShowAccountResponse';
import { ISortObject } from '../../../services/interfaces/ISortObject.ts';
import {
  AccountsResetAction,
  RESET,
  SET_ACCOUNT,
  SET_ACCOUNT_PAYMENTS,
  SET_ACCOUNTS,
  SET_LOADING,
  SetAccountsAction,
  SetLoadingAction,
  SetSelectedAccountAction,
  SetSelectedAccountPaymentsAction,
  SHOW_EDIT_MODAL,
  SHOW_MODAL,
  SHOW_TRANSFER_MODAL,
  ShowEditModalAction,
  ShowModalAction,
  ShowTransferModalAction,
} from './types';

export const setLoading = (value: boolean): SetLoadingAction => ({
  type: SET_LOADING,
  payload: value
});

export const resetAccounts = (): AccountsResetAction => ({
  type: RESET
});

export const showModal = (value: boolean): ShowModalAction => ({
  type: SHOW_MODAL,
  payload: value
});

export const showEditModal = (value: boolean): ShowEditModalAction => ({
  type: SHOW_EDIT_MODAL,
  payload: value
});

export const toogleShowTransferModal = (value: boolean): ShowTransferModalAction => ({
  type: SHOW_TRANSFER_MODAL,
  payload: value
});

export const setAccounts = (accounts: IndexAccountsResponse): SetAccountsAction => ({
  type: SET_ACCOUNTS,
  payload: accounts
});

export const setSelectedAccount = (data: ShowAccountResponse | null): SetSelectedAccountAction => ({
  type: SET_ACCOUNT,
  payload: data
});

export const setSelectedAccountPayments = (data: ShowAccountResponse[] | null): SetSelectedAccountPaymentsAction => ({
  type: SET_ACCOUNT_PAYMENTS,
  payload: data
});

export const getAccounts = (clinicId: number, page?: number, searchValue?: any, sortObject?: ISortObject): any => {
  return dispatch => {
    dispatch(setLoading(true));
    indexClinicAccounts(clinicId, page, searchValue, sortObject)
      .then(data => {
        dispatch(setAccounts(data));
        dispatch(setLoading(false));
      })
      .catch(() => dispatch(setLoading(false)));
  };
};

export const getAccountsDetails = (clinicId: number, id: number): any => {
  return dispatch => {
    dispatch(setSelectedAccount(null));
    dispatch(setLoading(true));
    showClinicAccount(clinicId, id)
      .then(data => {
        dispatch(setSelectedAccount(data));
        dispatch(setLoading(false));
      })
      .catch(() => dispatch(setLoading(false)));
  };
};

export const getAccountsDetailsPayments = (clinicId: number, id: number, page?: number, searchValue?: any, sortObject?: ISortObject): any => {
  return dispatch => {
    dispatch(setLoading(true));
    showClinicAccountPayments(clinicId, id, page, searchValue, sortObject)
      .then(data => {
        dispatch(setSelectedAccountPayments(data));
        dispatch(setLoading(false));
      })
      .catch(() => dispatch(setLoading(false)));
  };
};

export const createAccount = (clinicId: number, data): any => {
  return dispatch => {
    dispatch(setLoading(true));
    createClinicAccount(clinicId, data)
      .then(() => {
        dispatch(getAccounts(clinicId));
        dispatch(showModal(false));
      })
      .catch(() => dispatch(setLoading(false)));
  };
};

export const updateAccount = (clinicId: number, accountId: number, data: CreateUpdateAccountRequest): any => {
  return dispatch => {
    dispatch(setLoading(true));
    updateClinicAccount(clinicId, accountId, data)
      .then(() => {
        dispatch(getAccountsDetails(clinicId, accountId));
        dispatch(showEditModal(false));
      })
      .catch(() => dispatch(setLoading(false)));
  };
};

export const removeAccount = (clinicId: number, id: number): any => {
  return dispatch => {
    dispatch(setLoading(true));
    destroyClinicAccount(clinicId, id)
      .then(() => {
        dispatch(setLoading(false));
      })
      .catch(() => dispatch(setLoading(false)));
  };
};

export const transferAccount = (clinicId: number, accountId: number, data: ITranferAccount, callbackFunc: () => void): any => {
  return dispatch => {
    dispatch(setLoading(true));
    transferClinicAccounts(clinicId, accountId, data)
      .then(() => {
        dispatch(getAccountsDetails(clinicId, accountId));
        dispatch(toogleShowTransferModal(false));
        if (callbackFunc) {
          callbackFunc();
        }
      })
      .catch(() => dispatch(setLoading(false)));
  };
};
