import {
  IndexDictionaryRecordsResponse
} from '../../../services/clinic/dictionaries/models/IndexDictionaryRecordsResponse';

export const GET_DIAGNOSES = '[diagnoses] Set';
export const SHOW_MODAL = '[diagnoses] SHOW_MODAL';
export const SET_LOADING = '[diagnoses] SET_LOADING';
export const SET_SEARCH_QUERY = '[diagnoses] SetSearchQuery';
export const RESET = '[diagnoses] RESET';

export interface DiagnosesResetAction {
  type: typeof RESET
}

export interface GetDiagnosesAction {
  type: typeof GET_DIAGNOSES
  payload: IndexDictionaryRecordsResponse
}

export interface ShowModalAction {
  type: typeof SHOW_MODAL
  payload: boolean
}

export interface SetLoadingAction {
  type: typeof SET_LOADING
  payload: boolean
}

export interface SetSearchQueryAction {
  type: typeof SET_SEARCH_QUERY
  payload: any[]
}

export type DiagnosesActions =
  | DiagnosesResetAction
  | GetDiagnosesAction
  | SetSearchQueryAction
  | ShowModalAction
  | SetLoadingAction;
