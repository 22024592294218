import { Col, DatePicker, Form, FormInstance, Select } from 'antd';
import { DATE_TIME_FORMAT } from 'constants/common';
import { vaccinationsReportOptions } from 'constants/dictionary/default/selectOptions';
import { Dayjs } from 'dayjs';
import useClinicId from 'hooks/useClinicId';
import useLocalizedList from 'hooks/useLocalizedList';
import { IAppState } from 'interfaces/app-state';
import { ILocale } from 'interfaces/locale';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { generateVaccinationReport } from 'redux/patients/actions';
import { requiredRule } from 'utils/form-rules';

type Props = {
  form: FormInstance;
  locale: ILocale;
  children: React.ReactNode;
};

type IVaccinationReport = {
  time_from: Dayjs;
  time_to: Dayjs;
  report_type: string;
};

const GenerateVaccinationReportForm = ({ form, children, locale }: Props) => {
  const dispatch = useDispatch();
  const clinicId = useClinicId();
  const commonState = useSelector<IAppState, any>((state) => state.common);

  useEffect(() => {
    if (!commonState.backendError) {
      form.resetFields();
    }
  }, []);

  const onSubmit = (data: IVaccinationReport) => {
    const searchArray = [];

    if (data.time_from) {
      const dateFrom = data.time_from.tz('utc').format(DATE_TIME_FORMAT);
      searchArray.push({
        name: 'time_from',
        value: dateFrom
      });
    }
    if (data.time_to) {
      const dateTo = data.time_to.tz('utc').format(DATE_TIME_FORMAT);
      searchArray.push({
        name: 'time_to',
        value: dateTo
      });
    }
    dispatch(generateVaccinationReport(clinicId, searchArray));
  };

  return (
    <Form onFinish={onSubmit} autoComplete='off' layout='vertical' form={form}>
      <Col>
        <Form.Item name='report_type' label={locale.labels.reportType}>
          <Select
            filterOption={false}
            options={useLocalizedList(vaccinationsReportOptions)}
            defaultValue='report'
          />
        </Form.Item>
        <Form.Item rules={[requiredRule()]} name='time_from' label={locale.labels.dateFrom}>
          <DatePicker />
        </Form.Item>
        <Form.Item rules={[requiredRule()]} name='time_to' label={locale.labels.dateTo}>
          <DatePicker />
        </Form.Item>
      </Col>
      {children}
    </Form>
  );
};

export default GenerateVaccinationReportForm;
