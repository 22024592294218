import { IPageData } from 'interfaces/page';

import { RESET_PAGE_DATA, ResetPageDataAction, SET_PAGE_DATA, SetPageDataAction } from './types';

export const setPageData = (data: IPageData): SetPageDataAction => ({
  type: SET_PAGE_DATA,
  payload: data
});

export const resetPageData = (): ResetPageDataAction => ({
  type: RESET_PAGE_DATA
});
