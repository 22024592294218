import './Pricing.scss';

import { Button, Card, Col, Form, Row, Typography } from 'antd';
import VLXModal from 'components/VLXModal';
import { useLocale } from 'hooks/useLocale';
import RegistrationRequestForm from 'layout/components/actions/RegistrationRequestForm';
import React, { useState } from 'react';

const PricingOption = ({included, title }): JSX.Element => (
    <Row>
        <i className={`icofont-${included ? 'tick-mark' : 'close'}`}></i>
        <Typography.Text>{title}</Typography.Text>
    </Row>
);

export const Pricing = (): JSX.Element => {
  const locale = useLocale('public.pricing');
  const currencies = useLocale('private.currencies').labels;
  const [registrationRequest] = Form.useForm();

  const [visible, changeVisible] = useState(false);

  const pricingOptions = [{
    key: 'card1',
    type: 'Basic',
    included: true,
    price: 1,
    options: [
        { included: true, title: locale.cards.administration },
        { included: true, title: locale.cards.appointmentSchedulingHistory },
        { included: true, title: locale.cards.clientProfiles },
        { included: true, title: locale.cards.patientProfilesHistory },
        { included: true, title: locale.cards.vaccinations },
        { included: false, title: locale.cards.documents },
        { included: false, title: locale.cards.inventoryManagement },
        { included: false, title: locale.cards.shop },
        { included: false, title: locale.cards.financialModule },
        { included: false, title: locale.cards.analyticsReporting }

    ]
  },
  {
    key: 'card2',
    type: 'Advanced',
    included: true,
    price: 350,
    options: [
        { included: true, title: locale.cards.administration },
        { included: true, title: locale.cards.appointmentSchedulingHistory },
        { included: true, title: locale.cards.clientProfiles },
        { included: true, title: locale.cards.patientProfilesHistory },
        { included: true, title: locale.cards.vaccinations },
        { included: true, title: locale.cards.documents },
        { included: true, title: locale.cards.inventoryManagement },
        { included: true, title: locale.cards.shop },
        { included: true, title: locale.cards.financialModule },
        { included: false, title: locale.cards.analyticsReporting }

    ]
  },
  {
    key: 'card3',
    type: 'Advanced+',
    included: true,
    price: 400,
    options: [
        { included: true, title: locale.cards.administration },
        { included: true, title: locale.cards.appointmentSchedulingHistory },
        { included: true, title: locale.cards.clientProfiles },
        { included: true, title: locale.cards.patientProfilesHistory },
        { included: true, title: locale.cards.vaccinations },
        { included: true, title: locale.cards.documents },
        { included: true, title: locale.cards.inventoryManagement },
        { included: true, title: locale.cards.shop },
        { included: true, title: locale.cards.financialModule },
        { included: true, title: locale.cards.analyticsReporting }

    ]
  }];

  return (
    <div className='pricing-page'>
        <Typography.Title level={3}>{locale.labels.plansAndPricing}</Typography.Title>
        <Row justify={'center'} align={'middle'} style={{ height: '100%', gap: 40 }}>
            {pricingOptions.map(el => <Card key={el.key} className='ant-card-bordered with-shadow pricing-page__card'>
                <Typography.Title level={3}>{el.type}</Typography.Title>
                <Typography.Title level={3} style={{ marginBottom: 0 }}>{el.price} {currencies.uah}</Typography.Title>
                <Typography.Text>{locale.labels.perUserPerMonths}</Typography.Text>
                <Col>
                    {el.options.map(item => PricingOption( {included: item.included, title: item.title} ))}
                    <Button type='primary' onClick={() => changeVisible(true)} style={{ marginTop: 20 }}>
                        {locale.labels.registrationRequest}
                    </Button>
                </Col>
            </Card>)}
        </Row>

        <VLXModal
            title={locale.labels.registrationRequest}
            form={registrationRequest}
            open={visible}
            onModalClose={() => changeVisible(false)}
        >
            <RegistrationRequestForm form={registrationRequest} closeModal={() => changeVisible(false)} />
        </VLXModal>
    </div>
  );
};
