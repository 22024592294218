import { useLocation, useParams } from 'react-router-dom';

export default function usePatientId (): number {
  const location = useLocation();
  const { patientId }: any = useParams();
  if (!location.pathname.includes('patients') && !patientId) {
    return null;
  }
  return Number(patientId) || Number(location.pathname.split('/')[4]);
}
