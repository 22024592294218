import React from 'react';
import useLocalizedList from '../../../hooks/useLocalizedList';
import { Select } from 'antd';
import { useSelector } from 'react-redux';
import { IAppState } from '../../../interfaces/app-state';
import { roomStatuses } from '../../../constants/dictionary/default/selectOptions';
import { IRoom } from '../../../interfaces/room';

interface Props {
  appointment?: boolean
  disabled?: boolean
  onChange?: () => void
}

const { Option } = Select;

export const RoomsSelect = ({ appointment = false, disabled = false, onChange }: Props): JSX.Element => {
  const rooms = useSelector(({ rooms }: IAppState) => rooms.data);
  const localizedRoomStatuses = useLocalizedList(roomStatuses);

  const getRoomStatus = (stat: string): string => {
    const res = localizedRoomStatuses.find((el) => el?.value === stat);
    return res?.label;
  };

  const roomsList = ():JSX.Element[] => {
    let roomsList: IRoom[] = rooms;
    if(appointment){
      roomsList = rooms?.filter((room: IRoom) => !room.not_for_appointment);
    }
    return roomsList?.map((item: IRoom) => {
        return (
          <Option key={`${item.name}${item.id}`} title={item.name} value={item.id}>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <span style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>{item.name}</span>
              <span style={{ color: 'grey', paddingLeft: 10 }}>{getRoomStatus(item?.status)}</span>
            </div>
          </Option>
        );
      });
  };

  return (
    <Select
      id='location-room'
      onChange={onChange}
      disabled={disabled}
      filterOption={(input, option) => {
        return option.key.toLowerCase().indexOf(input.toLowerCase()) >= 0;
      }}
      showSearch
    >
      {roomsList()}
    </Select>
  );
};
