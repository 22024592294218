import birdAvatar from 'assets/img/bird_avatar.svg';
import catAvatar from 'assets/img/cat_avatar.svg';
import dogAvatar from 'assets/img/dog_avatar.svg';
import pawAvatar from 'assets/img/dog_paw.svg';
import exoticAvatar from 'assets/img/exotic_avatar.svg';
import rodentAvatar from 'assets/img/rodent_avatar.svg';
import moment from 'moment';

export function getAnimalAge(locale, birthDate: string, deathDate?: string): string {
  if (!deathDate && !birthDate) return null;
  if (deathDate) {
    const deathDateFormatted = moment(deathDate).format('DD.MM.YYYY');
    const passedAwayOn = `${locale.labels.passedAwayOn} ${deathDateFormatted}`;
    if (!birthDate) {
      return passedAwayOn;
    } else {
      if (moment(deathDate).diff(birthDate, 'years') > 0) {
        return `${passedAwayOn} ${locale.labels.atAge} ${
          moment(deathDate).diff(birthDate, 'years') + locale.labels.y
        }`;
      } else if (moment(deathDate).diff(birthDate, 'month') > 0) {
        return `${passedAwayOn} ${locale.labels.atAge} ${
          moment(deathDate).diff(birthDate, 'month') + locale.labels.m
        }`;
      } else {
        return `${passedAwayOn} ${locale.labels.atAge} ${
          moment(deathDate).diff(birthDate, 'days') + locale.labels.d
        }`;
      }
    }
  }
  if (birthDate) {
    if (moment().diff(birthDate, 'years') > 0) {
      return moment().diff(birthDate, 'years') + locale.labels.y;
    } else if (moment().diff(birthDate, 'month') > 0) {
      return moment().diff(birthDate, 'month') + locale.labels.m;
    } else {
      return moment().diff(birthDate, 'days') + locale.labels.d;
    }
  }
}

export function getDefaultAvatar(animalSpecies: string): string {
  let imageSrc = '';

  switch (animalSpecies?.toLowerCase()) {
    case 'cat':
      imageSrc = catAvatar;
      break;
    case 'dog':
      imageSrc = dogAvatar;
      break;
    case 'rodent':
      imageSrc = rodentAvatar;
      break;
    case 'bird':
      imageSrc = birdAvatar;
      break;
    case 'exotic':
      imageSrc = exoticAvatar;
      break;
    default:
      imageSrc = pawAvatar;
  }

  return imageSrc;
}
