import {
  RESET,
  SET_LOADING,
  SET_SKILLS,
  SetLoadingAction,
  SetSkillsAction, SkillsResetAction
} from './types';

import { ISkill } from '../../interfaces/skills';
import { createNewSkill, getSkills } from '../../services/clinic/users/skillsService';

export const setSkills = (data: ISkill[]): SetSkillsAction => ({
  type: SET_SKILLS,
  payload: data
});

export const resetSkills = (): SkillsResetAction => ({
  type: RESET
});

export const setLoading = (value: boolean): SetLoadingAction => ({
  type: SET_LOADING,
  payload: value
});

export const getSkillsAction = (page?, searchParams?): any => {
  return dispatch => {
    dispatch(setLoading(true));
    getSkills(page, searchParams)
      .then(data => {
        dispatch(setSkills(data));
        dispatch(setLoading(false));
      })
      .catch(() => dispatch(setLoading(false)));
  };
};

export const createSkill = (data: any): any => {
  return dispatch => {
    dispatch(setLoading(true));
    createNewSkill(data)
      .then(() => {
        dispatch(getSkills());
      })
      .catch(err => console.log(err))
      .finally(() => dispatch(setLoading(false)));
  };
};
