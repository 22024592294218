import { IDocumentDetails } from 'interfaces/document-details';
import {
  ShowInventoryItemResponse,
} from 'services/clinic/inventory/invoices/inventoryItems/models/showInventoryItemResponse';
import { IInvoiceData, IInvoiceDataItem } from 'services/interfaces/clinics/inventory/invoice/IInvoiceData';

import {
  InvoiceActions,
  MERGE_INVOICE_DOCUMENTS_LIST,
  RESET,
  SET_BE_ERROR,
  SET_INVOICE,
  SET_INVOICE_DOCUMENTS_LIST,
  SET_LOADING,
  SET_SELECTED_INVOICE,
  SET_SELECTED_INVOICE_STOCK_ITEMS,
  SHOW_CREATE_MODAL,
} from './types';

export interface InvoiceListState extends IInvoiceData {
  loading: boolean;
  showCreateModal: boolean;
  backendError: boolean;
  selectedInvoice: IInvoiceDataItem;
  selectedInvoiceStockItems: ShowInventoryItemResponse;
  selectedInvoiceDocuments: IDocumentDetails[];
}

const initialState: InvoiceListState = {
  data: [],
  metadata: {
    total: 0,
    page: 1,
    next: null,
    last: 1,
    prev: null
  },
  loading: false,
  showCreateModal: false,
  backendError: false,
  selectedInvoice: null,
  selectedInvoiceStockItems: null,
  selectedInvoiceDocuments: []
};

export function invoiceReducer(state: InvoiceListState = initialState, action: InvoiceActions): any {
  switch (action.type) {
    case SET_INVOICE: {
      return {
        ...state,
        ...action.payload
      };
    }

    case RESET: {
      return initialState;
    }

    case SET_SELECTED_INVOICE: {
      return {
        ...state,
        selectedInvoice: action.payload
      };
    }

    case SET_SELECTED_INVOICE_STOCK_ITEMS: {
      return {
        ...state,
        selectedInvoiceStockItems: action.payload
      };
    }

    case SET_LOADING: {
      return {
        ...state,
        loading: action.payload
      };
    }

    case SHOW_CREATE_MODAL: {
      return {
        ...state,
        showCreateModal: action.payload
      };
    }

    case SET_BE_ERROR: {
      return {
        ...state,
        backendError: action.payload
      };
    }

    case SET_INVOICE_DOCUMENTS_LIST: {
      return {
        ...state,
        selectedInvoiceDocuments: action.payload
      };
    }

    case MERGE_INVOICE_DOCUMENTS_LIST: {
      return {
        ...state,
        selectedInvoiceDocuments: [action.payload, ...state.selectedInvoiceDocuments]
      };
    }

    default: {
      return state;
    }
  }
}
