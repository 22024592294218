import { IAppSettings } from '../../interfaces/settings';
import { DEFAULT_SETTINGS } from './settings';
import { RESET_SETTINGS, SET_SETTINGS, SettingsActionTypes, TOGGLE_SIDEBAR, UPDATE_SETTINGS } from './types';

const initialState: IAppSettings = DEFAULT_SETTINGS;

export const settingsReducer = (
  state: IAppSettings = initialState,
  action: SettingsActionTypes
): IAppSettings => {
  switch (action.type) {
    case SET_SETTINGS:
      return { ...action.payload };
    case UPDATE_SETTINGS:
      return { ...state, ...action.payload };
    case RESET_SETTINGS:
      return { ...initialState };
    case TOGGLE_SIDEBAR:
      return { ...state, sidebarOpened: !state.sidebarOpened };
    default:
      return { ...state };
  }
};
