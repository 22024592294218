const animalColors = [
  {
    value: 'black',
    enGB: 'Black',
    ukUA: 'Чорний'
  },
  {
    value: 'white',
    enGB: 'White',
    ukUA: 'Білий'
  },
  {
    value: 'gray',
    enGB: 'Gray',
    ukUA: 'Сірий'
  },
  {
    value: 'orange',
    enGB: 'Orange/Red',
    ukUA: 'Рудий'
  },
  {
    value: 'cream',
    enGB: 'Cream',
    ukUA: 'Кремовий'
  },
  {
    value: 'brown',
    enGB: 'Brown',
    ukUA: 'Коричневий (Шоколадний)'
  },
  {
    value: 'tortoiseshell',
    enGB: 'Tortoiseshell',
    ukUA: 'Черепаховий'
  },
  {
    value: 'calico',
    enGB: 'Calico',
    ukUA: 'Каліко'
  },
  {
    value: 'tabby',
    enGB: 'Tabby',
    ukUA: 'Таббі'
  },
  {
    value: 'bicolor',
    enGB: 'Bicolor',
    ukUA: 'Двоколірний'
  },
  {
    value: 'colorpoint',
    enGB: 'Colorpoint',
    ukUA: 'Колорпойнт'
  },
  {
    value: 'multi_color',
    enGB: 'Multi-color',
    ukUA: 'Багатоколірний'
  },
  {
    value: 'albino',
    enGB: 'Albino',
    ukUA: 'Альбінос'
  },
  {
    value: 'other',
    enGB: 'Other',
    ukUA: 'Інший'
  }
];

export default animalColors;
