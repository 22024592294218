import { Params, useParams } from 'react-router-dom';

export default function useClinicId(externalLocation?: any): number {
  const location = externalLocation || window.location;
  const { clinicId }: Params<string> = useParams();
  if (!location.pathname.includes('clinic') && !clinicId) {
    return null;
  }
  return Number(clinicId) || Number(location.pathname.split('/')[2]);
}
