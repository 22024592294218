import { Select } from 'antd';
import { IAppState } from 'interfaces/app-state';
import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';

interface Props {
  disabled?: boolean;
  onChange?: (e) => void;
  selectedDoctorId?: number;
  mode?: 'tags' | 'multiple' | null;
  value?: number;
}

const { Option } = Select;

export const EmployeesSelect = ({
  disabled = false,
  onChange,
  selectedDoctorId = null,
  mode = 'multiple',
  value
}: Props): JSX.Element => {
  const employees = useSelector(({ employees }: IAppState) => employees.data);

  const attendeesList = useMemo(
    () =>
      employees?.map(
        (item) =>
          item.id !== selectedDoctorId && (
            <Option key={item.full_name} title={item.full_name} value={item.id}>
              {item.full_name}
            </Option>
          )
      ),
    [employees, selectedDoctorId]
  );

  const getValue = () => (mode ? value : employees.find((el) => el.id === value)?.full_name);

  return (
    <Select
      id='attendees'
      onChange={onChange}
      disabled={disabled}
      value={getValue()}
      mode={mode}
      filterOption={(input, option) => option.key.toLowerCase().indexOf(input.toLowerCase()) >= 0}
      showSearch
    >
      {attendeesList}
    </Select>
  );
};
