import '../stock/scss/StockList.scss';
import './InventoryInfo.scss';

import { Card, Col, Pagination, Row, Spin, Statistic, Table, TableColumnProps } from 'antd';
import ListFieldWithTooltip from 'components/ListFieldWithTooltip';
import { scrollToTop } from 'helpers/list-helpers';
import useClinicId from 'hooks/useClinicId';
import { useI18n } from 'hooks/usei18n';
import { useLocale } from 'hooks/useLocale';
import { useUserAccess } from 'hooks/useUserAccess';
import { measurementsLocale } from 'i18n/measurements';
import { stockStatuses } from 'i18n/stockStatuses';
import { IAppState } from 'interfaces/app-state';
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { getClinic } from 'redux/clinic/actions';
import { getStock, getStockStats, resetStock, setSelectedStock } from 'redux/inventory/stock/actions';
import { IStockItem } from 'services/interfaces/clinics/inventory/stock/IStockItem';

import getStatus from '../stock/helpers/TagSelector';

const searchItemsArray = [
  { name: 'status', value: 'out_of_stock', isArrayValue: true },
  { name: 'status', value: 'critical_level', isArrayValue: true }
];

const InventoryInfo = (): JSX.Element => {
  const dispatch = useDispatch();
  const clinicId = useClinicId();
  const userAccess = useUserAccess();
  const navigate = useNavigate();

  const statuses = useI18n(stockStatuses);
  const measurements = useI18n(measurementsLocale);
  const locale = useLocale('private.inventory.stock');

  const { data, metadata } = useSelector(({ stock }: IAppState) => stock);
  const loading = useSelector(({ stock }: IAppState) => stock.loading);
  const stockStats = useSelector(({ stock }: IAppState) => stock.stockStats);

  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    if (clinicId) {
      dispatch(getStock(clinicId, 10, 1, searchItemsArray));
      dispatch(getStockStats(clinicId));
    }
  }, [clinicId]);

  useEffect(() => {
    dispatch(getClinic(clinicId));
    dispatch(setSelectedStock(null));

    return () => {
      dispatch(resetStock());
    };
  }, []);

  const redirectToStockDetails = (record: any) =>
    userAccess.accesses.stock.showItem && {
      onClick: () => {
        navigate(`/clinic/${clinicId}/inventory/stock/${record.id}`);
      }
    };

  const getUsageMeasurementLabel = (e: IStockItem): string => {
    if (!e.quantity) return '-';

    return `${e.quantity} (${e.remaining_capacity} ${measurements[e.unit_of_measurement]})`;
  };

  const getStatusLabel = (e: IStockItem): '-' | JSX.Element => {
    if (!e?.status) return '-';

    return getStatus({ value: e.status, label: statuses[e.status] });
  };

  const columns = useMemo(
    (): TableColumnProps<IStockItem>[] => [
      {
        key: 'name',
        title: locale.labels.name,
        sorter: (a, b) => (a.name > b.name ? 1 : -1),
        onCell: redirectToStockDetails,
        render: (e) => (e.name ? ListFieldWithTooltip(e.name, 15) : '-')
      },
      {
        key: 'manufacturer',
        title: locale.labels.manufacturer,
        sorter: (a, b) => (a.manufacturer > b.manufacturer ? 1 : -1),
        onCell: redirectToStockDetails,
        render: (e) => (e.manufacturer ? ListFieldWithTooltip(e.manufacturer, 15) : '-')
      },
      {
        key: 'status',
        title: locale.labels.status,
        sorter: (a, b) => (a.status > b.status ? 1 : -1),
        onCell: redirectToStockDetails,
        render: getStatusLabel
      },
      {
        key: 'quantity',
        title: locale.labels.remainingQuantity,
        sorter: (a, b) => (a.quantity > b.quantity ? 1 : -1),
        onCell: redirectToStockDetails,
        render: getUsageMeasurementLabel
      }
    ],
    [data]
  );

  const onPageChange = (page: number): void => {
    dispatch(getStock(clinicId, 10, page, searchItemsArray));
    scrollToTop();
  };

  return (
    <Spin spinning={loading}>
      <Row gutter={[20, 0]} className='inventory-info-page'>
        <Col span={24} sm={24} md={8} lg={7} xl={6}>
          <Card className='ant-card-bordered personal-info-card with-shadow'>
            <div className='inventory-info-page__statistics-box'>
              <Statistic
                style={{
                  display: 'flex',
                  flexDirection: 'column'
                }}
                title={locale.labels.totalStockProductsCount}
                valueStyle={{
                  color: '#cf1322',
                  fontSize: '20px',
                  justifyContent: 'center',
                  display: 'flex'
                }}
                value={stockStats?.available_stock_count}
              />
              <Statistic
                style={{
                  display: 'flex',
                  flexDirection: 'column'
                }}
                title={locale.labels.runningOutOfStock}
                valueStyle={{
                  color: '#3f8600',
                  fontSize: '20px',
                  justifyContent: 'center',
                  display: 'flex'
                }}
                value={stockStats?.low_stock_count}
                precision={0}
              />
              <Statistic
                style={{
                  display: 'flex',
                  flexDirection: 'column'
                }}
                title={locale.labels.ranOutOfStock}
                valueStyle={{
                  color: '#cf1322',
                  fontSize: '20px',
                  justifyContent: 'center',
                  display: 'flex'
                }}
                value={stockStats?.out_of_stock_count}
                precision={0}
              />
            </div>
          </Card>
        </Col>
        <Col span={24} sm={24} md={16} lg={17} xl={18}>
          <Table
            pagination={false}
            className='accent-header stock-list'
            rowKey='id'
            dataSource={data}
            columns={columns}
          />
          <Row style={{ justifyContent: 'space-between', marginTop: 10, alignItems: 'center' }}>
            <Pagination
              className={'equipment-pagination'}
              defaultCurrent={1}
              current={+currentPage}
              showSizeChanger={false}
              showLessItems
              pageSize={10}
              total={metadata.total}
              onChange={(current) => {
                setCurrentPage(current);
                onPageChange(current);
              }}
            />
          </Row>
        </Col>
      </Row>
    </Spin>
  );
};

export default InventoryInfo;
