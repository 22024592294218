import { Form } from 'antd';
import ConfirmCancelModal from 'components/ConfirmCancelModal';
import ViewDetailsButtonsGroup from 'components/ViewDetailsButtonsGroup';
import VLXModal from 'components/VLXModal';
import { useLocale } from 'hooks/useLocale';
import { useUserAccess } from 'hooks/useUserAccess';
import { IAppState } from 'interfaces/app-state';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { showBalanceReplenishmentModal } from 'redux/clients/actions';

import { ReplenishmentPopupForm } from './ReplenishmentPopupForm';

export const ReplenishmentPopup = (): JSX.Element => {
  const dispatch = useDispatch();
  const userAccess = useUserAccess();
  const locale = useLocale('private.clients.client');
  const [form] = Form.useForm();

  const clients = useSelector(({ clients }: IAppState) => clients);

  const [showCancelModal, setShowCancelModal] = useState<boolean>(false);

  const onConfirmCancelClick = () => {
    form.resetFields();
    dispatch(showBalanceReplenishmentModal(false));
    setShowCancelModal(false);
  };

  return (
    <VLXModal
      title={locale.labels.replenishmentClientBalance}
      centered
      form={form}
      open={clients.showBalanceReplenishmentModal}
      onModalClose={() => dispatch(showBalanceReplenishmentModal(false))}
    >
      <>
        {clients.showBalanceReplenishmentModal && (
          <ReplenishmentPopupForm
            locale={locale}
            form={form}
          >
            <ViewDetailsButtonsGroup
              fixed
              accessGroup={userAccess.accesses.financialAccounts}
              editMode
              hideDeleteBtn
              onCancelClick={() => {
                if (form.isFieldsTouched()) {
                  setShowCancelModal(true);
                } else {
                  dispatch(showBalanceReplenishmentModal(false));
                }
              }}
              onDeleteClick={() => {
                setShowCancelModal(false);
              }}
            />
          </ReplenishmentPopupForm>
        )}
      </>
      <ConfirmCancelModal
        open={showCancelModal}
        onOk={onConfirmCancelClick}
        onCancel={() => setShowCancelModal(false)}
      />
    </VLXModal>
  );
};
