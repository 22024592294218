import './scss/ViewEditTool.scss';

import { Form, Spin } from 'antd';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useBlocker, useNavigate, useParams } from 'react-router-dom';

import ConfirmCancelModal from '../../../components/ConfirmCancelModal';
import ConfirmDeleteModal from '../../../components/ConfirmDeleteModal';
import ViewDetailsButtonsGroup from '../../../components/ViewDetailsButtonsGroup';
import { toolStatuses } from '../../../constants/dictionary/default/inventoryOptions';
import { useLocale } from '../../../hooks/useLocale';
import useLocalizedList from '../../../hooks/useLocalizedList';
import { useUserAccess } from '../../../hooks/useUserAccess';
import { IAppState } from '../../../interfaces/app-state';
import { getToolById, removeTool, updateTool } from '../../../redux/inventory/tools/actions';
import ToolForm from './components/ToolForm';
import { conditionStatus } from './helpers/TagSelector';

dayjs.extend(customParseFormat);

const ViewEditTool = () => {
  const dispatch = useDispatch();
  const { clinicId, toolId }: any = useParams();
  const navigate = useNavigate();
  const selectedTool = useSelector<IAppState, any>((state) => state.tools.selectedTool);
  const loading = useSelector<IAppState, any>((state) => state.tools.loading);
  const userAccess = useUserAccess();
  const [editMode, setEditMode] = useState<boolean>(false);
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
  const [showCancelModal, setShowCancelModal] = useState<boolean>(false);
  const [form] = Form.useForm();
  const locale = useLocale('private.inventory.tools');

  useEffect(() => {
    dispatch(getToolById(clinicId, toolId));
  }, []);

  const onSubmit = (data: any) => {
    if (form.isFieldsTouched()) dispatch(updateTool(clinicId, selectedTool?.id, data));
    setEditMode(false);
  };

  function onConfirmCancelClick() {
    form.resetFields();
    dispatch(getToolById(clinicId, toolId));
    setEditMode(false);
    setShowCancelModal(false);
    if (blocker.state === 'blocked') {
      blocker.proceed();
    }
  }

  function onConfirmDeleteClick() {
    dispatch(removeTool(clinicId, toolId, locale));
    navigate(`/clinic/${clinicId}/inventory/tools`);
  }

  function handleDate(date) {
    return date ? dayjs(date, 'YYYY-MM-DD') : null;
  }

  function mapValues() {
    const tool = {
      ...selectedTool,
      warranty_expiration_date: handleDate(selectedTool.warranty_expiration_date),
      next_inspection_date: handleDate(selectedTool.next_inspection_date),
      last_inspection_date: handleDate(selectedTool.last_inspection_date),
      purchase_date: handleDate(selectedTool.purchase_date)
    };
    if (!selectedTool.warranty_expiration_date) delete tool.warranty_expiration_date;
    delete tool.inspected_by;
    delete tool.location;
    delete tool.user_id;
    return tool;
  }

  const onFieldsChange = (e: any) => {
    if (e.length < 2 && !editMode) {
      setEditMode(true);
    }
  };

  const localizedMaintenanceStatuses = useLocalizedList(toolStatuses);

  const getToolStatus = (e: any) => {
    const res = localizedMaintenanceStatuses.find((el) => el.value === e);
    const tag = conditionStatus(res);
    return res ? tag : '';
  };

  const blocker = useBlocker(({ currentLocation, nextLocation }) => {
    console.log(nextLocation);
    if (nextLocation) {
      setShowCancelModal(true);
    }
    return editMode;
  });

  return (
    <Spin spinning={loading} className='stock-details-page-spin-container'>
      {!!selectedTool && (
        <div style={{ paddingLeft: '20px' }}>
          <div className={'name-statuses-form-header'}>
            <h4 style={{ marginRight: '8px' }} id='tool-name'>
              {selectedTool.name}
            </h4>
            {getToolStatus(selectedTool?.condition)}
          </div>
          <ToolForm
            onFieldsChange={onFieldsChange}
            editMode={editMode}
            onFinish={onSubmit}
            form={form}
            initialValues={mapValues()}
            clinicId={clinicId}
            initialResponsible={{
              value: selectedTool.responsible_person.id,
              label: selectedTool.responsible_person.full_name
            }}
            initialRoom={{
              value: selectedTool.location?.id,
              label: selectedTool.location?.details
            }}
            initialLocationType={selectedTool.location.type}
            initialLocationDetails={selectedTool.location.details}
          >
            <ViewDetailsButtonsGroup
              accessGroup={userAccess.accesses.tools}
              editMode={editMode}
              onCancelClick={() => {
                if (form.isFieldsTouched()) {
                  setShowDeleteModal(false);
                  setShowCancelModal(true);
                }
                setEditMode(false);
              }}
              onEditClick={() => setEditMode(true)}
              onDeleteClick={() => {
                setShowCancelModal(false);
                setShowDeleteModal(true);
              }}
            />
          </ToolForm>
          <ConfirmDeleteModal
            type={'device'}
            open={showDeleteModal}
            onOk={onConfirmDeleteClick}
            onCancel={() => setShowDeleteModal(false)}
          />
          <ConfirmCancelModal
            open={showCancelModal}
            onOk={onConfirmCancelClick}
            onCancel={() => setShowCancelModal(false)}
          />
        </div>
      )}
    </Spin>
  );
};

export default ViewEditTool;
