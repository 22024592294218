export const companyTypes = {
  ukUA: {
    clinic: 'Ветеринарна клініка',
    veterinary_center: 'Ветеринарний центр',
    solo_practice: 'Приватна практика',
    grooming: 'Грумінг салон',
    rehabilitation: 'Реабілітаційний центр',
    shop_pharmacy: 'Ветеринарна аптека/магазин'
  },
  enGB: {
    clinic: 'Veterinary clinic',
    veterinary_center: 'Veterinary center',
    solo_practice: 'Private practice',
    grooming: 'Grooming salon',
    rehabilitation: 'Rehabilitation center',
    shop_pharmacy: 'Veterinary pharmacy/store'
  }
};
