import baseClient from '../../baseClient';
import { generateSearchQuery } from '../../helpers/commonUtils';
import { CrateSkillRequest } from '../../interfaces/skills/create-skill-request';

const usersUrl: string = '/v1';

export const getSkills = async (page: number = 1, query?: any[]) =>
  baseClient
    .get(`${usersUrl}/skills?page=${page}${generateSearchQuery(query)}`)
    .then((response: any) => {
      return response.data;
    });

export const createNewSkill = async (createSkillData: CrateSkillRequest) =>
  baseClient.post(`${usersUrl}/skills`, createSkillData).then((response: any) => {
    return response.data;
  });
