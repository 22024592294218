import './SalesList.scss';

import { Pagination, Row, Spin, Table, TableColumnProps } from 'antd';
import StickyButton from 'components/StickyButton';
import VLXSearch from 'components/VLXSearch';
import { paymentStatuses } from 'constants/dictionary/default/selectOptions';
import dayjs from 'dayjs';
import { money } from 'helpers/list-helpers';
import useClinicId from 'hooks/useClinicId';
import { useI18n } from 'hooks/usei18n';
import useLocalizedList from 'hooks/useLocalizedList';
import { useSearch } from 'hooks/useSearch';
import { sales } from 'i18n/pages/sales';
import { IAppState } from 'interfaces/app-state';
import { ISaleDetails, ISaleItem } from 'interfaces/sales';
import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { setSelectedBill } from 'redux/finances/bills/actions';
import { getSales, resetSales } from 'redux/sales/actions';
import { getOpenedShift } from 'redux/shifts/actions';

import getPaymentStatus from '../finances/helpers/PaymentStatusTagSelector';

const SalesList = (): JSX.Element => {
  const dispatch = useDispatch();
  const clinicId = useClinicId();
  const navigate = useNavigate();
  const locale = useI18n<typeof sales['ukUA']>(sales);

  const shifts = useSelector(({ shifts }: IAppState) => shifts);
  const loading = useSelector(({ sales }: IAppState) => sales.loading);
  const { data, metadata } = useSelector(({ sales }: IAppState) => sales);

  const openedShift = shifts?.openedShift;

 const { handleChange, onPageChange, filterResults } = useSearch({action: getSales, clinicId, defaultSortKey: 'created_at', defaultSortOrder: 'desc'});

  useEffect(() => {
    dispatch(setSelectedBill(null));
    dispatch(getOpenedShift(clinicId));

    return () => {
      dispatch(resetSales());
    };
  }, []);

  const redirectToSaleDetails = (record: ISaleItem) => ({
    onClick: () => {
      navigate(`/clinic/${clinicId}/shop-farmacy/${record.id}`);
    }
  });

  const redirectToClient = (record: ISaleDetails): void => {
    navigate(`/clinic/${clinicId}/clients/${record?.client?.id}`);
  };

  const redirectToEmployee = (record: ISaleDetails): void => {
    navigate(`/clinic/${clinicId}/administration/employees/${record?.user?.id}`);
  };

  const localizedPaymentStatuses = useLocalizedList(paymentStatuses);

  const getStatus = (e: ISaleItem): JSX.Element | '' => {
    const res = localizedPaymentStatuses.find((el) => el.value === e);
    return getPaymentStatus(res) || '';
  };

  const columns = useMemo(
    (): TableColumnProps<ISaleItem>[] => [
      {
        key: 'created_at',
        dataIndex: 'created_at',
        title: locale.labels.date,
        sorter: (a, b) => null,
        render: (created_at) =>
          `${created_at ? dayjs(created_at).local().format('DD.MM.YYYY HH:mm') : '-'}`,
        onCell: redirectToSaleDetails
      },
      {
        key: 'client_name',
        title: locale.labels.client,
        sorter: () => null,
        render: (e) =>
          e?.client?.name ? (
            <a onClick={() => redirectToClient(e)} className={'custom-links'}>
              {e?.client?.name}
            </a>
          ) : (
            '-'
          )
      },
      {
        key: 'payment_status',
        sorter: () => null,
        title: locale.labels.paymentStatus,
        render: (e) => getStatus(e.payment_status),
        onCell: redirectToSaleDetails
      },
      {
        key: 'final_amount',
        title: locale.labels.totalBilledAmount,
        sorter: () => null,
        render: (e) =>
          e.total_billed_amount !== undefined
            ? `${money(e.total_billed_amount)} ${locale.labels.uah}`
            : '-',
        onCell: redirectToSaleDetails
      },
      {
        key: 'total_income_amount',
        title: locale.labels.totalPayedAmount,
        sorter: () => null,
        render: (e) =>
          e.total_payed_amount !== undefined
            ? `${money(e.total_payed_amount)} ${locale.labels.uah}`
            : '-',
        onCell: redirectToSaleDetails
      },
      {
        key: 'remaining_amount',
        title: locale.labels.remainingAmount,
        sorter: () => null,
        render: (e) =>
          e.remaining_amount !== undefined
            ? `${money(e.remaining_amount)} ${locale.labels.uah}`
            : '-',
        onCell: redirectToSaleDetails
      },
      {
        key: 'created_by_name',
        title: locale.labels.createdBy,
        sorter: () => null,
        render: (e) => (
          <a onClick={() => redirectToEmployee(e)} className={'custom-links'}>
            {e?.user?.name || '-'}
          </a>
        )
      }
    ],
    [data]
  );

  return (
    <>
      <VLXSearch filterResults={filterResults} savedFiltersName={'salesListFilters'}/>
      <Spin spinning={loading} className='clients-list-page-spin-container'>
        <Table
          pagination={false}
          className='accent-header sales-list-table'
          rowKey='id'
          dataSource={data}
          onChange={handleChange}
          columns={columns}
        />
        <Row style={{ justifyContent: 'space-between', marginTop: 10, alignItems: 'center' }}>
          <Pagination
            defaultCurrent={1}
            showSizeChanger={false}
            showLessItems
            pageSize={20}
            current={metadata.page}
            total={metadata.total}
            onChange={onPageChange}
          />
          <StickyButton
            offsetBottom={10}
            onClick={() => navigate(`/clinic/${clinicId}/shop-farmacy/new`)}
            tooltip={!openedShift?.open ? locale.labels.paymentBlockedClosedShift : null}
            disabled={!openedShift?.open}
            iconElement={
              <span
                className='icofont icofont-plus mr-2 sticky-btn-icon'
                style={{ fontSize: '1.3em' }}
              />
            }
            textElement={locale.buttons.addSale}
          />
        </Row>
      </Spin>
    </>
  );
};

export default SalesList;
