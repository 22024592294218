import './PatientDatails.scss';

import { Form, Pagination, Row, Spin, Table, TableColumnProps } from 'antd';
import ConfirmDeleteModal from 'components/ConfirmDeleteModal';
import ListFieldWithTooltip from 'components/ListFieldWithTooltip';
import StickyButton from 'components/StickyButton';
import { vaccineAntigens } from 'constants/dictionary/default/selectOptions';
import dayjs from 'dayjs';
import { scrollToTop } from 'helpers/list-helpers';
import useLocalizedList from 'hooks/useLocalizedList';
import { IAppState } from 'interfaces/app-state';
import { IVaccinationDetails, IVaccinationItemData } from 'interfaces/vaccinationDetails';
import AddVaccination from 'layout/modals/vaccination/AddVaccination';
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { deletePatientVaccination, getPatientVaccinations } from 'redux/patients/actions';

const PatientVaccinationDetails = ({ locale, patientId, clinicId }: any): JSX.Element => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [selectedItem, setSelectedItem] = useState(null);
  const [showDeleteItemModal, setShowDeleteItemModal] = useState<boolean>(false);
  const [showEditItemModal, setShowEditItemModal] = useState<boolean>(false);
  const [vaccinationEditMode, setVaccinationEditMode] = useState(false);

  const { data, metadata } =
    useSelector<IAppState, IVaccinationDetails>(
      ({ patients }) => patients.selectedPatientVaccinations
    ) ?? {};

  useEffect((): void => {
    if (patientId) {
      dispatch(getPatientVaccinations(clinicId, patientId, 1));
    }
  }, [clinicId, patientId]);

  const onConfirmDeleteItemClick = (): void => {
    dispatch(deletePatientVaccination(clinicId, patientId, selectedItem?.id));
    setShowDeleteItemModal(false);
    closeVaccinationModal();
  };

  const localizedVaccineAntigens = useLocalizedList(vaccineAntigens);

  const getCoverageCellData = (data: string[]): string | JSX.Element[] => {
    if (data?.length) {
      return data.map((el) => (
        <div key={el}>
          {ListFieldWithTooltip(localizedVaccineAntigens.find((item) => item.value === el).label)}
        </div>
      ));
    }

    return '-';
  };

  const openEditItemForm = (record: IVaccinationItemData): { onClick: () => void } => ({
    onClick: () => {
      setSelectedItem(record);
      setVaccinationEditMode(true);
      setShowEditItemModal(true);
    }
  });

  const columns = useMemo(
    (): TableColumnProps<IVaccinationItemData>[] => [
      {
        key: 'name',
        onCell: openEditItemForm,
        title: locale.labels.vacinationName,
        sorter: (a, b) => (a.name > b.name ? 1 : -1),
        render: (e) => ListFieldWithTooltip(e.name)
      },
      {
        key: 'coverage',
        onCell: openEditItemForm,
        title: locale.labels.coverage,
        sorter: (a, b) => (a.coverage > b.coverage ? 1 : -1),
        render: (e) => getCoverageCellData(e.coverage)
      },
      {
        key: 'manufacturer',
        onCell: openEditItemForm,
        title: locale.labels.manufacturer,
        sorter: (a, b) => (a.manufacturer > b.manufacturer ? 1 : -1),
        render: (e) => ListFieldWithTooltip(e.manufacturer)
      },
      {
        key: 'serial_number',
        onCell: openEditItemForm,
        title: locale.labels.serialNumber,
        sorter: (a, b) => (a.serial_number > b.serial_number ? 1 : -1),
        render: (e) => ListFieldWithTooltip(e.serial_number, 10)
      },
      {
        key: 'date_of_vaccination',
        onCell: openEditItemForm,
        title: locale.labels.dateVaccination,
        sorter: (a, b) => (a.date_of_vaccination > b.date_of_vaccination ? 1 : -1),
        render: (e) =>
          e.date_of_vaccination ? dayjs(e.date_of_vaccination).format('DD.MM.YYYY') : '-'
      },
      {
        key: 'date_of_next_vaccination',
        onCell: openEditItemForm,
        title: locale.labels.dateNextVaccination,
        sorter: (a, b) => (a.date_of_next_vaccination > b.date_of_next_vaccination ? 1 : -1),
        render: (e) =>
          e.date_of_next_vaccination ? dayjs(e.date_of_next_vaccination).format('DD.MM.YYYY') : '-'
      }
    ],
    [data]
  );

  const closeVaccinationModal = (): void => {
    setSelectedItem(null);
    setVaccinationEditMode(false);
    setShowEditItemModal(false);
  };

  const onPageChange = (page: number): void => {
    dispatch(getPatientVaccinations(clinicId, patientId, page));
    scrollToTop();
  };

  return (
    <Spin spinning={!data}>
      <Row className='patient-details__vacination-tab' style={{ marginBottom: 20 }}>
        <div style={{ width: '100%' }}>
          <Table
            pagination={false}
            className='accent-header patients-vaccination-table'
            rowKey='id'
            dataSource={data}
            columns={columns}
          />
        </div>
        <Row
          style={{
            justifyContent: 'space-between',
            marginTop: 10,
            alignItems: 'center',
            width: '100%'
          }}
        >
          <Pagination
            defaultCurrent={1}
            pageSize={10}
            current={metadata?.page}
            total={metadata?.total}
            showSizeChanger={false}
            showLessItems
            onChange={onPageChange}
          />
          <StickyButton
            offsetBottom={10}
            onClick={() => setShowEditItemModal(true)}
            iconElement={
              <span
                className='icofont icofont-plus mr-2 sticky-btn-icon'
                style={{ fontSize: '1.3em' }}
              />
            }
            textElement={locale.buttons.addVaccination}
          />
        </Row>
      </Row>
      <ConfirmDeleteModal
        type={'item'}
        open={showDeleteItemModal}
        onOk={onConfirmDeleteItemClick}
        onCancel={() => setShowDeleteItemModal(false)}
      />
      <AddVaccination
        initialValues={selectedItem}
        showModal={showEditItemModal}
        closeVaccinationModal={closeVaccinationModal}
        form={form}
        editMode={vaccinationEditMode}
        patientId={patientId}
      />
    </Spin>
  );
};

export default PatientVaccinationDetails;
