import { Checkbox, Form, FormInstance, Input, Select, Spin } from 'antd';
import { AllDayPicker } from 'components/AllDayPicker';
import DateRangePicker from 'components/DateRangePicker';
import { TextEditor } from 'components/TextEditor';
import { DATE_TIME_FORMAT } from 'constants/common';
import { locationTypes } from 'constants/dictionary/default/selectOptions';
import dayjs, { Dayjs } from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import useClinicId from 'hooks/useClinicId';
import { useLocale } from 'hooks/useLocale';
import useLocalizedList from 'hooks/useLocalizedList';
import { IAppState } from 'interfaces/app-state';
import { IEvent } from 'interfaces/event';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { editEvent } from 'redux/events/actions';
import { patternRule, PATTERNS, requiredRule } from 'utils/form-rules';

import { EmployeesSelect } from '../../components/employeesSelect/EmployeesSelect.tsx';

const { Option } = Select;

dayjs.extend(utc);
dayjs.extend(timezone);

interface Props {
  initialValues: IEvent;
  callbackFunc: () => void;
  form: FormInstance;
  children: React.ReactNode;
}

export const EditEventForm = ({
  form,
  initialValues,
  children,
  callbackFunc
}: Props): JSX.Element => {
  const dispatch = useDispatch();
  const clinicId = useClinicId();
  const locale = useLocale('private.appointments.event');

  const events = useSelector<IAppState, any>((state) => state.events);
  const rooms = useSelector<IAppState, any>((state) => state.rooms.data);
  const userId = useSelector(({ user }: IAppState) => user.id);

  const [startDate, setStartDate] = useState<Dayjs>(dayjs(new Date()));
  const [endDate, setEndDate] = useState<Dayjs>(null);
  const [duration, setDuration] = useState<Dayjs>(null);
  const [locationType, setLocationType] = useState<string>(initialValues?.location?.type);
  const [allDayChecked, setAllDayChecked] = useState(initialValues?.all_day);

  useEffect(() => {
    form.resetFields();
    if (initialValues?.all_day) {
      initialValues.date_range = [dayjs(initialValues.start_time), dayjs(initialValues.end_time)];
      form.setFieldValue('date_range', initialValues.date_range);
    }
    if (initialValues?.start_time) {
      const eventDuration = dayjs(
        dayjs(initialValues.end_time).diff(dayjs(initialValues.start_time)) - 10800000
      );
      setStartDate(dayjs(initialValues?.start_time));
      setEndDate(dayjs(initialValues?.end_time));
      setDuration(dayjs(eventDuration));
      form.setFieldValue('date_from', dayjs(initialValues?.start_time));
      form.setFieldValue('time_from', dayjs(initialValues?.start_time));
      form.setFieldValue('end_time', dayjs(initialValues?.end_time));
      form.setFieldValue('duration', dayjs(eventDuration));
    }
    setLocationType(initialValues?.location?.type);
  }, [initialValues]);

  const onSubmit = (data: any): void => {
    if (data.all_day) {
      data.start_time = data.date_range[0].tz('utc').format(DATE_TIME_FORMAT);
      data.end_time = data.date_range[1].add(1, 'm').tz('utc').format(DATE_TIME_FORMAT);
    } else {
      data.start_time = startDate.tz('utc').format(DATE_TIME_FORMAT);
      data.end_time = endDate.tz('utc').format(DATE_TIME_FORMAT);
    }

    delete data.date_from;
    delete data.time_from;
    delete data.duration;
    delete data.date_range;
    dispatch(editEvent(data, initialValues.id, callbackFunc));
  };

  const onLocationTypeSelect = (value: any) => {
    setLocationType(value);
  };

  const locations = useLocalizedList(locationTypes);

  const roomsList = rooms.map((item) => {
    return (
      <Option key={`${item.name}${item.id}`} title={item.name} value={item.id}>
        {item.name}
      </Option>
    );
  });

  return (
    <>
      <Spin spinning={events.loading}>
        <Form
          onFinish={onSubmit}
          autoComplete='off'
          layout='vertical'
          form={form}
          initialValues={initialValues}
        >
          <div className='row'>
            <div className='col-md-6 col-sm-12'>
              <Form.Item name='clinic_id' initialValue={clinicId} hidden>
                <Input />
              </Form.Item>
              <Form.Item
                label={locale.labels.title}
                name='title'
                rules={[
                  requiredRule(locale.messages.mandatoryField),
                  patternRule(PATTERNS.NAME, locale.errors.titleNotValid)
                ]}
              >
                <Input hidden={true} />
              </Form.Item>
              <Form.Item
                className='add-appointment-form__multi-select-field'
                label={locale.labels.attendees}
                name='attendees'
              >
                <EmployeesSelect selectedDoctorId={userId} />
              </Form.Item>
              <Form.Item name='all_day' label={locale.labels.allDay} valuePropName='checked'>
                <Checkbox onChange={(e) => setAllDayChecked(e.target.checked)} />
              </Form.Item>
              {!allDayChecked && (
                <DateRangePicker
                  startDate={startDate}
                  endDate={endDate}
                  duration={duration}
                  setStartDate={setStartDate}
                  setEndDate={setEndDate}
                  setDuration={setDuration}
                />
              )}
              {allDayChecked && <AllDayPicker />}
              <Form.Item label={''}>
                <Form.Item
                  className='add-equipment-form__single-select-field'
                  name={['location', 'type']}
                  label={locale.labels.location}
                  initialValue={'room'}
                  rules={[requiredRule(locale.errors.enterLocationType)]}
                >
                  <Select
                    filterOption={false}
                    onSelect={onLocationTypeSelect}
                    options={locations}
                  />
                </Form.Item>
                <Form.Item
                  name={['location', 'details']}
                  label={locale.labels.details}
                  hidden={locationType !== 'other'}
                  rules={
                    locationType === 'other' ? [requiredRule(locale.errors.enterTheLocation)] : []
                  }
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  name={['location', 'id']}
                  className='add-equipment-form__single-select-field'
                  hidden={locationType !== 'room'}
                  label={locale.labels.room}
                  rules={locationType === 'room' ? [requiredRule(locale.errors.enterRoom)] : []}
                >
                  <Select
                    filterOption={(input, option) => {
                      return option.key.toLowerCase().indexOf(input.toLowerCase()) >= 0;
                    }}
                    showSearch
                  >
                    {roomsList}
                  </Select>
                </Form.Item>
              </Form.Item>
            </div>
            <div className='col-md-6 col-sm-12'>
              <TextEditor label={locale.labels.notes} name={['notes']} />
            </div>
          </div>
          {children}
        </Form>
      </Spin>
    </>
  );
};
