import React from 'react';

interface LinkButtonProps {
  children: any;
  onClick?: () => void;
}

const LinkButton = ({ children, onClick }: LinkButtonProps) => {
  const onClickAction = (e) => {
    e.preventDefault();
    onClick();
  };
  return (
    <a href='' onClick={onClickAction}>
      {children}
    </a>
  );
};

export default LinkButton;
