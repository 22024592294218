export const packagesLocale = {
  ukUA: {
    box: 'коробка',
    packet: 'пачка',
    tin: 'консервна бляшанка',
    can: 'бляшанка',
    bag: 'мішок',
    bottle: 'пляшка',
    vial: 'флакон',
    tube: 'тюбик',
    aerosol: 'аерозоль',
    spray: 'спрей',
    blisterPack: 'блістер',
    ampoule: 'ампула',
    container: 'контейнер',
    sachet: 'cаше',
    jar: 'банка',
    tray: 'лоток',
    tub: 'відро',
    canister: 'каністра',
    pallet: 'палета',
    pouch: 'оптова упаковка'
  },
  enGB: {
    box: 'box',
    packet: 'packet',
    tin: 'tin',
    can: 'can',
    bag: 'bag',
    bottle: 'bottle',
    vial: 'vial',
    tube: 'tube',
    aerosol: 'aerosol',
    spray: 'spray',
    blisterPack: 'blister pack',
    ampoule: 'ampoule',
    container: 'container',
    sachet: 'sachet',
    jar: 'jar',
    tray: 'tray',
    tub: 'tub',
    canister: 'canister',
    pallet: 'pallet',
    pouch: 'pouch'
  }
};
