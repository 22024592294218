import {
  IndexDictionaryRecordsResponse
} from '../../../services/clinic/dictionaries/models/IndexDictionaryRecordsResponse';

export const GET_SERVICE_GROUPS = '[service_groups] Set';
export const SHOW_MODAL = '[service_groups] SHOW_MODAL';
export const SET_LOADING = '[service_groups] SET_LOADING';
export const SET_SEARCH_QUERY = '[service_groups] SetSearchQuery';
export const RESET = '[service_groups] RESET';

export interface ServiceGroupsResetAction {
  type: typeof RESET
}

export interface GetServiceGroupsAction {
  type: typeof GET_SERVICE_GROUPS
  payload: IndexDictionaryRecordsResponse
}

export interface ShowModalAction {
  type: typeof SHOW_MODAL
  payload: boolean
}

export interface SetLoadingAction {
  type: typeof SET_LOADING
  payload: boolean
}

export interface SetSearchQueryAction {
  type: typeof SET_SEARCH_QUERY
  payload: any[]
}

export type ServiceGroupsActions =
  | ServiceGroupsResetAction
  | GetServiceGroupsAction
  | SetSearchQueryAction
  | ShowModalAction
  | SetLoadingAction;
