import { Divider, Form, Input, Spin, Transfer } from 'antd';
import React, { Key, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { requiredRule } from 'utils/form-rules';

import { useLocale } from '../../../hooks/useLocale';
import { IAppState } from '../../../interfaces/app-state';
import { getPermissions } from '../../../redux/permissions/actions';
import { createRole } from '../../../redux/roles/actions';

const AddRoleForm = ({ clinicId, form, children }: any) => {
  const dispatch = useDispatch();
  const permissions = useSelector<IAppState, any>((state) => state.permissions);
  const roles = useSelector<IAppState, any>((state) => state.roles);
  const [targetKeys, setTargetKeys] = useState<Key[]>([]);
  const locale = useLocale('private.administration.roles');

  const onSubmit = (data: any) => {
    const request = {
      name: data.name,
      permissions_ids: targetKeys.map((key) => parseInt(key as string))
    };
    dispatch(createRole(clinicId, request));
  };

  useEffect(() => {
    if (clinicId) dispatch(getPermissions(clinicId));
  }, [clinicId]);

  useEffect(() => {
    if (!roles.backendError) {
      form.resetFields();
      setTargetKeys([]);
    }
  }, [roles.backendError]);

  const data = permissions.data.map((item) => {
    return {
      key: item.id,
      title: item.name,
      description: '',
      chosen: false
    };
  });

  return (
    <Spin spinning={permissions.loading}>
      <Form onFinish={onSubmit} autoComplete='off' layout='vertical' form={form}>
        <div className='row'>
          <div className='col-md-4 col-sm-12'>
            <Form.Item
              label={locale.labels.name}
              name='name'
              rules={[requiredRule(locale.messages.nameEmpty) 
              ]}
            >
              <Input />
            </Form.Item>
          </div>
          <div className='col-md-8 col-sm-12'>
            <Transfer
              className={'transfer'}
              dataSource={data}
              listStyle={{
                maxWidth: '50%',
                minWidth: '30%',
                width: 400,
                height: 500
              }}
              showSearch
              oneWay={true}
              targetKeys={targetKeys}
              onChange={(newTargetKeys) => {
                setTargetKeys(newTargetKeys);
              }}
              render={(item) => item.title}
              pagination
            />
          </div>
        </div>
        <Divider />
        {children}
      </Form>
    </Spin>
  );
};

export default AddRoleForm;
