import { Form, Spin } from 'antd';
import ConfirmCancelModal from 'components/ConfirmCancelModal';
import ViewDetailsButtonsGroup from 'components/ViewDetailsButtonsGroup';
import VLXModal from 'components/VLXModal';
import { useLocale } from 'hooks/useLocale';
import { useUserAccess } from 'hooks/useUserAccess';
import { IAppState } from 'interfaces/app-state';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toggleGenerateVaccinationReportModal } from 'redux/patients/actions';

import GenerateVaccinationReportForm from './GenerateVaccinationReportForm';

const GenerateVaccinationReport = (): JSX.Element => {
  const dispatch = useDispatch();
  const patients = useSelector<IAppState, any>((state) => state.patients);
  const [showCancelModal, setShowCancelModal] = useState<boolean>(false);
  const userAccess = useUserAccess();
  const locale = useLocale('private.patients.patient-page');
  const [form] = Form.useForm();

  const onConfirmCancelClick = () => {
    form.resetFields();
    dispatch(toggleGenerateVaccinationReportModal(false));
    setShowCancelModal(false);
  };

  return (
    <>
      {patients.showGenerateVaccinationReportModal && (
        <VLXModal
          title={locale.labels.generateVaccinationReport}
          centered={true}
          form={form}
          open={patients.showGenerateVaccinationReportModal}
          onModalClose={() => dispatch(toggleGenerateVaccinationReportModal(false))}
        >
          <Spin spinning={patients?.loading}>
            <GenerateVaccinationReportForm
              locale={locale}
              form={form}
            >
              <ViewDetailsButtonsGroup
                fixed
                accessGroup={userAccess.accesses.patients}
                editMode={true}
                hideDeleteBtn={true}
                onCancelClick={() => {
                  if (form.isFieldsTouched()) {
                    setShowCancelModal(true);
                  } else {
                    dispatch(toggleGenerateVaccinationReportModal(false));
                  }
                }}
                onDeleteClick={() => {
                  setShowCancelModal(false);
                }}
              />
            </GenerateVaccinationReportForm>
          </Spin>
        </VLXModal>
      )}
      <ConfirmCancelModal
        defZindex={99999}
        open={showCancelModal}
        onOk={onConfirmCancelClick}
        onCancel={() => setShowCancelModal(false)}
      />
    </>
  );
};

export default GenerateVaccinationReport;
