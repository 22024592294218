import { AxiosResponse } from 'axios';

import baseClient from '../../../baseClient';
import { IBillDetails } from '../../bills/models/IBillDetails';

function url(clinic_id, appointment_id): string {
  return `/v1/clinics/${clinic_id}/appointments/${appointment_id}/bill`;
}

export const showClinicAppointmentBill = async (clinicId: number, appointmentId: number) =>
  baseClient
    .get(url(clinicId, appointmentId))
    .then((response: AxiosResponse<IBillDetails>) => {
      return response.data;
    })
    .catch((e) => console.error(e));
