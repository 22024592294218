import { IEvent } from 'interfaces/event';
import { EventsIndexResponse } from 'services/interfaces/clinics/events/EventsIndexResponse';

import {
  EventsActions,
  GET_EVENTS,
  RESET,
  SET_BE_ERROR,
  SET_EMPLOYEES_EVENTS,
  SET_EVENT,
  SET_LOADING,
  SHOW_EDIT_EVENT_MODAL,
  SHOW_MODAL,
} from './types';

export interface EventsState {
  data: EventsIndexResponse[];
  loading: boolean;
  showModal: boolean;
  selectedEvent: IEvent | null;
  backendError: boolean;
  showEditEventModal: boolean;
}

export const initialState: EventsState = {
  data: [],
  loading: false,
  showModal: false,
  selectedEvent: null,
  backendError: false,
  showEditEventModal: false
};

export function eventsReducer(state: EventsState = initialState, action: EventsActions): any {
  switch (action.type) {
    case GET_EVENTS: {
      return {
        ...state,
        data: action.payload
      };
    }

    case RESET: {
      return initialState;
    }

    case SHOW_MODAL: {
      return {
        ...state,
        showModal: action.payload
      };
    }

    case SET_LOADING: {
      return {
        ...state,
        loading: action.payload
      };
    }

    case SET_BE_ERROR: {
      return {
        ...state,
        backendError: action.payload
      };
    }

    case SET_EVENT: {
      return {
        ...state,
        selectedEvent: action.payload
      };
    }

    case SET_EMPLOYEES_EVENTS: {
      return {
        ...state,
        employeesEvents: action.payload
      };
    }

    case SHOW_EDIT_EVENT_MODAL: {
      return {
        ...state,
        showEditEventModal: action.payload
      };
    }

    default: {
      return state;
    }
  }
}
