import './EventsCalendar.scss';

import {
  CaretLeftOutlined,
  CaretRightOutlined,
  CloseCircleOutlined,
  MenuFoldOutlined,
  MenuUnfoldOutlined
} from '@ant-design/icons';
import { Button, Calendar, Checkbox, Collapse, Divider, Input, Layout, Typography } from 'antd';
import dayjs from 'dayjs';
import { useLocale } from 'hooks/useLocale';
import { IAppState } from 'interfaces/app-state';
import { IEvent } from 'interfaces/event';
import { IUserAccesses } from 'interfaces/user-accesses';
import * as ls from 'local-storage';
import debounce from 'lodash/debounce';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Calendar as BigCalendar, Views } from 'react-big-calendar';
import { useDispatch, useSelector } from 'react-redux';
import { getAppointmentDetails, setInitialDataForAppointment } from 'redux/appointments/actions';
import { getEventById, resetEvents, retrieveUserEvents } from 'redux/events/actions';

import { EventModal } from './EventModal';
import { CalendarViewType, ColoredClinic, LocalStorageFilters } from './types';
import { bigCalendarFormats } from './utils/big-calendar-formats';
import { dateLocalizer } from './utils/dateLocalizer';
import { defineTimeFrameFilter } from './utils/define-time-frame-filter';
import { getColoredClinics } from './utils/get-colored-clinics';

const localizer = dateLocalizer();

const UserCalendar = (): JSX.Element => {
  const mobile = window.innerWidth < 992;
  const dispatch = useDispatch();
  const locale = useLocale('private.calendar.calendar');

  const events = useSelector(({ events }: IAppState) => events.data);
  const selectedEventData = useSelector(({ events }: IAppState) => events.selectedEvent);
  const selectedAppointmentData = useSelector(
    ({ appointments }: IAppState) => appointments.selectedAppointment
  );
  const user = useSelector(({ user }: IAppState) => user);

  const [calendarHeight, setCalendarHeight] = useState(window.innerHeight - 350);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [collapsed, setCollapsed] = useState(mobile);
  const [selectedEvent, setSelectedEvent] = useState<IEvent>(null);
  const [selectedClinics, setSelectedClinics] = useState<number[]>([user.id]);
  const [clinicsFilter, setClinicsFilter] = useState(null);
  const [calendarView, setCalendarView] = useState<CalendarViewType>(null);
  const [hasDefinedFilters, setHasDefinedFilters] = useState(false);

  useEffect(() => {
    dispatch(setInitialDataForAppointment(null));

    window.onresize = () => {
      setCalendarHeight(window.innerHeight - 369);
    };

    return () => {
      window.onresize = null;
      dispatch(resetEvents());
    };
  }, []);

  useEffect((): void => {
    const calendarFilters: LocalStorageFilters = ls.get('userCalendarFilters');

    const { selectedClinics, view, selectedDate } = calendarFilters ?? {};

    const clinicIds = filteredClinics.map((item) => item.clinicId);
    setSelectedClinics(clinicIds);

    if (calendarFilters) setHasDefinedFilters(true);
    if (selectedClinics) setSelectedClinics(selectedClinics);
    if (selectedDate) setSelectedDate(selectedDate);

    const calendarView = view || (window.innerWidth < 992 ? 'day' : 'week');
    setCalendarView(calendarView);

    fetchData(selectedDate, calendarView);
  }, []);

  const coloredClinics = useMemo(
    (): ColoredClinic[] => getColoredClinics(user.userAccesses),
    [user.userAccesses]
  );

  const setLocalStorageFilters = (newVals: LocalStorageFilters): void => {
    setHasDefinedFilters(true);
    let calendarFilters: LocalStorageFilters = ls.get('userCalendarFilters');
    ls.set('userCalendarFilters', { ...calendarFilters, ...newVals });
  };

  const clearSelectedFilters = (): void => {
    ls.remove('userCalendarFilters');
    setHasDefinedFilters(false);
    setSelectedDate(new Date());

    const view = window.innerWidth < 992 ? 'day' : 'week';
    setCalendarView(view);

    fetchData(null, view);

    const clinicIds = filteredClinics.map((item): number => item.clinicId);
    setSelectedClinics(clinicIds);
  };

  const setCurrentDate = (date, { source }): void => {
    const view = source === 'date' ? 'week' : 'month';

    fetchData(date, view);
    setCalendarView(view);
    setSelectedDate(date);
    setLocalStorageFilters({ current: date });
  };

  const onNavigate = (date: Date, view: CalendarViewType): void => {
    fetchData(date, view);
    setSelectedDate(date);
    setLocalStorageFilters({ selectedDate: date });
  };

  const fetchData = (date: Date, view: CalendarViewType): void => {
    const filters = defineTimeFrameFilter(date, view);
    dispatch(retrieveUserEvents(filters));
  };

  const onSelectEvent = (info): void => {
    const { id, resource } = info;
    setSelectedEvent(info);

    if (!id || !resource) return;

    if (resource.event_type === 'appointment') {
      dispatch(getAppointmentDetails(resource.clinic_id, resource.appointment_id));
    } else if (resource.event_type === 'event' && resource.location?.details !== 'unavailable') {
      dispatch(getEventById(resource.clinic_id, id));
    }
  };

  const filterClinics = (e): void => {
    const data = e.target.value;
    setClinicsFilter(data);
  };

  const filteredClinics = useMemo((): (IUserAccesses & { color: string })[] => {
    if (!clinicsFilter) return coloredClinics;
    return coloredClinics.filter((item) =>
      item.clinicName.toLowerCase().includes(clinicsFilter.toLowerCase())
    );
  }, [coloredClinics, clinicsFilter]);

  const onWeekClick = useCallback((): void => {
    fetchData(selectedDate, 'week');
    setCalendarView('week');
    setLocalStorageFilters({ view: 'week' });
  }, [selectedDate]);

  const onDayClick = useCallback((): void => {
    fetchData(selectedDate, 'day');
    setCalendarView('day');
    setLocalStorageFilters({ view: 'day' });
  }, [selectedDate]);

  const onMonthClick = useCallback((): void => {
    fetchData(selectedDate, 'month');
    setCalendarView('month');
    setLocalStorageFilters({ view: 'month' });
  }, [selectedDate]);

  const onSelectClinic = (selected: boolean, clinicId: number): void => {
    const res = selected
      ? [...selectedClinics, clinicId]
      : selectedClinics.filter((item) => item !== clinicId);

    setSelectedClinics(res);
    setLocalStorageFilters({ selectedClinics: res });
  };

  const onSelectAll = (selected: boolean): void => {
    const res = selected ? filteredClinics.map((item) => item.clinicId) : [];
    setSelectedClinics(res);
    setLocalStorageFilters({ selectedClinics: res });
  };

  const getClinicColor = (id: number): string =>
    coloredClinics.find((clinic) => clinic.clinicId === id)?.color;

  const allEvents = useMemo(
    () =>
      events
        ?.filter((item): boolean => selectedClinics.includes(item.clinic_id) && item.occupied)
        .map((item) => ({
          title: item.title === 'Busy' ? locale.labels.busy : item.title,
          id: item.id,
          allDay: item.all_day,
          start: new Date(item.start_time),
          end: new Date(item.end_time),
          location: item.location,
          resource: {
            ...item,
            color: getClinicColor(item.clinic_id)
          }
        })),
    [events, selectedClinics, user, coloredClinics]
  );

  const collapseItems = [
    {
      key: 1,
      label: locale.labels.selectDate,
      children: (
        <Calendar
          style={{ paddingInlineStart: 0 }}
          fullscreen={false}
          value={dayjs(selectedDate)}
          onSelect={setCurrentDate}
        />
      )
    },
    {
      key: 2,
      label: locale.labels.clinics,
      children: (
        <>
          <Input
            placeholder={locale.placeholders.search}
            allowClear
            onChange={debounce(filterClinics, 500)}
          />
          <Checkbox
            onChange={(e) => onSelectAll(e.target.checked)}
            style={{ marginTop: '15px' }}
            checked={selectedClinics?.length === filteredClinics?.length}
          >
            {locale.labels.selectUnselectAll}
          </Checkbox>
          <Divider style={{ margin: '15px 0' }} />
          {filteredClinics.map(
            (item): JSX.Element => (
              <div key={item.clinicId} className='colored-label-wrapper'>
                <span
                  className='colored-label'
                  style={{ background: getClinicColor(item.clinicId) }}
                />
                <Checkbox
                  onChange={(e) => onSelectClinic(e.target.checked, item.clinicId)}
                  checked={selectedClinics.includes(item.clinicId)}
                >
                  {item.clinicName}
                </Checkbox>
              </div>
            )
          )}
        </>
      )
    }
  ];

  const buttonIcon = useMemo((): JSX.Element => {
    if (collapsed) return <MenuUnfoldOutlined style={{ margin: '0px' }} />;

    return <MenuFoldOutlined style={{ margin: '0px' }} />;
  }, [collapsed]);

  return (
    !!calendarView && (
      <>
        <Typography.Title style={{ fontSize: 22, marginBottom: 10 }}>
          {locale.labels.title}
        </Typography.Title>
        <Button
          type='primary'
          size='small'
          icon={buttonIcon}
          onClick={() => setCollapsed(!collapsed)}
        >
          {locale.buttons.filter}
        </Button>
        {hasDefinedFilters && (
          <Button
            style={{ marginLeft: 10 }}
            type='primary'
            size='small'
            icon={<CloseCircleOutlined style={{ margin: '0px' }} />}
            onClick={clearSelectedFilters}
          >
            {locale.buttons.resetFilters}
          </Button>
        )}
        <Layout
          className='ant-layout-has-sider calendar-layout calendar'
          style={{
            background: 'none',
            paddingTop: 10
          }}
        >
          <aside className={collapsed ? 'aside-closed' : 'aside'}>
            <Collapse
              accordion
              defaultActiveKey='1'
              className='calendar-filter-panel'
              items={collapseItems}
            ></Collapse>
          </aside>
          <Layout style={{ background: 'none' }}>
            <BigCalendar
              onSelecting={() => false}
              localizer={localizer}
              culture={user.locale}
              events={allEvents}
              startAccessor='start'
              endAccessor='end'
              style={{
                height: mobile ? 500 : calendarHeight,
                minHeight: '100%'
              }}
              date={selectedDate}
              onNavigate={onNavigate}
              showMultiDayTimes
              onView={() => {}}
              onSelectEvent={onSelectEvent}
              selectable
              components={{
                eventWrapper: ({ event, children }) => {
                  const updatedChild = React.cloneElement(children, {
                    style: {
                      ...children.props.style,
                      borderLeftColor: event.resource.color
                    }
                  });
                  return (
                    <div
                      className='custom-event-wrapper'
                      onContextMenu={(e) => {
                        alert(`${event.title} is clicked.`);
                        e.preventDefault();
                      }}
                    >
                      {updatedChild}
                    </div>
                  );
                }
              }}
              views={[Views.MONTH, Views.WEEK, Views.DAY]}
              view={calendarView}
              calendarView={calendarView}
              step={15}
              formats={bigCalendarFormats}
              scrollToTime={new Date()}
              messages={{
                week: (
                  <span onClick={onWeekClick} className='ant-btn ant-btn-primary ant-btn-sm'>
                    {locale.buttons.week}
                  </span>
                ),
                day: (
                  <span onClick={onDayClick} className='ant-btn ant-btn-primary ant-btn-sm'>
                    {locale.buttons.day}
                  </span>
                ),
                month: (
                  <span onClick={onMonthClick} className='ant-btn ant-btn-primary ant-btn-sm'>
                    {locale.buttons.month}
                  </span>
                ),
                previous: (
                  <span className='ant-btn ant-btn-primary ant-btn-sm ant-btn-icon-only'>
                    <CaretLeftOutlined />
                  </span>
                ),
                next: (
                  <span className='ant-btn ant-btn-primary ant-btn-sm ant-btn-icon-only'>
                    <CaretRightOutlined />
                  </span>
                ),
                yesterday: 'Yesterday',
                tomorrow: 'Tomorrow',
                today: (
                  <span className='ant-btn ant-btn-primary ant-btn-sm'>{locale.buttons.today}</span>
                )
              }}
            />
          </Layout>
        </Layout>
        <Divider />
        {!!selectedEvent && (
          <EventModal
            selectedEvent={selectedEvent}
            selectedEventData={selectedEventData}
            setSelectedEvent={setSelectedEvent}
            selectedAppointmentData={selectedAppointmentData}
            locale={locale}
          />
        )}
      </>
    )
  );
};

export default UserCalendar;
