import { H } from 'highlight.run';

import { IUser } from '../../interfaces/user';
import {
  updateUserProfile,
  uploadAvatar,
  userDetails
} from '../../services/clinic/users/userService';
import { initialState } from './reducer';
import {
  CLEAR_USER,
  ClearUserAction,
  GET_USER,
  GetUserAction,
  SET_AVATAR,
  SET_LOADING,
  SET_REDIRECT,
  SET_TOKEN,
  SET_USER_CLINIC_ACCESSES,
  SetLoadingAction,
  SetTokenAction,
  SetUserAvatarAction,
  SetUserClinicAccesses,
  SetUserRedirect,
  SHOW_MODAL,
  ShowModalAction
} from './types';

function identifyHighlight(id: string): void {
  if (window.location.host === 'localhost:3000') return;

  H.identify(id, { id });
}

export const setUser = (data: IUser): GetUserAction => ({
  type: GET_USER,
  payload: data
});

export const clearUser = (): ClearUserAction => ({
  type: CLEAR_USER
});

export const showModal = (value: boolean): ShowModalAction => ({
  type: SHOW_MODAL,
  payload: value
});

export const setLoading = (value: boolean): SetLoadingAction => ({
  type: SET_LOADING,
  payload: value
});

export const setToken = (value: string): SetTokenAction => ({
  type: SET_TOKEN,
  payload: value
});

export const setUserAvatar = (avatar: string): SetUserAvatarAction => ({
  type: SET_AVATAR,
  payload: avatar
});

export const setUserRedirect = (redirect: boolean): SetUserRedirect => ({
  type: SET_REDIRECT,
  payload: redirect
});

export const setUserClinicAccesses = (data: any): SetUserClinicAccesses => ({
  type: SET_USER_CLINIC_ACCESSES,
  payload: data
});

export const getUser = (cb?: () => void): any => {
  return (dispatch) => {
    dispatch(setLoading(true));
    userDetails()
      .then((data) => {
        identifyHighlight(data.id);
        dispatch(setUser(data));
        cb?.();
      })
      .catch((error) => {
        dispatch(setUser(initialState));
      })
      .finally(() => dispatch(setLoading(false)));
  };
};

export const editUser = (data: any): any => {
  return (dispatch) => {
    dispatch(setLoading(true));
    updateUserProfile(data)
      .then((data) => {
        dispatch(showModal(false));
        dispatch(setUser(data));
      })
      .finally(() => dispatch(setLoading(false)));
  };
};

export const setAvatar = (formData, callBackFnc): any => {
  return (dispatch) => {
    dispatch(setLoading(true));
    uploadAvatar(formData)
      .then((data) => {
        dispatch(getUser());
        if (callBackFnc) {
          callBackFnc();
        }
      })
      .finally(() => dispatch(setLoading(false)))
      .catch((err) => console.log(err));
  };
};
