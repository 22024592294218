import './AppointmentDetails.scss';
import getStatusTag from './helpers/StatusTagSelector';
import getPaymentStatusTag from './helpers/PaymentStatusTagSelector';

const getAppointmentStatus = (status, localizedStatuses) => {
  return { value: status, label: (getLocalizedLabel(status, localizedStatuses))?.label || '-' };
};

const getLocalizedLabel = (val: any, localizedStatuses) => {
  return localizedStatuses.find((el: any) => el.value === val);
};

const getPaymentStatus = (status, localizedStatuses) => {
  return { value: status, label: (getLocalizedLabel(status, localizedStatuses)).label };
};

export const renderPaymentStatus = (paymentStatus, localizedStatuses) => {
  if (!paymentStatus) {
    return null;
  }
  return getPaymentStatusTag(getPaymentStatus(paymentStatus, localizedStatuses));
};

export const renderAppointmentStatus = (appointmentStatus, localizedStatuses) => {
  if (!appointmentStatus) {
    return null;
  }
  return getStatusTag(getAppointmentStatus(appointmentStatus, localizedStatuses));
};
