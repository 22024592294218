import React from 'react';
import { Tag } from 'antd';
import moment from 'moment';

const getExpirationStatus = (expirationDate) => {
  let color;

  const expDate = new Date(expirationDate);
  const currentDate = new Date();
  const thirtyDaysFromNow = new Date(currentDate.getTime() + (30 * 24 * 60 * 60 * 1000)); // 30 days in milliseconds

  if (expDate < currentDate) {
    color = 'red';
  } else if (expDate <= thirtyDaysFromNow && expDate > currentDate) {
    color = 'orange';
  } else {
    color = 'green';
  }

  return tag(color, expirationDate);
};

const tag = (color: string, value: string) => {
  return <Tag id='stock-item-expiration-status' color={color}>{moment(value).format('DD.MM.YYYY')}</Tag>;
};

export default getExpirationStatus;
