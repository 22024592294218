import { ISortObject } from 'services/interfaces/ISortObject.ts';

import { IAppointment, IAppointmentsState } from '../../interfaces/appointment';
import { ISearchCriteria } from '../../interfaces/search-criteria.ts';
import {
  cancelClinicAppointment,
  createClinicAppointment,
  editClinicAppointment,
  getClinicAppointmentDetails,
  patchClinicAppointment,
  updateClinicAppointment,
} from '../../services/clinic/appointments/appointmentsService';
import { showClinicAppointmentBill } from '../../services/clinic/appointments/bills/billsService';
import {
  addNewExistingPatientToClinicAppointment,
  deleteClientAppointmentDetailsPatient,
  getClinicAppointmentDetailsPatients,
} from '../../services/clinic/appointments/patients/patientsService';
import { updateClinicBill } from '../../services/clinic/bills/billsService';
import { CreateUpdateBillRequest } from '../../services/clinic/bills/models/CreateUpdateBillRequest';
import { IBillDetails } from '../../services/clinic/bills/models/IBillDetails';
import { getClinicAppointments, getClinicUserAppointments } from '../../services/clinic/clinicsService';
import {
  addPatientHealthRecord,
  addPatientHealthRecordInventory,
  deletePatientHealthRecord,
  deletePatientHealthRecordInventory,
  getAppointmentPatientHealthRecords,
  getClinicPatientHealthRecord,
  getPatientHealthRecordById,
  getPatientHealthRecordInventory,
  putPatientHealthRecord,
  updatePatientHealthRecordInventory,
} from '../../services/clinic/patients/health-records/healthRecordsService';
import { retrieveClinicEvents, showModal as showEventModal } from '../events/actions';
import {
  AppointmentsResetAction,
  GET_APPOINTMENTS,
  GetAppointmentsAction,
  RESET,
  SET_APPOINTMENT,
  SET_APPOINTMENT_BILL,
  SET_APPOINTMENT_HEALTH_RECORD_INVENTORY,
  SET_APPOINTMENT_HEALTH_RECORDS,
  SET_APPOINTMENT_PATIENT_HEALTH_RECORDS,
  SET_APPOINTMENT_PATIENTS,
  SET_APPOINTMENT_STATUS,
  SET_APPOINTMENTS_LOADING,
  SET_INITIAL_DATA_FOR_APPOINTMENT,
  SET_SELECTED_PATIENT_HEALTH_RECORDS,
  SetAppointmentBill,
  SetAppointmentStatus,
  SetInitialDataForAppointment,
  SetLoadingAction,
  SetSelectedAppointment,
  SetSelectedAppointmentHealthRecordInventory,
  SetSelectedAppointmentHealthRecords,
  SetSelectedAppointmentPatientHealthRecords,
  SetSelectedAppointmentPatients,
  setSelectedPatientHealsRecords,
  SHOW_BILL_MODAL,
  SHOW_EDIT_APPOINTMENT_MODAL,
  SHOW_MODAL,
  SHOW_PATIENT_MODAL,
  SHOW_PAYMENT_MODAL,
  ShowBillModalAction,
  ShowEditAppointmentModalAction,
  ShowModalAction,
  ShowPatientModalAction,
  ShowPaymentModalAction,
} from './types';

export const resetAppointments = (): AppointmentsResetAction => ({
  type: RESET
});

export const setAppointments = (appointments: IAppointmentsState): GetAppointmentsAction => ({
  type: GET_APPOINTMENTS,
  payload: appointments
});

export const showModal = (value: boolean): ShowModalAction => ({
  type: SHOW_MODAL,
  payload: value
});

export const showEditAppoinmentModal = (value: boolean): ShowEditAppointmentModalAction => ({
  type: SHOW_EDIT_APPOINTMENT_MODAL,
  payload: value
});

export const showPatientModal = (value: boolean): ShowPatientModalAction => ({
  type: SHOW_PATIENT_MODAL,
  payload: value
});

export const showBillModal = (value: boolean): ShowBillModalAction => ({
  type: SHOW_BILL_MODAL,
  payload: value
});

export const showPaymentModal = (value: boolean): ShowPaymentModalAction => ({
  type: SHOW_PAYMENT_MODAL,
  payload: value
});

export const setLoading = (value: boolean): SetLoadingAction => ({
  type: SET_APPOINTMENTS_LOADING,
  payload: value
});

export const setSelectedAppointment = (data: IAppointment | null): SetSelectedAppointment => ({
  type: SET_APPOINTMENT,
  payload: data
});

export const setAppointmentBill = (data: IBillDetails | null): SetAppointmentBill => ({
  type: SET_APPOINTMENT_BILL,
  payload: data
});

export const setSelectedAppointmentPatients = (
  data: IAppointment | null
): SetSelectedAppointmentPatients => ({
  type: SET_APPOINTMENT_PATIENTS,
  payload: data
});

export const setSelectedAppointmentPatientHealthRecords = (
  data: IAppointment | null
): SetSelectedAppointmentPatientHealthRecords => ({
  type: SET_APPOINTMENT_PATIENT_HEALTH_RECORDS,
  payload: data
});

export const setSelectedAppointmentHealthRecords = (
  data: IAppointment | null
): SetSelectedAppointmentHealthRecords => ({
  type: SET_APPOINTMENT_HEALTH_RECORDS,
  payload: data
});

export const setInitialDataForAppointment = (data: any): SetInitialDataForAppointment => ({
  type: SET_INITIAL_DATA_FOR_APPOINTMENT,
  payload: data
});

export const setAppointmentStatus = (data: string): SetAppointmentStatus => ({
  type: SET_APPOINTMENT_STATUS,
  payload: data
});

export const setSelectedAppointmentHealthRecordInventory = (
  data: IAppointment | null
): SetSelectedAppointmentHealthRecordInventory => ({
  type: SET_APPOINTMENT_HEALTH_RECORD_INVENTORY,
  payload: data
});

export const setSelectedPatientHealsRecordsData = (
  data: IAppointment | null
): setSelectedPatientHealsRecords => ({
  type: SET_SELECTED_PATIENT_HEALTH_RECORDS,
  payload: data
});

export const getAppointments = (clinicId: number, page?: number, searchValue?: any, sortObject?: ISortObject): any => {
  return (dispatch) => {
    dispatch(setLoading(true));
    getClinicAppointments(clinicId, page, searchValue, sortObject)
      .then((data) => {
        dispatch(setAppointments(data));
        dispatch(setLoading(false));
      })
      .catch(() => dispatch(setLoading(false)));
  };
};

export const getUserAppointments = (page?: number, searchValue?: any, sortObject?: ISortObject): any => {
  return (dispatch) => {
    dispatch(setLoading(true));
    getClinicUserAppointments(page, searchValue, sortObject)
      .then((data) => {
        dispatch(setAppointments(data));
        dispatch(setLoading(false));
      })
      .catch(() => dispatch(setLoading(false)));
  };
};

export const createAppointment = (
  clinicId: number,
  data,
  calendarView = true,
  searchItemsArray?: ISearchCriteria[],
  callbackFn?: () => void
): any => {
  return (dispatch) => {
    dispatch(setLoading(true));
    createClinicAppointment(clinicId, data)
      .then(() => {
        if (callbackFn) {
          callbackFn();
        } else {
          dispatch(getAppointments(clinicId, 1, searchItemsArray));
        }
        if (calendarView) {
          dispatch(retrieveClinicEvents(clinicId, searchItemsArray));
        }
        dispatch(showModal(false));
        dispatch(showEventModal(false));
      })
      .catch(() => dispatch(setLoading(false)));
  };
};

export const editAppointment = (clinicId: number, appointmentId: number, data): any => {
  return (dispatch) => {
    dispatch(setLoading(true));
    editClinicAppointment(clinicId, appointmentId, data)
      .then(() => {
        dispatch(getAppointmentDetails(clinicId, appointmentId));
        dispatch(retrieveClinicEvents(clinicId));
        dispatch(showEditAppoinmentModal(false));
      })
      .catch(() => dispatch(setLoading(false)));
  };
};

export const getAppointmentBill = (clinicId: number, appointmentId: number): any => {
  return (dispatch) => {
    dispatch(setLoading(true));
    showClinicAppointmentBill(clinicId, appointmentId)
      .then((data) => {
        if (data) dispatch(setAppointmentBill(data));
      })
      .finally(() => dispatch(setLoading(false)));
  };
};

export const updateAppointmentBill = (
  clinicId: number,
  billId: number,
  appointment: IAppointment,
  statusData: any,
  data: CreateUpdateBillRequest
): any => {
  return (dispatch) => {
    dispatch(setLoading(true));
    updateClinicBill(clinicId, billId, data)
      .then((data) => {
        dispatch(patchAppointmentDetails(clinicId, appointment, statusData));
        dispatch(getAppointmentBill(clinicId, appointment.id));
        dispatch(showBillModal(false));
      })
      .finally(() => dispatch(setLoading(false)));
  };
};

export const patchAppointmentDetails = (clinicId: number, appointment: IAppointment, data): any => {
  return (dispatch) => {
    dispatch(setLoading(true));
    patchClinicAppointment(clinicId, data, appointment.id)
      .then(() => {
        dispatch(setSelectedAppointment(appointment));
      })
      .finally(() => dispatch(setLoading(false)));
  };
};

export const cancelAppointmentOnList = (
  clinicId: number,
  appointmentId: number,
  data,
  currentPage,
  searchArray,
  getUserAppointmentsDataAfterSuccess?: any
): any => {
  return (dispatch) => {
    dispatch(setLoading(true));
    cancelClinicAppointment(clinicId, data, appointmentId)
      .then(() => {
        if (getUserAppointmentsDataAfterSuccess) {
          dispatch(getUserAppointments(currentPage, searchArray));
        } else {
          dispatch(getAppointments(clinicId, currentPage, searchArray));
        }
      })
      .catch(() => dispatch(setLoading(false)))
      .finally(() => dispatch(setLoading(false)));
  };
};

export const cancelAppointmentOnDetails = (clinicId: number, appointmentId: number, data): any => {
  return (dispatch) => {
    dispatch(setLoading(true));
    cancelClinicAppointment(clinicId, data, appointmentId)
      .then(() => {
        dispatch(getAppointmentDetailsPatients(clinicId, appointmentId));
        dispatch(getAppointmentDetails(clinicId, appointmentId));
      })
      .finally(() => dispatch(setLoading(false)));
  };
};

export const patchAppointmentOnTheList = (
  clinicId: number,
  appointmentId: number,
  data,
  page?: number,
  searchValue?: any,
  getUserAppointmentsDataAfterSuccess?: any
): any => {
  return (dispatch) => {
    dispatch(setLoading(true));
    patchClinicAppointment(clinicId, data, appointmentId)
      .then(() => {
        if (getUserAppointmentsDataAfterSuccess) {
          dispatch(getUserAppointments(page, searchValue));
        } else {
          dispatch(getAppointments(clinicId, page, searchValue));
        }
      })
      .finally(() => dispatch(setLoading(false)));
  };
};

export const getAppointmentDetails = (clinicId: number, id: number): any => {
  return (dispatch) => {
    dispatch(setSelectedAppointment(null));
    dispatch(setLoading(true));
    getClinicAppointmentDetails(clinicId, id)
      .then((data) => {
        dispatch(setSelectedAppointment(data));
        dispatch(setLoading(false));
      })
      .catch(() => dispatch(setLoading(false)));
  };
};

export const getAppointmentDetailsPatients = (clinicId: number, id: number): any => {
  return (dispatch) => {
    dispatch(setLoading(true));
    getClinicAppointmentDetailsPatients(clinicId, id)
      .then((data) => {
        dispatch(setSelectedAppointmentPatients(data));
        dispatch(setLoading(false));
      })
      .catch(() => dispatch(setLoading(false)));
  };
};

export const deleteAppointmentDetailsPatient = (
  clinicId: number,
  appointmentId: number,
  patientId: number,
  callbackFn?: (patientId: number) => void
): any => {
  return (dispatch) => {
    dispatch(setLoading(true));
    deleteClientAppointmentDetailsPatient(clinicId, appointmentId, patientId)
      .then((data) => {
        if (callbackFn) {
          callbackFn(patientId);
        }
        dispatch(getAppointmentDetailsPatients(clinicId, appointmentId));
        dispatch(setLoading(false));
      })
      .catch(() => dispatch(setLoading(false)));
  };
};

export const getPatientHealthRecord = (clinicId: number, patientId: number): any => {
  return (dispatch) => {
    dispatch(setSelectedPatientHealsRecordsData(null));
    dispatch(setLoading(true));
    getClinicPatientHealthRecord(clinicId, patientId)
      .then((data) => {
        dispatch(setSelectedPatientHealsRecordsData(data?.data?.reverse()));
        dispatch(setLoading(false));
      })
      .catch(() => dispatch(setLoading(false)));
  };
};

export const getAppointmentDetailsPatientHealthRecords = (
  clinicId: number,
  patientId: number,
  healthRecordsId: number
): any => {
  return (dispatch) => {
    dispatch(setLoading(true));
    getPatientHealthRecordById(clinicId, patientId, healthRecordsId)
      .then((data) => {
        dispatch(setSelectedAppointmentPatientHealthRecords(data));
        dispatch(setLoading(false));
      })
      .catch(() => dispatch(setLoading(false)));
  };
};

export const getAppointmentDetailsHealthRecords = (
  clinicId: number,
  appointment_id: number,
  patientId: number
): any => {
  return (dispatch) => {
    dispatch(setLoading(true));
    getAppointmentPatientHealthRecords(clinicId, appointment_id, patientId)
      .then((data) => {
        dispatch(
          setSelectedAppointmentHealthRecords(
            data?.data?.find((el) => el.appointment_id === appointment_id)
          )
        );
        dispatch(setLoading(false));
      })
      .catch(() => dispatch(setLoading(false)));
  };
};

export const getAppointmentDetailsHealthRecordInventory = (
  clinicId: number,
  appointmentId: number,
  healthRecordId: number
): any => {
  return (dispatch) => {
    dispatch(setLoading(true));
    getPatientHealthRecordInventory(clinicId, appointmentId, healthRecordId)
      .then((data) => {
        dispatch(setSelectedAppointmentHealthRecordInventory(data));
        dispatch(setLoading(false));
      })
      .catch(() => {
        dispatch(setLoading(false));
        dispatch(setSelectedAppointmentHealthRecordInventory(null));
      });
  };
};

export const updateAppointmentDetailsHealthRecordInventory = (
  clinicId: number,
  appointmentId: number,
  healthRecordId: number,
  inventoryId: number,
  data: any
): any => {
  return (dispatch) => {
    dispatch(setLoading(true));
    updatePatientHealthRecordInventory(clinicId, appointmentId, healthRecordId, inventoryId, data)
      .then((data) => {
        dispatch(setSelectedAppointmentHealthRecordInventory(data));
        dispatch(setLoading(false));
      })
      .catch(() => dispatch(setLoading(false)));
  };
};

export const deleteAppointmentDetailsHealthRecordInventory = (
  clinicId: number,
  appointmentId: number,
  healthRecordId: number,
  inventoryId: number
): any => {
  return (dispatch) => {
    dispatch(setLoading(true));
    deletePatientHealthRecordInventory(clinicId, appointmentId, healthRecordId, inventoryId)
      .then((data) => {
        dispatch(setSelectedAppointmentHealthRecordInventory(data));
        dispatch(setLoading(false));
      })
      .catch(() => dispatch(setLoading(false)));
  };
};

export const addAppointmentDetailsHealthRecordInventory = (
  clinicId: number,
  appointmentId: number,
  healthRecordId: number,
  data: any
): any => {
  return (dispatch) => {
    dispatch(setLoading(true));
    addPatientHealthRecordInventory(clinicId, appointmentId, healthRecordId, data)
      .then((data) => {
        dispatch(getAppointmentDetailsPatients(clinicId, appointmentId));
        dispatch(getAppointmentBill(clinicId, appointmentId));
        dispatch(setSelectedAppointmentHealthRecordInventory(data));
        dispatch(setLoading(false));
      })
      .catch(() => dispatch(setLoading(false)));
  };
};

export const deleteAppointmentDetailsPatientHealthRecords = (
  clinicId: number,
  appointmentId: number,
  patientId: number,
  healthRecordsId: number
): any => {
  return (dispatch) => {
    dispatch(setLoading(true));
    deletePatientHealthRecord(clinicId, patientId, healthRecordsId)
      .then((data) => {
        dispatch(getAppointmentDetailsPatients(clinicId, appointmentId));
        dispatch(setLoading(false));
      })
      .catch(() => dispatch(setLoading(false)));
  };
};

export const putAppointmentDetailsPatientHealthRecords = (
  clinicId: number,
  patientId: number,
  healthRecordsId: number,
  appointment_id: number,
  data: any,
  callBackFunction?: any
): any => {
  return (dispatch) => {
    dispatch(setLoading(true));
    putPatientHealthRecord(clinicId, patientId, healthRecordsId, data)
      .then((data) => {
        dispatch(setSelectedAppointmentHealthRecords(data));
        dispatch(getAppointmentDetailsPatients(clinicId, appointment_id));
        dispatch(getAppointmentBill(clinicId, appointment_id));
        dispatch(setLoading(false));
        if (callBackFunction) {
          callBackFunction(data);
        }
      })
      .catch(() => dispatch(setLoading(false)));
  };
};

export const addNewExistingPatientToAppointment = (
  clinicId: number,
  appointmentId: number,
  data: any
): any => {
  return (dispatch) => {
    dispatch(setLoading(true));
    addNewExistingPatientToClinicAppointment(clinicId, appointmentId, data)
      .then((data) => {
        dispatch(showPatientModal(false));
        dispatch(getAppointmentDetailsPatients(clinicId, appointmentId));
        dispatch(setLoading(false));
      })
      .catch(() => dispatch(setLoading(false)));
  };
};

export const addAppointmentDetailsPatientHealthRecords = (
  clinicId: number,
  id: number,
  patientId: number,
  data: any,
  callBackFunction?: any
): any => {
  return (dispatch) => {
    dispatch(setLoading(true));
    addPatientHealthRecord(clinicId, id, patientId, data)
      .then((data) => {
        dispatch(setSelectedAppointmentHealthRecords(data));
        dispatch(getAppointmentDetailsPatients(clinicId, id));
        dispatch(getAppointmentBill(clinicId, id));
        dispatch(setLoading(false));
        if (callBackFunction) {
          callBackFunction(data);
        }
      })
      .catch(() => dispatch(setLoading(false)));
  };
};

export const updateAppointment = (clinicId: number, data, appointmentId: number): any => {
  return (dispatch) => {
    dispatch(setLoading(true));
    updateClinicAppointment(clinicId, data, appointmentId)
      .then(() => {
        dispatch(getAppointments(clinicId));
        dispatch(retrieveClinicEvents(clinicId));
      })
      .catch(() => dispatch(setLoading(false)));
  };
};
