import {
  SET_LOADING,
  SetLoadingAction,
  SHOW_DICTIONARY_MODAL,
  ShowModalAction
} from './types';

export const setLoading = (value: boolean): SetLoadingAction => ({
  type: SET_LOADING,
  payload: value
});

export const showModal = (value: boolean): ShowModalAction => ({
  type: SHOW_DICTIONARY_MODAL,
  payload: value
});
