import { ISelectedStock } from 'services/interfaces/clinics/inventory/stock/ISelectedStock';
import { StockIndexResponse } from 'services/interfaces/clinics/inventory/stock/StockIndexResponse';
import { StockItemsShowResponse } from 'services/interfaces/clinics/inventory/stock/StockItemsShowResponse';

import {
  RESET,
  SET_BE_ERROR,
  SET_SELECTED_STOCK,
  SET_SELECTED_STOCK_ITEMS,
  SET_STOCK,
  SET_STOCK_ITEMS_LOADING,
  SET_STOCK_LOADING,
  SET_STOCK_STATS,
  SHOW_CREATE_MODAL,
  StockActions
} from './types';

export interface StockState extends StockIndexResponse {
  loading: boolean;
  stockItemsLoading: boolean;
  showCreateModal: boolean;
  backendError: boolean;
  selectedStock: ISelectedStock;
  selectedStockItems: StockItemsShowResponse;
  stockStats: any;
}

const initialState: StockState = {
  data: [],
  metadata: {
    total: 0,
    page: 1,
    next: null,
    last: 1,
    prev: null
  },
  loading: true,
  showCreateModal: false,
  backendError: false,
  selectedStock: null,
  selectedStockItems: null,
  stockItemsLoading: true,
  stockStats: null
};

export function stockReducer(state: StockIndexResponse = initialState, action: StockActions): any {
  switch (action.type) {
    case SET_STOCK: {
      return {
        ...state,
        ...action.payload
      };
    }

    case RESET: {
      return initialState;
    }

    case SET_SELECTED_STOCK: {
      return {
        ...state,
        selectedStock: action.payload
      };
    }

    case SET_SELECTED_STOCK_ITEMS: {
      return {
        ...state,
        selectedStockItems: action.payload
      };
    }

    case SET_STOCK_LOADING: {
      return {
        ...state,
        loading: action.payload
      };
    }

    case SET_STOCK_ITEMS_LOADING: {
      return {
        ...state,
        stockItemsLoading: action.payload
      };
    }

    case SHOW_CREATE_MODAL: {
      return {
        ...state,
        showCreateModal: action.payload
      };
    }

    case SET_BE_ERROR: {
      return {
        ...state,
        backendError: action.payload
      };
    }

    case SET_STOCK_STATS: {
      return {
        ...state,
        stockStats: action.payload
      };
    }

    default: {
      return state;
    }
  }
}
