import { Tag } from 'antd';
import React from 'react';

const getPaymentStatusTag = (paymentStatus) => {
  let color = null;

  switch (paymentStatus?.value.toLowerCase()) {
    case 'paid':
      color = 'green';
      break;
    case 'partially_paid':
      color = 'yellow';
      break;
    case 'unpaid':
      color = 'orange';
      break;
    case 'overpaid':
      color = 'orange';
      break;
  }

  return tag(color, paymentStatus?.label);
};

const tag = (color: string, value: string) => {
  return (
    <Tag
      id='bill-status-type'
      color={color}
      style={{ userSelect: 'none', marginLeft: 5, fontWeight: 'bold' }}
    >
      {value}
    </Tag>
  );
};

export default getPaymentStatusTag;
