import { IInvoiceData } from 'services/interfaces/clinics/inventory/invoice/IInvoiceData';
import {
  ISelectedProvidersItem,
  ISelectedProvidersItemsData,
} from 'services/interfaces/clinics/inventory/providers/ISelectedProvidersItemsData';
import { ISortObject } from 'services/interfaces/ISortObject';

import {
  createClinicProvider,
  getClinicProviderById,
  getClinicProviderByIdInvoices,
  getClinicProviders,
  removeClinicProvider,
  updateClinicProvider,
} from '../../../services/clinic/inventory/providersService';
import {
  SET_PROVIDERS_DATA,
  SET_PROVIDERS_LOADING,
  SET_SELECTED_PROVIDER_DATA,
  SET_SELECTED_PROVIDER_INVOICES_DATA,
  SET_SHOW_CREATE_PROVIDER_MODAL,
  SET_SHOW_EDIT_PROVIDER_MODAL,
  SetLoadingAction,
  SetProvidersDataAction,
  SetSelectedProviderDataAction,
  SetSelectedProviderInvoicesDataAction,
  SetShowCreateProviderModalAction,
  SetShowEditProviderModalAction,
} from './types';

export const setProvidersData = (data: ISelectedProvidersItemsData): SetProvidersDataAction => ({
  type: SET_PROVIDERS_DATA,
  payload: data
}); 

export const setSelectedProviderData = (data: ISelectedProvidersItem): SetSelectedProviderDataAction => ({
  type: SET_SELECTED_PROVIDER_DATA,
  payload: data
});

export const setSelectedProviderInvoicesData = (data: IInvoiceData): SetSelectedProviderInvoicesDataAction => ({
  type: SET_SELECTED_PROVIDER_INVOICES_DATA,
  payload: data
});
  
export const setLoading = (value: boolean): SetLoadingAction => ({
  type: SET_PROVIDERS_LOADING,
  payload: value
});
 
export const setShowCreateProviderModal = (value: boolean): SetShowCreateProviderModalAction => ({
  type: SET_SHOW_CREATE_PROVIDER_MODAL,
  payload: value
});

export const setShowEditProviderModal = (value: boolean): SetShowEditProviderModalAction => ({
  type: SET_SHOW_EDIT_PROVIDER_MODAL,
  payload: value
});

export const getProviders = (clinicId: number, page: number, searchArray?: any[], sortObject?: ISortObject, callbackFunc?: (data: ISelectedProvidersItemsData) => void): any => {
  return (dispatch) => {
    dispatch(setLoading(true));
    getClinicProviders(clinicId, page, searchArray, sortObject)
      .then((data) => {
        dispatch(setProvidersData(data));
        if (callbackFunc) {
          callbackFunc(data);
        }
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => dispatch(setLoading(false)));
  };
};

export const getProviderById = (clinicId: number, providerId: number): any => {
  return (dispatch) => {
    dispatch(setLoading(true));
    getClinicProviderById(clinicId, providerId)
      .then((data) => {
        dispatch(setSelectedProviderData(data));
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => dispatch(setLoading(false)));
  };
};

export const getProviderByIdInvoices = (clinicId: number, providerId: number): any => {
  return (dispatch) => {
    dispatch(setLoading(true));
    getClinicProviderByIdInvoices(clinicId, providerId)
      .then((data) => {
        dispatch(setSelectedProviderInvoicesData(data));
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => dispatch(setLoading(false)));
  };
};

export const createProvider = (clinicId: number, data: ISelectedProvidersItem, callbackFunc?: (data: ISelectedProvidersItem) => void): any => {
  return (dispatch) => {
    dispatch(setLoading(true));
    createClinicProvider(clinicId, data)
      .then((data) => {
        dispatch(setShowCreateProviderModal(false));
        if (callbackFunc) {
          callbackFunc(data);
        }
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => dispatch(setLoading(false)));
  };
};

export const updateProvider = (clinicId: number, providerId: number, data: ISelectedProvidersItem): any => {
  return (dispatch) => {
    updateClinicProvider(clinicId, providerId, data)
      .then((data) => {
        dispatch(getProviderById(clinicId, providerId));
        dispatch(getProviders(clinicId, 1));
        dispatch(setShowEditProviderModal(false));
      })
      .catch((err) => { 
        console.log(err);
      })
      .finally(() => dispatch(setLoading(false)));
  };
};

export const removeProvider = (clinicId: number, providerId: number): any => {
  return (dispatch) => {
    removeClinicProvider(clinicId, providerId)
      .then((data) => {
        dispatch(setSelectedProviderData(null));
        dispatch(getProviders(clinicId, 1));
        dispatch(setShowEditProviderModal(false));
      })
      .catch((err) => { 
        console.log(err);
      })
      .finally(() => dispatch(setLoading(false)));
  };
};
