import { ToolsIndexResponse } from '../../../services/interfaces/clinics/inventory/tools/ToolsIndexResponse';
import { ToolShowResponse } from '../../../services/interfaces/clinics/inventory/tools/ToolShowResponse';

export const SET_TOOLS = '[tools] Set';
export const SET_LOADING = '[tools] Loading';
export const SHOW_CREATE_MODAL = '[tools] Create Modal';
export const SET_BE_ERROR = '[tools] BE Error';
export const SET_SELECTED_TOOL = '[tools] Selected Device';
export const RESET = '[tools] RESET';

export interface ToolsResetAction {
  type: typeof RESET
}

export interface SetToolsAction {
  type: typeof SET_TOOLS
  payload: ToolsIndexResponse
}

export interface SetSelectedToolAction {
  type: typeof SET_SELECTED_TOOL
  payload: ToolShowResponse
}

export interface SetLoadingAction {
  type: typeof SET_LOADING
  payload: boolean
}

export interface ShowCreateModal {
  type: typeof SHOW_CREATE_MODAL
  payload: boolean
}

export interface SetBeError {
  type: typeof SET_BE_ERROR
  payload: boolean
}

export type ToolsActions =
  | SetToolsAction
  | ToolsResetAction
  | SetSelectedToolAction
  | ShowCreateModal
  | SetBeError
  | SetLoadingAction;
