import '../Finances.scss';
import './ShiftsList.scss';

import { Pagination, Spin, Table, TableColumnProps, Tag } from 'antd';
import dayjs from 'dayjs';
import useClinicId from 'hooks/useClinicId';
import { useLocale } from 'hooks/useLocale';
import { IAppState } from 'interfaces/app-state';
import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { getShifts, setSelectedShift } from 'redux/shifts/actions';
import { AppState } from 'redux/store';
import { ShiftsData } from 'services/clinic/shifts/models/IndexShiftsResponse';

import { currentEarnings, getShiftDuration, userData } from './ShiftsBaseHelper';
import { useSearch } from '../../../hooks/useSearch.ts';
import VLXSearch from '../../../components/VLXSearch.tsx';

const ShiftsList = (): JSX.Element => {
  const dispatch = useDispatch();
  const clinicId = useClinicId();
  const navigate = useNavigate();
  const locale = useLocale('private.finances.shifts');
  const currencies = useLocale('private.currencies').labels;

  const { data, metadata } = useSelector(({ shifts }: IAppState) => shifts);
  const loading = useSelector(({ shifts }: AppState) => shifts.loading);

  const redirectToDetails = (record: ShiftsData): { onClick: () => void } => ({
    onClick: (): void => {
      navigate(`/clinic/${clinicId}/finances/shifts/${record.id}`);
    }
  });

  const { handleChange, onPageChange, filterResults } = useSearch({
    action: getShifts,
    clinicId,
    defaultSortKey: 'opened_at',
    defaultSortOrder: 'desc'
  });

  const columns = useMemo(
    (): TableColumnProps<ShiftsData>[] => [
      {
        key: 'opened_at',
        dataIndex: 'opened_at',
        title: locale.labels.openedAt,
        sorter: () => null,
        render: (opened_at, e) => dayjs(opened_at).local().format('DD.MM.YYYY HH:mm')
      },
      {
        key: 'opened_by_name',
        title: locale.labels.openedBy,
        sorter: () => null,
        render: (e) => userData(e.opened_by)
      },
      {
        key: 'open',
        dataIndex: 'open',
        title: locale.labels.status,
        sorter: () => null,
        render: (open) =>
          open ? (
            <Tag color='green' style={{ fontWeight: 600 }}>
              {locale.labels.opened}
            </Tag>
          ) : (
            <Tag color='red' style={{ fontWeight: 600 }}>
              {locale.labels.closed}
            </Tag>
          )
      },
      {
        key: 'closed_at',
        dataIndex: 'closed_at',
        title: locale.labels.closedAt,
        sorter: () => null,
        render: (closed_at, e) =>
          closed_at ? dayjs(closed_at).local().format('DD.MM.YYYY HH:mm') : '-'
      },
      {
        key: 'closed_by_name',
        title: locale.labels.closedBy,
        sorter: () => null,
        render: (e) => userData(e.closed_by)
      },
      {
        key: 'total_diff',
        dataIndex: 'total_diff',
        title: locale.labels.totalDiff,
        sorter: () => null,
        render: (total_diff) => currentEarnings(total_diff, currencies)
      },
      {
        key: 'duration',
        dataIndex: 'duration',
        title: locale.labels.duration,
        render: (duration, e) => getShiftDuration(e, locale)
      }
    ],
    [data]
  );

  useEffect((): void => {
    dispatch(setSelectedShift(null));
  }, [clinicId]);

  return (
    <Spin spinning={loading} className='bills-list-page-spin-container'>
      <>
        <VLXSearch filterResults={filterResults} savedFiltersName={'shiftsListFilters'} />
        <Table
          pagination={false}
          className='accent-header'
          rowKey='id'
          onChange={handleChange}
          dataSource={data}
          columns={columns}
          onRow={redirectToDetails}
        />
        <Pagination
          style={{ marginTop: 10 }}
          defaultCurrent={1}
          pageSize={20}
          current={metadata.page}
          total={metadata.total}
          onChange={onPageChange}
          showSizeChanger={false}
          showLessItems
        />
      </>
    </Spin>
  );
};

export default ShiftsList;
