import {
  IndexDictionaryRecordsResponse
} from '../../../services/clinic/dictionaries/models/IndexDictionaryRecordsResponse';

export const GET_STOCK_TYPES = '[stock_types] Set';
export const SHOW_MODAL = '[stock_types] SHOW_MODAL';
export const SET_LOADING = '[stock_types] SET_LOADING';
export const SET_SEARCH_QUERY = '[stock_types] SetSearchQuery';
export const RESET = '[stock_types] RESET';

export interface StockTypesResetAction {
  type: typeof RESET
}

export interface GetStockTypesAction {
  type: typeof GET_STOCK_TYPES
  payload: IndexDictionaryRecordsResponse
}

export interface ShowModalAction {
  type: typeof SHOW_MODAL
  payload: boolean
}

export interface SetLoadingAction {
  type: typeof SET_LOADING
  payload: boolean
}

export interface SetSearchQueryAction {
  type: typeof SET_SEARCH_QUERY
  payload: any[]
}

export type StockTypesActions =
  | StockTypesResetAction
  | GetStockTypesAction
  | SetSearchQueryAction
  | ShowModalAction
  | SetLoadingAction;
