import { Spin } from 'antd';
import DocumentsTab from 'components/DocumentsTab';
import useClinicId from 'hooks/useClinicId';
import { IAppState } from 'interfaces/app-state';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getDocumentsList, mergeClinicDocumentsList, removePatientDocument, setLoading } from 'redux/clinic/actions';

const ClinicDocuments = (): JSX.Element => {
  const dispatch = useDispatch();
  const clinicId = useClinicId();
  const loading = useSelector(({ clinic }: IAppState) => clinic.docsLoading);
  const selectedClinicDocuments = useSelector(
    ({ clinic }: IAppState) => clinic.selectedClinicDocuments
  );

  const removeDocument = (doc) => {
    dispatch(removePatientDocument(clinicId, doc?.id));
  };

  const setDocumentsList = (data): void => {
    dispatch(mergeClinicDocumentsList(data));
  };

  useEffect(() => {
    dispatch(getDocumentsList(clinicId));

    return () => {
      setLoading(true);
    };
  }, []);

  return (
    <Spin spinning={loading}>
      <div style={{ padding: 20 }}>
        <DocumentsTab
          uploadDocumentLink={`/v1/clinics/${clinicId}/documents`}
          data={selectedClinicDocuments}
          setDocumentsList={setDocumentsList}
          removeDocument={removeDocument}
        />
      </div>
    </Spin>
  );
};

export default ClinicDocuments;
