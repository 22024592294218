import './FinancesInfo.scss';

import { Button, Card, Col, Row, Spin } from 'antd';
import useClinicId from 'hooks/useClinicId';
import { useLocale } from 'hooks/useLocale';
import { IAppState } from 'interfaces/app-state';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { closeShift, getOpenedShift, openShift } from 'redux/shifts/actions';

import ShiftStatusCard from '../components/ShiftStatusCard';

const FinancesInfo = (): JSX.Element => {
  const clinicId = useClinicId();
  const dispatch = useDispatch();
  const shifts = useSelector<IAppState, any>((state) => state.shifts);
  const shiftsLoading = useSelector<IAppState, any>((state) => state.shifts.loading);
  const openedShift = shifts?.openedShift;
  const locale = useLocale('private.finances.shifts');
  const [dataLoading, setDataLoading] = useState(true);

  useEffect(() => {
    dispatch(getOpenedShift(clinicId));
  }, [clinicId]);

  function onOpenShiftClick() {
    dispatch(openShift(clinicId));
  }

  function onCloseShiftClick() {
    dispatch(closeShift(clinicId, openedShift.id));
  }

  useEffect(() => {
    if (shiftsLoading && !dataLoading) {
      setDataLoading(true);
    } else if (!shiftsLoading && dataLoading) {
      setDataLoading(false);
    }
  }, [shiftsLoading, dataLoading]);

  useEffect(() => {
    return () => setDataLoading(true);
  }, []);

  return (
    <Spin spinning={dataLoading}>
      <Row id='finances-dashboard'>
        <Col span={12} xs={24} md={18} xl={15} xxl={10}>
          <Card className='ant-card-bordered shift-details-card with-shadow'>
            <ShiftStatusCard shift={openedShift} />
            <Row id='change-status' justify={'end'} style={{ marginTop: '20px' }}>
              {openedShift?.open ? (
                <Button type={'primary'} id='close-shift' onClick={onCloseShiftClick}>
                  {locale.labels.closeShit}
                </Button>
              ) : (
                <Button type={'primary'} id='open-shift' onClick={onOpenShiftClick}>
                  {locale.labels.openShift}
                </Button>
              )}
            </Row>
          </Card>
        </Col>
      </Row>
    </Spin>
  );
};

export default FinancesInfo;
