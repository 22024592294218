import format from 'date-fns/format';
import getDay from 'date-fns/getDay';
import getMonth from 'date-fns/getMonth';
import en from 'date-fns/locale/en-GB';
import uk from 'date-fns/locale/uk';
import parse from 'date-fns/parse';
import startOfWeek from 'date-fns/startOfWeek';
import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import { dateFnsLocalizer } from 'react-big-calendar';

dayjs.extend(utc);
dayjs.extend(timezone);

const locales = {
  ukUA: uk,
  enGB: en
};

export const dateLocalizer = () =>
  dateFnsLocalizer({
    getDay,
    locales,
    startOfWeek,
    format,
    parse,
    getMonth
  });
