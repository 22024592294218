export const deviceTypes =
  [{
    ukUA: 'Офісне',
    enGB: 'Office',
    value: 'office'
  }, {
    ukUA: 'Хірургічне',
    enGB: 'Surgical',
    value: 'hurgical'
  }, {
    ukUA: 'Лабораторне',
    enGB: 'Laboratory',
    value: 'laboratory'
  }, {
    ukUA: 'Господарське',
    enGB: 'Household',
    value: 'household'
  }];

export const toolTypes =
  [{
    ukUA: 'Офісний',
    enGB: 'Office',
    value: 'office'
  }, {
    ukUA: 'Хірургічний',
    enGB: 'Surgical',
    value: 'surgical'
  }, {
    ukUA: 'Господарський',
    enGB: 'Household',
    value: 'household'
  }];

export const maintenanceStatuses = [{
  ukUA: 'Робочий',
  enGB: 'Maintained',
  value: 'serviced'
}, {
  ukUA: 'Протерміноване обслуговуванння',
  enGB: 'Maintenance overdue',
  value: 'maintenance_overdue'
}, {
  ukUA: 'Потребує обслуговуванння згодом',
  enGB: 'Maintenance required soon',
  value: 'maintenance_required_soon'
}];

export const deviceStatuses = [{
  ukUA: 'Активний',
  enGB: 'Operating',
  value: 'operating'
}, {
  ukUA: 'Потребує ремонту',
  enGB: 'Repair required',
  value: 'repair_required'
}, {
  ukUA: 'Потребує обслуговування',
  enGB: 'Maintenance required soon',
  value: 'maintenance_required_soon'
}, {
  ukUA: 'На обслуговуванні',
  enGB: 'In maintenance',
  value: 'maintenance'
}];

export const toolStatuses = [{
  ukUA: 'Відмінний',
  enGB: 'Good',
  value: 'good'
}, {
  ukUA: 'Задовільний',
  enGB: 'Normal',
  value: 'normal'
}, {
  ukUA: 'Потребує ремонту',
  enGB: 'Repair required',
  value: 'repair_required'
}];
