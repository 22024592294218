import { FormInstance, Spin } from 'antd';
import { IAppState } from 'interfaces/app-state';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { createInvoice } from 'redux/inventory/invoice/actions';
import { InvoiceCreateUpdateRequest } from 'services/interfaces/clinics/inventory/invoice/InvoiceCreateUpdateRequest';

import InvoiceForm from './components/InvoiceForm';

type Props = React.PropsWithChildren<{
  form: FormInstance;
  clinicId: number;
}>;
const AddInvoiceForm = ({ clinicId, form, children }: Props): JSX.Element => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const invoices = useSelector(({ invoices }: IAppState) => invoices);

  useEffect(() => {
    form.resetFields();
  }, []);

  const onSubmit = (data: InvoiceCreateUpdateRequest) => {
    if (data?.purchase_date) {
      data.purchase_date = data.purchase_date.format('DD.MM.YYYY');
    }
    dispatch(
      createInvoice(clinicId, data, (id: number): void => {
        navigate(`/clinic/${clinicId}/inventory/invoices/${id}`);
      })
    );
  };

  return (
    <>
      <Spin spinning={invoices.loading}>
        <InvoiceForm onFinish={onSubmit} form={form}>
          {children}
        </InvoiceForm>
      </Spin>
    </>
  );
};

export default AddInvoiceForm;
