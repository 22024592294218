import { useI18n } from 'hooks/usei18n';
import { textEditor } from 'i18n/textEditor';
import { useEffect, useRef, useState } from 'react';
import ReactQuill from 'react-quill-new';

type Props = {
  value: string;
  id?: string;
  borderless?: boolean;
  showAll?: boolean;
};

const showMoreStyle = {
  color: '#0e3048',
  cursor: 'pointer',
  marginBottom: 15,
  width: '100%',
  justifyContent: 'flex-end',
  display: 'flex'
};

const DEFAULT_HEIGHT = 110;
const LINES_THRESHOLD = 5; // amount of lines when height should be limited;
const CHARS_THRESHOLD = 300; // amount of characters when height should be limited;
const EMPTY_CONTENT = '<p><br></p>';

export const TextEditorView = ({ value, id, borderless, showAll = false }: Props): JSX.Element => {
  const locale = useI18n<typeof textEditor['ukUA']>(textEditor);
  const quillRef = useRef<ReactQuill>();

  const [showMore, setShowMoreNotes] = useState<boolean>(false);
  const [lines, setLines] = useState(0);

  const noValue: boolean = !quillRef.current?.editor.getText().trim().length;

  useEffect(() => {
    let linesCount = quillRef.current?.editor.getLines().length;
    if (quillRef.current?.editor.getLength() > CHARS_THRESHOLD) {
      setLines(LINES_THRESHOLD);
    } else {
      setLines(linesCount);
    }
  }, [quillRef.current, value]);

  const toggleShowMore = (): void => {
    setShowMoreNotes(!showMore);
  };

  return !value || value === EMPTY_CONTENT ? (
    <span>-</span>
  ) : (
    <>
      <ReactQuill
        id={id || ''}
        theme={borderless ? 'bubble' : 'snow'}
        ref={quillRef}
        className='full-length-view'
        modules={{ toolbar: null }}
        readOnly
        value={noValue ? '-' : value}
        style={{
          height: lines < LINES_THRESHOLD || (showMore || showAll) ? 'initial' : DEFAULT_HEIGHT,
          marginTop: 8,
          marginBottom: 8
        }}
      />
      {(lines >= LINES_THRESHOLD && !showAll) && (
        <span style={showMoreStyle} onClick={toggleShowMore}>
          {showMore ? ` ...${locale.showLess}` : ` ...${locale.showMore}`}
        </span>
      )}
    </>
  );
};
