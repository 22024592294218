export const bigCalendarFormats = {
  timeGutterFormat: (date, culture, localizer) => localizer.format(date, 'HH:mm', culture),
  eventTimeRangeFormat: (range, culture, localizer) =>
    `${localizer.format(range.start, 'HH:mm', culture)} - ${localizer.format(
      range.end,
      'HH:mm',
      culture
    )}`,
  selectRangeFormat: (range, culture, localizer) =>
    `${localizer.format(range.start, 'HH:mm', culture)} - ${localizer.format(
      range.end,
      'HH:mm',
      culture
    )}`
};
