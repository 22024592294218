import { ISkillsList } from '../../interfaces/skills';
import { RESET, SET_LOADING, SET_SKILLS, SkillsActions } from './types';

interface SkillsState extends ISkillsList {
  loading: boolean;
}

const initialState: SkillsState = {
  skills: [],
  loading: false
};

export function skillsReducer(state: SkillsState = initialState, action: SkillsActions): any {
  switch (action.type) {
    case SET_SKILLS: {
      return {
        ...state,
        skills: action.payload
      };
    }

    case RESET: {
      return initialState;
    }

    case SET_LOADING: {
      return {
        ...state,
        loading: action.payload
      };
    }

    default: {
      return state;
    }
  }
}
