import '../../modals/finances/addBill/AddBill.scss';
import './ViewEditBillForm.scss';

import { Col, Divider, Form, FormInstance, Row, Typography } from 'antd';
import VLXInputNumber from 'components/VLXInputNumber';
import useClinicId from 'hooks/useClinicId';
import { useLocale } from 'hooks/useLocale';
import { ILocale } from 'interfaces/locale';
import ClientCard from 'pages/appointments/components/ClientCard';
import React, { useCallback, useEffect, useState } from 'react';
import { IBillDetails } from 'services/clinic/bills/models/IBillDetails';

import { BillFormPrice } from './BillFormPrice';
import UsedStockItemsTable from './UsedStockItemsTable';

type Props = React.PropsWithChildren<{
  form: FormInstance;
  localization: ILocale;
  billDetails: IBillDetails;
  finalTotalPrice: number;
  setFinalTotalPrice: (val) => void;
  billDetailsPage: boolean;
}>;

export const ViewEditBillForm = ({
  form,
  localization,
  billDetails,
  children,
  finalTotalPrice,
  setFinalTotalPrice,
  billDetailsPage
}: Props): JSX.Element => {
  const clinicId = useClinicId();
  const locale = localization.labels;
  const currencies = useLocale('private.currencies').labels;

  const [stockTotalPrice, setStockTotalPrice] = useState(
    (Number(billDetails?.total_stock_price) - Number(billDetails?.stock_discount?.amount)).toFixed(
      2
    ) || 0
  );
  const [servicesTotalPrice, setServicesTotalPrice] = useState(
    (
      Number(billDetails?.total_services_price) - Number(billDetails?.services_discount?.amount)
    ).toFixed(2) || 0
  );

  useEffect(() => {
    form.resetFields();
  }, []);

  const onServiceDiscountPercentageChange = (val): void => {
    if (val === null) {
      form.setFieldValue(['services_discount', 'amount'], null);
      setServicesTotalPrice(Number(billDetails?.total_services_price).toFixed(2));
      setFinalTotalPrice(Number(billDetails?.total_services_price) + Number(stockTotalPrice));
      return;
    }
    const summ = billDetails?.total_services_price;
    const res = summ * val * 0.01;
    const total = (summ - res).toFixed(2);
    form.setFieldValue(['services_discount', 'amount'], res.toFixed(2));
    setServicesTotalPrice(total);
    setFinalTotalPrice(Number(total) + Number(stockTotalPrice));
  };

  const onServiceDiscountAmountChange = (val): void => {
    if (val === null) {
      form.setFieldValue(['services_discount', 'percentage'], null);
      setServicesTotalPrice(Number(billDetails?.total_services_price).toFixed(2));
      setFinalTotalPrice(Number(billDetails?.total_services_price) + Number(stockTotalPrice));
      return;
    }
    const summ = billDetails?.total_services_price;
    const res = (val / summ) * 100;
    const total = (summ - val).toFixed(2);
    form.setFieldValue(['services_discount', 'percentage'], Number(res).toFixed(0));
    setServicesTotalPrice(total);
    setFinalTotalPrice(Number(total) + Number(stockTotalPrice));
  };

  const onStockDiscountPercentageChange = (val): void => {
    if (val === null) {
      form.setFieldValue(['stock_discount', 'amount'], null);
      setStockTotalPrice(Number(billDetails?.total_stock_price).toFixed(2));
      setFinalTotalPrice(Number(billDetails?.total_stock_price) + Number(servicesTotalPrice));
      return;
    }
    const summ = billDetails?.total_stock_price;
    const res = summ * val * 0.01;
    const total = (summ - res).toFixed(2);
    form.setFieldValue(['stock_discount', 'amount'], res.toFixed(2));
    setStockTotalPrice(total);
    setFinalTotalPrice(Number(total) + Number(servicesTotalPrice));
  };

  const onStockDiscountAmountChange = (val): void => {
    if (val === null) {
      form.setFieldValue(['stock_discount', 'percentage'], null);
      setStockTotalPrice(Number(billDetails?.total_stock_price).toFixed(2));
      setFinalTotalPrice(Number(billDetails?.total_stock_price) + Number(servicesTotalPrice));
      return;
    }
    const summ = billDetails?.total_stock_price;
    const res = (val / summ) * 100;
    const total = (summ - val).toFixed(2);
    form.setFieldValue(['stock_discount', 'percentage'], Number(res).toFixed(0));
    setStockTotalPrice(total);
    setFinalTotalPrice(Number(total) + Number(servicesTotalPrice));
  };

  const DiscountInputs = useCallback(
    ({ type, onPercentageChange, onAmountChange, totalPrice }): JSX.Element => {
      return (
        <>
          <Col
            sm={24}
            md={12}
            style={{
              display: 'flex',
              flexDirection: 'row'
            }}
          >
            <Col sm={10} md={10} style={{ marginRight: '10px' }}>
              <Form.Item
                name={[type, 'percentage']}
                label={`${locale.discount} (%)`}
                initialValue={Number(billDetails?.[type]?.percentage)}
              >
                <VLXInputNumber
                  id={`${type}_percentage`}
                  max={100}
                  disabled={billDetailsPage}
                  onChange={onPercentageChange}
                />
              </Form.Item>
            </Col>
            <Col sm={10} md={10}>
              <Form.Item
                initialValue={Number(billDetails?.[type]?.amount).toFixed(2)}
                name={[type, 'amount']}
                label={`${locale.discount} (${currencies.uah})`}
              >
                <VLXInputNumber
                  id={`${type}_amount`}
                  disabled={billDetailsPage}
                  max={Number(totalPrice)?.toFixed(2) || 0}
                  onChange={onAmountChange}
                />
              </Form.Item>
            </Col>
          </Col>
        </>
      );
    },
    [billDetails]
  );

  return (
    <>
      <ClientCard appointmentClient={billDetails.client} clinicId={clinicId}>
        {billDetails?.appointment?.patients.map((item: any) => (
          <UsedStockItemsTable key={item.id} item={item} />
        ))}
        <Form autoComplete='off' layout='vertical' form={form}>
          <Row
            justify={'space-between'}
            style={{
              width: '100%',
              display: 'flex',
              flexDirection: 'column'
            }}
          >
            <Row>
              <Typography.Title level={4}>{locale.services}</Typography.Title>
            </Row>
            <Row justify={'space-between'}>
              <DiscountInputs
                type='services_discount'
                onPercentageChange={onServiceDiscountPercentageChange}
                onAmountChange={onServiceDiscountAmountChange}
                totalPrice={billDetails?.total_services_price}
              />
              <Col sm={24} md={12} className='service-price-container'>
                <BillFormPrice
                  label={locale.servicesPrice}
                  value={`${Number(billDetails?.total_services_price).toFixed(2) || 0} ${
                    currencies.uah
                  }`}
                  id='services-price'
                />
                <BillFormPrice
                  label={locale.finalPrice}
                  value={`${servicesTotalPrice} ${currencies.uah}`}
                  id='total-services-price'
                />
              </Col>
            </Row>
            {(billDetails?.total_stock_price > 0 || billDetailsPage) && (
              <>
                {' '}
                <Row>
                  <Typography.Title level={4}>{locale.stock}</Typography.Title>
                </Row>
                <Row justify={'space-between'}>
                  <DiscountInputs
                    type='stock_discount'
                    onPercentageChange={onStockDiscountPercentageChange}
                    onAmountChange={onStockDiscountAmountChange}
                    totalPrice={billDetails?.total_stock_price}
                  />
                  <Col sm={24} md={12} className='service-price-container'>
                    <BillFormPrice
                      label={locale.stockPrice}
                      value={`${Number(billDetails?.total_stock_price).toFixed(2) || 0} ${
                        currencies.uah
                      }`}
                      id='stock-price'
                    />
                    <BillFormPrice
                      label={locale.finalPrice}
                      value={`${stockTotalPrice} ${currencies.uah}`}
                      id='total-stock-price'
                    />
                  </Col>
                </Row>
              </>
            )}
          </Row>
          <Divider />
          <Row className='total-price-row'>
            <div style={{ display: 'flex' }}>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center'
                }}
              >
                <span
                  style={{
                    fontWeight: 600,
                    fontSize: '18px'
                  }}
                >
                  {`${locale.totalPrice}: `}
                </span>
              </div>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  marginLeft: '10px'
                }}
              >
                <span id='total-bill-price'>{`${finalTotalPrice?.toFixed(2)} ${
                  currencies.uah
                }`}</span>
              </div>
            </div>
          </Row>
          {!billDetailsPage && children}
        </Form>
      </ClientCard>
    </>
  );
};
