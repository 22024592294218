import 'assets/scss/pagination.scss';

import { Col, Form, Pagination, Row, Spin } from 'antd';
import SearchFiltersHorizontal from 'components/SearchFiltersHorizontal';
import useClinicId from 'hooks/useClinicId';
import { useLocale } from 'hooks/useLocale';
import { IAppState } from 'interfaces/app-state';
import Contact from 'layout/components/doctor/Contact';
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getEmployees, setSelectedEmployee } from 'redux/employees/actions';
import className from 'utils/class-names';

const getClass = (index: number, length: number): string =>
  className({
    'mb-0': index === length - 1,
    'mb-md-0': index === length - 2 || index === length - 3
  });

const EmployeesList = (): JSX.Element => {
  const dispatch = useDispatch();
  const clinicId = useClinicId();
  const employees = useSelector<IAppState, any>(({ employees }) => employees);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [currentPageSize, setCurrentPageSize] = useState(5);
  const [searchItemsArray, setSearchItemsArray] = useState([]);
  const [form] = Form.useForm();
  const locale = useLocale('private.administration.employees');

  const searchItems = useMemo(
    () => [
      {
        name: 'title',
        placeholder: locale.placeholders.title
      },
      {
        name: 'role',
        placeholder: locale.placeholders.role
      },
      {
        name: 'skill',
        placeholder: locale.placeholders.skill
      },
      {
        name: 'first_name',
        placeholder: locale.placeholders.firstName
      },
      {
        name: 'last_name',
        placeholder: locale.placeholders.lastName
      },
      {
        name: 'email',
        placeholder: locale.placeholders.email
      },
      {
        name: 'phone_number',
        placeholder: locale.placeholders.phone
      }
    ],
    [locale]
  );

  useEffect(() => {
    if (clinicId) dispatch(getEmployees(clinicId, 1));
  }, [clinicId]);

  useEffect(() => {
    dispatch(setSelectedEmployee(null));
  }, []);

  const onPageNumberChange = (currentPage): void => {
    dispatch(getEmployees(clinicId, currentPage, searchItemsArray, currentPageSize));
  };
  const onPageSizeChange = (itemsNumber): void => {
    dispatch(getEmployees(clinicId, currentPage, searchItemsArray, itemsNumber));
  };

  const onFinish = (): void => {
    const searchArray = [];
    const object = form.getFieldsValue();
    Object.entries(object).forEach(([key, value]) => {
      if (value) {
        searchArray.push({
          name: key,
          value
        });
      }
    });
    setCurrentPage(1);
    setSearchItemsArray(searchArray);
    dispatch(getEmployees(clinicId, 1, searchArray, currentPageSize));
  };

  return (
    <>
      <SearchFiltersHorizontal onFinish={onFinish} formInstance={form} object={searchItems} />
      <Spin spinning={!employees.data.length}>
        <div style={{ textAlign: 'end' }}>
          <Row justify='start' style={{ minWidth: '100%' }} gutter={[20, 20]}>
            {employees.data.map((employee, index) => (
              <Col key={index} span={24} sm={12} md={8} xl={6} xxl={5}>
                <Contact
                  locale={locale}
                  clinicId={clinicId}
                  className={getClass(index, employees.data.length)}
                  {...employee}
                />
              </Col>
            ))}
          </Row>
          <Row style={{ justifyContent: 'space-between', marginTop: 20, alignItems: 'flex-end' }}>
            <Pagination
              className={'employees-pagination'}
              responsive={true}
              showSizeChanger={false}
              showLessItems
              current={+currentPage}
              total={employees.metadata.total}
              defaultPageSize={5}
              showTotal={(total) => `${locale.labels.total} ${total}`}
              onChange={(current) => {
                setCurrentPage(current);
                onPageNumberChange(current);
              }}
              onShowSizeChange={(current, pageSize) => {
                setCurrentPageSize(pageSize);
                onPageSizeChange(pageSize);
              }}
            />
          </Row>
        </div>
      </Spin>
    </>
  );
};

export default EmployeesList;
