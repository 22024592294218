import animalsList from 'constants/dictionary/animals';
import { IPatient } from 'interfaces/patient';

export const localizedPetData = (
  data: IPatient,
  localeCode: string,
  speciesOnly?: boolean
): Record<string, string> => {
  const obj = {
    color: '',
    coat: '',
    breed: '',
    species: ''
  };
  if (!data?.species) return obj;

  const species = animalsList.find((el) => el.value === data.species);

  obj.species = species?.[localeCode];

  if (!species || speciesOnly) return obj;

  obj.breed = species.breeds.find((el) => el.value === data.breed)?.[localeCode] ?? '';
  obj.coat = species.coats?.find((el) => el.value === data.fur)?.[localeCode] ?? '';
  obj.color = species.colors?.find((el) => el.value === data.color)?.[localeCode] ?? '';

  return obj;
};
