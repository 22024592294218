import '../scss/ViewEditInvoice.scss';

import { Button, Spin } from 'antd';
import ConfirmDeleteModal from 'components/ConfirmDeleteModal';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { useLocale } from 'hooks/useLocale';
import { useUserAccess } from 'hooks/useUserAccess';
import { IAppState } from 'interfaces/app-state';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { getInvoiceById, getInvoiceStockItemsById, removeInvoice } from 'redux/inventory/invoice/actions';

import { AddItemsToStockModal } from '../../stock/modals/AddItemsToStockModal';
import EditInvoiceModal from '../EditInvoiceModal';
import { ViewEditInvoiceItemsTable } from '../ViewEditInvoiceItemsTable';
import { InvoiceDetailsHeader } from './InvoiceDetailsHeader';

dayjs.extend(customParseFormat);

const InvoiceDetails = (): JSX.Element => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { clinicId, invoiceId }: any = useParams();
  const userAccess = useUserAccess();
  const currencies = useLocale('private.currencies').labels;
  const locale = useLocale('private.inventory.invoices');

  const selectedInvoice = useSelector(({ invoices }: IAppState) => invoices.selectedInvoice);
  const selectedInvoiceStockItems = useSelector(
    ({ invoices }: IAppState) => invoices.selectedInvoiceStockItems?.data
  );
  const loading = useSelector(({ invoices }: IAppState) => invoices.loading);

  const [showEditInvoiceModal, setShowEditInvoiceModal] = useState<boolean>(false);
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
  const [showAddItemsToStockModal, setShowAddItemsToStockModal] = useState<boolean>(false);

  const editingDisabled = selectedInvoice?.status === 'paid' || selectedInvoice?.status === 'partially_paid';

  useEffect((): void => {
    dispatch(getInvoiceById(clinicId, invoiceId));
    dispatch(getInvoiceStockItemsById(clinicId, invoiceId));
  }, []);

  const onConfirmDeleteClick = (): void => {
    dispatch(removeInvoice(clinicId, invoiceId, locale));
    navigate(`/clinic/${clinicId}/inventory/invoices`);
  };

  return (
    <Spin spinning={loading} className='invoice-details-page-spin-container'>
      {!!selectedInvoice && (
        <div style={{ paddingLeft: '20px' }}>
          <div className={'name-statuses-form-header'}>
            <h4 style={{ marginRight: '8px' }} id='invoice-number'>
              {selectedInvoice.invoice_number}
            </h4>
          </div>
          <div className='invoice-row-container__form' style={{ marginBottom: 10 }}>
            <InvoiceDetailsHeader
              setShowEditInvoiceModal={setShowEditInvoiceModal}
              selectedInvoice={selectedInvoice}
              clinicId={clinicId}
              currencies={currencies}
              locale={locale}
              editingDisabled={editingDisabled}
            />
          </div>
          <div className='invoice-row-container__table'>
            {Boolean(selectedInvoiceStockItems) && (
              <ViewEditInvoiceItemsTable
                data={selectedInvoiceStockItems.reverse()}
              />
            )}
            {!editingDisabled && <div className='invoice-row-container__add-item-container'>
              <Button
                className='icofont icofont-plus'
                type={'primary'}
                style={{ borderRadius: 30, float: 'right' }}
                onClick={() => setShowAddItemsToStockModal(true)}
              >
                {locale.buttons.addInvoiceStockItems}
              </Button>
            </div>}
          </div>
          {showEditInvoiceModal && (
            <EditInvoiceModal
              locale={locale}
              selectedInvoice={selectedInvoice}
              invoiceId={invoiceId}
              setShowEditInvoiceModal={setShowEditInvoiceModal}
              showEditInvoiceModal={showEditInvoiceModal}
            />
          )}
          {showDeleteModal && (
            <ConfirmDeleteModal
              type={'device'}
              open={showDeleteModal}
              onOk={onConfirmDeleteClick}
              onCancel={() => setShowDeleteModal(false)}
            />
          )}
          {showAddItemsToStockModal && (
            <AddItemsToStockModal
              accessGroup={userAccess.accesses.invoices}
              invoiceId={invoiceId}
              locale={locale}
              setShowAddItemsToStockModal={setShowAddItemsToStockModal}
            />
          )}
        </div>
      )}
    </Spin>
  );
};

export default InvoiceDetails;
