import React from 'react';
import { Tag } from 'antd';

const getStatus = (stockData) => {
  let color = null;

  switch (stockData.value) {
    case 'in_stock':
      color = 'green';
      break;
    case 'critical_level':
      color = 'yellow';
      break;
    case 'out_of_stock':
      color = 'red';
      break;
  }

  return tag(color, stockData.label);
};

const tag = (color: string, value: string) => {
  return <Tag id='stock-status' color={color}>{value}</Tag>;
};

export default getStatus;
