import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { IPageData } from '../interfaces/page';
import { setPageData } from '../redux/page-data/actions';

export function usePageData(pageData: IPageData) {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setPageData({ ...pageData, loaded: true }));
  }, [pageData, dispatch]);
}
