import { Form, Input } from 'antd';
import ConfirmCancelModal from 'components/ConfirmCancelModal';
import ViewDetailsButtonsGroup from 'components/ViewDetailsButtonsGroup';
import VLXModal from 'components/VLXModal';
import { useLocale } from 'hooks/useLocale';
import { useUserAccess } from 'hooks/useUserAccess';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { cancelAppointmentOnDetails, cancelAppointmentOnList } from 'redux/appointments/actions';
import { requiredRule } from 'utils/form-rules';

const CancellationForm = ({
  listView,
  appointmentId,
  clinicId,
  showCancellationModal,
  setShowCancellationModal,
  currentPage,
  searchArray,
  getUserAppointmentsDataAfterSuccess
}: any): JSX.Element => {
  const [form] = Form.useForm();
  const userAccess = useUserAccess();
  const locale = useLocale('private.appointments.cancellation-form');
  const dispatch = useDispatch();
  const [showCancelModal, setShowCancelModal] = useState(false);

  useEffect(() => {
    form.resetFields();
    setShowCancelModal(false);
  }, []);

  function onSubmit() {
    const values = form.getFieldsValue();
    if (listView) {
      dispatch(
        cancelAppointmentOnList(
          clinicId,
          appointmentId,
          { cancellation_reason: values.reason },
          currentPage,
          searchArray,
          getUserAppointmentsDataAfterSuccess
        )
      );
    } else {
      dispatch(
        cancelAppointmentOnDetails(clinicId, appointmentId, { cancellation_reason: values.reason })
      );
    }
    form.resetFields();
    setShowCancellationModal(false);
  }

  return (
    <VLXModal
      title={locale.labels.appointmentCancellation}
      centered={true}
      form={form}
      open={showCancellationModal}
      onModalClose={() => setShowCancellationModal(false)}
      width={800}
    >
      <Form form={form} onFinish={onSubmit}>
        <Form.Item
          name='reason'
          label={locale.labels.reason}
          rules={[requiredRule(locale.errors.reasonCanNotBeEmpty)]}
        >
          <Input />
        </Form.Item>
        <ViewDetailsButtonsGroup
          fixed
          showCreateBtn
          accessGroup={userAccess.accesses.appointments}
          editMode={true}
          hideDeleteBtn={true}
          onCancelClick={() => {
            if (form.isFieldsTouched()) {
              setShowCancelModal(true);
            } else {
              setShowCancellationModal(false);
            }
          }}
        />
      </Form>
      <ConfirmCancelModal
        centered={true}
        open={showCancelModal}
        onOk={() => {
          setShowCancelModal(false);
        }}
        onCancel={() => {
          setShowCancelModal(false);
        }}
      />
    </VLXModal>
  );
};

export default CancellationForm;
