import { ISalesList } from 'interfaces/sales';

import {
  RESET,
  SalesActions,
  SET_SALE_DETAILS,
  SET_SALES,
  SET_SALES_LOADING,
  SHOW_SALES_MODAL
} from './types';

const initialState: ISalesList = {
  data: [],
  metadata: {
    total: 0,
    page: 1,
    next: null,
    last: 1,
    prev: null
  },
  loading: true,
  showModal: false,
  saleDetails: null
};

export function salesReducer(state: ISalesList = initialState, action: SalesActions): any {
  switch (action.type) {
    case SET_SALES: {
      return {
        ...state,
        ...action.payload
      };
    }

    case RESET: {
      return initialState;
    }

    case SHOW_SALES_MODAL: {
      return {
        ...state,
        showModal: action.payload
      };
    }

    case SET_SALES_LOADING: {
      return {
        ...state,
        loading: action.payload
      };
    }

    case SET_SALE_DETAILS: {
      return {
        ...state,
        saleDetails: action.payload
      };
    }

    default: {
      return state;
    }
  }
}
