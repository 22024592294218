import { IndexPaymentsResponse } from '../../../services/clinic/payments/models/IndexPaymentsResponse';
import { ShowPaymentResponse } from '../../../services/clinic/payments/models/ShowPaymentResponse';

export const SET_LOADING = 'SET_LOADING';
export const SET_PAYMENTS = 'SET_PAYMENTS';
export const SET_PAYMENT = 'SET_PAYMENT';
export const SHOW_MODAL = 'SHOW_MODAL';
export const RESET = '[payments] RESET';

export interface PaymentsResetAction {
  type: typeof RESET
}

export interface SetPaymentsAction {
  type: typeof SET_PAYMENTS
  payload: IndexPaymentsResponse
}

export interface SetLoadingAction {
  type: typeof SET_LOADING
  payload: boolean
}

export interface ShowModalAction {
  type: typeof SHOW_MODAL
  payload: boolean
}

export interface SetSelectedPaymentAction {
  type: typeof SET_PAYMENT
  payload: ShowPaymentResponse | null
}

export type PaymentsActions =
  | SetPaymentsAction
  | PaymentsResetAction
  | ShowModalAction
  | SetSelectedPaymentAction
  | SetLoadingAction;
