import { IClientsList } from '../../interfaces/client';
import {
  ClientsActions,
  GET_CLIENTS,
  GET_FILTERED_CLIENTS,
  MERGE_CLIENT_DOCUMENTS_LIST,
  RESET,
  SET_CLIENT,
  SET_CLIENT_DOCUMENTS_LIST,
  SET_CLIENT_PATIENTS,
  SET_CLIENTS_LOADING,
  SHOW_BALANCE_REPLANISHMENT_MODAL,
  SHOW_CLIENT_MODAL,
} from './types';

const initialState: IClientsList = {
  data: [],
  metadata: {
    total: 0,
    page: 1,
    next: null,
    last: 1,
    prev: null
  },
  loading: true,
  showModal: false,
  selectedClient: null,
  clientPatients: null,
  filteredClients: [],
  selectedClientDocuments: [],
  showBalanceReplenishmentModal: false
};

export function clientsReducer(state: IClientsList = initialState, action: ClientsActions): any {
  switch (action.type) {
    case RESET: {
      return initialState;
    }

    case GET_CLIENTS: {
      return {
        ...state,
        ...action.payload
      };
    }

    case GET_FILTERED_CLIENTS: {
      return {
        ...state,
        filteredClients: action.payload.data
      };
    }

    case SHOW_CLIENT_MODAL: {
      return {
        ...state,
        showModal: action.payload
      };
    }

    case SET_CLIENTS_LOADING: {
      return {
        ...state,
        loading: action.payload
      };
    }

    case SET_CLIENT: {
      return {
        ...state,
        selectedClient: action.payload
      };
    }

    case SET_CLIENT_PATIENTS: {
      return {
        ...state,
        clientPatients: action.payload
      };
    }

    case SET_CLIENT_DOCUMENTS_LIST: {
      return {
        ...state,
        selectedClientDocuments: action.payload
      };
    }

    case MERGE_CLIENT_DOCUMENTS_LIST: {
      return {
        ...state,
        selectedClientDocuments: [action.payload, ...state.selectedClientDocuments]
      };
    }

    case SHOW_BALANCE_REPLANISHMENT_MODAL: {
      return {
        ...state,
        showBalanceReplenishmentModal: action.payload
      };
    }

    default: {
      return state;
    }
  }
}
