import { ISaleStockItem } from 'interfaces/sales';

export interface ISalePayloadItem {
  stock_id: number;
  location_id: number;
  price_id: number;
  quantity_in_packages: number;
  quantity_in_units: number;
  price: string;
}

export const formPayloadItems = (item: ISaleStockItem): ISalePayloadItem => ({
  stock_id: item.id,
  location_id: item.stock_location.id,
  price_id: item.stock_price.id,
  quantity_in_packages: item.quantity_in_packages || 0,
  quantity_in_units: item.quantity_in_units || 0,
  price: Number(item.price).toFixed(2)
});
