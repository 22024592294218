import '../scss/StockDetails.scss';

import { Form } from 'antd';
import ConfirmCancelModal from 'components/ConfirmCancelModal';
import ConfirmDeleteModal from 'components/ConfirmDeleteModal';
import ViewDetailsButtonsGroup from 'components/ViewDetailsButtonsGroup';
import VLXModal from 'components/VLXModal';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { ILocale } from 'interfaces/locale';
import { IAccesses } from 'interfaces/user-accesses';
import React, { Dispatch, SetStateAction, useState } from 'react';
import { ISelectedStock } from 'services/interfaces/clinics/inventory/stock/ISelectedStock';

import { AddItemsToStockForm } from '../forms/AddItemsToStockForm';

dayjs.extend(customParseFormat);

type Props = {
  accessGroup: IAccesses;
  locale: ILocale;
  invoiceId?: number;
  selectedStock?: ISelectedStock;
  setShowAddItemsToStockModal: Dispatch<SetStateAction<boolean>>;
};

export const AddItemsToStockModal = ({
  accessGroup,
  locale,
  invoiceId,
  selectedStock,
  setShowAddItemsToStockModal
}: Props): JSX.Element => {
  const [form] = Form.useForm();

  const [showItemCancelModal, setShowItemCancelModal] = useState<boolean>(false);
  const [showDeleteItemModal, setShowDeleteItemModal] = useState<boolean>(false);

  const onConfirmCancelClick = (): void => {
    form.resetFields();
    setShowItemCancelModal(false);
    setShowAddItemsToStockModal(false);
  };

  return (
    <>
      <VLXModal
        title={locale.labels.createStockItem}
        centered
        form={form}
        open
        onModalClose={() => {
          setShowAddItemsToStockModal(false);
        }}
        width={800}
      >
        <AddItemsToStockForm
          invoiceId={invoiceId}
          form={form}
          selectedStock={selectedStock}
          setShowAddItemsToStockModal={setShowAddItemsToStockModal}
        >
          <ViewDetailsButtonsGroup
            fixed
            accessGroup={accessGroup}
            editMode
            hideDeleteBtn
            onCancelClick={() => {
              if (form.isFieldsTouched()) {
                setShowDeleteItemModal(false);
                setShowItemCancelModal(true);
              } else {
                setShowAddItemsToStockModal(false);
              }
            }}
            onDeleteClick={() => {
              setShowItemCancelModal(false);
              setShowDeleteItemModal(true);
            }}
          />
        </AddItemsToStockForm>
      </VLXModal>
      <ConfirmCancelModal
        open={showItemCancelModal}
        onOk={onConfirmCancelClick}
        onCancel={() => setShowItemCancelModal(false)}
      />
      <ConfirmDeleteModal
        type={'item'}
        open={showDeleteItemModal}
        onCancel={() => setShowDeleteItemModal(false)}
      />
    </>
  );
};
