const animalColors = [
  {
    value: 'black',
    enGB: 'Black',
    ukUA: 'Чорний'
  },
  {
    value: 'white',
    enGB: 'White',
    ukUA: 'Білий'
  },
  {
    value: 'brown',
    enGB: 'Brown',
    ukUA: 'Коричневий'
  },
  {
    value: 'gray',
    enGB: 'Gray',
    ukUA: 'Сірий'
  },
  {
    value: 'red',
    enGB: 'Red',
    ukUA: 'Рудий'
  },
  {
    value: 'fawn',
    enGB: 'Fawn',
    ukUA: 'Пісочний'
  },
  {
    value: 'cream',
    enGB: 'Cream',
    ukUA: 'Кремовий'
  },
  {
    value: 'sable',
    enGB: 'Sable',
    ukUA: 'Соболь'
  },
  {
    value: 'blue',
    enGB: 'Blue',
    ukUA: 'Синій'
  },
  {
    value: 'merle',
    enGB: 'Merle',
    ukUA: 'Мерл'
  },
  {
    value: 'brindle',
    enGB: 'Brindle',
    ukUA: 'Тигровий'
  },
  {
    value: 'tan',
    enGB: 'Tan',
    ukUA: 'Плямистий'
  },
  {
    value: 'saddle',
    enGB: 'Saddle',
    ukUA: 'Сідельний'
  },
  {
    value: 'parti-color',
    enGB: 'Parti-color',
    ukUA: 'Багатобарвний'
  },
  {
    value: 'harlequin',
    enGB: 'Harlequin',
    ukUA: 'Арлекін'
  },
  {
    value: 'piebald',
    enGB: 'Piebald',
    ukUA: 'Пайболд'
  },
  {
    value: 'tricolor',
    enGB: 'Tricolor',
    ukUA: 'Триколор'
  },
  {
    value: 'roan',
    enGB: 'Roan',
    ukUA: 'Роан'
  },
  {
    value: 'wolf sable',
    enGB: 'Wolf Sable',
    ukUA: 'Вовчий соболь'
  },
  {
    value: 'silver',
    enGB: 'Silver',
    ukUA: 'Сріблястий'
  },
  {
    value: 'liver',
    enGB: 'Liver',
    ukUA: 'Коричневий (печіночний)'
  },
  {
    value: 'chocolate',
    enGB: 'Chocolate',
    ukUA: 'Шоколадний'
  },
  {
    value: 'silver brindle',
    enGB: 'Silver Brindle',
    ukUA: 'Сріблястий тигровий'
  },
  {
    value: 'blue merle',
    enGB: 'Blue Merle',
    ukUA: 'Синій мерл'
  },
  {
    value: 'red merle',
    enGB: 'Red Merle',
    ukUA: 'Рудий мерл'
  },
  {
    value: 'albino',
    enGB: 'Albino',
    ukUA: 'Альбінос'
  },
  {
    value: 'other',
    enGB: 'Other',
    ukUA: 'Інший'
  }
];

export default animalColors;
