import { IClinic } from '../../interfaces/clinic';

export const GET_CLINIC = '[clinics] Set';
export const SHOW_MODAL = '[clinics] Add';
export const SET_DOCS_LOADING = '[clinics] Docs Loading';
export const SET_CLINIC_LOADING = '[clinics] Clinic Loading';
export const SET_PERMISSIONS = '[clinics] Permissions';
export const SET_CLINIC_DOCUMENTS_LIST = '[clinics] Documents set';
export const MERGE_CLINIC_DOCUMENTS_LIST = '[clinics] Documents merge';
export const RESET = '[clinics] RESET';

export interface ClinicsResetAction {
  type: typeof RESET;
}

export interface GetClinicAction {
  type: typeof GET_CLINIC;
  payload: IClinic;
}

export interface ShowModalAction {
  type: typeof SHOW_MODAL;
  payload: boolean;
}

export interface SetLoadingAction {
  type: typeof SET_CLINIC_LOADING;
  payload: boolean;
}

export interface SetDocsLoadingAction {
  type: typeof SET_DOCS_LOADING;
  payload: boolean;
}

export interface SetPermissionsAction {
  type: typeof SET_PERMISSIONS;
  payload: string[];
}

export interface SetSelectedClinicDocumentsListAction {
  type: typeof SET_CLINIC_DOCUMENTS_LIST;
  payload: any;
}

export interface MergeClinicDocumentsListAction {
  type: typeof MERGE_CLINIC_DOCUMENTS_LIST;
  payload: any;
}

export type ClinicActions =
  | GetClinicAction
  | ClinicsResetAction
  | ShowModalAction
  | SetLoadingAction
  | SetDocsLoadingAction
  | SetPermissionsAction
  | SetSelectedClinicDocumentsListAction
  | MergeClinicDocumentsListAction;
