import './AuditDetails.scss';

import { Button, Form, Pagination, Table, TableColumnProps, Tag, Tooltip } from 'antd';
import ConfirmDeleteModal from 'components/ConfirmDeleteModal';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { useI18n } from 'hooks/usei18n';
import { useLocale } from 'hooks/useLocale';
import { measurementsLocale } from 'i18n/measurements';
import { packagesLocale } from 'i18n/packages';
import { IAppState } from 'interfaces/app-state';
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { destroyAuditItem, getAuditItemsById } from 'redux/inventory/audit/actions';
import { ISelectedAuditItem } from 'services/interfaces/clinics/inventory/audit/ISelectedAuditItemsData';

import { PerformAuditItemModal } from '../AuditModals/PerformAuditItemModal';
import { Stat } from '../components/Stat';
import { itemColumnTitle } from './utils/itemColumnTitle';

dayjs.extend(customParseFormat);

export const AuditItemsTable = ({
  itemsData,
  selectedAudit,
  currentPage,
  setCurrentPage,
  locale,
  clinicId
}: any): JSX.Element => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const measurements = useI18n(measurementsLocale);
  const packages = useI18n(packagesLocale);
  const currencies = useLocale('private.currencies').labels;

  const getLoading = useSelector(({ audit }: IAppState) => audit.getLoading);
  const metadata = useSelector(({ audit }: IAppState) => audit.selectedAuditItems.metadata);

  const [selectedStockId, setSelectedStockId] = useState<number>();
  const [showDeleteItemModal, setShowDeleteItemModal] = useState<boolean>(false);
  const [showPerformAuditItemModal, setShowPerformAuditItemModal] = useState<boolean>(false);
  const [itemIdToDelete, setItemIdToDelete] = useState(null);

  useEffect(() => {
    if (metadata.last < currentPage) {
      setCurrentPage(metadata.last);
    }
  }, [metadata]);

  const displayTotalStats = (stock: ISelectedAuditItem): JSX.Element => {
    const surplus = +stock.global_stats.items_surplus_packages;
    const shortage = +stock.global_stats.items_shortage_packages;
    const unit =
      stock.packaging_format === 'none'
        ? measurements[stock.unit_of_measurement]
        : packages[stock.packaging_format];

    return (
      <>
        {surplus !== 0 && (
          <>
            <Stat color='green'>
              {`+${surplus} ${unit} (~${stock.global_stats.money_surplus_packages?.toFixed(2)} ${
                currencies.uah
              })`}
            </Stat>
            <br />
          </>
        )}
        {shortage !== 0 && (
          <Stat color='red'>
            {`-${Math.abs(shortage)} ${unit} (~${Math.abs(
              stock.global_stats.money_shortage_packages
            ).toFixed(2)} ${currencies.uah})`}
          </Stat>
        )}
        {surplus === 0 && shortage === 0 && '-  '}
      </>
    );
  };

  const columns = useMemo(
    (): TableColumnProps<ISelectedAuditItem>[] =>
      [
        {
          key: 'name',
          title: locale.labels.name,
          sorter: (a, b) => (a.name > b.name ? 1 : -1),
          render: (name, e) => (
            <Link className={'custom-links'} to={`/clinic/${clinicId}/inventory/stock/${e?.id}`}>
              {e?.name}
            </Link>
          )
        },
        {
          key: 'manufacturer',
          title: locale.labels.manufacturer,
          sorter: (a, b) => (a.manufacturer > b.manufacturer ? 1 : -1),
          render: (e) => e.manufacturer || '-'
        },
        {
          key: 'completed',
          title: locale.labels.status,
          sorter: (a, b) => (a.progress_status > b.progress_status ? 1 : -1),
          render: ({ progress_status }): JSX.Element => {
            const isFinished = progress_status === 'finished';

            if (progress_status === 'no_match') {
              return (
                <Tooltip title={locale.labels.incorrectQuantityTooltip}>
                  <Tag className='appointment-status' color={'red'}>
                    {locale.labels.incorrectQuantity}
                  </Tag>
                </Tooltip>
              );
            }
            return (
              <Tag className='appointment-status' color={isFinished ? 'green' : 'red'}>
                {isFinished ? locale.labels.completed : locale.labels.uncompleted}
              </Tag>
            );
          }
        },
        {
          key: 'category',
          title: locale.labels.category,
          sorter: (a, b) => (a.category > b.category ? 1 : -1),
          render: (e) => e.category || '-'
        },
        {
          key: 'total_diff',
          title: itemColumnTitle(locale.labels.statistics),
          render: displayTotalStats
        },
        {
          key: 'measurements',
          title: locale.labels.measurements,
          render: (e: ISelectedAuditItem) =>
            e.packaging_format === 'none'
              ? measurements[e.unit_of_measurement]
              : `1 ${packages[e.packaging_format] || ''} = ${e.quantity_per_package} ${
                  measurements[e.unit_of_measurement]
                }`
        },
        {
          key: 'actions',
          title: locale.labels.operation,
          render: (record: ISelectedAuditItem, _, index) => (
            <div className='buttons-list nowrap center'>
              <Button
                style={{ zIndex: 8 }}
                onClick={() => {
                  setSelectedStockId(index);
                  setShowPerformAuditItemModal(true);
                }}
                type='primary'
                shape='circle'
                className={'inventory-update-buttons'}
              >
                <span className='icofont icofont-play-alt-2' />
              </Button>
              <Button
                style={{ zIndex: 8 }}
                onClick={(e) => {
                  e.stopPropagation();
                  setItemIdToDelete(record.id);
                  setShowDeleteItemModal(true);
                }}
                danger
                type='primary'
                shape='circle'
                className={'inventory-update-buttons'}
              >
                <span className='icofont icofont-trash' />
              </Button>
            </div>
          )
        }
      ].filter((el) => (selectedAudit?.status !== 'finished' ? true : el.key !== 'actions')),
    [itemsData, selectedAudit?.status]
  );

  const removeStockItem = (stockId: number): void => {
    dispatch(destroyAuditItem(clinicId, selectedAudit.id, stockId, currentPage));
  };

  const onConfirmDeleteItemClick = (): void => {
    removeStockItem(itemIdToDelete);
    setItemIdToDelete(null);
    setShowDeleteItemModal(false);
  };

  const onPageChange = (page: number): void => {
    setCurrentPage(page);
    dispatch(getAuditItemsById(clinicId, selectedAudit.id, page));
  };

  return (
    <>
      <Form form={form} component={false}>
        <Table
          dataSource={itemsData}
          loading={getLoading}
          columns={columns}
          rowKey='id'
          onRow={(record, rowIndex) => ({
            onClick: (event) => {
              event.preventDefault();
              setSelectedStockId(rowIndex);
              setShowPerformAuditItemModal(true);
            }
          })}
          pagination={false}
          className='accent-header audits-list-table'
        />
        <Pagination
          defaultCurrent={currentPage}
          pageSize={20}
          showSizeChanger={false}
          showLessItems
          current={currentPage}
          total={metadata.total}
          onChange={onPageChange}
        />
      </Form>
      {showPerformAuditItemModal && (
        <PerformAuditItemModal
          currentPage={currentPage}
          selectedStock={itemsData[selectedStockId]}
          locale={locale}
          setShowPerformAuditItemModal={setShowPerformAuditItemModal}
        />
      )}
      <ConfirmDeleteModal
        type={'item'}
        open={showDeleteItemModal}
        onOk={onConfirmDeleteItemClick}
        onCancel={() => {
          setShowDeleteItemModal(false);
          setItemIdToDelete(null);
        }}
      />
    </>
  );
};
