import { PaymentsActions, SET_PAYMENTS, SET_LOADING, SET_PAYMENT, SHOW_MODAL, RESET } from './types';
import { IndexPaymentsResponse } from '../../../services/clinic/payments/models/IndexPaymentsResponse';
import { ShowPaymentResponse } from '../../../services/clinic/payments/models/ShowPaymentResponse';
import { IMetaData } from '../../../interfaces/metadata';

export interface IPaymentsListState extends IndexPaymentsResponse {
  loading: boolean
  showModal: boolean
  selectedBill: ShowPaymentResponse
  metadata: IMetaData
  data: ShowPaymentResponse[]
}

const initialState: IPaymentsListState = {
  data: [],
  metadata: {
    total: 0,
    page: 1,
    next: null,
    last: 1,
    prev: null
  },
  loading: false,
  selectedBill: null,
  showModal: false
};

export function paymentsReducer (
  state: IPaymentsListState = initialState,
  action: PaymentsActions
): any {
  switch (action.type) {
    case SET_PAYMENTS: {
      return {
        ...state,
        ...action.payload,
        data: action.payload.data
      };
    }

    case SET_LOADING: {
      return {
        ...state,
        loading: action.payload
      };
    }

    case RESET: {
      return initialState;
    }

    case SHOW_MODAL: {
      return {
        ...state,
        showModal: action.payload
      };
    }

    case SET_PAYMENT: {
      return {
        ...state,
        selectedBill: action.payload
      };
    }

    default: {
      return state;
    }
  }
}
