import { AxiosResponse } from 'axios';
import {
  CreateAuditRequest,
  CreateAuditResponse,
} from 'services/interfaces/clinics/inventory/audit/CreateAuditRequest';
import { ISelectedAuditItemsData } from 'services/interfaces/clinics/inventory/audit/ISelectedAuditItemsData';
import { ISortObject } from 'services/interfaces/ISortObject';

import baseClient from '../../baseClient';
import { generateSearchQuery, sorting } from '../../helpers/commonUtils';
import { AddItemsToAuditRequest } from '../../interfaces/clinics/inventory/audit/AddItemsToAuditRequest';
import { AuditItemsHeadResponse } from '../../interfaces/clinics/inventory/audit/AuditItemsHeadResponse';
import { AuditItemUpdateRequest } from '../../interfaces/clinics/inventory/audit/AuditItemUpdateRequest';
import { IAuditList, IAuditListItem } from '../../interfaces/clinics/inventory/audit/IAuditList';

const auditUrl = (clinicId: number): string => {
  return `/v1/clinics/${clinicId}/audits`;
};

export const getClinicAudit = async (clinicId: number, page: number, searchArray?: any[], sortObject?: ISortObject) =>
  baseClient
    .get(`${auditUrl(clinicId)}?page=${page}${generateSearchQuery(searchArray)}${sorting(sortObject)}`)
    .then((response: AxiosResponse<IAuditList>) => {
      return response.data;
    });

export const createClinicAuditReport = async (clinicId: number, auditId: number): Promise<void> =>
  baseClient.post(`${auditUrl(clinicId)}/${auditId}/reports`);

export const getClinicAuditById = async (
  clinicId: number,
  auditId: number
): Promise<IAuditListItem> =>
  baseClient
    .get(`${auditUrl(clinicId)}/${auditId}`)
    .then((response: AxiosResponse<IAuditListItem>): IAuditListItem => response.data);

export const getClinicAuditItemsById = async (
  clinicId: number,
  auditId: number,
  page: number = 1
): Promise<ISelectedAuditItemsData> =>
  baseClient
    .get(`${auditUrl(clinicId)}/${auditId}/items?page=${page}`)
    .then(
      (response: AxiosResponse<ISelectedAuditItemsData>): ISelectedAuditItemsData => response.data
    );

export const headClinicAuditItemsById = async (clinicId: number, auditId: number) =>
  baseClient
    .get(`${auditUrl(clinicId)}/${auditId}/items/head`)
    .then((response: AxiosResponse<AuditItemsHeadResponse>) => {
      return response.data;
    });

export const createClinicAudit = async (
  clinicId: number,
  data: CreateAuditRequest
): Promise<CreateAuditResponse> =>
  baseClient
    .post(`${auditUrl(clinicId)}`, data)
    .then((response: AxiosResponse<CreateAuditResponse>) => response.data);

export const updateClinicAudit = async (
  clinicId: number,
  auditId: number,
  data: CreateAuditRequest
): Promise<CreateAuditResponse> =>
  baseClient
    .put(`${auditUrl(clinicId)}/${auditId}`, data)
    .then((response: AxiosResponse<CreateAuditResponse>) => response.data);

export const removeClinicAudit = async (clinicId: number, auditId: number): Promise<void> =>
  baseClient.delete(`${auditUrl(clinicId)}/${auditId}`);

export const createClinicAuditItem = async (
  clinicId: number,
  selectedIds: AddItemsToAuditRequest,
  auditId: number
) => baseClient.post(`${auditUrl(clinicId)}/${auditId}/items`, selectedIds);

export const updateClinicAuditItem = async (
  clinicId: number,
  auditId: number,
  auditItemId: number,
  data: AuditItemUpdateRequest
): Promise<void> => baseClient.put(`${auditUrl(clinicId)}/${auditId}/items/${auditItemId}`, data);

export const destroyClinicAuditItem = async (
  clinicId: number,
  auditId: number,
  stockId: number
): Promise<void> => baseClient.delete(`${auditUrl(clinicId)}/${auditId}/items/${stockId}`);
