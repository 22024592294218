import './AppointmentDetails.scss';

import { Checkbox, Col, DatePicker, Form, FormInstance, Input, Row, Select } from 'antd';
import { TextEditor } from 'components/TextEditor';
import { patientState } from 'constants/dictionary/default/selectOptions';
import dayjs from 'dayjs';
import useClinicId from 'hooks/useClinicId';
import useLocalizedList from 'hooks/useLocalizedList';
import { IAppState } from 'interfaces/app-state';
import { IAppointment } from 'interfaces/appointment.ts';
import { IHealthRecord } from 'interfaces/health-record.ts';
import { ILocale } from 'interfaces/locale.ts';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  addAppointmentDetailsPatientHealthRecords,
  putAppointmentDetailsPatientHealthRecords
} from 'redux/appointments/actions';
import { createDiagnosis, indexDiagnoses } from 'redux/dictionaries/diagnoses/actions';
import { getServices } from 'redux/services/actions';
import { ShowDictionaryRecordResponse } from 'services/clinic/dictionaries/models/ShowDictionaryRecordResponse.ts';

type Props = {
  form: FormInstance;
  initialValues: IHealthRecord;
  locale: ILocale;
  patientId: number;
  onFieldsChange: () => void;
  appointment: IAppointment;
  disabled: boolean;
  editMode: boolean;
  setEditMode: (value: boolean) => void;
  children: React.ReactNode;
  createNewRecord: boolean;
};

export const AppointmentDetailsPatientTabForm = ({
  form,
  initialValues,
  children,
  locale,
  patientId,
  onFieldsChange,
  appointment,
  disabled,
  createNewRecord,
  setEditMode
}: Props): JSX.Element => {
  const dispatch = useDispatch();
  const clinicId = useClinicId();

  const diagnoses = useSelector<IAppState, ShowDictionaryRecordResponse[]>(
    (state) => state.diagnoses.data
  );
  const [selectedDiagnosis, setSelectedDiagnosis] = useState<any>([]);
  const [selectedProvisionalDiagnosis, setSelectedProvisionalDiagnosis] = useState<any>([]);
  const [noDiagnosisRequired, setNoDiagnosisRequired] = useState<boolean>(false);

  useEffect(() => {
    form.resetFields();
    dispatch(indexDiagnoses(clinicId, 0));
  }, []);

  useEffect(() => {
    if (patientId && !initialValues?.id) {
      setNoDiagnosisRequired(false);
      setSelectedDiagnosis([]);
      setSelectedProvisionalDiagnosis([]);
    }
  }, [patientId]);

  useEffect(() => {
    setEditMode(false);

    if (initialValues?.id) {
      setNoDiagnosisRequired(initialValues?.no_diagnosis_required);
      setSelectedDiagnosis(tryParseData(initialValues?.diagnosis));
      setSelectedProvisionalDiagnosis(tryParseData(initialValues?.provisional_diagnosis));
    } else {
      setNoDiagnosisRequired(false);
      setSelectedDiagnosis([]);
      setSelectedProvisionalDiagnosis([]);
    }
  }, [initialValues]);

  function tryParseData(data: any) {
    if (data === null) {
      return [];
    }
    try {
      return JSON.parse(data);
    } catch (e) {
      return [data];
    }
  }

  const onSubmit = (data: any) => {
    const apiData = { ...data };
    // TODO: refactor this
    if (data.date_of_next_visit) {
      apiData.date_of_next_visit = dayjs(data.date_of_next_visit).format('DD.MM.YYYY');
    } else apiData.date_of_next_visit = null;
    if (data.diagnosis) {
      try {
        apiData.diagnosis = JSON.stringify(data.diagnosis);
      } catch (e) {
        apiData.diagnosis = data.diagnosis;
      }
    }
    if (data.provisional_diagnosis) {
      try {
        apiData.provisional_diagnosis = JSON.stringify(data.provisional_diagnosis);
      } catch (e) {
        apiData.provisional_diagnosis = data.provisional_diagnosis;
      }
    }
    if (!data.diagnosis && !data.provisional_diagnosis) {
      apiData.no_diagnosis_required = true;
    }
    apiData.services_ids = initialValues?.services.map((el: any) => el.id) || [];
    apiData.clinic_id = clinicId;
    if (createNewRecord) {
      dispatch(
        addAppointmentDetailsPatientHealthRecords(clinicId, appointment?.id, patientId, apiData)
      );
    } else {
      dispatch(
        putAppointmentDetailsPatientHealthRecords(
          clinicId,
          patientId,
          initialValues.id,
          appointment?.id,
          apiData
        )
      );
    }
  };

  useEffect(() => {
    form.resetFields();
  }, [initialValues, patientId]);

  const localizedPatientState = useLocalizedList(patientState);

  useEffect(() => {
    if (clinicId) {
      dispatch(getServices(clinicId, 0));
    }
  }, [clinicId]);

  const mapValues = () => {
    if (initialValues) {
      const data = {
        ...initialValues
      };
      if (initialValues.diagnosis) {
        try {
          data.diagnosis = JSON.parse(initialValues.diagnosis as string);
        } catch (e) {
          data.diagnosis = initialValues.diagnosis;
        }
      } else {
        data.diagnosis = [];
      }
      if (initialValues.provisional_diagnosis) {
        try {
          data.provisional_diagnosis = JSON.parse(initialValues.provisional_diagnosis);
        } catch (e) {
          data.provisional_diagnosis = initialValues.provisional_diagnosis;
        }
      } else {
        data.provisional_diagnosis = [];
      }
      if (initialValues.date_of_next_visit) {
        data.date_of_next_visit = dayjs(initialValues.date_of_next_visit);
      }
      return data;
    } else if (!initialValues && appointment?.services) {
      const data = { services_ids: null };
      data.services_ids = appointment?.services.map((el: any) => el.id);
      return data;
    } else {
      return null;
    }
  };

  function onChangeNoDiagnosisRequired(e: any) {
    setNoDiagnosisRequired(e.target.checked);
    if (e.target.checked) {
      form.setFieldsValue({
        diagnosis: [],
        provisional_diagnosis: []
      });
      form.setFields([
        {
          name: 'diagnosis',
          errors: []
        },
        {
          name: 'provisional_diagnosis',
          errors: []
        }
      ]);
      setSelectedDiagnosis([]);
      setSelectedProvisionalDiagnosis([]);
    }
  }

  function formatValue(value: any) {
    const lowerCaseValue = value.toLowerCase();
    return lowerCaseValue.charAt(0).toUpperCase() + lowerCaseValue.slice(1);
  }

  function onDiagnosisSelect(value: any) {
    const formattedValue = formatValue(value);
    if (!diagnoses.find((item: any) => item.name.toLowerCase() === formattedValue.toLowerCase())) {
      dispatch(
        createDiagnosis(
          clinicId,
          {
            name: formattedValue,
            description: null
          },
          0
        )
      );
    }
    form.setFields([
      {
        name: 'provisional_diagnosis',
        errors: []
      }
    ]);
    setSelectedDiagnosis([...selectedDiagnosis, formattedValue]);
  }

  function onDiagnosisDeselect(value: any) {
    setSelectedDiagnosis(selectedDiagnosis.filter((item: any) => item !== value));
  }

  function onProvisionalDiagnosisSelect(value: any) {
    const formattedValue = formatValue(value);
    if (!diagnoses.find((item: any) => item.name.toLowerCase() === formattedValue.toLowerCase())) {
      dispatch(
        createDiagnosis(
          clinicId,
          {
            name: formattedValue,
            description: null
          },
          0
        )
      );
    }
    form.setFields([
      {
        name: 'diagnosis',
        errors: []
      }
    ]);
    setSelectedProvisionalDiagnosis([...selectedProvisionalDiagnosis, formattedValue]);
  }

  function onProvisionalDiagnosisDeselect(value: any) {
    setSelectedProvisionalDiagnosis(
      selectedProvisionalDiagnosis.filter((item: any) => item !== value)
    );
  }

  return (
    <Form
      onFieldsChange={onFieldsChange}
      onFinish={onSubmit}
      autoComplete='off'
      layout='vertical'
      form={form}
      initialValues={mapValues()}
      disabled={disabled}
    >
      <Row gutter={[20, 10]}>
        <Col span={24} md={12}>
          <Form.Item label={locale.labels.complaints} name={'complaints'}>
            <Input />
          </Form.Item>
          <Form.Item
            label={locale.labels.noDiagnosisRequired}
            name='no_diagnosis_required'
            className='flat-checkbox-form-item'
            valuePropName='checked'
          >
            <Checkbox onChange={onChangeNoDiagnosisRequired} />
          </Form.Item>
          <Form.Item
            label={locale.labels.diagnosis}
            className='add-appointment-form__multi-select-field'
            name={'diagnosis'}
          >
            <Select
              value={selectedDiagnosis}
              disabled={noDiagnosisRequired || disabled}
              options={diagnoses.map((item: any) => ({
                key: item.id,
                value: item.name
              }))}
              mode='tags'
              onSelect={onDiagnosisSelect}
              onDeselect={onDiagnosisDeselect}
            />
          </Form.Item>
          <Form.Item
            label={locale.labels.preliminaryDiagnosis}
            name={'provisional_diagnosis'}
            className='add-appointment-form__multi-select-field'
          >
            <Select
              value={selectedProvisionalDiagnosis}
              disabled={noDiagnosisRequired || disabled}
              options={diagnoses.map((item: any) => ({
                key: item.id,
                value: item.name
              }))}
              mode='tags'
              onSelect={onProvisionalDiagnosisSelect}
              onDeselect={onProvisionalDiagnosisDeselect}
            />
          </Form.Item>
          <TextEditor label={locale.labels.anamnesis} name={['anamnesis']} />
        </Col>
        <Col span={24} md={12}>
          <Form.Item label={locale.labels.condition} name={'patient_state'}>
            <Select options={localizedPatientState} />
          </Form.Item>
          <TextEditor label={locale.labels.recommendations} name={['recommendations']} />
          {/* <Button type={'primary'} style={{ borderRadius: 30, marginBottom: 20 }}>{locale.buttons.createTreatmentPlan}</Button> */}
          <Form.Item label={locale.labels.returnVisitRecomended} name='date_of_next_visit'>
            <DatePicker disabledDate={(d) => !d || d.isBefore(dayjs())} format='YYYY-MM-DD' />
          </Form.Item>
          <TextEditor label={locale.labels.notes} name={['notes']} />
        </Col>
      </Row>
      {children}
    </Form>
  );
};
