import { IMetaData } from '../../../interfaces/metadata';
import { IndexAccountsResponse } from '../../../services/clinic/accounts/models/IndexAccountsResponse';
import { ShowAccountResponse } from '../../../services/clinic/accounts/models/ShowAccountResponse';
import {
  AccountsActions,
  RESET,
  SET_ACCOUNT,
  SET_ACCOUNT_PAYMENTS,
  SET_ACCOUNTS,
  SET_LOADING,
  SHOW_EDIT_MODAL,
  SHOW_MODAL,
  SHOW_TRANSFER_MODAL,
} from './types';

export interface IAccontsListState extends IndexAccountsResponse {
  loading: boolean
  showModal: boolean
  showEditModal: boolean
  selectedAccount: ShowAccountResponse
  metadata: IMetaData
  data: ShowAccountResponse[]
  selectedAccountPayments: ShowAccountResponse[]
  showTransferModal: boolean
}

const initialState: IAccontsListState = {
  data: [],
  metadata: {
    total: 0,
    page: 1,
    next: null,
    last: 1,
    prev: null
  },
  loading: false,
  selectedAccount: null,
  showModal: false,
  showEditModal: false,
  selectedAccountPayments: null,
  showTransferModal: false
};

export function financialAccountReducer (
  state: IAccontsListState = initialState,
  action: AccountsActions
): any {
  switch (action.type) {
    case SET_ACCOUNTS: {
      return {
        ...state,
        ...action.payload,
        data: action.payload.data
      };
    }

    case SET_LOADING: {
      return {
        ...state,
        loading: action.payload
      };
    }

    case RESET: {
      return initialState;
    }

    case SHOW_MODAL: {
      return {
        ...state,
        showModal: action.payload
      };
    }

    case SHOW_EDIT_MODAL: {
      return {
        ...state,
        showEditModal: action.payload
      };
    }

    case SHOW_TRANSFER_MODAL: {
      return {
        ...state,
        showTransferModal: action.payload
      };
    }

    case SET_ACCOUNT: {
      return {
        ...state,
        selectedAccount: action.payload
      };
    }

    case SET_ACCOUNT_PAYMENTS: {
      return {
        ...state,
        selectedAccountPayments: action.payload
      };
    }

    default: {
      return state;
    }
  }
}
