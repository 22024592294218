import { Select, Spin } from 'antd';
import * as config from 'config/config-manager.ts';
import { blacklistedStatus } from 'helpers/ViewClientHelper';
import { useLocale } from 'hooks/useLocale';
import { IAppState } from 'interfaces/app-state';
import { ISearchCriteria } from 'interfaces/search-criteria.ts';
import { ISortCriteria } from 'interfaces/sort-criteria.ts';
import debounce from 'lodash/debounce';
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getClients } from 'redux/clients/actions.ts';

const { Option } = Select;

type Props = {
  onChange?: (value?) => void;
  clinicId: number;
  selectedClientId?: number;
  disabled?: boolean;
};

export const ClientsSelect = ({
  onChange,
  clinicId,
  disabled,
  selectedClientId
}: Props): JSX.Element => {
  const dispatch = useDispatch();
  const clients = useSelector(({ clients }: IAppState) => clients.filteredClients);
  const locale = useLocale('private.select-with-required-search');

  const [loading, setLoading] = useState<boolean>(false);

  useEffect((): void => {
    if (selectedClientId) {
      performSearch(searchByIdObject(selectedClientId));
    }
  }, [selectedClientId]);

  const sortObject: ISortCriteria = {
    sort_key: 'name',
    sort_order: 'asc'
  };

  const searchObject = (values: string[]): ISearchCriteria[] => {
    return values.map((value) => ({
      name: 'composite',
      value,
      isArrayValue: true
    }));
  };

  const searchByIdObject = (id: number): ISearchCriteria[] => [
    {
      name: 'id',
      value: id.toString()
    }
  ];

  const performSearch = (searchCriteria: ISearchCriteria[]): void => {
    dispatch(getClients(clinicId, 0, searchCriteria, sortObject, true, () => setLoading(false)));
  };

  const onClientSearch = (value: string): void => {
    if (value.length < config.selectSearchMinSymbols) return;

    const searchValues: string[] = value.split(' ').filter((item: string) => item !== '');

    setLoading(true);
    performSearch(searchObject(searchValues));
  };

  const clientsList: JSX.Element[] = useMemo(
    () =>
      clients?.map((item) => {
        const title = `${item.first_name || ''} ${item.last_name || ''} ${
          item.phone_number ? `(+${item.country_code}${item.phone_number})` : ''
        }`;
        return (
          <Option
            key={`${item.id}_${crypto.randomUUID()}`}
            data={item}
            value={item.id}
            title={title}
          >
            {title}
            {blacklistedStatus(item)}
          </Option>
        );
      }),
    [clients]
  );

  return (
    <Select
      placeholder={locale.placeholders.inputMinForSearch}
      id={'clients-select'}
      virtual={false}
      onChange={onChange}
      notFoundContent={loading ? <Spin size='small' /> : null}
      value={clients?.length ? selectedClientId : null}
      disabled={disabled}
      filterOption={false}
      showSearch
      allowClear
      onSearch={debounce(onClientSearch, config.selectSearchDelay)}
    >
      {clientsList}
    </Select>
  );
};
