import { Form, FormInstance, Select } from 'antd';
import ConfirmCancelModal from 'components/ConfirmCancelModal';
import StickyButton from 'components/StickyButton';
import ViewDetailsButtonsGroup from 'components/ViewDetailsButtonsGroup';
import VLXModal from 'components/VLXModal';
import { useLocale } from 'hooks/useLocale';
import { useUserAccess } from 'hooks/useUserAccess';
import { IAppState } from 'interfaces/app-state';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setInitialDataForAppointment } from 'redux/appointments/actions';
import { showModal } from 'redux/events/actions';

import AddAppointmentForm from '../addAppointment/AddAppointmentForm';
import EventForm from './EventForm';

const { Option } = Select;

const AddEvent = (): JSX.Element => {
  const dispatch = useDispatch();
  const visible = useSelector<IAppState, any>((state) => state.events.showModal);
  const [createChange, onCreateChange] = useState<any>('appointment');
  const [addEventForm] = Form.useForm();
  const [addAppointmentForm] = Form.useForm();
  const locale = useLocale('private.appointments.event');
  const initialDataForAppointment = useSelector<IAppState, any>(
    (state) => state.appointments.initialDataForAppointment
  );
  const userAccess = useUserAccess();
  const [showCancelModal, setShowCancelModal] = useState<boolean>(false);

  function selectForm(): FormInstance<any> {
    if (createChange === 'appointment') {
      return addAppointmentForm;
    }
    return addEventForm;
  }

  function modalTitle(): string {
    return createChange === 'appointment'
      ? locale.labels.createNewAppointment
      : locale.labels.createNewEvent;
  }

  const onConfirmCancelClick = () => {
    addAppointmentForm.resetFields();
    dispatch(showModal(false));
    setShowCancelModal(false);
  };

  return (
    <>
      <div
        style={{
          display: 'flex',
          justifyContent: 'flex-end'
        }}
      >
        <StickyButton
          offsetBottom={10}
          onClick={() => dispatch(showModal(true))}
          iconElement={
            <span
              className='icofont icofont-plus mr-2 sticky-btn-icon'
              style={{ fontSize: '1.3em' }}
            />
          }
          textElement={locale.labels.addEvent}
        />
      </div>
      <VLXModal
        title={modalTitle()}
        form={selectForm()}
        open={visible}
        onModalClose={() => {
          dispatch(showModal(false));
          dispatch(setInitialDataForAppointment(null));
        }}
        width={800}
      >
        <Form.Item label={locale.labels.eventType}>
          <Select
            onChange={onCreateChange}
            defaultValue={createChange}
            className='event-type-select'
            id='event-type'
          >
            <Option value='event'>{locale.labels.meeting}</Option>
            <Option value='appointment'>{locale.labels.appointment}</Option>
          </Select>
        </Form.Item>
        {visible && (
          <>
            {createChange === 'appointment' ? (
              <AddAppointmentForm
                form={addAppointmentForm}
                initialValues={initialDataForAppointment}
                onClose={() => dispatch(showModal(false))}
                locale={locale}
              >
                <ViewDetailsButtonsGroup
                  fixed
                  accessGroup={userAccess.accesses.appointments}
                  editMode={true}
                  hideDeleteBtn={true}
                  onCancelClick={() => {
                    if (addAppointmentForm.isFieldsTouched()) {
                      setShowCancelModal(true);
                    } else {
                      dispatch(showModal(false));
                    }
                  }}
                  onDeleteClick={() => {
                    setShowCancelModal(false);
                  }}
                />
              </AddAppointmentForm>
            ) : (
              <EventForm
                form={addEventForm}
                onClose={() => dispatch(showModal(false))}
                initialValues={initialDataForAppointment}
                eventType={createChange}
                locale={locale}
              >
                <ViewDetailsButtonsGroup
                  fixed
                  accessGroup={userAccess.accesses.events}
                  editMode={true}
                  hideDeleteBtn={true}
                  onCancelClick={() => {
                    if (addEventForm.isFieldsTouched()) {
                      setShowCancelModal(true);
                    } else {
                      dispatch(showModal(false));
                    }
                  }}
                  onDeleteClick={() => {
                    setShowCancelModal(false);
                  }}
                />
              </EventForm>
            )}
          </>
        )}
        <ConfirmCancelModal
          open={showCancelModal}
          onOk={onConfirmCancelClick}
          onCancel={() => setShowCancelModal(false)}
        />
      </VLXModal>
    </>
  );
};

export default AddEvent;
