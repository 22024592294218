const coats = [
  {
    value: 'smooth',
    enGB: 'Smooth',
    ukUA: 'Гладка'
  },
  {
    value: 'short',
    enGB: 'Short',
    ukUA: 'Коротка'
  },
  {
    value: 'long',
    enGB: 'Long',
    ukUA: 'Довга'
  },
  {
    value: 'wiry',
    enGB: 'Wiry',
    ukUA: 'Дрітяна'
  },
  {
    value: 'curly',
    enGB: 'Curly',
    ukUA: 'Кучерява'
  },
  {
    value: 'silky',
    enGB: 'Silky',
    ukUA: 'Шовковиста'
  },
  {
    value: 'double',
    enGB: 'Double',
    ukUA: 'Подвійна'
  },
  {
    value: 'single',
    enGB: 'Single',
    ukUA: 'Одинарна'
  },
  {
    value: 'hairless',
    enGB: 'Hairless',
    ukUA: 'Безшерста'
  },
  {
    value: 'other',
    enGB: 'Other',
    ukUA: 'Інша'
  }
];

export default coats;
