import './AddVaccination.scss';

import { AutoComplete, Col, DatePicker, Form, Input, Row, Select } from 'antd';
import { TextEditor } from 'components/TextEditor';
import { vaccineAntigens } from 'constants/dictionary/default/selectOptions';
import dayjs from 'dayjs';
import useClinicId from 'hooks/useClinicId';
import { useLocale } from 'hooks/useLocale';
import useLocalizedList from 'hooks/useLocalizedList';
import { IAppState } from 'interfaces/app-state';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getStock } from 'redux/inventory/stock/actions';
import { addPatientVaccination, updatePatientVaccination } from 'redux/patients/actions';
import { patternRule, PATTERNS, requiredRule } from 'utils/form-rules';

type IStock = {
  id: number;
  name: string;
  condition: string;
  manufacturer: string;
  uses_left: number;
  tool_type: string;
  location: string;
  responsible_person: { id: number; full_name: string };
};

const AddVaccinationForm = ({
  form,
  initialValues,
  children,
  onFieldsChange,
  patientId,
  editMode,
  closeVaccinationModal,
  setFormEditMode
}: any) => {
  const dispatch = useDispatch();
  const clinicId = useClinicId();
  const locale = useLocale('private.patients.patient-page');
  const [inputVaccinationNameValue, setInputVaccinationNameValue] = useState('');
  const [inputManufacturerNameValue, setInputManufacturerNameValue] = useState('');
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [manufacturerDropdownOpen, setManufacturerDropdownOpen] = useState(false);
  const stock = useSelector<IAppState, any>((state) => state.stock.data);

  useEffect(() => {
    form.resetFields();
    dispatch(getStock(clinicId, 20, 0));
  }, []);

  const onSubmit = (data: any) => {
    const apiData = { ...data };
    if (data.expiration_date) {
      apiData.expiration_date = dayjs(data.expiration_date).format('DD.MM.YYYY');
    }
    if (data.date_of_vaccination) {
      apiData.date_of_vaccination = dayjs(data.date_of_vaccination).format('DD.MM.YYYY');
    }
    if (data.date_of_next_vaccination) {
      apiData.date_of_next_vaccination = dayjs(data.date_of_next_vaccination).format(
        'DD.MM.YYYY'
      );
    }
    if (editMode) {
      dispatch(updatePatientVaccination(clinicId, patientId, initialValues.id, apiData));
    } else {
      dispatch(addPatientVaccination(clinicId, patientId, apiData));
    }
    closeVaccinationModal();
    setFormEditMode(false);
  };

  const localizedVaccineAntigens = useLocalizedList(vaccineAntigens);

  const autoCompleteOptions = stock.map((option: IStock) => ({
    key: `${option.id}-${crypto.randomUUID()}`,
    value: option.name?.toString(),
    manufacturer: option.manufacturer?.toString()
  }));

  const manufacturers = stock.map((option: IStock): string => option.manufacturer?.toString());
  const uniqueManufacturers = manufacturers.filter((val, i, arr) => arr.indexOf(val) === i);

  const autoCompleteManufacturerOptions = uniqueManufacturers.map((manufacturer) => ({
    key: crypto.randomUUID(),
    value: manufacturer
  }));

  function onImmuneResponseChange() {
    const expirationDate = form.getFieldsValue().expiration_date;
    const nextVaccination = form.getFieldValue().date_of_next_vaccination;
    if (!nextVaccination && expirationDate) {
      form.setFieldsValue({
        date_of_next_vaccination: dayjs(expirationDate).add(1, 'day')
      });
    }
  }

  function onNextVaccinationChange() {
    const expirationDate = form.getFieldsValue().expiration_date;
    const nextVaccination = form.getFieldValue().date_of_next_vaccination;
    if (!expirationDate && nextVaccination) {
      form.setFieldsValue({
        expiration_date: dayjs(nextVaccination).subtract(1, 'day')
      });
    }
  }

  const remapInitialValues = () => {
    const data = { ...initialValues };
    data.date_of_vaccination = initialValues?.date_of_vaccination
      ? initialValues?.date_of_vaccination
      : dayjs();
    return data;
  };

  return (
    <Form
      onFinish={onSubmit}
      onFieldsChange={onFieldsChange}
      autoComplete='off'
      layout='vertical'
      form={form}
      initialValues={remapInitialValues()}
    >
      <Form.Item name='clinic_id' initialValue={clinicId} hidden>
        <Input />
      </Form.Item>
      <Row gutter={[20, 10]} className='add-vaccination-form'>
        <Col span={24} md={12}>
          <Form.Item
            label={locale.labels.vacinationName}
            name={['name']}
            rules={[
              requiredRule(locale.messages.inputVaccinationName),
              patternRule(PATTERNS.CHAR_100_MAX, locale.messages.maximum100Chars)
            ]}
          >
            <AutoComplete
              options={autoCompleteOptions}
              filterOption={(inputValue, option) =>
                option.value.toString().toUpperCase().includes(inputValue.toUpperCase())
              }
              onSelect={(value, option) => {
                setDropdownOpen(false);
              }}
              allowClear
              open={dropdownOpen && inputVaccinationNameValue.length >= 2}
              onSearch={(value) => {
                setInputVaccinationNameValue(value);
                setDropdownOpen(true);
              }}
              onBlur={() => {
                setDropdownOpen(false);
              }}
            />
          </Form.Item>
          <Form.Item
            label={locale.labels.manufacturer}
            name={['manufacturer']}
            rules={[
              requiredRule(locale.messages.inputManufacturer),
              patternRule(PATTERNS.CHAR_100_MAX, locale.messages.maximum100Chars)
            ]}
          >
            <AutoComplete
              options={autoCompleteManufacturerOptions}
              filterOption={(inputValue, option) =>
                option.value?.toString().toUpperCase().includes(inputValue.toUpperCase())
              }
              onSelect={() => {
                setManufacturerDropdownOpen(false);
              }}
              allowClear
              open={manufacturerDropdownOpen && inputManufacturerNameValue.length >= 2}
              onSearch={(value) => {
                setInputManufacturerNameValue(value);
                setManufacturerDropdownOpen(true);
              }}
              onBlur={() => {
                setManufacturerDropdownOpen(false);
              }}
            />
          </Form.Item>
          <Form.Item
            label={locale.labels.coverage}
            name={['coverage']}
            rules={[requiredRule(locale.messages.inputCoverage)]}
          >
            <Select
              mode='multiple'
              options={localizedVaccineAntigens.map((el) => ({ ...el, key: el.value }))}
            />
          </Form.Item>
          <Form.Item
            label={locale.labels.serialNumber}
            name={['serial_number']}
            rules={[patternRule(PATTERNS.CHAR_100_MAX, locale.messages.maximum100Chars)]}
          >
            <Input />
          </Form.Item>
        </Col>
        <Col span={24} md={12}>
          <Form.Item
            label={locale.labels.dateVaccination}
            name={['date_of_vaccination']}
            rules={[requiredRule(locale.messages.inputDateVaccination)]}
          >
            <DatePicker disabledDate={(d) => !d || d.isAfter(dayjs())} format='YYYY-MM-DD' />
          </Form.Item>
          <Form.Item label={locale.labels.expirationDate} name={['expiration_date']}>
            <DatePicker onChange={onImmuneResponseChange} />
          </Form.Item>
          <Form.Item label={locale.labels.dateNextVaccination} name={['date_of_next_vaccination']}>
            <DatePicker onChange={onNextVaccinationChange} />
          </Form.Item>
          <TextEditor label={locale.labels.notes} name={['notes']} />
        </Col>
      </Row>

      {children}
    </Form>
  );
};

export default AddVaccinationForm;
