import React from 'react';
import { Tag } from 'antd';

const getType = (bill) => {
  let color = null;

  switch (bill.value) {
    case 'income':
      color = 'green';
      break;
    case 'outcome':
      color = 'red';
      break;
  }

  return tag(color, bill.label);
};

const tag = (color: string, value: string) => {
  return <Tag id='bill-type' color={color}>{value}</Tag>;
};

export default getType;
