import {
  EquipmentIndexResponse
} from '../../../services/interfaces/clinics/inventory/equipment/equipment-index-response';
import {
  EquipmentShowResponse
} from '../../../services/interfaces/clinics/inventory/equipment/equipment-show-response';

export const SET_EQUIPMENT = '[equipment] Set';
export const SET_LOADING = '[equipment] Loading';
export const SHOW_CREATE_MODAL = '[equipment] Create Modal';
export const SET_BE_ERROR = '[equipment] BE Error';
export const SET_SELECTED_DEVICE = '[equipment] Selected Device';
export const RESET = '[equipment] RESET';

export interface EquipmentResetAction {
  type: typeof RESET
}

export interface SetEquipmentAction {
  type: typeof SET_EQUIPMENT
  payload: EquipmentIndexResponse
}

export interface SetSelectedDeviceAction {
  type: typeof SET_SELECTED_DEVICE
  payload: EquipmentShowResponse
}

export interface SetLoadingAction {
  type: typeof SET_LOADING
  payload: boolean
}

export interface ShowCreateModal {
  type: typeof SHOW_CREATE_MODAL
  payload: boolean
}

export interface SetBeError {
  type: typeof SET_BE_ERROR
  payload: boolean
}

export type EquipmentActions =
  | SetEquipmentAction
  | EquipmentResetAction
  | SetSelectedDeviceAction
  | ShowCreateModal
  | SetBeError
  | SetLoadingAction;
