import React from 'react';
import { Tag } from 'antd';

export function conditionStatus (condition_status) {
  let color = null;
  switch (condition_status?.value) {
    case 'good':
      color = 'green';
      break;
    case 'normal':
      color = 'yellow';
      break;
    case 'repair_required':
      color = 'red';
      break;
  }
  return tag(color, condition_status?.label);
}

function tag (color: string, status: string) {
  return <Tag color={color}>{status}</Tag>;
}
