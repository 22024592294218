export const SET_LOADING = 'SET_LOADING';
export const SHOW_DICTIONARY_MODAL = 'SHOW_DICTIONARY_MODAL';

export interface SetLoadingAction {
  type: typeof SET_LOADING
  payload: boolean
}

export interface ShowModalAction {
  type: typeof SHOW_DICTIONARY_MODAL
  payload: boolean
}

export type DictionaryActions =
  | ShowModalAction
  | SetLoadingAction;
