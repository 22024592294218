import { IUser } from '../../interfaces/user';

export const GET_USER = 'GET_USER';
export const SHOW_MODAL = 'SHOW_MODAL';
export const SET_LOADING = 'SET_LOADING';
export const SET_TOKEN = '[user] Token';
export const CLEAR_USER = '[user] Clear';
export const SET_AVATAR = '[avatar] Set';
export const SET_REDIRECT = '[user] Set Redirect';
export const SET_USER_CLINIC_ACCESSES = 'SET_USER_CLINIC_ACCESSES';

export interface GetUserAction {
  type: typeof GET_USER
  payload: IUser
}

export interface ClearUserAction {
  type: typeof CLEAR_USER
}

export interface ShowModalAction {
  type: typeof SHOW_MODAL
  payload: boolean
}

export interface SetLoadingAction {
  type: typeof SET_LOADING
  payload: boolean
}

export interface SetTokenAction {
  type: typeof SET_TOKEN
  payload: string
}

export interface SetUserAvatarAction {
  type: typeof SET_AVATAR
  payload: string
}

export interface SetUserRedirect {
  type: typeof SET_REDIRECT
  payload: boolean
}

export interface SetUserClinicAccesses {
  type: typeof SET_USER_CLINIC_ACCESSES
  payload: boolean
}

export type UserActions =
  | GetUserAction
  | SetUserRedirect
  | ClearUserAction
  | ShowModalAction
  | SetLoadingAction
  | SetTokenAction
  | SetUserAvatarAction
  | SetUserClinicAccesses;
