import './AppointmentDetails.scss';

import { Col, Collapse, Menu, MenuProps, Row, Spin, Tag, Tooltip, Typography } from 'antd';
import checkedIcon from 'assets/img/checked.svg';
import warningIcon from 'assets/img/warning.svg';
import DocumentsTab from 'components/DocumentsTab';
import HealthRecordsHistory from 'components/HealthRecordsHistory';
import useClinicId from 'hooks/useClinicId';
import { useLocale } from 'hooks/useLocale';
import useLinkParams from 'hooks/useSearchParams';
import { useUserAccess } from 'hooks/useUserAccess';
import { IAppState } from 'interfaces/app-state';
import { IPatient } from 'interfaces/patient';
import { IService } from 'interfaces/services';
import AddAppointmentPatient from 'layout/modals/addAppointmentPatient/AddAppointmentPatient';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import {
  getAppointmentBill,
  getAppointmentDetails,
  getAppointmentDetailsPatients,
  resetAppointments,
  showPatientModal,
} from 'redux/appointments/actions';
import { getClientDetails } from 'redux/clients/actions';
import { getPatientDocumentsList, mergePatientDocumentsList } from 'redux/patients/actions';
import { getServices } from 'redux/services/actions';

import { PremiumSubscriptionTooltip } from '../../components/PremiumSubscriptionTooltip';
import ClientDetailsPetsList from '../clients/ClientDetailsPetsList';
import ClientDetailsPetsTab from '../clients/ClientDetailsPetsTab';
import PatientVaccinationDetails from '../patients/PatientVaccinationDetails';
import AppointmentDetailsPatientTab from './AppointmentDetailsPatientTab';
import AppointmentDetailsTopContainer from './AppointmentDetailsTopContainer';
import { AppointmentServicesContainer } from './AppointmentServices/AppointmentServicesContainer';

const AppointmentDetails = (): JSX.Element => {
  const appointmentId = +useParams().appointmentId;
  const locale = useLocale('private.appointments.event');
  const patientsLocale = useLocale('private.patients.patient-page');
  const currencies = useLocale('private.currencies').labels;
  const editAccess = useUserAccess().accesses.appointments.edit;
  const dispatch = useDispatch();
  const clinicId = useClinicId();
  const patientIdSearchParam = useLinkParams('patientId');
  const activeTabSearchParam = useLinkParams('tab');
  const appModules = useUserAccess().availableModules;

  const appointment = useSelector(
    ({ appointments }: IAppState) => appointments.selectedAppointment
  );
  const appointmentBill = useSelector(
    ({ appointments }: IAppState) => appointments.appointmentBill
  );
  const appointmentLoading = useSelector(({ appointments }: IAppState) => appointments.loading);
  const appointmentPatients = useSelector(
    ({ appointments }: IAppState) => appointments.selectedAppointmentPatients?.data
  );
  const appointmentClient = useSelector(({ clients }: IAppState) => clients.selectedClient);
  const selectedPatientDocuments = useSelector(
    ({ patients }: IAppState) => patients.selectedPatientDocuments
  );

  const [selectedPatientServices, setSelectedPatientServices] = useState<IService[]>(null);
  const [servicesStockStatus, setServicesStockStatus] = useState([]);
  const [selectedPatient, setSelectedPatient] = useState(null);
  const [currentTab, setCurrentTab] = useState('card');
  const [currentPatient, setCurrentPatient] = useState(null);
  const [patientHealthRecord, setPatientHealthRecord] = useState(null);

  useEffect((): void => {
    if (clinicId) {
      dispatch(getAppointmentDetails(clinicId, appointmentId));
      dispatch(getAppointmentDetailsPatients(clinicId, appointmentId));
      dispatch(getServices(clinicId, 0));
      dispatch(getAppointmentBill(clinicId, appointmentId));
    }
  }, [clinicId, appointmentId]);

  useEffect(()=> {
    return () => {
      dispatch(resetAppointments());
    };
  }, []);

  useEffect((): void => {
    const statuses = selectedPatientServices?.map((el): { id: number; status: boolean } => ({
      id: el?.id,
      status: el?.stock_saved
    }));

    setServicesStockStatus(statuses);
  }, [selectedPatientServices]);

  useEffect(() => {
    changeSelectedPatient(currentPatient);
  }, [patientIdSearchParam, appointmentPatients]);

  useEffect(() => {
    if (appointment?.client?.id && appointmentClient?.id !== appointment?.client?.id) {
      dispatch(getClientDetails(clinicId, appointment?.client?.id));
    }
  }, [appointment]);

  useEffect(() => {
    if (activeTabSearchParam) {
      setCurrentTab(activeTabSearchParam);
    }
  }, [activeTabSearchParam]);

  const getDetailsTabIcon = (): JSX.Element => {
    const healthRecord = appointmentPatients?.find(
      (el) => el.id === selectedPatient?.id
    )?.health_record;

    return getTabIcon(healthRecord?.completed);
  };

  const getStockTabIcon = (): JSX.Element => {
    let checked = true;

    if (!servicesStockStatus?.length || servicesStockStatus?.some((service) => !service.status)) {
      checked = false;
    }

    return getTabIcon(checked);
  };

  const getTabIcon = (checked: boolean): JSX.Element => (
    <Tooltip trigger={'hover'} placement='topLeft' title={locale.labels.mandatoryToEnter}>
      <img
        style={{ height: 20, width: 20, verticalAlign: -4 }}
        src={checked ? checkedIcon : warningIcon}
      />
    </Tooltip>
  );

  const items: MenuProps['items'] = useMemo(
    () => [
      {
        label: locale.tabs.card,
        key: 'card'
      },
      {
        label: locale.tabs.details,
        key: 'details',
        icon: getDetailsTabIcon()
      },
      {
        label: locale.tabs.servicesAndStock,
        key: 'stock',
        icon: getStockTabIcon()
      },
      {
        label: locale.tabs.vaccinations,
        key: 'vaccinations'
      },
      {
        label: (
          <PremiumSubscriptionTooltip display={!appModules.documents}>
            {locale.tabs.documents}
          </PremiumSubscriptionTooltip>
        ),
        key: 'documents',
        disabled: !appModules.documents
      },
      {
        label: locale.tabs.history,
        key: 'history'
      }
    ],
    [servicesStockStatus, appointmentPatients, selectedPatient, appointment, appModules]
  );

  const changeSelectedPatient = (patientId: number): void => {
    if (!appointmentPatients?.length) return;

    const patientLookup = (id: number) => {
      const patient = appointmentPatients.find((el) => el?.id === id);
      if (patient) {
        onSelectedPatientChange(patient);
      }
    };

    if (patientId) {
      patientLookup(patientId);
      return;
    } else if (patientIdSearchParam) {
      patientLookup(+patientIdSearchParam);
      setCurrentPatient(patientIdSearchParam);
      return;
    }
    const patient = appointmentPatients[0];
    onSelectPatient(patient);
    onSelectedPatientChange(patient);
  };

  const onSelectedPatientChange = (patient: IPatient): void => {
    setSelectedPatient(patient);
    setPatientHealthRecord(patient?.health_record);
    setSelectedPatientServices(patient?.health_record?.services);
    if (appModules.documents) dispatch(getPatientDocumentsList(clinicId, patient?.id));
  };

  const disableFields = useMemo((): boolean => {
    if (!appointmentBill) return false;
    return (
      ['partially_paid', 'paid'].includes(appointmentBill.payment_status) ||
      ['finished', 'cancelled', 'future'].includes(appointment?.status)
    );
  }, [appointment?.status, appointmentBill?.payment_status]);

  const disableVisitDetails = (): boolean => {
    return ['cancelled', 'future'].includes(appointment?.status);
  };

  const disablePatientsListModifications = (): boolean => {
    if (!appointmentBill) return false;
    return ['cancelled', 'finished'].includes(appointment?.status);
  };

  const changeTab: MenuProps['onClick'] = (e): void => {
    pushHistoryState(currentPatient, e.key);
    setCurrentTab(e.key);

    if (e.key === 'details') {
      dispatch(getAppointmentDetailsPatients(clinicId, appointmentId));
    }
  };

  function pushHistoryState(patientId: number, tab: string): void {
    if (window.history.pushState) {
      const newUrl = `${window.location.pathname}?patientId=${patientId}&tab=${tab}`;
      window.history.pushState({ path: newUrl }, '', newUrl);
    }
  }

  const addNewPetAction = (): void => {
    dispatch(showPatientModal(true));
  };

  const onSelectPatient = (pet: IPatient): void => {
    pushHistoryState(pet.id, 'card');
    setCurrentTab('card');
    setCurrentPatient(pet.id);
    changeSelectedPatient(pet.id);
  };

  function saveStatusTag(serviceId: number): JSX.Element {
    const isServicesStockStatusSaved = servicesStockStatus?.find(
      (el) => el.id === serviceId
    )?.status;

    return (
      <Tag
        id='bill-status-type'
        color={isServicesStockStatusSaved ? 'green' : 'red'}
        style={{
          fontWeight: 'bold'
        }}
      >
        {isServicesStockStatusSaved
          ? locale.labels.savedStockChanges
          : locale.labels.unsavedStockChanges}
      </Tag>
    );
  }

  const collapseHeader = useCallback(
    (service: IService): JSX.Element => (
      <Row gutter={[24, 12]} justify={'start'} className={'collapse-header-row'}>
        <Col xs={24} sm={24} md={8} lg={7} xl={9}>
          <Typography.Title level={5}>{service?.name}</Typography.Title>
        </Col>
        <Col xs={24} sm={24} md={7} lg={8} xl={6}>
          <div>{saveStatusTag(service?.id)}</div>
        </Col>
        <Col xs={24} sm={24} md={9} lg={9} xl={7} style={{ justifyContent: 'end' }}>
          <Typography.Title level={5}>{calculateFullPrice(service)}</Typography.Title>
        </Col>
      </Row>
    ),
    [servicesStockStatus]
  );

  const calculateFullPrice = (service: IService): string => {
    const startingPrice = service?.total_price;
    let stockPrice = 0;
    service?.stock.forEach((item) => {
      stockPrice = stockPrice + (+item.price || 0);
    });

    const price = (Number(startingPrice) + Number(stockPrice)).toFixed(2);
    return `${currencies.totalPrice}: ${price} ${currencies.uah}`;
  };

  const setDocumentsList = (data): void => {
    dispatch(mergePatientDocumentsList(data));
  };

  const callbackAfterPatientRemove = (patientId: number) => {
    const res = appointmentPatients.filter((el) => el.id !== patientId);
    if (res.length > 0) {
      setCurrentPatient(res[0].id);
      changeSelectedPatient(res[0].id);
      pushHistoryState(res[0].id, 'card');
    }
  };

  return (
    <Spin spinning={!appointment} className='appointment-details-page-spin-container'>
      {appointment && (
        <>
          <AppointmentDetailsTopContainer
            appointment={appointment}
            locale={locale}
            currencies={currencies}
            appointmentClient={appointmentClient}
            appointmentBill={appointmentBill}
            appointmentPatients={appointmentPatients}
            servicesStockStatus={servicesStockStatus}
            appointmentLoading={appointmentLoading}
          />
          <Col span={24} className='appointment-container'>
            <Col className='ant-card-bordered personal-info-card'>
              {appointmentPatients && (
                <Row>
                  <ClientDetailsPetsList
                    appointment_id={appointment.id}
                    clientPatients={appointmentPatients}
                    selectedPatient={selectedPatient}
                    lgRowWidth={6}
                    callbackAfterPatientRemove={callbackAfterPatientRemove}
                    setSelectedPatient={onSelectPatient}
                    addNewPetAction={addNewPetAction}
                    addMarkIcon
                    bill={appointmentBill}
                    disabled={disablePatientsListModifications()}
                    showDeletePatientIcon={appointmentPatients?.length > 1}
                  />
                  <Col span={24} lg={18} className='client-details__patient-details'>
                    <Menu
                      onClick={changeTab}
                      selectedKeys={[currentTab]}
                      mode='horizontal'
                      items={items}
                      style={{ marginBottom: 20 }}
                    />
                    {currentTab === 'vaccinations' && (
                      <PatientVaccinationDetails
                        locale={patientsLocale}
                        patientId={selectedPatient?.id}
                        clinicId={clinicId}
                      />
                    )}
                    {currentTab === 'card' && !!selectedPatient && (
                      <ClientDetailsPetsTab
                        key={'clients tab'}
                        accessGroup={editAccess}
                        clinicId={clinicId}
                        data={selectedPatient}
                      />
                    )}
                    {currentTab === 'details' && (
                      <AppointmentDetailsPatientTab
                        key={'details tab'}
                        locale={locale}
                        onlyServices={false}
                        appointment={appointment}
                        patientId={selectedPatient?.id}
                        initialValues={patientHealthRecord}
                        disabled={disableVisitDetails()}
                      />
                    )}
                    {currentTab === 'stock' && (
                      <Spin spinning={selectedPatientServices === null}>
                        <React.Fragment key={'clients tab'}>
                          <AppointmentDetailsPatientTab
                            key={'details tab'}
                            locale={locale}
                            onlyServices
                            appointment={appointment}
                            patientId={selectedPatient?.id}
                            initialValues={patientHealthRecord}
                            disabled={disableFields}
                          />
                          {selectedPatientServices?.length > 0 && (
                            <Collapse
                              accordion
                              className='appointment-items-collapse-header'
                              size='large'
                              style={{ marginTop: 20 }}
                              items={selectedPatientServices.map((el) => ({
                                label: collapseHeader(el),
                                key: el?.id,
                                children: (
                                  <AppointmentServicesContainer
                                    key={el?.id}
                                    appointmentId={appointment?.id}
                                    healthRecordId={patientHealthRecord.id}
                                    disabled={disableFields}
                                    serviceData={el}
                                    servicesStockStatus={servicesStockStatus}
                                    setServicesStockStatus={setServicesStockStatus}
                                  />
                                )
                              }))}
                            ></Collapse>
                          )}
                        </React.Fragment>
                      </Spin>
                    )}
                    {currentTab === 'documents' && appModules.documents && (
                      <DocumentsTab
                        uploadDocumentLink={`/v1/clinics/${clinicId}/patients/${selectedPatient?.id}/documents`}
                        setDocumentsList={setDocumentsList}
                        data={selectedPatientDocuments}
                      />
                    )}
                    {currentTab === 'history' && !!selectedPatient && (
                      <HealthRecordsHistory
                        key={'history tab'}
                        clinicId={clinicId}
                        selectedPatientId={selectedPatient.id}
                      />
                    )}
                  </Col>
                </Row>
              )}
            </Col>
          </Col>
          <AddAppointmentPatient
            clientId={appointment?.client?.id}
            appointmentId={appointment?.id}
            appointmentPatients={appointmentPatients}
          />
        </>
      )}
    </Spin>
  );
};
export default AppointmentDetails;
