import { FormRule } from 'antd';

export const PATTERNS = {
  PHONE: /^\+?([1-9][0-9]\d{7})$/,
  DIGITS: /^\d+$/,
  FLOATS_10: /^[\d,.]{1,10}$/,
  DIGITS_1_9: /^[1-9]\d*$/,
  DIGITS_MAX_10: /^\d{0,10}$/,
  CHAR_100_MAX: /^.{1,100}$/,
  NAME: /^[\u0041-\u005A\u0061-\u007A\u0400-\u04FF\u0080-\u00FF\u0100-\u024F\s'\\.,;:!?&%$#@()*+=\-_ʼ"`~^0-9]{1,100}$/,
  EMAIL: /^(?=.{1,256}$)[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
  PASSWORD: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[\W_])(?!.*\s).{8,}$/,
  RGB: /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i,
  SOCIAL_LINK: /^(https:\/\/[a-zA-Z.]*\/)?(@{0,1}[A-Za-z0-9_.]{3,25})[/]{0,1}$/
};

export const requiredRule = (message?: string): { message: string; required: true } => ({
  required: true,
  ...(message && { message })
});

export const patternRule = (pattern: RegExp, message: string) =>
  ({
    pattern,
    message
  } as FormRule);

export const maxRule = (max: number, message: string): { message: string; max: number } => ({
  max,
  message
});

export const validatorRule = (condition: (value) => boolean, message: string) => ({
  message,
  validator: async (_, value) => {
    if (condition(value)) {
      return Promise.resolve();
    } else {
      return Promise.reject(message);
    }
  }
});
