import { Form } from 'antd';
import ConfirmCancelModal from 'components/ConfirmCancelModal';
import StickyButton from 'components/StickyButton';
import ViewDetailsButtonsGroup from 'components/ViewDetailsButtonsGroup';
import VLXModal from 'components/VLXModal';
import { useLocale } from 'hooks/useLocale';
import { useUserAccess } from 'hooks/useUserAccess';
import { IAppState } from 'interfaces/app-state';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { showModal } from 'redux/clients/actions';

import AddClientForm from './AddClientForm';

const AddClient = ({
  hideDefaultBtn,
  initialValues,
  editMode,
  callbackFunction,
  simplifiedValidation
}: any): JSX.Element => {
  const dispatch = useDispatch();
  const clients = useSelector<IAppState, any>((state) => state.clients);
  const locale = useLocale('private.clients.client');
  const [showCancelModal, setShowCancelModal] = useState<boolean>(false);
  const userAccess = useUserAccess();
  const [form] = Form.useForm();
  const onConfirmCancelClick = () => {
    form.resetFields();
    dispatch(showModal(false));
    setShowCancelModal(false);
  };

  return (
    <>
      {!hideDefaultBtn && (
        <StickyButton
          offsetBottom={10}
          onClick={() => dispatch(showModal(true))}
          iconElement={
            <span
              className='icofont icofont-plus mr-2 sticky-btn-icon'
              style={{ fontSize: '1.3em' }}
            />
          }
          textElement={locale.buttons.addClient}
        />
      )}
      <VLXModal
        title={editMode ? locale.labels.editClient : locale.labels.createNewClient}
        centered={true}
        form={form}
        open={clients.showModal}
        onModalClose={() => dispatch(showModal(false))}
        width={800}
      >
        {clients.showModal && (
          <AddClientForm
            simplifiedValidation={simplifiedValidation}
            form={form}
            initialValues={initialValues}
            editMode={editMode}
            locale={locale}
            callbackFunction={callbackFunction}
          >
            <ViewDetailsButtonsGroup
              fixed
              accessGroup={userAccess.accesses.clients}
              editMode={true}
              hideDeleteBtn={true}
              onCancelClick={() => {
                if (form.isFieldsTouched()) {
                  setShowCancelModal(true);
                } else {
                  dispatch(showModal(false));
                }
              }}
              onDeleteClick={() => {
                setShowCancelModal(false);
              }}
            />
          </AddClientForm>
        )}
        <ConfirmCancelModal
          open={showCancelModal}
          onOk={onConfirmCancelClick}
          onCancel={() => setShowCancelModal(false)}
        />
      </VLXModal>
    </>
  );
};

export default AddClient;
