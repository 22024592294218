import { IServicesList } from 'interfaces/services';

import {
  RESET,
  ServicesActions,
  SET_SERVICE,
  SET_SERVICES,
  SET_SERVICES_LOADING,
  SHOW_MODAL
} from './types';

const initialState: IServicesList = {
  data: [],
  metadata: {
    total: 0,
    page: 1,
    next: null,
    last: 1,
    prev: null
  },
  loading: true,
  selectedService: null,
  showModal: false
};

export function servicesReducer(
  state: IServicesList = initialState,
  action: ServicesActions
): IServicesList {
  switch (action.type) {
    case SET_SERVICES: {
      return {
        ...state,
        ...action.payload
      };
    }

    case RESET: {
      return initialState;
    }

    case SET_SERVICES_LOADING: {
      return {
        ...state,
        loading: action.payload
      };
    }

    case SHOW_MODAL: {
      return {
        ...state,
        showModal: action.payload
      };
    }

    case SET_SERVICE: {
      return {
        ...state,
        selectedService: action.payload
      };
    }

    default: {
      return state;
    }
  }
}
