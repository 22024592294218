import { IRole, IRolesList } from '../../interfaces/role';
import {
  createClinicRole,
  editClinicRole,
  getClinicRole,
  getClinicRoles
} from '../../services/clinic/clinicsService';
import { RoleCreateRequest } from '../../services/interfaces/clinics/roles/role-create-request';
import {
  GET_ROLES,
  GetRolesAction,
  RESET,
  RolesResetAction,
  SET_BE_ERROR,
  SET_EDIT_MODE,
  SET_ROLES_LOADING,
  SET_SELECTED_ROLE,
  SET_SELECTED_ROLE_ID,
  SetBackendErrorAction,
  SetEditModeAction,
  SetLoadingAction,
  SetSelectedRole,
  SetSelectedRoleId,
  SHOW_CREATE_MODAL,
  SHOW_EDIT_MODAL,
  SHOW_ROLE_DETAILS_MODAL,
  ShowCreateModalAction,
  ShowEditModalAction,
  ShowRoleDetailsModalAction
} from './types';

export const setRoles = (data: IRolesList): GetRolesAction => ({
  type: GET_ROLES,
  payload: data
});

export const resetRoles = (): RolesResetAction => ({
  type: RESET
});

export const setLoading = (value: boolean): SetLoadingAction => ({
  type: SET_ROLES_LOADING,
  payload: value
});

export const showCreateModal = (value: boolean): ShowCreateModalAction => ({
  type: SHOW_CREATE_MODAL,
  payload: value
});

export const setBackendError = (value: boolean): SetBackendErrorAction => ({
  type: SET_BE_ERROR,
  payload: value
});

export const setEditMode = (value: boolean): SetEditModeAction => ({
  type: SET_EDIT_MODE,
  payload: value
});

export const showEditModal = (value: boolean): ShowEditModalAction => ({
  type: SHOW_EDIT_MODAL,
  payload: value
});

export const showRoleDetailsModal = (value: boolean): ShowRoleDetailsModalAction => ({
  type: SHOW_ROLE_DETAILS_MODAL,
  payload: value
});

export const setSelectedRole = (value: IRole): SetSelectedRole => ({
  type: SET_SELECTED_ROLE,
  payload: value
});

export const setSelectedRoleId = (value: number): SetSelectedRoleId => ({
  type: SET_SELECTED_ROLE_ID,
  payload: value
});

export const getRoles = (clinicId: number): any => {
  return (dispatch) => {
    dispatch(setLoading(true));
    getClinicRoles(clinicId)
      .then((data) => {
        dispatch(setRoles(data));
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => dispatch(setLoading(false)));
  };
};

export const getRoleById = (clinicId: number, roleId: number): any => {
  return (dispatch) => {
    dispatch(setLoading(true));
    getClinicRole(clinicId, roleId)
      .then((data) => {
        dispatch(setSelectedRole(data));
      })
      .catch((err) => console.log(err))
      .finally(() => dispatch(setLoading(false)));
  };
};

export const createRole = (clinicId: number, payload: RoleCreateRequest): any => {
  return (dispatch) => {
    dispatch(setLoading(true));
    createClinicRole(clinicId, payload)
      .then(() => {
        dispatch(getRoles(clinicId));
        dispatch(setLoading(false));
        dispatch(showCreateModal(false));
        dispatch(setBackendError(false));
      })
      .catch(() => {
        dispatch(setLoading(false));
        dispatch(setBackendError(true));
      });
  };
};

export const editRole = (clinicId: number, roleId: number, payload: RoleCreateRequest): any => {
  return (dispatch) => {
    dispatch(setLoading(true));
    editClinicRole(clinicId, roleId, payload)
      .then(() => {
        dispatch(getRoles(clinicId));
        dispatch(getRoleById(clinicId, roleId));
        dispatch(setLoading(false));
        dispatch(setEditMode(false));
        dispatch(setBackendError(false));
      })
      .catch(() => {
        dispatch(setLoading(false));
        dispatch(setBackendError(true));
      });
  };
};
