import './EditAppointmentForm.scss';

import { Col, Form, Input, Row, Select, Spin } from 'antd';
import DateRangePicker from 'components/DateRangePicker';
import { TextEditor } from 'components/TextEditor';
import { DATE_TIME_FORMAT } from 'constants/common';
import { locationTypes } from 'constants/dictionary/default/selectOptions';
import dayjs, { Dayjs } from 'dayjs';
import useClinicId from 'hooks/useClinicId';
import useLocalizedList from 'hooks/useLocalizedList';
import { IAppState } from 'interfaces/app-state';
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { editAppointment } from 'redux/appointments/actions';
import { getEmployees } from 'redux/employees/actions';
import { getRooms } from 'redux/rooms/actions';
import { getServices } from 'redux/services/actions';
import { patternRule, PATTERNS, requiredRule } from 'utils/form-rules';

const { Option } = Select;

const EditAppointmentForm = ({
  form,
  initialValues,
  children,
  locale,
  onFieldsChange,
  disableTimeEditing
}: any) => {
  const dispatch = useDispatch();
  const clinicId = useClinicId();

  const appointments = useSelector<IAppState, any>((state) => state.appointments);
  const employees = useSelector<IAppState, any>((state) => state.employees.data);
  const services = useSelector<IAppState, any>((state) => state.services.data);
  const rooms = useSelector<IAppState, any>((state) => state.rooms.data);
  const [selectedDoctorId, setSelectedDoctorId] = useState(initialValues.doctor_id);
  const [selectedAttendees, setSelectedAttendees] = useState(initialValues.attendees || []);
  const [selectedRoomId, setSelectedRoomId] = useState(initialValues?.location?.id);
  const [locationType, setLocationType] = useState<string>(
    initialValues?.location?.type || 'other'
  );

  const onLocationTypeSelect = (value: any) => {
    setLocationType(value);
  };

  const [startDate, setStartDate] = useState<Dayjs>(dayjs(new Date()));
  const [endDate, setEndDate] = useState<Dayjs>(null);
  const [duration, setDuration] = useState<Dayjs>(null);

  useEffect(() => {
    form.resetFields();
  }, []);

  const onSubmit = (data: any) => {
    delete data.time;
    data.start_time = startDate.tz('utc').format(DATE_TIME_FORMAT);
    data.end_time = endDate.tz('utc').format(DATE_TIME_FORMAT);
    data.status = initialValues.status;
    dispatch(editAppointment(clinicId, initialValues.id, data));
  };

  useEffect(() => {
    if (clinicId) {
      dispatch(getRooms(clinicId, 0));
      dispatch(getEmployees(clinicId));
      dispatch(getServices(clinicId, 0));
    }
  }, [clinicId]);

  useEffect(() => {
    if (initialValues?.start_time) {
      setStartDate(dayjs(initialValues?.start_time));
      setEndDate(dayjs(initialValues?.end_time));
      form.setFieldValue('date_from', dayjs(initialValues?.start_time));
      form.setFieldValue('time_from', dayjs(initialValues?.start_time));
      form.setFieldValue('end_time', dayjs(initialValues?.end_time));

      const duration = dayjs(
        dayjs(initialValues.end_time).diff(dayjs(initialValues.start_time)) - 10800000
      );
      setDuration(duration);
      form.setFieldValue('duration', duration);
    } else {
      form.setFieldValue('date_from', dayjs(new Date()));
      form.setFieldValue('time_from', dayjs(new Date()));
    }
  }, []);

  const employeesList = employees.map((item) => {
    return (
      <Option key={item.full_name} title={item.full_name} value={item.id}>
        {item.full_name}
      </Option>
    );
  });

  const attendeesList = employees.map((item) => {
    return (
      item.id !== selectedDoctorId && (
        <Option key={item.full_name} title={item.full_name} value={item.id}>
          {item.full_name}
        </Option>
      )
    );
  });

  const roomsList = useMemo(
    (): JSX.Element[] =>
      rooms
        .filter(
          (room) =>
            (!room.not_for_appointment && !room.deleted) ||
            (room.id === initialValues?.location?.id &&
              selectedRoomId === initialValues?.location?.id)
        )
        .map((item) => {
          return (
            <Option key={`${item.name}${item.id}`} title={item.name} value={item.id}>
              {item.name}
            </Option>
          );
        }),
    [initialValues?.location?.id, selectedRoomId, rooms]
  );

  const servicesList = useMemo(
    () =>
      services
        .filter((s) => !s.deleted || initialValues?.services.some((ser) => ser.id === s.id))
        .map((item) => (
          <Option key={item.name} title={item.name} value={item.id}>
            {item.name}
          </Option>
        )),
    [services]
  );

  const locations = useLocalizedList(locationTypes);

  return (
    <Spin spinning={appointments.loading}>
      <Form
        onFieldsChange={onFieldsChange}
        onFinish={onSubmit}
        autoComplete='off'
        layout='vertical'
        form={form}
        initialValues={initialValues}
      >
        <Form.Item name='clinic_id' initialValue={clinicId} hidden>
          <Input />
        </Form.Item>
        <Row gutter={[20, 10]}>
          <Col span={24} md={12}>
            <Form.Item
              label={locale.labels.visitReason}
              name='title'
              rules={[
                requiredRule(locale.placeholders.mandatoryField),
                patternRule(PATTERNS.NAME, locale.errors.titleNotValid)
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              className='add-appointment-form__multi-select-field'
              label={locale.labels.service}
              name='services'
            >
              <Select
                mode='multiple'
                filterOption={(input, option) => {
                  return option?.title.toString().toLowerCase().includes(input?.toLowerCase());
                }}
                showSearch
                allowClear
              >
                {servicesList}
              </Select>
            </Form.Item>
            <Form.Item
              className='add-appointment-form__doctor-field'
              label={locale.labels.doctor}
              name='doctor_id'
              rules={[requiredRule(locale.errors.doctorNotValid)]}
            >
              <Select
                onChange={(e) => {
                  setSelectedDoctorId(e);
                }}
                filterOption={(input, option) => {
                  return option.key.toLowerCase().indexOf(input.toLowerCase()) >= 0;
                }}
                showSearch
              >
                {employeesList}
              </Select>
            </Form.Item>
            <Form.Item
              className='add-appointment-form__multi-select-field'
              label={locale.labels.attendees}
              name='attendees'
            >
              <Select
                className='attendees-select'
                mode='multiple'
                value={selectedAttendees}
                onSelect={(value) => setSelectedAttendees([...selectedAttendees, value])}
                filterOption={(input, option) => {
                  return option.key.toLowerCase().indexOf(input.toLowerCase()) >= 0;
                }}
                showSearch
              >
                {attendeesList?.length &&
                  attendeesList.filter((el) => el.value !== selectedDoctorId)}
              </Select>
            </Form.Item>
            <Form.Item label={''}>
              <Form.Item
                className='add-equipment-form__single-select-field'
                name={['location', 'type']}
                label={locale.labels.location}
                rules={[requiredRule(locale.errors.enterLocationType)]}
              >
                <Select
                  className='appointment-form-room-select'
                  filterOption={false}
                  onSelect={onLocationTypeSelect}
                  options={locations}
                />
              </Form.Item>
              <Form.Item
                name={['location', 'details']}
                label={locale.labels.details}
                hidden={locationType !== 'other'}
                rules={
                  locationType === 'other' ? [requiredRule(locale.errors.enterTheLocation)] : []
                }
              >
                <Input />
              </Form.Item>
              <Form.Item
                name={['location', 'id']}
                className='add-equipment-form__single-select-field'
                hidden={locationType !== 'room'}
                label={locale.labels.room}
                rules={locationType === 'room' ? [requiredRule(locale.errors.enterRoom)] : []}
              >
                <Select
                  onChange={setSelectedRoomId}
                  filterOption={(input, option) => {
                    return option.key.toLowerCase().indexOf(input.toLowerCase()) >= 0;
                  }}
                  showSearch
                >
                  {roomsList}
                </Select>
              </Form.Item>
            </Form.Item>
          </Col>
          <Col span={24} md={12}>
            <DateRangePicker
              disableTimeEditing={disableTimeEditing}
              startDate={startDate}
              endDate={endDate}
              duration={duration}
              setStartDate={setStartDate}
              setEndDate={setEndDate}
              setDuration={setDuration}
            />
          </Col>
        </Row>
        <TextEditor label={locale.labels.notes} name={['notes']} />
        {children}
      </Form>
    </Spin>
  );
};

export default EditAppointmentForm;
