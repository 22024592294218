import { Button, Form, Input, Spin } from 'antd';
import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { patternRule, PATTERNS, requiredRule } from 'utils/form-rules';

import { useLocale } from '../../hooks/useLocale';
import MessageModal from '../../layout/components/messageModal/MessageModal';
import { resetPassword } from '../../services/authService';

const SetNewPassword = () => {
  const navigate = useNavigate();
  const { token } = useParams() as any;
  const [loading, setLoading] = useState(false);
  const locale = useLocale('public.modals.sign-up');

  const onSubmit = (data) => {
    setLoading(true);
    resetPassword(data)
      .then(() => {
        setLoading(false);
        navigate('/');
        MessageModal('success', 'New password accepted');
      })
      .catch(() => {
        setLoading(false);
      });
  };

  return (
    <div style={{ maxWidth: 690, margin: '0 auto' }}>
      <Spin spinning={loading}>
        <Form onFinish={onSubmit} autoComplete='off' layout='vertical'>
          <Form.Item name='auth_code' initialValue={token} hidden>
            <Input />
          </Form.Item>
          <Form.Item
            label='Enter Password'
            name='password'
            rules={[
              requiredRule(locale.messages.inputPassword),
              patternRule(PATTERNS.PASSWORD, locale.messages.inputPassword)
            ]}
          >
            <Input.Password />
          </Form.Item>
          <Form.Item
            label='Repeat Password'
            name='repeatPassword'
            dependencies={['password']}
            hasFeedback
            rules={[
              requiredRule(locale.messages.passwordsDontMatch),
              ({ getFieldValue }) => ({
                async validator(_, value) {
                  if (!value || getFieldValue('password') === value) {
                    return await Promise.resolve();
                  }
                  return await Promise.reject(new Error(locale.messages.passwordsDontMatch));
                }
              })
            ]}
          >
            <Input.Password />
          </Form.Item>
          <Form.Item>
            <Button block type='primary' htmlType='submit'>
              Save
            </Button>
          </Form.Item>
        </Form>
      </Spin>
    </div>
  );
};

export default SetNewPassword;
