export const measurementsLocale = {
  enGB: {
    pcs: 'pcs',
    tablet: 'tablet',
    capsule: 'capsule',
    ampoule: 'ampoule',
    dose: 'dose',
    pair: 'pair',
    ml: 'ml',
    liter: 'liter',
    milligram: 'milligram',
    gram: 'gram',
    kg: 'kg',
    vial: 'vial',
    pipette: 'pipette',
    cm: 'cm',
    m: 'm',
    fluidOunce: 'fluid ounce',
    ounce: 'ounce',
    pound: 'pound',
    gallon: 'gallon'
  },
  ukUA: {
    pcs: 'шт',
    tablet: 'таблетка',
    capsule: 'капсула',
    ampoule: 'ампула',
    dose: 'доза',
    pair: 'пара',
    ml: 'мл',
    liter: 'літр',
    milligram: 'міліграм',
    gram: 'грам',
    kg: 'кг',
    vial: 'флакон',
    pipette: 'піпетка',
    cm: 'см',
    m: 'м',
    fluidOunce: 'рідка унція',
    ounce: 'унція',
    pound: 'фунт',
    gallon: 'галон'
  }
};
