import { UserOutlined } from '@ant-design/icons';
import { Avatar, Dropdown, Tooltip } from 'antd';
import * as config from 'config/config-manager.ts';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';

import { useLocale } from '../../../hooks/useLocale';
import { IAppState } from '../../../interfaces/app-state';
import { logout } from '../../../services/authService';

import type { MenuProps } from 'antd';
type MenuItem = Required<MenuProps>['items'][number];

const SettingsDropdown = () => {
  const dispatch = useDispatch();
  const user = useSelector<IAppState, any>((state) => state.user);
  const userName = `${user.first_name} ${user.last_name}`;
  const locale = useLocale('private.header');

  const accountItems = (): Array<{text: string, icon: string, route: string}> => {
    const items = [
      {
        text: locale.buttons.myProfile,
        icon: 'icofont-ui-user',
        route: '/user-profile'
      }
    ];
    if (!config.disabledForProd) {
      items.push({
        text: locale.buttons.createNewOrg,
        icon: 'icofont-ui-home',
        route: '/clinic-registration'
      });
    }
    return items;
  };

  const onLogout = () => {
    logout().finally(() => {
      dispatch({ type: 'RESET_STORE' });
      localStorage.clear();
    });
  };

  const accountMenu: MenuItem[] = accountItems().map((item, index) => ({
    key: index,
    label: (
      <NavLink to={item.route}>
        <span className='text'>{item.text}</span>
      </NavLink>
    ),
    icon: <span className={`icon mr-3 ${item.icon}`} />
  }));
  accountMenu.unshift({
    key: 11,
    label: <span className='menu-username'>{userName}</span>,
    disabled: true
  });
  accountMenu.push({
    onClick: onLogout,
    key: 10,
    label: (
      <span>
        {/* <span className={'icon mr-3 icofont-logout'}>&nbsp;&nbsp;</span> */}
        <span className={'icon mr-3 icofont-logout'} style={{ marginRight: 8 }} />
        <span className='text'>{locale.buttons.logout}</span>
      </span>
    )
  });

  return (
    <Dropdown menu={{ items: accountMenu }} trigger={['click']} placement='bottomRight'>
      <div className='item'>
        <Avatar size={40} className='mr-1' src={user.avatar} icon={<UserOutlined />} />
        <span className='actions-username'>
          {userName.length > 30 ? (
            <Tooltip title={userName}>{userName.toString().slice(0, 30) + '...'}</Tooltip>
          ) : (
            userName
          )}
        </span>
        <span className='icofont-simple-down' />
      </div>
    </Dropdown>
  );
};

export default SettingsDropdown;
