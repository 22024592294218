import { IPermissionsList } from '../../interfaces/permission';

export const GET_PERMISSIONS = '[permissions] Set';
export const SET_LOADING = '[permissions] Loading';
export const RESET = '[permissions] RESET';

export interface PermissionsResetAction {
  type: typeof RESET
}

export interface GetPermissionsAction {
  type: typeof GET_PERMISSIONS
  payload: IPermissionsList
}

export interface SetLoadingAction {
  type: typeof SET_LOADING
  payload: boolean
}

export type PermissionsActions =
    | GetPermissionsAction
    | PermissionsResetAction
    | SetLoadingAction;
