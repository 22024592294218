import { IService } from 'interfaces/services';
import {
  createClinicService,
  getClinicServiceDetails,
  getClinicServices,
  removeClinicService,
  updateClinicService,
} from 'services/clinic/services/ServicesService';
import { ISortObject } from 'services/interfaces/ISortObject';

import { setBackendError } from '../common/actions';
import {
  RESET,
  ServicesResetAction,
  SET_SERVICE,
  SET_SERVICES,
  SET_SERVICES_LOADING,
  SetLoadingAction,
  SetSelectedServiceAction,
  SetServicesAction,
  SHOW_MODAL,
  ShowModalAction,
} from './types';

export const setLoading = (payload: boolean): SetLoadingAction => ({
  type: SET_SERVICES_LOADING,
  payload
});

export const resetServices = (): ServicesResetAction => ({
  type: RESET
});

export const showModal = (payload: boolean): ShowModalAction => ({
  type: SHOW_MODAL,
  payload
});

export const setServices = (payload: IService[]): SetServicesAction => ({
  type: SET_SERVICES,
  payload
});

export const setSelectedService = (payload: IService | null): SetSelectedServiceAction => ({
  type: SET_SERVICE,
  payload
});

export const getServices = (clinicId: number, page?: number, searchArray?, sortObject?: ISortObject): any => {
  return (dispatch) => {
    dispatch(setLoading(true));
    getClinicServices(clinicId, page, searchArray, sortObject)
      .then((data: IService[]) => {
        dispatch(setServices(data));
        dispatch(setLoading(false));
      })
      .catch(() => dispatch(setLoading(false)));
  };
};

export const getServicesDetails = (clinicId: number, id: number): any => {
  return (dispatch) => {
    dispatch(setSelectedService(null));
    dispatch(setLoading(true));
    getClinicServiceDetails(clinicId, id)
      .then((data) => {
        dispatch(setSelectedService(data));
        dispatch(setLoading(false));
      })
      .catch(() => dispatch(setLoading(false)));
  };
};

export const getServicesDetailsById = async (clinicId: number, id: number): Promise<any> => {
  return async (dispatch) => {
    return getClinicServiceDetails(clinicId, id)
      .then((data) => data)
      .catch(() => dispatch(setLoading(false)));
  };
};

export const createService = (clinicId: number, data, callBackAfterCreate): any => {
  return (dispatch) => {
    dispatch(setLoading(true));
    createClinicService(clinicId, data)
      .then(() => {
        if (callBackAfterCreate) {
          callBackAfterCreate();
        } else {
          dispatch(getServices(clinicId));
        }
        dispatch(showModal(false));
        dispatch(setBackendError(false));
      })
      .catch(() => dispatch(setBackendError(true)))
      .finally(() => dispatch(setLoading(false)));
  };
};

export const updateService = (clinicId: number, data, serviceId: number): any => {
  return (dispatch) => {
    dispatch(setLoading(true));
    updateClinicService(clinicId, data, serviceId)
      .then(() => {
        dispatch(getServicesDetails(clinicId, serviceId));
        dispatch(showModal(false));
        dispatch(setBackendError(false));
      })
      .catch(() => dispatch(setBackendError(true)))
      .finally(() => dispatch(setLoading(false)));
  };
};

export const removeService = (clinicId: number, id: number): any => {
  return (dispatch) => {
    dispatch(setLoading(true));
    removeClinicService(clinicId, id)
      .then(() => {
        dispatch(setLoading(false));
      })
      .catch(() => dispatch(setLoading(false)));
  };
};
