import { Form } from 'antd';
import ConfirmCancelModal from 'components/ConfirmCancelModal';
import ConfirmDeleteModal from 'components/ConfirmDeleteModal';
import ViewDetailsButtonsGroup from 'components/ViewDetailsButtonsGroup';
import VLXModal from 'components/VLXModal';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import useClinicId from 'hooks/useClinicId';
import { useUserAccess } from 'hooks/useUserAccess';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { getAuditById, removeAudit, updateAudit } from 'redux/inventory/audit/actions';
import { CreateAuditRequest } from 'services/interfaces/clinics/inventory/audit/CreateAuditRequest';

import AuditForm from '../AuditForms/AuditForm';

dayjs.extend(customParseFormat);

export const EditAuditInfoModal = ({
  locale,
  selectedAudit,
  auditId,
  setShowEditAuditInfoModal,
  showDeleteModal,
  setShowDeleteModal
}: any): JSX.Element => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const clinicId = useClinicId();
  const userAccess = useUserAccess();
  const [editMode, setEditMode] = useState<boolean>(false);
  const [showCancelModal, setShowCancelModal] = useState<boolean>(false);
  const [form] = Form.useForm();

  const onSubmit = (data: CreateAuditRequest): void => {
    if (form.isFieldsTouched()) dispatch(updateAudit(clinicId, selectedAudit.id, data));
    setEditMode(false);
    setShowEditAuditInfoModal(false);
  };

  const onConfirmCancelClick = (): void => {
    form.resetFields();
    dispatch(getAuditById(clinicId, auditId));
    setEditMode(false);
    setShowCancelModal(false);
  };

  const onConfirmDeleteClick = (): void => {
    dispatch(removeAudit(clinicId, auditId, locale));
    setShowDeleteModal(false);
    navigate(`/clinic/${clinicId}/inventory/audit`);
  };

  const onFieldsChange = (formFieldData: string): void => {
    if (formFieldData.length < 2 && !editMode) {
      setEditMode(true);
    }
  };

  return (
    <>
      <VLXModal
        title={locale.labels.editAudit}
        centered
        form={form}
        open
        onModalClose={() => {
          setShowEditAuditInfoModal(false);
        }}
        width={800}
      >
        <AuditForm
          onFieldsChange={onFieldsChange}
          onSubmit={onSubmit}
          form={form}
          initialValues={{
            ...selectedAudit,
            date: dayjs(selectedAudit.date)
          }}
        >
          <ViewDetailsButtonsGroup
            fixed
            accessGroup={userAccess.accesses.audit}
            editMode={editMode}
            onCancelClick={() => {
              if (form.isFieldsTouched()) {
                setShowDeleteModal(false);
                setShowCancelModal(true);
              }
              setEditMode(false);
            }}
            onEditClick={() => setEditMode(true)}
            onDeleteClick={() => {
              setShowCancelModal(false);
              setShowDeleteModal(true);
            }}
          />
        </AuditForm>
      </VLXModal>
      <ConfirmDeleteModal
        type={'device'}
        open={showDeleteModal}
        onOk={onConfirmDeleteClick}
        onCancel={() => setShowDeleteModal(false)}
      />
      <ConfirmCancelModal
        open={showCancelModal}
        onOk={onConfirmCancelClick}
        onCancel={() => setShowCancelModal(false)}
      />
    </>
  );
};
