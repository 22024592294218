import React from 'react';
import income from '../../../assets/img/income.svg';
import spending from '../../../assets/img/spending.svg';

const FinanceIcon = ({ type }: any) => {
  switch (type) {
    case 'income':
      return <img src={income} className='finances-list-income-icon' />;
    case 'outcome':
      return <img src={spending} className='finances-list-income-icon' />;
  }
};

export default FinanceIcon;
