import '../scss/Provider.scss';

import { Button, Col, Form, FormInstance, Input, Row, Typography } from 'antd';
import PhoneFieldFormItem from 'components/PhoneFieldFormItem';
import { TextEditor } from 'components/TextEditor';
import useClinicId from 'hooks/useClinicId';
import { useLocale } from 'hooks/useLocale';
import React, { ReactNode, useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { createProvider, updateProvider } from 'redux/inventory/providers/actions';
import { ISelectedProvidersItem } from 'services/interfaces/clinics/inventory/providers/ISelectedProvidersItemsData';
import { maxRule, patternRule, PATTERNS, requiredRule } from 'utils/form-rules';

const { TextArea } = Input;

type Props = {
  form: FormInstance;
  children: ReactNode;
  formEditMode?: boolean;
  onFieldsChange?: (changedFields: any, allFields: any) => void;
  initialValues?: ISelectedProvidersItem;
  callBackAfterCreate?: (data: ISelectedProvidersItem) => void;
  defaultProviderNameValue?: string;
};

export const AddProviderForm = ({
  form,
  initialValues,
  formEditMode,
  children,
  onFieldsChange,
  callBackAfterCreate,
  defaultProviderNameValue
}: Props): JSX.Element => {
  const dispatch = useDispatch();
  const locale = useLocale('private.inventory.providers');
  const clinicId = useClinicId();

  useEffect(() => {
    form.resetFields();
  }, [initialValues]);

  const onSubmit = (data: any): void => {
    const apiData = { ...data };
    apiData.emails = apiData.emails
      ? JSON.stringify(apiData.emails.map(({ email, description }) => ({ email, description })))
      : [];
    apiData.phone_numbers = JSON.stringify(apiData.phone_numbers);

    if (formEditMode) {
      dispatch(updateProvider(clinicId, initialValues.id, apiData));
    } else {
      dispatch(createProvider(clinicId, apiData, callBackAfterCreate));
    }
  };

  const parseValues = useMemo(() => {
    if (initialValues) {
      const data = { ...initialValues };
      data.emails = JSON.parse(initialValues.emails);
      data.phone_numbers = JSON.parse(initialValues.phone_numbers);
      return data;
    }
  }, [initialValues]);

  const EmailFormItem = ({ name }): JSX.Element => (
    <Form.Item
      style={{ marginBottom: 0 }}
      name={[name, 'email']}
      rules={[patternRule(PATTERNS.EMAIL, locale.messages.emailNotValid)]}
    >
      <Input placeholder={locale.labels.emails} />
    </Form.Item>
  );

  const PhoneFormItem = ({ name }): JSX.Element => (
    <PhoneFieldFormItem
      formItemPhonePrefix={[name, 'country_code']}
      formItemPhoneNumber={[name, 'phone_number']}
      hideLabel
    />
  );

  const ContactsFormItem = ({ title, listName }): JSX.Element => (
    <div style={{ marginBottom: 15 }}>
      <Typography className='form-label'>
        <span style={{ color: '#ff4d4f', paddingRight: 4 }}>*</span>
        {title}
      </Typography>
      <Form.List name={listName}>
        {(fields, { add, remove }) => (
          <>
            {fields?.map(({ key, name }) => (
              <Row key={key} gutter={[20, 10]}>
                <Col span={24} md={8}>
                  {listName === 'emails' ? (
                    <EmailFormItem name={name} />
                  ) : (
                    <PhoneFormItem name={name} />
                  )}
                </Col>
                <Col span={24} md={9} xs={key > 0 ? 21 : 24}>
                  <Form.Item name={[name, 'description']}>
                    <Input placeholder={locale.labels.description} />
                  </Form.Item>
                </Col>
                {key > 0 && (
                  <Col span={1}>
                    <Button
                      onClick={(e) => {
                        remove(name);
                        formEditMode && onFieldsChange([{ e }], [{ e }]);
                      }}
                      danger
                      type='primary'
                      shape={'circle'}
                      className={'inventory-update-buttons'}
                    >
                      <span className='icofont icofont-trash' />
                    </Button>
                  </Col>
                )}
              </Row>
            ))}
            <Row style={{ marginTop: 15 }}>
              <Button
                className='icofont icofont-plus'
                type={'primary'}
                style={{
                  borderRadius: 30,
                  fontWeight: 'bold'
                }}
                onClick={() => add()}
              >
                {locale.buttons.addMore}
              </Button>
            </Row>
          </>
        )}
      </Form.List>
    </div>
  );

  return (
    <Form
      onFinish={onSubmit}
      onFieldsChange={onFieldsChange}
      autoComplete='off'
      layout='vertical'
      form={form}
      initialValues={parseValues}
    >
      <Form.Item name='clinic_id' hidden>
        <Input />
      </Form.Item>
      <Row gutter={[20, 10]}>
        <Col span={24} md={12}>
          <Form.Item
            label={locale.labels.name}
            name={'name'}
            rules={[requiredRule()]}
            initialValue={defaultProviderNameValue}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label={locale.labels.address}
            name='address'
            rules={[maxRule(255, locale.errors.length_rule_255)]}
          >
            <TextArea rows={2} />
          </Form.Item>
          <TextEditor label={locale.labels.comments} name={['comments']} rows={3} />
        </Col>
      </Row>
      <ContactsFormItem listName='emails' title={locale.labels.emails} />
      <ContactsFormItem listName='phone_numbers' title={locale.labels.contactPhone} />

      {children}
    </Form>
  );
};
