import { Button, Col } from 'antd';

export const RemoveButton = ({ remove }): JSX.Element => (
  <Col span={2} md={2} sm={4} xs={4}>
    <Button
      onClick={remove}
      danger
      type='primary'
      shape={'circle'}
      className={'inventory-update-buttons'}
    >
      <span className='icofont icofont-trash' />
    </Button>
  </Col>
);
