import { ISaleDetails } from 'interfaces/sales';

import baseClient from '../../baseClient';
import { generateSearchQuery, sorting } from '../../helpers/commonUtils';
import { ISortObject } from '../../interfaces/ISortObject';
import { CreateSaleRequest } from '../../interfaces/sales/create-sale-request';

const clinicsUrl: string = '/v1/clinics';

export const getClinicSales = async (
  id: number,
  page: number = 0,
  searchArray?: any[],
  sortObject?: ISortObject
) =>
  baseClient
    .get(
      `${clinicsUrl}/${id}/sales?page=${page}${generateSearchQuery(searchArray)}${sorting(
        sortObject
      )}`
    )
    .then((response: any) => {
      return response.data;
    });

export const getClinicSaleDetails = async (id: number, saleId: number) =>
  baseClient.get(`${clinicsUrl}/${id}/sales/${saleId}`).then((response: any) => {
    return response.data;
  });

export const createClinicSale = async (clinicId: number, data: CreateSaleRequest) =>
  baseClient
    .post(`/v1/clinics/${clinicId}/sales`, data)
    .then((response: any): ISaleDetails => response.data);

export const updateClinicSale = async (clinicId: number, saleId: number, data: CreateSaleRequest) =>
  baseClient
    .put(`/v1/clinics/${clinicId}/sales/${saleId}`, data)
    .then((response: any): ISaleDetails => response.data);

export const removeClinicSale = async (clinicId: number, saleId: number) =>
  baseClient.delete(`/v1/clinics/${clinicId}/sales/${saleId}`).then((response: any) => {
    return response.data;
  });
