import baseClient from './baseClient';
import { CreateInvitationRequest } from './interfaces/invitations/create-invitation-request';
import {
  InvitationEmployeeRegisterRequest
} from './interfaces/invitations/invitation-employee-register-request';
import { AcceptInvitationRequest } from './interfaces/invitations/accept-invitation-request';
import { ConfirmInvitationRequest } from './interfaces/invitations/confirm-invitation-request';

const invitationsUrl: string = '/v1/invitations';

export const createInvitation = async (invitationData: CreateInvitationRequest) => baseClient
  .post(`${invitationsUrl}/`, invitationData)
  .then((response: any) => {
    return response.data;
  });

export const acceptInvitation = async (acceptData: AcceptInvitationRequest) => baseClient
  .post(`${invitationsUrl}/accept`, acceptData)
  .then((response: any) => {
    return response.data;
  });

export const confirmInvitation = async (confirmData: ConfirmInvitationRequest) => baseClient
  .post(`${invitationsUrl}/confirm`, confirmData)
  .then((response: any) => {
    return response.data;
  });

export const registerUserByInvitation = async (
  registrationData: InvitationEmployeeRegisterRequest
) => baseClient
  .post(`${invitationsUrl}/employee/register`, registrationData)
  .then((response: any) => {
    return response.data;
  });
