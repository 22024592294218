import './Footer.scss';

import { Col, Row, Typography } from 'antd';
import { useLocale } from 'hooks/useLocale';
import React from 'react';
import { Link } from 'react-router-dom';

const FooterItems = (): JSX.Element => {
  const locale = useLocale('public.footer');

  return (
    <Row justify={'space-between'} className='footer-items'>
      <Col>
        <Typography.Text style={{ marginBottom: 8, fontSize: 14, display: 'block' }}>&#169; {new Date().getFullYear()} Vetlyx. {locale.labels.allRightsReserved}</Typography.Text>
        <Row style={{gap: 10}}>
          <Link
            target='_blank'
            className={'custom-links'}
            to={`/terms-and-conditions`}
          >
            {locale.labels.termsAndConditions}
          </Link>
          <span>|</span>
          <Link
            target='_blank'
            className={'custom-links'}
            to={`/privacy-policy`}
          >
            {locale.labels.privacyPolicy}
          </Link>
        </Row>
      </Col>
      <Col>
        <Row justify={'end'} style={{ marginBottom: 8 }}>
          <Typography.Text strong style={{ fontSize: 14 }}>{locale.labels.contactUs}</Typography.Text>
        </Row>
        <Row style={{gap: 10}} align={'middle'}>
          <Row className='footer__link-with-icon' align={'middle'}>
            <i className="icofont-telegram" style={{ fontSize: '20px' }}></i>
            <Link
              className={'custom-links'}
              to={`https://t.me/vetlyx`}
            >
              Telegram
            </Link>
          </Row>
          <span>|</span>
          <Row className='footer__link-with-icon' align={'middle'}>
            <i className="icofont-envelope" style={{ fontSize: '20px' }}></i>
            <Link
              target='_blank'
              className={'custom-links'}
              to={`mailto:support@vetlyx.com`}
            >
              support@vetlyx.com
            </Link>
          </Row>
        </Row>
      </Col>
    </Row>
  );
};

export default FooterItems;
