import './scss/HealthRecordsHistory.scss';
import { useLocale } from 'hooks/useLocale';
import React from 'react';
import { Tooltip } from 'antd';

type Props = {
  display: boolean;
  children: React.ReactNode;
};

export const PremiumSubscriptionTooltip = ({ display, children }: Props): JSX.Element => {
  const locale = useLocale('private.subscription-tooltip');
  return (
    <Tooltip
      trigger={'hover'}
      placement='topLeft'
      title={display ? locale.messages.availableInPremium : null}
    >
      {children}
    </Tooltip>
  );
};
