import { IEvent } from 'interfaces/event';
import { EventsIndexResponse } from 'services/interfaces/clinics/events/EventsIndexResponse';

export const GET_EVENTS = '[events] Set';
export const SHOW_MODAL = '[events] Add';
export const SET_LOADING = '[events] Edit';
export const SET_EVENT = '[events] Select';
export const SET_FILTERS = 'SET_FILTERS';
export const SET_EMPLOYEES_EVENTS = 'SET_EMPLOYEES_EVENTS';
export const SET_BE_ERROR = '[events] BackEndError';
export const RESET = '[events] RESET';
export const SHOW_EDIT_EVENT_MODAL = 'SHOW_EDIT_EVENT_MODAL';

export interface EventsResetAction {
  type: typeof RESET;
}

export interface GetEventsAction {
  type: typeof GET_EVENTS;
  payload: EventsIndexResponse[];
}

export interface ShowModalAction {
  type: typeof SHOW_MODAL;
  payload: boolean;
}

export interface SetBackendErrorAction {
  type: typeof SET_BE_ERROR;
  payload: boolean;
}

export interface SetLoadingAction {
  type: typeof SET_LOADING;
  payload: boolean;
}

export interface SetSelectedEvent {
  type: typeof SET_EVENT;
  payload: IEvent | null;
}

export interface SetFiltersAction {
  type: typeof SET_FILTERS;
  payload: any;
}

export interface SetEmployeesEventsAction {
  type: typeof SET_EMPLOYEES_EVENTS;
  payload: {
    [key: number]: IEvent[];
  };
}

export interface ShowEditEventModalAction {
  type: typeof SHOW_EDIT_EVENT_MODAL;
  payload: boolean;
}

export type EventsActions =
  | GetEventsAction
  | EventsResetAction
  | ShowModalAction
  | SetLoadingAction
  | SetBackendErrorAction
  | SetSelectedEvent
  | SetFiltersAction
  | SetEmployeesEventsAction
  | ShowEditEventModalAction;
