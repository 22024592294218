const breeds = [
  {
    value: 'mixed',
    enGB: 'Mixed',
    ukUA: 'Метис'
  },
  {
    value: 'abyssinian',
    enGB: 'Abyssinian',
    ukUA: 'Абіссінська'
  },
  {
    value: 'aegean',
    enGB: 'Aegean',
    ukUA: 'Егейська'
  },
  {
    value: 'american_bobtail',
    enGB: 'American Bobtail',
    ukUA: 'Американський Бобтейл'
  },
  {
    value: 'american_curl',
    enGB: 'American Curl',
    ukUA: 'Американський Керл'
  },
  {
    value: 'american_shorthair',
    enGB: 'American Shorthair',
    ukUA: 'Американська Короткошерста'
  },
  {
    value: 'american_wirehair',
    enGB: 'American Wirehair',
    ukUA: 'Американська Жорсткошерста'
  },
  {
    value: 'arabian_mau',
    enGB: 'Arabian Mau',
    ukUA: 'Арабська Мау (Аравійська Мау)'
  },
  {
    value: 'australian_mist',
    enGB: 'Australian Mist',
    ukUA: 'Австралійська Димчаста'
  },
  {
    value: 'balinese',
    enGB: 'Balinese',
    ukUA: 'Балійська (Балінезійська довгошерстаб Балінез)'
  },
  {
    value: 'bambino',
    enGB: 'Bambino',
    ukUA: 'Бамбіно'
  },
  {
    value: 'bengal',
    enGB: 'Bengal',
    ukUA: 'Бенгальська'
  },
  {
    value: 'birman',
    enGB: 'Birman',
    ukUA: 'Бірманська'
  },
  {
    value: 'bombay',
    enGB: 'Bombay',
    ukUA: 'Бомбей (Бомбейська)'
  },
  {
    value: 'british_longhair',
    enGB: 'British Longhair',
    ukUA: 'Британська Довгошерста'
  },
  {
    value: 'british_shorthair',
    enGB: 'British Shorthair',
    ukUA: 'Британська Короткошерста'
  },
  {
    value: 'burmese',
    enGB: 'Burmese',
    ukUA: 'Бурманська'
  },
  {
    value: 'burmilla',
    enGB: 'Burmilla',
    ukUA: 'Бурмила'
  },
  {
    value: 'california_spangled',
    enGB: 'California Spangled',
    ukUA: 'Каліфорнійська Сяюча'
  },
  {
    value: 'chantilly-tiffany',
    enGB: 'Chantilly-Tiffany',
    ukUA: 'Шантільська Тіффані (Шантільї-Тіффані)'
  },
  {
    value: 'chartreux',
    enGB: 'Chartreux',
    ukUA: 'Шартрез (Картезіанська)'
  },
  {
    value: 'chausie',
    enGB: 'Chausie',
    ukUA: 'Чаузі'
  },
  {
    value: 'colorpoint_shorthair',
    enGB: 'Colorpoint Shorthair',
    ukUA: 'Колорпоінт Короткошерста'
  },
  {
    value: 'cornish_rex',
    enGB: 'Cornish Rex',
    ukUA: 'Корніш-рекс'
  },
  {
    value: 'cymric',
    enGB: 'Cymric',
    ukUA: 'Кімрійська'
  },
  {
    value: 'devon_rex',
    enGB: 'Devon Rex',
    ukUA: 'Девон-Рекс'
  },
  {
    value: 'donskoy',
    enGB: 'Donskoy',
    ukUA: 'Донська'
  },
  {
    value: 'dragon_li',
    enGB: 'Dragon Li',
    ukUA: 'Дракон Лі'
  },
  {
    value: 'egyptian_mau',
    enGB: 'Egyptian Mau',
    ukUA: 'Єгипетська Мау'
  },
  {
    value: 'european_burmese',
    enGB: 'European Burmese',
    ukUA: 'Європейська Бурманська'
  },
  {
    value: 'european_shorthair',
    enGB: 'European Shorthair',
    ukUA: 'Європейська Короткошерста'
  },
  {
    value: 'exotic_shorthair',
    enGB: 'Exotic Shorthair',
    ukUA: 'Екзотична Короткошерста'
  },
  {
    value: 'havana_brown',
    enGB: 'Havana Brown',
    ukUA: 'Гавана Браун'
  },
  {
    value: 'himalayan',
    enGB: 'Himalayan',
    ukUA: 'Гімалайська'
  },
  {
    value: 'japanese_bobtail',
    enGB: 'Japanese Bobtail',
    ukUA: 'Японський Бобтейл'
  },
  {
    value: 'javanese',
    enGB: 'Javanese',
    ukUA: 'Яванська'
  },
  {
    value: 'khao_manee',
    enGB: 'Khao Manee',
    ukUA: 'Као Мані'
  },
  {
    value: 'korat',
    enGB: 'Korat',
    ukUA: 'Корат'
  },
  {
    value: 'kurilian',
    enGB: 'Kurilian',
    ukUA: 'Курильська'
  },
  {
    value: 'laperm',
    enGB: 'LaPerm',
    ukUA: 'Лаперм'
  },
  {
    value: 'maine_coon',
    enGB: 'Maine Coon',
    ukUA: 'Мейн-Кун'
  },
  {
    value: 'malayan',
    enGB: 'Malayan',
    ukUA: 'Малайська'
  },
  {
    value: 'manx',
    enGB: 'Manx',
    ukUA: 'Менська'
  },
  {
    value: 'munchkin',
    enGB: 'Munchkin',
    ukUA: 'Манчкін'
  },
  {
    value: 'nebelung',
    enGB: 'Nebelung',
    ukUA: 'Нібелунг'
  },
  {
    value: 'norwegian_forest_cat',
    enGB: 'Norwegian Forest Cat',
    ukUA: 'Норвезька Лісова'
  },
  {
    value: 'ocicat',
    enGB: 'Ocicat',
    ukUA: 'Оцикет'
  },
  {
    value: 'oriental',
    enGB: 'Oriental',
    ukUA: 'Орієнтальна'
  },
  {
    value: 'persian',
    enGB: 'Persian',
    ukUA: 'Персидська'
  },
  {
    value: 'pixie-bob',
    enGB: 'Pixie-bob',
    ukUA: 'Піксибоб (Піксібоб)'
  },
  {
    value: 'ragamuffin',
    enGB: 'Ragamuffin',
    ukUA: 'Рагамаффін'
  },
  {
    value: 'ragdoll',
    enGB: 'Ragdoll',
    ukUA: 'Регдол'
  },
  {
    value: 'russian_blue',
    enGB: 'Russian Blue',
    ukUA: 'Російська Блакитна'
  },
  {
    value: 'savannah',
    enGB: 'Savannah',
    ukUA: 'Саванна'
  },
  {
    value: 'scottish_fold',
    enGB: 'Scottish Fold',
    ukUA: 'Шотландська Висловуха'
  },
  {
    value: 'selkirk_rex',
    enGB: 'Selkirk Rex',
    ukUA: 'Селкірк-Рекс'
  },
  {
    value: 'siamese',
    enGB: 'Siamese',
    ukUA: 'Сіамська'
  },
  {
    value: 'siberian',
    enGB: 'Siberian',
    ukUA: 'Сибірська'
  },
  {
    value: 'singapura',
    enGB: 'Singapura',
    ukUA: 'Сингапурська'
  },
  {
    value: 'snowshoe',
    enGB: 'Snowshoe',
    ukUA: 'Білоніжка (Сноу-шу)'
  },
  {
    value: 'somali',
    enGB: 'Somali',
    ukUA: 'Сомалі (Сомалійська)'
  },
  {
    value: 'sphynx',
    enGB: 'Sphynx',
    ukUA: 'Сфінкс'
  },
  {
    value: 'tonkinese',
    enGB: 'Tonkinese',
    ukUA: 'Тонкінська'
  },
  {
    value: 'toyger',
    enGB: 'Toyger',
    ukUA: 'Тойгер'
  },
  {
    value: 'turkish_angora',
    enGB: 'Turkish Angora',
    ukUA: 'Турецька Ангора'
  },
  {
    value: 'turkish_van',
    enGB: 'Turkish Van',
    ukUA: 'Турецький Ван'
  },
  {
    value: 'other',
    enGB: 'Other',
    ukUA: 'Інша'
  }
];

export default breeds;
