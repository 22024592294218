import '../scss/StockForm.scss';

import {
  Button,
  Checkbox,
  Col,
  DatePicker,
  Form,
  FormInstance,
  Input,
  Row,
  Select,
  Typography
} from 'antd';
import { TextEditor } from 'components/TextEditor';
import VLXInputNumber from 'components/VLXInputNumber';
import dayjs from 'dayjs';
import { roundToHigher } from 'helpers/NumbersFormatter';
import useClinicId from 'hooks/useClinicId';
import { useI18n } from 'hooks/usei18n';
import { useLocale } from 'hooks/useLocale';
import { useUserAccess } from 'hooks/useUserAccess';
import { measurementsLocale } from 'i18n/measurements';
import { packagesLocale } from 'i18n/packages';
import { IAppState } from 'interfaces/app-state';
import { IRoom } from 'interfaces/room';
import { StockSelect } from 'layout/components/stockSelect/StockSelect';
import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getEmployees } from 'redux/employees/actions';
import { createInvoiceStockItem } from 'redux/inventory/invoice/actions';
import {
  createStockItem,
  getStock,
  getStockById,
  showCreateModal
} from 'redux/inventory/stock/actions';
import { getRooms } from 'redux/rooms/actions';
import {
  ISelectedStock,
  ISelectedStockPrices
} from 'services/interfaces/clinics/inventory/stock/ISelectedStock';
import { IStockItem } from 'services/interfaces/clinics/inventory/stock/IStockItem';
import { maxRule, patternRule, PATTERNS, requiredRule } from 'utils/form-rules';

import { CreateStockItemModal } from '../modals/CreateStockItemModal';

const { Option } = Select;

type Props = React.PropsWithChildren<{
  selectedStock: ISelectedStock;
  form: FormInstance;
  invoiceId: number;
  setShowAddItemsToStockModal: Dispatch<SetStateAction<boolean>>;
}>;

export const AddItemsToStockForm = ({
  children,
  selectedStock,
  form,
  invoiceId,
  setShowAddItemsToStockModal
}: Props): JSX.Element => {
  const dispatch = useDispatch();
  const clinicId = useClinicId();
  const locale = useLocale('private.inventory.stock');
  const currencies = useLocale('private.currencies').labels;
  const { accesses } = useUserAccess();

  const [purchasePrice, setPurchasePrice] = useState<number>(null);
  const [unassignedItems, setUnassignedItems] = useState(0);
  const [locatedItems, setLocatedItems] = useState({});
  const [selectedLocations, setSelectedLocations] = useState({});
  const [formSelectedStock, setFormSelectedStock] = useState(selectedStock);

  const defaultPrice = formSelectedStock?.prices?.find((price) => price.type === 'default');
  const showCreateStockModal = useSelector(({ stock }: IAppState) => stock.showCreateModal);
  const packagingFormat = useI18n(packagesLocale)[formSelectedStock?.packaging_format] || '';
  const unitOfMeasurement =
    useI18n(measurementsLocale)[formSelectedStock?.unit_of_measurement] || '';
  const noPackaging = formSelectedStock?.packaging_format === 'none';

  const stock = useSelector(({ stock }: IAppState) => stock.data);
  const rooms = useSelector(({ rooms }: IAppState) => rooms.data);

  useEffect(() => {
    dispatch(getEmployees(clinicId, 1));
    dispatch(getRooms(clinicId, 0, [{ name: 'deleted', value: 'false' }]));
    form.resetFields();
  }, []);

  const onEditingFormFinish = (payload): void => {
    payload.locations = payload.locations || [];

    const totalQuantity = payload.locations.reduce((sum, location) => location.quantity + sum, 0);
    if (totalQuantity > payload.initial_quantity) {
      return;
    }

    if (noPackaging) {
      payload.markup_package = payload.markup_unit;
      payload.selling_price = payload.unit_selling_price;
    }

    if (invoiceId) {
      dispatch(createInvoiceStockItem(clinicId, invoiceId, payload?.stock.id, payload));
    } else {
      dispatch(createStockItem(clinicId, payload, formSelectedStock?.id));
    }
    setShowAddItemsToStockModal(false);
  };

  const onStockSelect = (id: number): void => {
    const selectedStock = stock.find((el) => el.id === id);

    if (!selectedStock) return;

    getStockData(selectedStock);
  };

  const getStockData = (selectedStock: IStockItem): void => {
    form.setFieldsValue({
      markup_unit:
        selectedStock.markup_unit === 0 ? selectedStock.markup_package : selectedStock.markup_unit,
      markup_package: selectedStock.markup_package || 0
    });

    if (selectedStock.id && invoiceId)
      dispatch(getStockById(clinicId, selectedStock.id, setFormSelectedStock));
  };

  const getInitValues = (): { markup_package: number; markup_unit: number } => ({
    markup_package: formSelectedStock?.markup_package,
    markup_unit: formSelectedStock?.markup_unit || formSelectedStock?.markup_package
  });

  const onPurchasePriceChange = (purchasePrice: number): void => {
    setPurchasePrice(purchasePrice);
    const quantity = formSelectedStock.quantity_per_package;
    const pricePerUnit = purchasePrice / quantity;
    const pricePerUnitWithMarkup = roundToHigher(
      pricePerUnit + (pricePerUnit * form.getFieldValue('markup_unit')) / 100
    );
    const PricePerPackageWithMarkup = roundToHigher(
      purchasePrice + (purchasePrice * form.getFieldValue('markup_package')) / 100
    );

    form.setFieldsValue({
      selling_price: PricePerPackageWithMarkup.toFixed(2),
      unit_selling_price: pricePerUnitWithMarkup.toFixed(2)
    });
  };

  const onUnitSellingPriceChange = (sellingPrice: number): void => {
    const quantity = formSelectedStock?.quantity_per_package;
    const pricePerPackage = form.getFieldValue('price_per_unit');
    if (pricePerPackage) {
      const pricePerUnit = form.getFieldValue('price_per_unit') / quantity;
      const priceDiff = sellingPrice - pricePerUnit;
      const percentage = (priceDiff / pricePerUnit) * 100;
      form.setFieldsValue({
        markup_unit: Math.floor(percentage)
      });
    }
  };

  const onPackageSellingPriceChange = (sellingPrice: number): void => {
    const pricePerPackage = form.getFieldValue('price_per_unit');
    if (pricePerPackage) {
      const priceDiff = sellingPrice - pricePerPackage;
      const percentage = (priceDiff / pricePerPackage) * 100;

      form.setFieldsValue({
        markup_package: Math.floor(percentage)
      });
    }
  };

  const onMarkupUnitChange = (units: number): void => {
    const quantity = formSelectedStock?.quantity_per_package;
    const currentPrice = form.getFieldValue('price_per_unit');
    if (currentPrice) {
      const pricePerUnit = currentPrice / quantity;
      const pricePerUnitWithMarkup = roundToHigher(pricePerUnit + (pricePerUnit * units) / 100);
      form.setFieldsValue({
        unit_selling_price: pricePerUnitWithMarkup
      });
    }
  };

  const onMarkupPackageChange = (value: number): void => {
    const pricePerUnit = form.getFieldValue('price_per_unit');
    if (pricePerUnit) {
      const pricePerPackageWithMarkup = roundToHigher(pricePerUnit + (pricePerUnit * value) / 100);
      form.setFieldsValue({
        selling_price: pricePerPackageWithMarkup
      });
    }
  };

  const onInitialQuantityChange = (quantity: number): void => {
    const data = form.getFieldValue('locations');
    const newData = data?.map((item) => ({
      ...item,
      quantity: null
    }));
    form.setFieldValue('locations', newData);
    setLocatedItems({});
    setUnassignedItems(quantity || 0);
  };

  const restoreUnassignedItems = (locationKey: number): number => {
    const oldValue = locatedItems[locationKey]?.quantity || 0;
    return unassignedItems + oldValue;
  };

  const onLocationQuantityChange = (quantity: number, locationKey: number) => {
    const restoredQuantity = restoreUnassignedItems(locationKey);
    const items = {
      ...locatedItems,
      [locationKey]: { quantity }
    };
    setLocatedItems(items);
    const res = (restoredQuantity - quantity).toFixed(2);
    setUnassignedItems(Number(res));
  };

  const roomsList = rooms.map(
    (item: IRoom): JSX.Element => (
      <Option
        disabled={Object.values(selectedLocations).includes(item.id)}
        key={`${item.name}${item.id}`}
        title={item.name}
        value={item.id}
      >
        {item.name}
      </Option>
    )
  );

  const defaultPriceLabels = (text: string): JSX.Element => (
    <Typography.Text style={{ marginTop: '0px' }}>
      ({text} {currencies.uah})
    </Typography.Text>
  );

  const getDefaultPrice = (): JSX.Element | null => {
    const defaultPrice = formSelectedStock?.prices.find(
      (price: ISelectedStockPrices) => price.type === 'default'
    );
    if (defaultPrice) {
      if (noPackaging) {
        const unitsQuantity = defaultPrice.residual
          ? `${defaultPrice.residual} ${unitOfMeasurement} ${locale.labels.per} ${defaultPrice.price_per_unit} ${currencies.uah}`
          : '';
        return (
          !!unitsQuantity && (
            <Typography.Text>
              {locale.labels.remainingQuantityDefaultPrices}: {unitsQuantity}
            </Typography.Text>
          )
        );
      }

      const stockResidual =
        defaultPrice.residual - defaultPrice.quantity * selectedStock?.quantity_per_package;
      const packagesQuantity = defaultPrice.quantity
        ? `${defaultPrice.quantity} ${packagingFormat} ${locale.labels.per} ${defaultPrice.price_per_package} ${currencies.uah}`
        : '';
      const unitsQuantity = stockResidual
        ? `${stockResidual} ${unitOfMeasurement} ${locale.labels.per} ${defaultPrice.price_per_unit} ${currencies.uah}`
        : '';
      return (
        (packagesQuantity || unitsQuantity) && (
          <Typography.Text>
            {`${locale.labels.remainingQuantityDefaultPrices}: ${[packagesQuantity, unitsQuantity]
              .filter((str) => str)
              .join(', ')}`}
          </Typography.Text>
        )
      );
    }
  };

  const onLocationSelect = (value: string, key: number): void => {
    const locations = {
      ...selectedLocations,
      [key]: value
    };
    setSelectedLocations(locations);
  };

  const getPricePerUnitLabel = (): string => {
    const initLabel = locale.labels.pricePerUnit;
    const additionalInfo =
      !noPackaging && packagingFormat
        ? `(${packagingFormat} = ${formSelectedStock?.quantity_per_package} ${unitOfMeasurement})`
        : '';
    return `${initLabel} ${additionalInfo}`;
  };

  return (
    <Form
      key={'AddStockItemForm'}
      onFinish={onEditingFormFinish}
      autoComplete='off'
      layout='vertical'
      form={form}
      initialValues={getInitValues()}
    >
      <Row gutter={[40, 0]} className={'form-row'}>
        <Col span={24} sm={20} md={12} xl={12} xxl={12} className={'form-col'}>
          {!!invoiceId && (
            <>
              <Form.Item
                className='add-invoice-stock-item-form__stock-field'
                label={locale.labels.stockItem}
                name={['stock', 'id']}
                rules={[requiredRule(locale.messages.stockItemEmpty)]}
              >
                <StockSelect
                  clinicId={clinicId}
                  onChange={onStockSelect}
                  selectedStockId={formSelectedStock?.id}
                  locale={locale}
                  trackOutOfStock={false}
                />
              </Form.Item>
              <Form.Item>
                <Button
                  className='icofont icofont-plus'
                  type={'primary'}
                  style={{
                    borderRadius: 30,
                    fontWeight: 'bold'
                  }}
                  onClick={() => dispatch(showCreateModal(true))}
                >
                  {locale.buttons.createNew}
                </Button>
              </Form.Item>
            </>
          )}
          {!invoiceId && (
            <Form.Item
              name={['purchase_date']}
              label={locale.labels.purchaseDate}
              initialValue={dayjs()}
              rules={[requiredRule()]}
            >
              <DatePicker disabledDate={(d) => !d || d.isAfter(dayjs())} format='YYYY-MM-DD' />
            </Form.Item>
          )}
          <Form.Item
            name={['initial_quantity']}
            label={locale.labels.quantity}
            rules={[
              requiredRule(),
              patternRule(PATTERNS.DIGITS_1_9, locale.errors.onlyNumberAllowedMax10Chars)
            ]}
          >
            <VLXInputNumber onChange={onInitialQuantityChange} />
          </Form.Item>
          <Form.Item
            name={['price_per_unit']}
            label={getPricePerUnitLabel()}
            rules={[
              requiredRule(
                `${locale.errors.enterPurchasePrice} ${!noPackaging ? `(${packagingFormat})` : ''}`
              ),
              patternRule(PATTERNS.FLOATS_10, locale.errors.decimalNumberAllowedMax10Chars)
            ]}
          >
            <VLXInputNumber onChange={onPurchasePriceChange} />
          </Form.Item>
          <Form.Item name={['expiration_date']} label={locale.labels.expirationDate}>
            <DatePicker />
          </Form.Item>
          <Form.Item
            name={['series']}
            label={locale.labels.series}
            rules={[maxRule(50, locale.errors.length_rule_50)]}
          >
            <Input />
          </Form.Item>
        </Col>
        <Col span={24} sm={20} md={12} xl={12} xxl={12} className={'form-col'}>
          {!noPackaging && (
            <>
              <Form.Item
                label={`${locale.labels.perPackage} ${
                  packagingFormat ? `(${packagingFormat})` : ''
                }`}
                name='markup_package'
                rules={[requiredRule(locale.errors.canNotBeEmpty)]}
              >
                <VLXInputNumber onChange={onMarkupPackageChange} />
              </Form.Item>
              <Form.Item
                style={{ marginBottom: '5px' }}
                name={['selling_price']}
                label={`${locale.labels.sellingPrice} ${
                  packagingFormat ? `(${packagingFormat})` : ''
                }`}
                rules={[
                  requiredRule(locale.errors.enterSellingPrice),
                  patternRule(PATTERNS.FLOATS_10, locale.errors.decimalNumberAllowedMax10Chars)
                ]}
              >
                <VLXInputNumber disabled={!purchasePrice} onChange={onPackageSellingPriceChange} />
              </Form.Item>
              {defaultPrice &&
                defaultPriceLabels(
                  `${locale.labels.currentDefault}: ${defaultPrice.price_per_package}`
                )}
            </>
          )}
          <Form.Item
            label={`${locale.labels.perUnit} ${unitOfMeasurement ? `(${unitOfMeasurement})` : ''}`}
            name='markup_unit'
            rules={[requiredRule(locale.errors.canNotBeEmpty)]}
          >
            <VLXInputNumber onChange={onMarkupUnitChange} />
          </Form.Item>
          <Form.Item
            style={{ marginBottom: '5px' }}
            name={['unit_selling_price']}
            label={`${locale.labels.sellingPrice} ${
              unitOfMeasurement ? `(${unitOfMeasurement})` : ''
            }`}
            rules={[
              requiredRule(locale.errors.enterSellingPrice),
              patternRule(PATTERNS.FLOATS_10, locale.errors.decimalNumberAllowedMax10Chars)
            ]}
          >
            <VLXInputNumber onChange={onUnitSellingPriceChange} disabled={!purchasePrice} />
          </Form.Item>
          {defaultPrice &&
            defaultPriceLabels(`${locale.labels.currentDefault}: ${defaultPrice.price_per_unit}`)}
          <TextEditor label={locale.labels.notes} name={['notes']} />
        </Col>
      </Row>
      <Form.Item
        style={{ marginBottom: 10 }}
        hidden={formSelectedStock?.prices?.length === 0}
        label={locale.labels.assignPriceAsDefault}
        className='stock-modal-leftover'
        valuePropName='checked'
        initialValue={false}
        name={['create_leftovers']}
      >
        <Checkbox />
      </Form.Item>
      {formSelectedStock?.prices?.length > 0 && getDefaultPrice()}
      <Form.Item
        label={
          <>
            <h5 style={{ lineHeight: '5px', marginRight: 5 }}>{locale.labels.locations}</h5>
            <Typography.Text strong>
              {' '}
              ({locale.labels.unassigned}: {unassignedItems})
            </Typography.Text>
          </>
        }
      >
        <Form.List name='locations'>
          {(fields, { add, remove }) => (
            <>
              {unassignedItems < 0 && (
                <div style={{ color: '#ff4d4f', marginBottom: 12 }}>
                  {locale.errors.notEnoughItems}
                </div>
              )}
              {fields?.map(({ key, name, ...restField }) => (
                <Row key={key + name} gutter={[20, 10]}>
                  <Form.Item {...restField} hidden initialValue={'room'} name={[name, 'type']}>
                    <Input />
                  </Form.Item>
                  <Col span={22} md={11}>
                    <Form.Item
                      {...restField}
                      name={[name, 'id']}
                      rules={[requiredRule(locale.errors.locationIsRequired)]}
                    >
                      <Select
                        placeholder={locale.placeholders.pleaseSelectLocation}
                        filterOption={(input, option) => {
                          return option.key.toLowerCase().indexOf(input.toLowerCase()) >= 0;
                        }}
                        onSelect={(value) => onLocationSelect(value, name)}
                        showSearch
                      >
                        {roomsList}
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col span={22} md={11}>
                    <Form.Item
                      {...restField}
                      name={[name, 'quantity']}
                      rules={[
                        requiredRule(locale.errors.quantityIsRequired),
                        patternRule(PATTERNS.FLOATS_10, locale.errors.onlyNumberAllowedMax10Chars)
                      ]}
                    >
                      <VLXInputNumber
                        id='stock_discount_amount'
                        placeholder={locale.placeholders.pleaseSpecifyQuantity}
                        onChange={(value) => onLocationQuantityChange(value, name)}
                      />
                    </Form.Item>
                  </Col>
                  <Button
                    onClick={() => {
                      const restoredQuantity = restoreUnassignedItems(name);
                      const items = {
                        ...locatedItems,
                        [name]: { quantity: 0 }
                      };
                      const locations = {
                        ...selectedLocations,
                        [name]: null
                      };
                      setSelectedLocations(locations);
                      setLocatedItems(items);
                      setUnassignedItems(restoredQuantity);
                      remove(name);
                    }}
                    danger
                    type='primary'
                    shape={'circle'}
                    className={'inventory-update-buttons'}
                  >
                    <span className='icofont icofont-trash' />
                  </Button>
                </Row>
              ))}
              <Row>
                <Form.Item>
                  <Button
                    className='icofont icofont-plus'
                    type={'primary'}
                    style={{
                      borderRadius: 30,
                      fontWeight: 'bold'
                    }}
                    onClick={() => add()}
                  >
                    {fields.length ? locale.labels.addAnother : locale.labels.addLocation}
                  </Button>
                </Form.Item>
              </Row>
            </>
          )}
        </Form.List>
      </Form.Item>
      {children}
      {accesses.stock.create && showCreateStockModal && (
        <CreateStockItemModal
          clinicId={clinicId}
          callbackFn={(newStock): void => {
            dispatch(
              getStock(clinicId, null, 0, [], null, () => {
                form.setFieldValue(['stock', 'id'], newStock.id);
                getStockData(newStock);
              })
            );
          }}
        />
      )}
    </Form>
  );
};
