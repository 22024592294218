import {
  EquipmentActions, RESET,
  SET_BE_ERROR,
  SET_EQUIPMENT,
  SET_LOADING,
  SET_SELECTED_DEVICE,
  SHOW_CREATE_MODAL
} from './types';
import {
  EquipmentIndexResponse
} from '../../../services/interfaces/clinics/inventory/equipment/equipment-index-response';
import {
  EquipmentShowResponse
} from '../../../services/interfaces/clinics/inventory/equipment/equipment-show-response';

export interface EquipmentListState extends EquipmentIndexResponse {
  loading: boolean
  showCreateModal: boolean
  backendError: boolean
  selectedDevice: EquipmentShowResponse
}

const initialState: EquipmentListState = {
  data: [],
  metadata: {
    total: 0,
    page: 1,
    next: null,
    last: 1,
    prev: null
  },
  loading: false,
  showCreateModal: false,
  backendError: false,
  selectedDevice: null
};

export function equipmentReducer (
  state: EquipmentIndexResponse = initialState,
  action: EquipmentActions
): any {
  switch (action.type) {
    case SET_EQUIPMENT: {
      return {
        ...state,
        ...action.payload
      };
    }

    case RESET: {
      return initialState;
    }

    case SET_SELECTED_DEVICE: {
      return {
        ...state,
        selectedDevice: action.payload
      };
    }

    case SET_LOADING: {
      return {
        ...state,
        loading: action.payload
      };
    }

    case SHOW_CREATE_MODAL: {
      return {
        ...state,
        showCreateModal: action.payload
      };
    }

    case SET_BE_ERROR: {
      return {
        ...state,
        backendError: action.payload
      };
    }

    default: {
      return state;
    }
  }
}
