import { IUser } from '../../interfaces/user';
import { IAccesses, IUserAccesses } from '../../interfaces/user-accesses';
import {
  CLEAR_USER,
  GET_USER,
  SET_AVATAR,
  SET_LOADING,
  SET_REDIRECT,
  SET_TOKEN,
  SET_USER_CLINIC_ACCESSES,
  SHOW_MODAL,
  UserActions,
} from './types';

interface UserState extends IUser {
  loading: boolean;
  showModal: boolean;
  locale: string;
  clinicAccesses: Object;
}

const emptyAccesses: IAccesses = {
  showList: false,
  showItem: false,
  create: false,
  edit: false,
  delete: false
};

const emptyObject: IUserAccesses = {
  clinicId: null,
  clinicAvatar: null,
  clinicName: null,
  availableModules: {
    finances: false,
    inventory: false,
    shopPharmacy: false,
    documents: false,
    roles: false
  },
  accesses: {
    appointments: emptyAccesses,
    clinics: emptyAccesses,
    clinicDocuments: emptyAccesses,
    clientDocuments: emptyAccesses,
    patientDocuments: emptyAccesses,
    appointmentDocuments: emptyAccesses,
    employees: emptyAccesses,
    events: emptyAccesses,
    invitations: emptyAccesses,
    patients: emptyAccesses,
    patientHealthRecords: emptyAccesses,
    roles: emptyAccesses,
    rooms: emptyAccesses,
    clients: emptyAccesses,
    equipment: emptyAccesses,
    tools: emptyAccesses,
    services: emptyAccesses,
    invoices: emptyAccesses,
    invoiceInventoryItems: emptyAccesses,
    stock: emptyAccesses,
    financialAccounts: emptyAccesses,
    financialAccountPayments: emptyAccesses,
    audit: emptyAccesses,
    shifts: emptyAccesses,
    sales: emptyAccesses,
    bills: emptyAccesses,
    payments: emptyAccesses,
    skills: emptyAccesses,
    providers: emptyAccesses
  }
};

export const initialState: UserState = {
  address: null,
  birth_date: null,
  city: null,
  country: null,
  created_at: '',
  email: '',
  first_name: null,
  id: null,
  last_name: null,
  country_code: '',
  phone_number: '',
  region: null,
  updated_at: null,
  skills: [],
  user_scopes: [],
  userAccesses: [],
  token: null,
  loading: false,
  showModal: false,
  avatar: null,
  locale: null,
  redirect: true,
  clinicAccesses: emptyObject
};

function setAccesses(payload): IUserAccesses[] {
  return payload.user_scopes.map((item) => {
    const permissions = item.permissions;
    return {
      clinicId: item.clinic_id,
      clinicAvatar: item.avatar,
      clinicName: item.clinic_name,
      availableModules: {
        finances: item.available_modules.includes('finances'),
        inventory: item.available_modules.includes('inventory'),
        shopPharmacy: item.available_modules.includes('shop-pharmacy'),
        documents: item.available_modules.includes('documents'),
        roles: item.available_modules.includes('roles')
      },
      accesses: {
        appointments: {
          showList: permissions.includes('clinic.appointments.index'),
          showItem: permissions.includes('clinic.appointments.show'),
          create: permissions.includes('clinic.appointments.create'),
          edit: permissions.includes('clinic.appointments.update'),
          delete: permissions.includes('')
        },
        clinics: {
          showList: permissions.includes('clinics.show'),
          showItem: permissions.includes('clinics.show'),
          create: permissions.includes('clinics.create'),
          edit: permissions.includes('clinics.update'),
          delete: permissions.includes('')
        },
        employees: {
          showList: permissions.includes('clinics.show_employees'),
          showItem: permissions.includes('clinic.employees.show'),
          create: permissions.includes(''),
          edit: permissions.includes('clinic.employees.update'),
          delete: permissions.includes('')
        },
        events: {
          showList: permissions.includes('events.index'),
          showItem: permissions.includes('events.show'),
          create: permissions.includes('events.create'),
          edit: permissions.includes('clinic.events.update'),
          delete: permissions.includes('')
        },
        skills: {
          showList: permissions.includes('skills.index'),
          showItem: permissions.includes('skills.show'),
          create: permissions.includes('skills.create'),
          edit: permissions.includes('skills.update'),
          delete: permissions.includes('skills.destroy')
        },
        invitations: {
          showList: permissions.includes('clinics.show_invitations'),
          showItem: permissions.includes('invitations.show'),
          create: permissions.includes('invitations.create'),
          edit: permissions.includes('invitations.update'),
          delete: permissions.includes('invitations.destroy')
        },
        clientDocuments: {
          showList: permissions.includes('clinic.client.documents.index'),
          showItem: permissions.includes('clinic.client.documents.show'),
          create: permissions.includes('clinic.client.documents.create'),
          edit: permissions.includes('clinic.client.documents.update'),
          delete: permissions.includes('clinic.client.documents.destroy')
        },
        clinicDocuments: {
          showList: permissions.includes('clinic.documents.index'),
          showItem: permissions.includes('clinic.documents.show'),
          create: permissions.includes('clinic.documents.create'),
          edit: permissions.includes('clinic.documents.update'),
          delete: permissions.includes('clinic.documents.destroy')
        },
        patientDocuments: {
          showList: permissions.includes('clinic.patient.documents.index'),
          showItem: permissions.includes('clinic.patient.documents.show'),
          create: permissions.includes('clinic.patient.documents.create'),
          edit: permissions.includes('clinic.patient.documents.update'),
          delete: permissions.includes('clinic.patient.documents.destroy')
        },
        appointmentDocuments: {
          showList: permissions.includes('clinic.appointment.documents.index'),
          showItem: permissions.includes('clinic.appointment.documents.show'),
          create: permissions.includes('clinic.appointment.documents.create'),
          edit: permissions.includes('clinic.appointment.documents.update'),
          delete: permissions.includes('clinic.appointment.documents.destroy')
        },
        patients: {
          showList: permissions.includes('clinic.patients.index'),
          showItem: permissions.includes('clinic.patients.show'),
          create: permissions.includes('clinic.patients.create'),
          edit: permissions.includes('clinic.patients.update'),
          delete: permissions.includes('clinic.patients.destroy')
        },
        patientHealthRecords: {
          showList: permissions.includes('clinic.patient.health_records.index'),
          showItem: permissions.includes('clinic.patient.health_records.show'),
          create: permissions.includes('clinic.patient.health_records.create'),
          edit: permissions.includes('clinic.patient.health_records.update'),
          delete: permissions.includes('clinic.patient.health_records.destroy')
        },
        roles: {
          showList: permissions.includes('clinic.roles.index'),
          showItem: permissions.includes('clinic.roles.show'),
          create: permissions.includes('clinic.roles.create'),
          edit: permissions.includes('clinic.roles.update'),
          delete: permissions.includes('')
        },
        rooms: {
          showList: permissions.includes('rooms.index'),
          showItem: permissions.includes('rooms.show'),
          create: permissions.includes('rooms.create'),
          edit: permissions.includes('rooms.update'),
          delete: permissions.includes('rooms.destroy')
        },
        clients: {
          showList: permissions.includes('clinic.clients.index'),
          showItem: permissions.includes('clinic.clients.show'),
          create: permissions.includes('clinic.clients.create'),
          edit: permissions.includes('clinic.clients.update'),
          delete: permissions.includes('clinic.clients.destroy')
        },
        equipment: {
          showList: permissions.includes('clinic.equipment.index'),
          showItem: permissions.includes('clinic.equipment.show'),
          create: permissions.includes('clinic.equipment.create'),
          edit: permissions.includes('clinic.equipment.update'),
          delete: permissions.includes('clinic.equipment.destroy')
        },
        tools: {
          showList: permissions.includes('clinic.tools.index'),
          showItem: permissions.includes('clinic.tools.show'),
          create: permissions.includes('clinic.tools.create'),
          edit: permissions.includes('clinic.tools.update'),
          delete: permissions.includes('clinic.tools.destroy')
        },
        stock: {
          showList: permissions.includes('clinic.stock.index'),
          showItem: permissions.includes('clinic.stock.show'),
          create: permissions.includes('clinic.stock.create'),
          edit: permissions.includes('clinic.stock.update'),
          delete: permissions.includes('clinic.stock.destroy')
        },
        invoices: {
          showList: permissions.includes('clinic.invoices.index'),
          showItem: permissions.includes('clinic.invoices.show'),
          create: permissions.includes('clinic.invoices.create'),
          edit: permissions.includes('clinic.invoices.update')
        },
        bills: {
          showList: permissions.includes('clinic.bills.index'),
          showItem: permissions.includes('clinic.bills.show'),
          create: permissions.includes('clinic.bills.create'),
          edit: permissions.includes('clinic.bills.update'),
          delete: permissions.includes('clinic.bills.destroy')
        },
        payments: {
          showList: permissions.includes('clinic.payments.index'),
          showItem: permissions.includes('clinic.payments.show'),
          create: permissions.includes('clinic.payments.create'),
          edit: permissions.includes('clinic.payments.update'),
          delete: permissions.includes('clinic.payments.destroy')
        },
        invoiceInventoryItems: {
          showList: permissions.includes('clinic.invoice.inventory_items.index'),
          showItem: permissions.includes(''),
          create: permissions.includes('clinic.invoices.create'),
          edit: permissions.includes('')
        },
        services: {
          showList: permissions.includes('clinic.services.index'),
          showItem: permissions.includes('clinic.services.show'),
          create: permissions.includes('clinic.services.create'),
          edit: permissions.includes('clinic.services.update'),
          delete: permissions.includes('clinic.services.destroy')
        },
        financialAccounts: {
          showList: permissions.includes('clinic.financial_accounts.index'),
          showItem: permissions.includes('clinic.financial_accounts.show'),
          create: permissions.includes('clinic.financial_accounts.create'),
          edit: permissions.includes('clinic.financial_accounts.update'),
          delete: permissions.includes('clinic.financial_accounts.destroy')
        },
        financialAccountPayments: {
          showList: permissions.includes('clinic.financial_accounts.index'),
          showItem: permissions.includes('clinic.financial_accounts.show'),
          create: permissions.includes('clinic.financial_accounts.create'),
          edit: permissions.includes('clinic.financial_accounts.update'),
          delete: permissions.includes('clinic.financial_accounts.destroy')
        },
        audit: {
          showList: permissions.includes('clinic.inventory_audits.index'),
          showItem: permissions.includes('clinic.inventory_audits.show'),
          create: permissions.includes('clinic.inventory_audits.create'),
          edit: permissions.includes('clinic.inventory_audits.update'),
          delete: permissions.includes('clinic.inventory_audits.destroy')
        },
        sales: {
          showList: permissions.includes('clinic.sales.index'),
          showItem: permissions.includes('clinic.sales.show'),
          create: permissions.includes('clinic.sales.create'),
          edit: permissions.includes('clinic.sales.update'),
          delete: permissions.includes('clinic.sales.destroy')
        },
        shifts: {
          showList: permissions.includes('clinic.shifts.index'),
          showItem: permissions.includes('clinic.shifts.show'),
          create: permissions.includes('clinic.shifts.create'),
          edit: permissions.includes('clinic.shifts.update'),
          delete: permissions.includes('clinic.shifts.destroy')
        },
        providers: {
          showList: permissions.includes('clinic.shifts.index'),
          showItem: permissions.includes('clinic.shifts.show'),
          create: permissions.includes('clinic.shifts.create'),
          edit: permissions.includes('clinic.shifts.update'),
          delete: permissions.includes('clinic.shifts.destroy')
        }
      }
    };
  });
}

export function userReducer(state: IUser = initialState, action: UserActions): any {
  switch (action.type) {
    case GET_USER: {
      return {
        ...state,
        ...action.payload,
        userAccesses: setAccesses(action.payload),
        loading: false
      };
    }

    case CLEAR_USER: {
      return initialState;
    }

    case SHOW_MODAL: {
      return {
        ...state,
        showModal: action.payload
      };
    }

    case SET_REDIRECT: {
      return {
        ...state,
        redirect: action.payload
      };
    }

    case SET_LOADING: {
      return {
        ...state,
        loading: action.payload
      };
    }

    case SET_TOKEN: {
      return {
        ...state,
        token: action.payload
      };
    }

    case SET_AVATAR: {
      return {
        ...state,
        avatar: action.payload
      };
    }

    case SET_USER_CLINIC_ACCESSES: {
      return {
        ...state,
        clinicAccesses: action.payload
      };
    }

    default: {
      return state;
    }
  }
}
