import React from 'react';
import useLocalizedList from '../../../hooks/useLocalizedList';
import { Select } from 'antd';
import './StatusSelect.scss';

interface Props {
  status?: string
  onStatusChange?: any
  service?: any
  disabled?: any
  statusesList?: any
}

const { Option } = Select;

const StatusSelect = ({ status, onStatusChange, service, disabled, statusesList }: Props) => {
  const onChange = (newStatus: string) => {
    onStatusChange(newStatus, service);
  };

  const list = useLocalizedList(statusesList).map((item: any, index: any) => {
    return <Option key={`status_${index}`} value={item.value}>{item.label}</Option>;
  });

  return (
    <div>
      <Select
        disabled={disabled}
        onChange={onChange}
        value={status}
      >{list}</Select>
    </div>
  );
};

export default StatusSelect;
