import { Pagination, Row, Spin, Table, TableColumnProps, Tag } from 'antd';
import { statusColor } from 'constants/invitationStatuses';
import { scrollToTop } from 'helpers/list-helpers';
import useClinicId from 'hooks/useClinicId';
import { IAppState } from 'interfaces/app-state';
import { IInvitation } from 'interfaces/invitation';
import Invitation from 'layout/modals/invitation/Invitation';
import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { getInvitations } from 'redux/invitations/actions';

const InvitationsList = (): JSX.Element => {
  const dispatch = useDispatch();
  const clinicId = useClinicId();

  const { data, metadata } = useSelector(({ invitations }: IAppState) => invitations);
  const { loading } = useSelector(({ invitations }: IAppState) => invitations);

  const columns = useMemo(
    (): TableColumnProps<IInvitation>[] => [
      {
        key: 'phone_number',
        dataIndex: 'phone_number',
        title: 'Phone number',
        sorter: (a, b) =>
          `${a.country_code}${a.phone_number}` > `${a.country_code}${b.phone_number}` ? 1 : -1,
        render: (e, record) => (
          <Link
            to={`tel:${record.country_code ?? ''}${record.phone_number}`}
            className='custom-links d-flex align-baseline nowrap'
          >
            <span className='icofont icofont-ui-cell-phone mr-1' style={{ fontSize: 16 }} />+
            {record.country_code}
            {record.phone_number}
          </Link>
        )
      },
      {
        key: 'email',
        dataIndex: 'email',
        title: 'E-mail',
        sorter: (a, b) => (a.email > b.email ? 1 : -1),
        render: (email) => (
          <Link to={`mailto:${email}`} className='custom-links d-flex align-baseline nowrap'>
            <span className='icofont icofont-email mr-1' style={{ fontSize: 16 }} />
            {email}
          </Link>
        )
      },
      {
        key: 'role',
        dataIndex: 'role',
        title: 'Role',
        sorter: (a, b) => (a.role > b.role ? 1 : -1),
        render: (role) => (
          <span className='nowrap' style={{ color: '#a5a5a5' }}>
            {role}
          </span>
        )
      },
      {
        key: 'status',
        dataIndex: 'status',
        title: 'Status',
        render: (status) => (
          <Tag style={{ borderRadius: 20 }} color={statusColor[status]}>
            {status}
          </Tag>
        )
      }
    ],
    [data]
  );

  const onPageChange = (page: number): void => {
    dispatch(getInvitations(clinicId, page));
    scrollToTop();
  };

  useEffect((): void => {
    if (clinicId) dispatch(getInvitations(clinicId, 1));
  }, [clinicId]);

  return (
    <Spin spinning={loading}>
      <Table
        pagination={false}
        className='accent-header'
        rowKey='id'
        dataSource={data}
        columns={columns}
      />
      <Row style={{ justifyContent: 'space-between', marginTop: 10, alignItems: 'flex-end' }}>
        <Pagination
          defaultCurrent={1}
          showSizeChanger={false}
          showLessItems
          pageSize={20}
          current={metadata.page}
          total={metadata.total}
          onChange={onPageChange}
        />
        <Invitation />
      </Row>
    </Spin>
  );
};

export default InvitationsList;
