import { InputNumber } from 'antd';
import React from 'react';

class VLXInputNumber extends React.Component<any, any> {
  customParser = (value): string => {
    return value ? value.toString().replace(/,/g, '.') : '';
  };

  handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    const allowedKeys = ['Backspace', 'Delete', 'ArrowRight', 'ArrowLeft', 'Shift', 'Meta', 'Control', 'Alt'];
    const pressedKey = event.key;
    const inputValue = event.currentTarget.value;
    const isCopyPaste = (event.ctrlKey || event.metaKey) && (pressedKey === 'c' || pressedKey === 'v');

    if (!/[0-9.,]/.test(pressedKey) && !allowedKeys.includes(pressedKey) && !isCopyPaste) {
      event.preventDefault();
    } else if ((pressedKey === '.' || pressedKey === ',') && (inputValue.includes('.') || inputValue.includes(','))) {
      event.preventDefault();
    }
  };

  handlePaste = (event: React.ClipboardEvent<HTMLInputElement>) => {
    const pasteData = event.clipboardData.getData('Text');
    const inputValue = event.currentTarget.value;

    if (!/^[0-9.,]*$/.test(pasteData) || (pasteData.includes('.') && inputValue.includes('.'))
      || (pasteData.includes(',') && inputValue.includes(','))) {
      event.preventDefault();
    }
  };

  render() {
    return (
      <InputNumber
        className={!this.props.disabled ? 'vlx-number-input' : 'vlx-number-input-disabled'}
        parser={this.customParser}
        inputMode='decimal'
        placeholder='0'
        precision={2}
        step={0.1}
        min='0'
        onKeyDown={this.handleKeyPress}
        onPaste={this.handlePaste}
        {...this.props}
      />
    );
  }
}

export default VLXInputNumber;
