import './scss/VLXSearch.scss';

import { CloseCircleOutlined } from '@ant-design/icons';
import { Button, DatePicker, Input, Row, Select } from 'antd';
import { enterKeyCode } from 'constants/common';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import useClinicId from 'hooks/useClinicId';
import { useLocale } from 'hooks/useLocale';
import useLinkParams from 'hooks/useSearchParams';
import React, { useEffect, useRef, useState } from 'react';

import { LocalStorageFilters } from './types';

const { Option } = Select;
dayjs.extend(customParseFormat);

const VLXSearch = ({
  selectOptions,
  selectPlaceholder,
  enableFilteringByDate,
  savedFiltersName,
  filterResults,
  renderAdditionalContent
}: any): JSX.Element => {
  const clinicId = useClinicId();
  const filterParams = useLinkParams('filter');
  const selectParams = useLinkParams('statuses');
  const startDateFromParams = useLinkParams('startDateFrom');
  const startDateToParams = useLinkParams('startDateTo');
  const locale = useLocale('private.composite-search');
  const [searchValue, setSearchValue] = useState<string>(filterParams);
  const [selectValue, setSelectValue] = useState<any>([]);
  const [dateFrom, setDateFrom] = useState<string>(null);
  const [dateTo, setDateTo] = useState<string>(null);
  const selectRef = useRef(null);

  useEffect((): void => {
    if (savedFiltersName) {
      const savedFilters = JSON.parse(localStorage.getItem(savedFiltersName));
      let lsFilters: LocalStorageFilters = null;
      if (savedFilters !== null) {
        if (savedFilters[clinicId]) {
          lsFilters = savedFilters[clinicId];
        }
      }

      setSearchValue(filterParams || lsFilters?.searchValue);
      setSelectValue(selectParams?.split(',') ?? lsFilters?.selectValue);
      setDateFrom(startDateFromParams ?? lsFilters?.dateFrom);
      setDateTo(startDateToParams ?? lsFilters?.dateTo);

      filterResults({
        filter: lsFilters?.searchValue,
        startDateFrom: lsFilters?.dateFrom,
        startDateTo: lsFilters?.dateTo,
        statuses: lsFilters?.selectValue
      });
    }
  }, [savedFiltersName]);

  useEffect(()=>{
    return () => {
      setSearchValue('');
      setSelectValue([]);
      setDateFrom('');
      setDateTo('');
    };
  },[]);

  const onKeyDown = (event): void => {
    if (event.keyCode === enterKeyCode) {
      onSearch();
    }
  };

  const onSearch = (): void => {
    filterResults({
      filter: searchValue,
      startDateFrom: dateFrom,
      startDateTo: dateTo,
      statuses: selectValue
    });
    setLocalStorageFilters();
  };

  const onSelectValueChange = (vals): void => {
    setSelectValue(vals.filter((el) => el));
  };

  const selectOptionsFormatted = selectOptions?.map(
    (item): JSX.Element => (
      <Option key={item.value} title={item.label} value={item.value}>
        {item.label}
      </Option>
    )
  );

  const onResetFilters = (): void => {
    const filters = JSON.parse(localStorage.getItem(savedFiltersName));
    if (filters) {
      for (const key in filters) {
        if (+key === +clinicId) {
          filters[clinicId] = null;
        }
      }
      localStorage.setItem(savedFiltersName, JSON.stringify(filters));
    }

    setDateFrom(null);
    setDateTo(null);
    setSearchValue('');
    setSelectValue([]);
    filterResults({
      filter: '',
      startDateFrom: '',
      startDateTo: '',
      statuses: ''
    });
  };

  const setLocalStorageFilters = (): void => {
    const filters = JSON.parse(localStorage.getItem(savedFiltersName));
    if (filters) {
      filters[clinicId] = { searchValue, selectValue, dateFrom, dateTo };
      localStorage.setItem(savedFiltersName, JSON.stringify(filters));
    } else {
      localStorage.setItem(
        savedFiltersName,
        JSON.stringify({ [clinicId]: { searchValue, selectValue, dateFrom, dateTo } })
      );
    }
  };

  return (
    <Row className='composit-search-select-option-search'>
      <Input
        className='search-field composit-search-select-option-search-input'
        allowClear
        onChange={(e) => setSearchValue(e.target.value)}
        value={searchValue}
        onKeyDown={onKeyDown}
        style={{ margin: 0 }}
        placeholder={locale.placeholders.searchCriteria}
      />
      {selectOptions && (
        <Select
          getPopupContainer={(triggerNode) => triggerNode.parentElement}
          className='composit-search-select-option-search-select'
          placeholder={selectPlaceholder}
          mode='multiple'
          showSearch={false}
          onChange={onSelectValueChange}
          value={selectValue?.filter((el) => el)}
          ref={selectRef}
        >
          {selectOptionsFormatted}
        </Select>
      )}
      {enableFilteringByDate && (
        <>
          <DatePicker
            className='composit-search-select-option-search-date-picker'
            onChange={(e) => setDateFrom(e ? e.format('DD-MM-YYYY') : null)}
            format={'DD-MM-YYYY'}
            value={dateFrom ? dayjs(dateFrom, 'DD-MM-YYYY') : null}
            placeholder={locale.placeholders.dateFrom}
          />
          <DatePicker
            className='composit-search-select-option-search-date-picker'
            onChange={(e) => setDateTo(e ? e.format('DD-MM-YYYY') : null)}
            format={'DD-MM-YYYY'}
            value={dateTo ? dayjs(dateTo, 'DD-MM-YYYY') : null}
            placeholder={locale.placeholders.dateTo}
          />
        </>
      )}
      <Button
        className='composit-search-select-option-search-button'
        type='primary'
        onClick={onSearch}
      >
        {locale.buttons.search}
      </Button>
      <Button
        className='composit-search-select-option-reset-button'
        disabled={!searchValue && !selectValue?.length && !dateFrom && !dateTo}
        icon={<CloseCircleOutlined style={{ margin: '0px' }} />}
        type='primary'
        onClick={onResetFilters}
      >
        {locale.buttons.resetFilters}
      </Button>
      {renderAdditionalContent}
    </Row>
  );
};

export default VLXSearch;
