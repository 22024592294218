import { Form, FormInstance, Modal } from 'antd';
import ConfirmCancelModal from 'components/ConfirmCancelModal';
import ConfirmDeleteModal from 'components/ConfirmDeleteModal';
import ViewDetailsButtonsGroup from 'components/ViewDetailsButtonsGroup';
import { useLocale } from 'hooks/useLocale';
import { useUserAccess } from 'hooks/useUserAccess';
import { IAppointment } from 'interfaces/appointment';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { showBillModal, updateAppointmentBill } from 'redux/appointments/actions';
import { updateBill } from 'redux/finances/bills/actions';
import { removeService } from 'redux/services/actions';
import { IBillDetails } from 'services/clinic/bills/models/IBillDetails';

import { ViewEditBillForm } from './ViewEditBillForm';

type Props = {
  initialValues: IBillDetails;
  appointment?: IAppointment;
  form: FormInstance;
  billDetailsPage?: boolean;
};

export const ViewEditBill = ({
  initialValues,
  appointment,
  form,
  billDetailsPage
}: Props): JSX.Element => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const userAccess = useUserAccess();
  const { clinicId, serviceId } = useParams();
  const locale = useLocale('private.appointments.appointment-bill');

  const [formValid, setFormValid] = useState<boolean>(false);
  const [finalTotalPrice, setFinalTotalPrice] = useState<number>(null);
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
  const [showCancelModal, setShowCancelModal] = useState<boolean>(false);
  const values = Form.useWatch([], form);

  useEffect((): void => {
    const totalPrice =
      typeof initialValues?.final_amount === 'number'
        ? initialValues?.final_amount
        : initialValues?.amount || 0;

    setFinalTotalPrice(totalPrice);
  }, [initialValues]);

  useEffect(() => {
    form.validateFields({ validateOnly: true }).then(
      () => {
        setFormValid(true);
      },
      () => {
        setFormValid(false);
      }
    );
  }, [values]);

  const onConfirmCancelClick = (): void => {
    form.resetFields();
    dispatch(showBillModal(false));
    setShowCancelModal(false);
  };

  const statusPayload = (status: string) => ({
    properties: [
      {
        name: 'status',
        value: status
      }
    ]
  });

  const onSubmit = (): void => {
    const data = form.getFieldsValue();

    const getDiscount = ({ amount, percentage }: { amount: number; percentage: number }) => ({
      amount: amount ? Number(amount).toFixed(2) : null,
      percentage: percentage ? Math.round(percentage) : null
    });

    const apiData = {
      name: initialValues?.name,
      description: initialValues?.description,
      date: initialValues?.date,
      amount: Number(finalTotalPrice).toFixed(2),
      vat_included: initialValues?.vat_included,
      bill_type: initialValues?.bill_type,
      services_discount: getDiscount(data.services_discount),
      stock_discount: data.stock_discount
        ? getDiscount(data.stock_discount)
        : { amount: '0.00', percentage: 0 },
      client_id: initialValues?.client?.id
    };

    if (appointment) {
      const appointmentData = {
        ...appointment,
        status: 'finished'
      };
      const statusData = statusPayload('finished');
      dispatch(
        updateAppointmentBill(+clinicId, initialValues.id, appointmentData, statusData, apiData)
      );
    } else {
      dispatch(updateBill(+clinicId, initialValues.id, apiData));
    }
  };

  const onSaveModalClick = (): void => {
    if (!formValid) return;

    if (billDetailsPage) {
      onSubmit();
    } else {
      Modal.confirm(modalParams);
    }
  };

  const modalParams = {
    title: locale.labels.confirmModalTitle,
    content: locale.messages.confirmModalMessage,
    okText: locale.labels.confirmButton,
    cancelText: locale.labels.cancelButton,
    centered: true,
    onOk: onSubmit
  };

  const onConfirmDeleteClick = (): void => {
    dispatch(removeService(+clinicId, +serviceId));
    navigate(`/clinic/${clinicId}/services`);
  };

  return (
    <>
      {initialValues && (
        <ViewEditBillForm
          localization={locale}
          form={form}
          billDetails={initialValues}
          finalTotalPrice={finalTotalPrice}
          setFinalTotalPrice={setFinalTotalPrice}
          billDetailsPage={billDetailsPage}
        >
          <ViewDetailsButtonsGroup
            fixed
            accessGroup={userAccess.accesses.services}
            onSaveModalClick={onSaveModalClick}
            editMode
            hideDeleteBtn
            onCancelClick={() => {
              if (form.isFieldsTouched()) {
                setShowCancelModal(true);
              } else {
                dispatch(showBillModal(false));
              }
            }}
            onDeleteClick={() => {
              setShowCancelModal(false);
              setShowDeleteModal(true);
            }}
          />
        </ViewEditBillForm>
      )}
      <ConfirmCancelModal
        open={showCancelModal}
        onOk={onConfirmCancelClick}
        onCancel={() => setShowCancelModal(false)}
      />
      <ConfirmDeleteModal
        type={'device'}
        open={showDeleteModal}
        onOk={onConfirmDeleteClick}
        onCancel={() => setShowDeleteModal(false)}
      />
    </>
  );
};
