import { IService } from '../../interfaces/services';

export const SET_SERVICES_LOADING = 'SET_SERVICES_LOADING';
export const SET_SERVICES = 'SET_SERVICES';
export const SET_SERVICE = 'SET_SERVICE';
export const SHOW_MODAL = 'SHOW_MODAL';
export const RESET = '[services] RESET';

export interface ServicesResetAction {
  type: typeof RESET;
}

export interface SetServicesAction {
  type: typeof SET_SERVICES;
  payload: IService[];
}

export interface SetLoadingAction {
  type: typeof SET_SERVICES_LOADING;
  payload: boolean;
}

export interface ShowModalAction {
  type: typeof SHOW_MODAL;
  payload: boolean;
}

export interface SetSelectedServiceAction {
  type: typeof SET_SERVICE;
  payload: IService | null;
}

export type ServicesActions =
  | SetServicesAction
  | ServicesResetAction
  | ShowModalAction
  | SetSelectedServiceAction
  | SetLoadingAction;
