import { AxiosResponse } from 'axios';

import baseClient from '../../baseClient';
import { generateSearchQuery, sorting } from '../../helpers/commonUtils';
import { IndexShiftsResponse } from './models/IndexShiftsResponse';
import { ShowShiftResponse } from './models/ShowShiftResponse';
import { ISortObject } from '../../interfaces/ISortObject.ts';

function url(clinic_id) {
  return `/v1/clinics/${clinic_id}/shifts`;
}

export const openClinicShift = async (clinicId: number) =>
  baseClient.post(url(clinicId), '').then((response: AxiosResponse<ShowShiftResponse>) => {
    return response.data;
  });

export const closeClinicShift = async (clinicId: number, shiftId: number) =>
  baseClient
    .put(`${url(clinicId)}/${shiftId}`, '')
    .then((response: AxiosResponse<ShowShiftResponse>) => {
      return response.data;
    });

export const showClinicShift = async (clinicId: number, shiftId: number) =>
  baseClient
    .get(`${url(clinicId)}/${shiftId}`)
    .then((response: AxiosResponse<ShowShiftResponse>) => {
      return response.data;
    });

export const showOpenedClinicShift = async (clinicId: number) =>
  baseClient.get(`${url(clinicId)}/opened`).then((response: AxiosResponse<ShowShiftResponse>) => {
    return response.data;
  });

export const indexClinicShifts = async (clinicId: number, page: number, searchArray?: any[],
  sortObject?: ISortObject) =>
  baseClient
    .get(`${url(clinicId)}?page=${page}${generateSearchQuery(searchArray)}${sorting(sortObject)}`)
    .then((response: AxiosResponse<IndexShiftsResponse>) => {
      return response.data;
    });
