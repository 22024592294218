import React from 'react';
import { Tag } from 'antd';

const getPaymentStatus = (paymentStatus) => {
  let color = null;

  switch (paymentStatus.value) {
    case 'unpaid':
      color = 'red';
      break;
    case 'paid':
      color = 'green';
      break;
    case 'partially_paid':
      color = 'orange';
      break;
    case 'overpaid':
      color = 'orange';
      break;
    case 'payment_not_required':
      color = 'green';
      break;
  }

  return tag(color, paymentStatus.label);
};

const tag = (color: string, value: string) => {
  return <Tag id='payment-status-tag' color={color}>{value}</Tag>;
};

export default getPaymentStatus;
