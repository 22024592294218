import { IInvitation, IInvitationsList } from 'interfaces/invitation';
import InvitationsRejectedModal from 'layout/modals/invitation/InvitationsRejectedModal';
import { getClinicInvitations } from 'services/clinic/clinicsService';
import { createInvitation } from 'services/invitationsService';

import {
  GET_INVITATIONS,
  GetInvitationsAction,
  SET_INVITATION,
  SET_INVITATIONS_LOADING,
  SetLoadingAction,
  SetSelectedInvitation,
  SHOW_MODAL,
  ShowModalAction,
} from './types';

export const setInvitations = (rooms: IInvitationsList): GetInvitationsAction => ({
  type: GET_INVITATIONS,
  payload: rooms
});

export const showModal = (value: boolean): ShowModalAction => ({
  type: SHOW_MODAL,
  payload: value
});

export const setLoading = (value: boolean): SetLoadingAction => ({
  type: SET_INVITATIONS_LOADING,
  payload: value
});

export const setSelectedInvitation = (data: IInvitation | null): SetSelectedInvitation => ({
  type: SET_INVITATION,
  payload: data
});

export const getInvitations = (clinicId: number, page?: number): any => {
  return (dispatch) => {
    dispatch(setLoading(true));
    getClinicInvitations(clinicId, page)
      .then((data) => {
        dispatch(setInvitations(data));
        dispatch(setLoading(false));
      })
      .catch(() => dispatch(setLoading(false)))
      .finally(() => setLoading(false));
  };
};

export const sendInvitation = (requestData: any): any => {
  return (dispatch) => {
    dispatch(setLoading(true));
    createInvitation(requestData)
      .then((data) => {
        if (data.rejected) handleReject(data.rejected);
        dispatch(getInvitations(requestData.clinic_id));
      })
      .catch(() => dispatch(setLoading(false)))
      .finally(() => {dispatch(showModal(false)); setLoading(false);});
  };
};

function handleReject(data: any) {
  const list = data.list.map((item) => {
    return {
      email: item.email,
      country_code: item.country_code,
      phone_number: item.phone_number
    };
  });
  return InvitationsRejectedModal(list);
}
