import ConfirmCancelModal from 'components/ConfirmCancelModal';
import StickyButton from 'components/StickyButton';
import ViewDetailsButtonsGroup from 'components/ViewDetailsButtonsGroup';
import VLXModal from 'components/VLXModal';
import { useLocale } from 'hooks/useLocale';
import { useUserAccess } from 'hooks/useUserAccess';
import { IAppState } from 'interfaces/app-state';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { showModal } from 'redux/finances/financialAccounts/actions';

import AddFinancialAccountForm from './AddFinancialAccountForm';

const AddFinancialAccount = ({ hideDefaultCreateBtn, initialValues, form }: any): JSX.Element => {
  const dispatch = useDispatch();
  const userAccess = useUserAccess();
  const locale = useLocale('private.finances.financial-accounts');

  const [showCancelModal, setShowCancelModal] = useState<boolean>(false);
  const financialAccounts = useSelector<IAppState, any>(
    ({ financialAccounts }) => financialAccounts
  );

  const onConfirmCancelClick = (): void => {
    form.resetFields();
    dispatch(showModal(false));
    setShowCancelModal(false);
  };

  return (
    <>
      {!hideDefaultCreateBtn && (
        <StickyButton
          offsetBottom={10}
          onClick={() => dispatch(showModal(true))}
          iconElement={
            <span
              className='icofont icofont-plus mr-2 sticky-btn-icon'
              style={{ fontSize: '1.3em' }}
            />
          }
          textElement={locale.buttons.addAccount}
        />
      )}
      <VLXModal
        title={locale.labels.createAccount}
        centered={true}
        form={form}
        open={financialAccounts.showModal}
        onModalClose={() => dispatch(showModal(false))}
        width={800}
      >
        {financialAccounts.showModal && (
          <AddFinancialAccountForm locale={locale} form={form} initialValues={initialValues}>
            <ViewDetailsButtonsGroup
              fixed
              accessGroup={userAccess.accesses.services} // HERE ACCESS CHANGE
              editMode={true}
              hideDeleteBtn={true}
              onCancelClick={() => {
                if (form.isFieldsTouched()) {
                  setShowCancelModal(true);
                } else {
                  dispatch(showModal(false));
                }
              }}
              onDeleteClick={() => {
                setShowCancelModal(false);
              }}
            />
          </AddFinancialAccountForm>
        )}
        <ConfirmCancelModal
          open={showCancelModal}
          onOk={onConfirmCancelClick}
          onCancel={() => setShowCancelModal(false)}
        />
      </VLXModal>
    </>
  );
};

export default AddFinancialAccount;
