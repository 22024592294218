import { Menu } from 'antd';
import useClinicId from 'hooks/useClinicId';
import { useLocale } from 'hooks/useLocale';
import React, { useEffect, useState } from 'react';
import { NavLink, Outlet, useLocation } from 'react-router-dom';

const FinancesActions = () => {
  const clinicId = useClinicId();
  const params = useLocation().pathname.split('/')[4];
  const [current, setCurrent] = useState(params || '/');
  const locale = useLocale('private.finances.tabs');

  useEffect(() => {
    setCurrent(params?.length ? params : '/');
  }, [params]);

  const tabs = [
    {
      name: 'info',
      key: 'finances-dashboard',
      label: locale.tabs.dashboard,
      permission: true
    },
    {
      name: 'shifts',
      label: locale.tabs.shifts,
      key: 'shifts',
      permission: true // scopes.finances.showList
    },
    {
      name: 'bills',
      label: locale.tabs.bills,
      key: 'bills',
      permission: true // scopes.finances.showList
    },
    {
      name: 'payments',
      label: locale.tabs.payments,
      key: 'payments',
      permission: true // scopes.finances.showList
    },
    {
      name: 'financial-accounts',
      label: locale.tabs.financialAccounts,
      key: 'financial-accounts',
      permission: true // scopes.finances.showList
    }
  ];

  const generateOptions = () =>
    tabs.map((item) => {
      if (!item.permission) return null;
      return {
        key: item.name,
        label: <NavLink to={`/clinic/${clinicId}/finances/${item.name}`}>{item.label}</NavLink>
      };
    });

  return (
    <>
      <Menu
        selectedKeys={[current]}
        mode='horizontal'
        items={generateOptions()}
        style={{ marginBottom: '10px' }}
      />
      <Outlet />
    </>
  );
};

export default FinancesActions;
