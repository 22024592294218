import { ErrorBoundary } from '@highlight-run/react';
import * as Sentry from "@sentry/browser";
import { notification } from 'antd';
import { H } from 'highlight.run';
import { createHashHistory } from 'history';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { applyMiddleware, compose, createStore } from 'redux';
import thunk from 'redux-thunk';

import App from './App';
import { highlightProjectId, sentryDSN } from './config/config-manager';
import { rootReducer } from './redux/store';
import { interceptor } from './services/baseClient';

if (window.location.host !== 'localhost:3000') {
  H.init(highlightProjectId, {
    serviceName: 'frontend-app',
    tracingOrigins: ['api.dev.vetlyx.com/api', 'api.vetlyx.com/api', 'api.qa.vetlyx.com/api'],
    networkRecording: {
      enabled: true,
      recordHeadersAndBody: true,
      urlBlocklist: [
        // insert full or partial urls that you don't want to record here
        // Out of the box, Highlight will not record these URLs (they can be safely removed):
        'https://www.googleapis.com/identitytoolkit',
        'https://securetoken.googleapis.com'
      ]
    }
  });

  Sentry.init({ dsn: sentryDSN });
}

notification.config({
  placement: 'topRight',
  maxCount: 3,
  duration: 5,
  rtl: false
});

/* eslint-disable no-underscore-dangle */
const composeEnhancers: any = (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const store = createStore(rootReducer, composeEnhancers(applyMiddleware(thunk)));
/* eslint-enable */

export const history = createHashHistory();
interceptor(store);

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLDivElement);

root.render(
  <ErrorBoundary>
    <Provider store={store}>
      <App />
    </Provider>
  </ErrorBoundary>
);
