import './AppointmentServices.scss';

import { Button, Checkbox, Col, Divider, Form, FormInstance, Row, Select } from 'antd';
import ConfirmDeleteModal from 'components/ConfirmDeleteModal';
import VLXInputNumber from 'components/VLXInputNumber';
import useClinicId from 'hooks/useClinicId';
import { useI18n } from 'hooks/usei18n';
import { measurementsLocale } from 'i18n/measurements';
import { sales } from 'i18n/pages/sales';
import { StockSelect } from 'layout/components/stockSelect/StockSelect';
import React, { useEffect, useMemo, useState } from 'react';
import { getClinicStockById } from 'services/clinic/inventory/stockService';
import {
  ISelectedStock,
  ISelectedStockLocations,
  ISelectedStockPrices,
} from 'services/interfaces/clinics/inventory/stock/ISelectedStock';
import { IStockItem } from 'services/interfaces/clinics/inventory/stock/IStockItem';
import { requiredRule, validatorRule } from 'utils/form-rules';

const { Option } = Select;

type Props = {
  name: number;
  initialData: IStockItem;
  remove: (id) => void;
  form: FormInstance;
  setAddedItems: (items) => void;
  disabled: boolean;
};

export const AppointmentInventoryFormItem = ({
  name,
  initialData,
  remove,
  form,
  setAddedItems,
  disabled
}: Props): JSX.Element => {
  const clinicId = useClinicId();
  const locale = useI18n<typeof sales['ukUA']>(sales);

  const [stockDetails, setStockDetails] = useState<ISelectedStock>(null);
  const unitOfMeasurement = useI18n(measurementsLocale)[stockDetails?.unit_of_measurement];

  const [selectedLocation, setSelectedLocation] = useState(null);
  const [selectedPrice, setSelectedPrice] = useState(null);

  const isLocationResidualSmaller = selectedLocation?.residual < selectedPrice?.residual;
  const residualErrorMsg = isLocationResidualSmaller
    ? locale.messages.notEnoughInLocation
    : locale.messages.notEnoughWithPrice;

  const [initialResidual, setInitualResidual] = useState(0);
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
  const [includedInPrice, setIncludedInPrice] = useState(initialData?.included_in_service_price);
  const [priceInputDisabled, setPriceInputDisabled] = useState(!initialData?.quantity);
  useEffect((): void => {
    if (initialData) {
      getItemsForNewStock(initialData.stock.id);
    } else {
      setAddedItems((arr) => [...arr, name]);
    }
  }, []);

  const presetSaleSelection = (
    id: number,
    selectionId: number,
    saleSelection: ISelectedStockLocations[] | ISelectedStockPrices[]
  ): ISelectedStockLocations | ISelectedStockPrices => {
    const isEditedItem = id === stockDetails.id && selectionId;

    if (isEditedItem) return [...saleSelection]?.find((el) => el.id === selectionId);

    const selectionsWithResidual = [...saleSelection]?.filter((el) => el.residual && !el.deleted);

    if (selectionsWithResidual.length === 1) return selectionsWithResidual[0];

    return null;
  };

  useEffect((): void => {
    if (!stockDetails) return;

    const { stock, stock_price_id, stock_location_id } = initialData || { stock: {} };
    const price = presetSaleSelection(
      stock.id,
      stock_price_id,
      stockDetails.prices
    ) as ISelectedStockPrices;
    const location = presetSaleSelection(stock.id, stock_location_id, stockDetails.locations);

    setSelectedPrice(price);
    setSelectedLocation(location);

    form.setFieldValue(['stock', name, 'stock_price_id'], price?.id);
    form.setFieldValue(['stock', name, 'stock_location_id'], location?.id);

    if (location && price) {
      let quantity = 0;
      if (stock.id === stockDetails.id && initialData.id) {
        quantity = initialData.quantity;
      }
      setInitualResidual(Math.min(location.residual, price.residual) + quantity);

      if (initialData && !initialData.included_in_service_price) {
        form.setFieldValue(['stock', name, 'price'], initialData.quantity * price.price_per_unit);
      }
    }
  }, [stockDetails?.id]);

  const getItemsForNewStock = (id: number): void => {
    getClinicStockById(clinicId, id).then((response: ISelectedStock) => {
      setStockDetails(response);
    });
  };

  const onStockChange = (id: number): void => {
    getItemsForNewStock(id);
    form.setFieldValue(['stock', name, 'stock', 'id'], id);
    setPriceInputDisabled(true);

    ['quantity', 'stock_price_id', 'stock_location_id', 'price'].forEach((key) => {
      form.setFields([
        {
          name: ['stock', name, key],
          value: null,
          errors: []
        }
      ]);
    });
  };

  const onNewLocationSelect = (id: number): void => {
    const location = stockDetails?.locations.find((el) => el.id === id);
    const residual = Math.min(location.residual, selectedPrice?.residual);
    setSelectedLocation(location);
    onQuantityChange(selectedPrice);
    residualValidate(residual);
  };

  const onNewPriceSelect = (id: number): void => {
    const price = stockDetails?.prices.find((el) => el.id === id);
    const residual = Math.min(price.residual, selectedLocation?.residual);
    setSelectedPrice(price);
    onQuantityChange(price);
    residualValidate(residual);
  };

  const residualValidate = (residual: number): void => {
    setInitualResidual(residual);

    form.setFields([
      {
        name: ['stock', name, 'quantity'],
        errors: form.getFieldValue(['stock', name, 'quantity']) > residual ? [residualErrorMsg] : []
      }
    ]);
  };

  const quantityTooltip = (): string => {
    if (!(selectedLocation && selectedPrice)) return null;

    const remainingIn = locale.labels[isLocationResidualSmaller ? 'inLocation' : 'inPrice'];
    const formatLabel = `${initialResidual} ${unitOfMeasurement}`;

    return locale.labels.remainingTooltip.replace(/%1/, formatLabel).replace(/%2/, remainingIn);
  };

  const onQuantityChange = (newPrice?: ISelectedStockPrices): void => {
    const price = includedInPrice
      ? 0
      : (
          form.getFieldValue(['stock', name, 'quantity']) *
            (newPrice || selectedPrice)?.price_per_unit || 0
        ).toFixed(2);

    form.setFieldValue(['stock', name, 'price'], price);

    setPriceInputDisabled(false);
  };

  const locationSelectLabel = (item: ISelectedStockLocations): string => {
    const name = `${item.name === 'unassigned' ? locale.labels.unassigned : item.name}`;
    return `${name} | ${item.residual} ${unitOfMeasurement}`;
  };

  const locationsList = useMemo(
    (): JSX.Element[] =>
      stockDetails?.locations
        .filter((el) => !el.deleted || selectedLocation?.id === el.id)
        .sort((item) => (item.residual > 0 ? -1 : 1))
        .map((item) => (
          <Option
            className='item-form-select'
            key={item.id}
            disabled={item.residual <= 0}
            title={item.name}
            value={item.id}
          >
            {locationSelectLabel(item)}
          </Option>
        )),
    [stockDetails?.locations, selectedLocation]
  );

  const priceSelectLabel = (item: ISelectedStockPrices): string => {
    const defaultPrice = item.type === 'default' ? locale.labels.defaultPrice : '';
    const placeholder = `${locale.labels.uah} ${defaultPrice}| `;

    return `${item.price_per_unit} ${placeholder} ${item.residual} ${unitOfMeasurement}`;
  };

  const pricesList = useMemo(
    (): JSX.Element[] =>
      stockDetails?.prices
        ?.filter((el) => !el.deleted || selectedPrice?.id === el.id)
        .sort((item) => (item.residual > 0 ? -1 : 1))
        .map((item) => (
          <Option
            className='item-form-select'
            key={item.id}
            disabled={item.residual <= 0}
            title={item.id}
            value={item.id}
          >
            {priceSelectLabel(item)}
          </Option>
        )),
    [stockDetails?.prices, selectedPrice]
  );

  const onIncludedInPriceChange = (e): void => {
    const price = selectedPrice?.price_per_unit;
    const newPrice = e.target.checked
      ? 0
      : (form.getFieldValue(['stock', name, 'quantity']) || 0) * (price || 0);

    form.setFields([
      {
        name: ['stock', name, 'price'],
        value: newPrice.toFixed(2),
        errors: []
      }
    ]);
    setIncludedInPrice(e.target.checked);
  };

  return (
    <>
      <Row gutter={[20, 10]} className='appointment-inventory-items-form top-container'>
        <Row
          gutter={[24, 12]}
          className='appointment-inventory-items-form'
          style={{ width: '100%' }}
        >
          {/* STOCK SELECTOR */}
          <Col span={12} md={9} xs={24} xl={9}>
            <Form.Item
              key='stockId'
              label={locale.labels.name}
              rules={[requiredRule()]}
              className='long-value-form-item'
              name={[name, 'stock', 'id']}
            >
              <StockSelect
                clinicId={clinicId}
                onChange={onStockChange}
                selectedStockId={stockDetails?.id}
                locale={locale}
              />
            </Form.Item>
          </Col>
          {/* PRICE SELECTOR */}
          <Col span={9} md={8} xs={24} xl={8}>
            <Form.Item
              label={`${locale.labels.price} ${unitOfMeasurement || ''}`}
              rules={[requiredRule(locale.messages.priceShouldBeSelected)]}
              className='long-value-form-item'
              name={[name, 'stock_price_id']}
            >
              <Select
                disabled={disabled || !stockDetails}
                onSelect={onNewPriceSelect}
                value={selectedPrice?.id}
              >
                {pricesList}
              </Select>
            </Form.Item>
          </Col>
          {/* LOCATION SELECTOR*/}
          <Col span={7} md={7} xs={24} xl={7}>
            <Form.Item
              label={locale.labels.location}
              rules={[requiredRule(locale.messages.locationShouldBeSelected)]}
              className='long-value-form-item'
              name={[name, 'stock_location_id']}
            >
              <Select
                disabled={disabled || !stockDetails}
                onSelect={onNewLocationSelect}
                value={selectedLocation?.id}
              >
                {locationsList}
              </Select>
            </Form.Item>
          </Col>
        </Row>
        {/* QUANTITY */}
        <Row
          gutter={[24, 12]}
          className='appointment-inventory-items-form'
          style={{ width: '100%' }}
        >
          <Col span={13} md={7} sm={12} xs={24}>
            <Form.Item
              label={locale.labels.quantity}
              name={[name, 'quantity']}
              rules={[
                requiredRule(),
                validatorRule((value) => value <= initialResidual, residualErrorMsg)
              ]}
              extra={quantityTooltip()}
            >
              <VLXInputNumber
                disabled={disabled || (!selectedPrice || !selectedLocation)}
                onChange={() => onQuantityChange()}
                precision={3}
                step={0.1}
              />
            </Form.Item>
          </Col>
          {/* PRICE */}
          <Col span={13} md={7} sm={12} xs={24}>
            <Form.Item
              label={locale.labels.sum}
              rules={!includedInPrice ? [requiredRule()] : []}
              name={[name, 'price']}
            >
              <VLXInputNumber disabled={priceInputDisabled || includedInPrice} precision={2} />
            </Form.Item>
          </Col>
          <Col span={7} sm={21} md={7} xs={19} style={{ display: 'flex' }}>
            <Form.Item
              className={'flat-checkbox-form-item'}
              label={locale.labels.includedInServicePrice}
              name={[name, 'included_in_service_price']}
              valuePropName='checked'
            >
              <Checkbox
                disabled={initialData ? disabled : priceInputDisabled}
                onChange={onIncludedInPriceChange}
                checked={includedInPrice}
              />
            </Form.Item>
          </Col>
          {/* REMOVE BUTTON */}
          <Col span={2} md={2} sm={3} xs={5}>
            <Button
              onClick={() => {
                setShowDeleteModal(true);
              }}
              danger
              disabled={disabled}
              type='primary'
              shape={'circle'}
              className={'inventory-update-buttons'}
              style={{ minHeight: '40px', minWidth: '40px' }}
            >
              <span className='icofont icofont-trash' />
            </Button>
          </Col>
        </Row>
        <ConfirmDeleteModal
          open={showDeleteModal}
          onOk={() => remove(name)}
          onCancel={() => setShowDeleteModal(false)}
        />
      </Row>
      <Divider style={{ marginTop: '5px', marginBottom: '5px' }} />
    </>
  );
};
