import { StockCategoriesActions, GET_STOCK_CATEGORIES, RESET, SET_LOADING, SET_SEARCH_QUERY, SHOW_MODAL } from './types';
import {
  IndexDictionaryRecordsResponse
} from '../../../services/clinic/dictionaries/models/IndexDictionaryRecordsResponse';

export interface StockCategoriesState extends IndexDictionaryRecordsResponse {
  loading: boolean
  showModal: boolean
  searchQuery: any[]
}

const initialState: StockCategoriesState = {
  data: [],
  metadata: {
    total: 0,
    page: 1,
    next: null,
    last: 1,
    prev: null
  },
  loading: false,
  showModal: false,
  searchQuery: []
};

export function stockCategoriesReducer (
  state: StockCategoriesState = initialState,
  action: StockCategoriesActions
): any {
  switch (action.type) {
    case GET_STOCK_CATEGORIES: {
      return {
        ...state,
        ...action.payload
      };
    }

    case RESET: {
      return initialState;
    }

    case SHOW_MODAL: {
      return {
        ...state,
        showModal: action.payload
      };
    }

    case SET_LOADING: {
      return {
        ...state,
        loading: action.payload
      };
    }

    case SET_SEARCH_QUERY: {
      return {
        ...state,
        searchQuery: action.payload
      };
    }

    default: {
      return state;
    }
  }
}
