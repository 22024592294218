import '../../modals/finances/addBill/AddBill.scss';
import './ViewEditBillForm.scss';

import { Col, Divider, Table, TableColumnProps, Typography } from 'antd';
import animals from 'constants/dictionary/animals';
import { animalSex } from 'constants/dictionary/default/selectOptions';
import { money } from 'helpers/list-helpers';
import useClinicId from 'hooks/useClinicId';
import { useLocale } from 'hooks/useLocale';
import useLocalizedList from 'hooks/useLocalizedList';
import { measurementsLocale } from 'i18n/measurements';
import { IAppState } from 'interfaces/app-state';
import { IService } from 'interfaces/services';
import { getAnimalAge } from 'pages/patients/helpers/PatientHelper';
import React, { ReactNode, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

const UsedStockItemsTable = ({ item, expandTableRows, showFullPatientInfo }: any): JSX.Element => {
  const clinicId = useClinicId();
  const [currentExpanded, setCurrentExpended] = useState([]);
  const locale = useLocale('private.appointments.appointment-bill')?.labels;
  const currencies = useLocale('private.currencies').labels;
  const localizedSpecies = useLocalizedList(animals);
  const patientLocale = useLocale('private.patients.patient-page');
  const localeCode = useSelector(({ localeCode }: IAppState) => localeCode);
  const navigate = useNavigate();

  const getLocalisedLabel = (
    locale: Record<string, any>[],
    value: string | boolean,
    localeCode: string
  ): string => locale.find((el) => el.value === value)?.[localeCode] ?? '';
  const petSex = useMemo(() => getLocalisedLabel(animalSex, item.sex, localeCode), []);

  const columns: Array<TableColumnProps<any>> = [
    {
      key: 'name',
      title: locale.serviceName,
      render: (service, e) => `${service.name}`
    },
    {
      key: 'service_price',
      title: `${locale.servicePrice}`,
      render: (service, e) => `${money(service.price)} ${currencies.uah}`
    },
    {
      key: 'stock_items_price',
      title: `${locale.stockItemsPrice}`,
      render: (service, e) => `${servicePrice(service).stockItemsPrice} ${currencies.uah}`
    },
    {
      key: 'price',
      title: `${locale.totalPrice}`,
      render: (service, e) => `${servicePrice(service).totalPrice} ${currencies.uah}`
    }
  ];

  const stockQuantity = (items: any) => {
    let quantity = 0;
    items.forEach((item: any) => {
      quantity += item.quantity;
    });
    return quantity;
  };

  const getStockPrice = (items: any) => {
    let price = 0;
    items.forEach((item: any) => {
      price += item.price;
    });
    return price;
  };

  const servicePrice = (service: any) => {
    let stockPrice = 0;
    service.stock.forEach((item: any) => {
      item.items.forEach((stockItem: any) => {
        stockPrice += stockItem.price;
      });
    });

    return {
      servicePrice: service.price.toFixed(2),
      stockItemsPrice: stockPrice.toFixed(2),
      totalPrice: (service.price + stockPrice).toFixed(2)
    };
  };

  const stockRowRender = (record: IService): ReactNode => {
    if (showFullPatientInfo && !record.stock.length) return;

    const stockColumns = [
      {
        key: 'name',
        title: locale.stockName,
        render: (stock) => stock.name
      },
      {
        key: 'quantity',
        title: locale.stockQuantity,
        render: (stock) =>
          `${stockQuantity(stock.items)} ${
            measurementsLocale[localeCode][stock?.unit_of_measurement]
          }`
      },
      {
        key: 'price',
        title: `${locale.price}`,
        render: (stock) => `${getStockPrice(stock.items).toFixed(2)} ${currencies.uah}`
      }
    ];

    return (
      <Table
        rowKey='id'
        columns={stockColumns}
        dataSource={record.stock}
        pagination={false}
        size='small'
        className='client-table'
        bordered
      />
    );
  };

  const getAnimalSpecies = (label: string): string => {
    const species = localizedSpecies.find((el) => el.value === label);
    return species?.label || '';
  };

  const getAge = (date: string): string => (date ? `, ${getAnimalAge(patientLocale, date)}` : '');

  const getExpandable = () => {
    if (expandTableRows) {
      return {
        defaultExpandAllRows: true,
        expandedRowRender: stockRowRender,
        showExpandColumn: false
      };
    } else {
      return {
        onExpand: (expanded, record) => {
          let current = [];
          if (expanded) {
            current = [`${record.id}-${item.id}`, ...currentExpanded];
          } else {
            current = currentExpanded.filter((el) => el !== `${record.id}-${item.id}`);
          }
          setCurrentExpended(current);
        },
        expandedRowKeys: currentExpanded,
        expandedRowRender: stockRowRender
      };
    }
  };

  const getPatientInfo = () => {
    if (showFullPatientInfo) {
      return `${item.name} (${getAnimalSpecies(item?.species)}) ${petSex} ${getAge(
        item?.birth_date
      )}`;
    }
    return (
      <>
        <Typography.Link
          className='custom-links'
          id='patient-name-species'
          onClick={() => navigate(`/clinic/${clinicId}/patients/${item?.id}`)}
        >{`${item.name} (${getAnimalSpecies(item?.species)})`}</Typography.Link>
        {getAge(item?.birth_date)}
      </>
    );
  };

  return (
    <>
      <div className='card-content' style={{ marginBottom: '5px' }}>
        <Col>
          {item?.name && (
            <>
              <span style={{ fontWeight: '600' }}>{`${locale.patient}: `}</span>
              {getPatientInfo()}
            </>
          )}
        </Col>
      </div>
      <Table
        key={item.id}
        pagination={false}
        className='accent-header clients-list-table'
        rowKey={(record, index) => `${record.id}-${item.id}`}
        dataSource={item.services}
        columns={columns}
        expandable={getExpandable()}
      />
      {!expandTableRows && <Divider />}
    </>
  );
};

export default UsedStockItemsTable;
