import ConfirmCancelModal from 'components/ConfirmCancelModal';
import ConfirmDeleteModal from 'components/ConfirmDeleteModal';
import ViewDetailsButtonsGroup from 'components/ViewDetailsButtonsGroup';
import VLXModal from 'components/VLXModal';
import { useUserAccess } from 'hooks/useUserAccess';
import { IAppState } from 'interfaces/app-state';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { removePayment, showModal } from 'redux/finances/payments/actions';

import AddPaymentForm from './AddPaymentForm';

const EditPayment = ({ initialValues, form, locale }: any) => {
  const dispatch = useDispatch();
  const [showCancelModal, setShowCancelModal] = useState<boolean>(false);
  const payments = useSelector<IAppState, any>((state) => state.payments);
  const userAccess = useUserAccess();
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
  const [formIsTouched, setFormIsTouched] = useState(false);
  const { clinicId, paymentId }: any = useParams();
  const navigate = useNavigate();

  const onConfirmCancelClick = () => {
    form.resetFields();
    dispatch(showModal(false));
    setShowCancelModal(false);
    setFormIsTouched(false);
  };

  const onConfirmDeleteClick = () => {
    setShowDeleteModal(false);
    dispatch(showModal(false));
    setFormIsTouched(false);
    dispatch(removePayment(clinicId, paymentId));
    navigate(`/clinic/${clinicId}/finances/payments`);
  };

  return (
    <>
      {initialValues && (
        <VLXModal
          formIsTouched={formIsTouched}
          title={locale.labels.editPayment}
          centered={true}
          form={form}
          open={payments.showModal}
          onModalClose={() => {
            dispatch(showModal(false));
            setFormIsTouched(false);
          }}
          width={800}
        >
          <AddPaymentForm
            locale={locale}
            form={form}
            initialValues={initialValues}
            formEditMode
            setFormIsTouched={setFormIsTouched}
          >
            <ViewDetailsButtonsGroup
              fixed
              hideDeleteBtn
              accessGroup={userAccess.accesses.services}
              editMode
              onCancelClick={() => {
                if (form.isFieldsTouched() || formIsTouched) {
                  setShowCancelModal(true);
                } else {
                  dispatch(showModal(false));
                }
              }}
              onDeleteClick={() => {
                setShowCancelModal(false);
                setShowDeleteModal(true);
              }}
            />
          </AddPaymentForm>
        </VLXModal>
      )}
      <ConfirmCancelModal
        open={showCancelModal}
        onOk={onConfirmCancelClick}
        onCancel={() => setShowCancelModal(false)}
      />
      <ConfirmDeleteModal
        type={'device'}
        open={showDeleteModal}
        onOk={onConfirmDeleteClick}
        onCancel={() => setShowDeleteModal(false)}
      />
    </>
  );
};

export default EditPayment;
