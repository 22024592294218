import '../assets/scss/select.scss';

import { Select } from 'antd';
import { useLocale } from 'hooks/useLocale';
import { IAppState } from 'interfaces/app-state';
import debounce from 'lodash/debounce';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createSkill, getSkillsAction } from 'redux/skills/actions';

const MultiSkillsSelect = ({
  user,
  editMode,
  selectedItems,
  setSelectedItems,
  onChangeFieldValue,
  setInitialSelectedItemsData
}: any) => {
  const dispatch = useDispatch();
  const skills = useSelector<IAppState, any>((state) => state.skills);
  const locale = useLocale('private.user.user-profile');

  useEffect(() => {
    setSelectedItems(user.skills.map((skill) => skill.name));
    if (setInitialSelectedItemsData) {
      setInitialSelectedItemsData(user.skills.map((skill) => skill.name));
    }
  }, [user]);

  function onCh(items) {
    setSelectedItems(items);
    if (onChangeFieldValue) {
      onChangeFieldValue();
    }
  }

  function handleSearch(newValue: string) {
    if (newValue.length === 0) return;
    const searchParams = [];
    searchParams.push({
      name: 'name',
      value: newValue
    });
    dispatch(getSkillsAction(1, searchParams));
  }

  function onSelect(value) {
    const exists = skills.skills.some((o) => o.name === value);
    if (!exists) {
      dispatch(createSkill({ name: value }));
    }
  }

  return (
    <Select
      className='skills-tags-select'
      mode='tags'
      placeholder={locale.labels.skills}
      value={selectedItems}
      onSelect={onSelect}
      disabled={!editMode}
      onSearch={debounce(handleSearch, 500)}
      onChange={onCh}
      tokenSeparators={[',']}
      options={skills.skills
        .filter((o) => !selectedItems.includes(o.name))
        .map((item) => ({
          value: item.name,
          label: item.name
        }))}
    />
  );
};

export default MultiSkillsSelect;
