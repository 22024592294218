import './Actions.scss';

import { IAppState } from 'interfaces/app-state';
import React from 'react';
import { useSelector } from 'react-redux';

import { HeaderButtons } from './HeaderButtons';
import SettingsDropdown from './SettingsDropdown';

export const Actions = (): JSX.Element => {
  const loggedIn = useSelector(({ user }: IAppState) => user.id);

  return loggedIn ? (
    <>
      <SettingsDropdown />
    </>
  ) : (
    <HeaderButtons containerClass='elem-list hide-on-mobile' />
  );
};
